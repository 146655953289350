import { handleActions } from "redux-actions";
import {
  QualityCheckApplyFiles,
  QualityCheckGetFiles,
  QualityCheckViewerVisibility,
  QualityCheckDeleteFile,
  QualityCheckLocalUpdatesOnFiles,
  QualityCheckFileExtractions,
  QualityCheckSaveChangesAndApply,
  QualityCheckRegisterLinkLocalUpdate,
  QualityCheckValidateLink,
  QualityCheckLinkRecurringCycle,
  QualityCheckSyncOnDemand,
  QualityCheckGetRegisterLinkData,
  QualityCheckGenerateReport,
  QualityCheckMetadataList,
  QualityCheckMetadataLocal,
  QualityCheckGetMetadata,
  QualityCheckGetNameDetails,
  QualityCheckCreateNameDetails,
  QualityCheckUpdateNameDetails,
  QualityCheckDeleteNameDetails,
  QualityCheckAddDetailsLocal,
  QualityCheckCreateMetadata,
  QualityCheckUpdateMetadata,
  QualityCheckDeleteMetadata,
  QualityCheckGetImportDataOptions,
  QualityCheckImportData,
  QualityCheckRevalidate,
} from "../actions/qualityCheck";
import { AsyncStates } from "src/constants";

type registerLinkDataType = {
  link_type: 'external' | 'internal';
  drive_type: 'azure' | 'google_drive' | 'onedrive' | 'dropbox' | 'box';
  link: string;
  validation_status: 'pending' | 'success' | 'link_error' | 'access_error';
  is_recurring: boolean;
  recurring_type: 'daily' | 'weekly' | 'monthly';
  recurring_time: string;
  recurring_days_of_week: string[];
  recurring_days_of_month: string[];
}

export const registerDataInitialState: registerLinkDataType = {
  link_type: "external",
  drive_type: "azure",
  link: "",
  validation_status: "pending",
  is_recurring: false,
  recurring_type: "daily",
  recurring_time: "00:00",
  recurring_days_of_week: ["monday"],
  recurring_days_of_month: ["1"],
}

export const initialMetadataStepErrorsState = {
  add_details: false,
    metadata_mapping: false,
    test_title_mapping: false,
    input_criteria: false
}

export type QualityCheckState = {
  fileNavigationOpen?: boolean;
  selectedFile?: any;
  pageNumber?: number;
  isReportSectionUnsaved?: boolean;
  registerLinkModalVisible?: boolean;
  fileDrawerVisible?: boolean;
  fileUploadModalVisible?: boolean;

  getFilesData?: any[];
  getFilesTotal?: number;
  getFilesStatusCount?: any;

  getFilesDataStatus?: AsyncStates;
  getFilesDataError?: any;
  getFilesPageNumber: number;
  getFilesPageSize: number;
  getFilesFilters?: any;
  getFilesSerachTerm?: string;

  applyFilesStatus?: AsyncStates;
  applyFilesError?: any;
  applyFilesResponse?: any;

  fileExtractionsStatus?: AsyncStates;
  fileExtractionsError?: any;
  fileExtractionsResponse?: any;

  saveChangedAndApplyStatus?: AsyncStates;
  saveChangedAndApplyError?: any;
  saveChangedAndApplyResponse?: any;

  deleteFileStatus?: AsyncStates;
  deleteFileError?: any;
  deleteFileResponse?: any;

  registerLinkData: registerLinkDataType;

  getRegisterLinkStatus?: AsyncStates;
  getRegisterLinkError?: any;

  validateLinkStatus?: AsyncStates;
  validateLinkError?: any;
  validateLinkResponse?: any;

  linkRecurringCycleStatus?: AsyncStates;
  linkRecurringCycleError?: any;
  linkRecurringCycleResponse?: any;

  syncOnDemandStatus?: AsyncStates;
  syncOnDemandError?: any;
  syncOnDemandResponse?: any;

  filesToReport?: any;
  reportData?: any;
  reportStatus?: AsyncStates;
  reportError?: any;
  isSelectAllFiles?: boolean;
  filesToIgnore?: any[];

  revalidateStatus?: AsyncStates;
  revalidateError?: any;
  revalidateResponse?: any;


  // Metadata
  metadataList: any[];
  metadataListTotal: number;
  metadataListStatus: AsyncStates;
  metadataListError: any;

  metadataPageNumber: number;
  metadataPageSize: number;
  metadataFilters: any;
  metadataSearchTerm: string;

  metadataEditDrawerVisible: boolean;

  metadataStepErrors?: any;

  getMetadataResponse?: any;
  getMetadataStatus?: AsyncStates;
  getMetadataError?: any;

  createMetadataResponse?: any;
  createMetadataStatus?: AsyncStates;
  createMetadataError?: any;

  updateMetadataResponse?: any;
  updateMetadataStatus?: AsyncStates;
  updateMetadataError?: any;

  deleteMetadataResponse?: any;
  deleteMetadataStatus?: AsyncStates;
  deleteMetadataError?: any;

  // Name details
  getNameDetailsResponse?: any;
  getNameDetailsStatus?: AsyncStates;
  getNameDetailsError?: any;

  createNameDetailsResponse?: any;
  createNameDetailsStatus?: AsyncStates;
  createNameDetailsError?: any;

  updateNameDetailsResponse?: any;
  updateNameDetailsStatus?: AsyncStates;
  updateNameDetailsError?: any;

  deleteNameDetailsResponse?: any;
  deleteNameDetailsStatus?: AsyncStates;
  deleteNameDetailsError?: any;

  importDataOptionsResponse?: any[];
  importDataOptionsStatus?: AsyncStates;
  importDataOptionsError?: any;

  importDataResponse?: any;
  importDataStatus?: AsyncStates;
  importDataError?: any;

  addDetails?: any;
};

const defaultState: QualityCheckState = {
  fileNavigationOpen: true,
  selectedFile: {},
  pageNumber: 1,
  isReportSectionUnsaved: false,
  registerLinkModalVisible: false,
  fileDrawerVisible: false,
  fileUploadModalVisible: false,

  getFilesData: [],
  getFilesTotal: 0,
  getFilesStatusCount:{},
  getFilesDataStatus: AsyncStates.INITIAL,
  getFilesDataError: null,
  getFilesPageNumber: 1,
  getFilesPageSize: 20,
  getFilesFilters:{
    suppliers:[],
    categories:[],
    product_name: [],
    batch_number: '',
    status: [],
    created_at: null,
    production_date: null,
    operator: 'and'
  },
  getFilesSerachTerm:'',

  applyFilesStatus: AsyncStates.INITIAL,
  applyFilesError: null,
  applyFilesResponse: null,

  fileExtractionsStatus: AsyncStates.INITIAL,
  fileExtractionsError: null,
  fileExtractionsResponse: null,

  saveChangedAndApplyStatus: AsyncStates.INITIAL,
  saveChangedAndApplyError: null,
  saveChangedAndApplyResponse: null,

  deleteFileStatus: AsyncStates.INITIAL,
  deleteFileError: null,
  deleteFileResponse: null,

  registerLinkData: registerDataInitialState,

  getRegisterLinkStatus: AsyncStates.INITIAL,
  getRegisterLinkError: null,

  validateLinkStatus: AsyncStates.INITIAL,
  validateLinkError: null,
  validateLinkResponse: null,

  linkRecurringCycleStatus: AsyncStates.INITIAL,
  linkRecurringCycleError: null,
  linkRecurringCycleResponse: null,

  syncOnDemandStatus: AsyncStates.INITIAL,
  syncOnDemandError: null,
  syncOnDemandResponse: null,

  filesToReport: {1:[]},
  reportData: null,
  reportStatus: AsyncStates.INITIAL,
  reportError: null,
  isSelectAllFiles: false,
  filesToIgnore: [],

  // Metadata
  metadataList: [],
  metadataListTotal: 0,
  metadataListStatus: AsyncStates.INITIAL,
  metadataListError: null,
  metadataPageNumber: 1,
  metadataPageSize: 20,
  metadataFilters: {
    categories: [],
    suppliers: [],
    product_name: [],
    updated_at: null,
    operator: 'and',
    is_draft: null
  },
  metadataSearchTerm: '',

  metadataEditDrawerVisible: false,

  metadataStepErrors: initialMetadataStepErrorsState,

  getMetadataResponse: null,
  getMetadataStatus: AsyncStates.INITIAL,
  getMetadataError: null,

  createMetadataResponse: null,
  createMetadataStatus: AsyncStates.INITIAL,
  createMetadataError: null,

  updateMetadataResponse: null,
  updateMetadataStatus: AsyncStates.INITIAL,
  updateMetadataError: null,

  deleteMetadataResponse: null,
  deleteMetadataStatus: AsyncStates.INITIAL,
  deleteMetadataError: null,

  // Name details
  getNameDetailsResponse: null,
  getNameDetailsStatus: AsyncStates.INITIAL,
  getNameDetailsError: null,

  createNameDetailsResponse: null,
  createNameDetailsStatus: AsyncStates.INITIAL,
  createNameDetailsError: null,

  updateNameDetailsResponse: null,
  updateNameDetailsStatus: AsyncStates.INITIAL,
  updateNameDetailsError: null,

  deleteNameDetailsResponse: null,
  deleteNameDetailsStatus: AsyncStates.INITIAL,
  deleteNameDetailsError: null,

  importDataOptionsResponse: [],
  importDataOptionsStatus: AsyncStates.INITIAL,
  importDataOptionsError: null,

  importDataResponse: null,
  importDataStatus: AsyncStates.INITIAL,
  importDataError: null,

  addDetails: {
    supplier_id: '',
    product_id: '',
    category_id: '',
  },

}

const qualityCheckReducer = handleActions({
  [QualityCheckViewerVisibility.QC_TOGGLE_FILE_NAVIGATION]: (state: QualityCheckState) => {
    return {
      ...state,
      fileNavigationOpen: !state.fileNavigationOpen,
    }
  },
  [QualityCheckViewerVisibility.QC_SET_SELECTED_FILE]: (state: QualityCheckState, action: any) => {
    const file_id = action.payload
    const selectedFile = state.getFilesData?.find((f: any) => String(f.file_id) === file_id)
    return {
      ...state,
      selectedFile: selectedFile,
    }
  },
  [QualityCheckViewerVisibility.QC_SET_PAGE_NUMBER]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      pageNumber: action.payload,
    }
  },
  [QualityCheckViewerVisibility.QC_SET_IS_REPORT_SECTION_UNSAVED]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      isReportSectionUnsaved: action.payload,
    }
  },
  [QualityCheckViewerVisibility.QC_SET_REGISTER_LINK_MODAL_VISIBLE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      registerLinkModalVisible: action.payload,
    }
  },
  [QualityCheckViewerVisibility.QC_SET_FILE_DRAWER_VISIBLE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      fileDrawerVisible: action.payload,
    }
  },
  [QualityCheckViewerVisibility.QC_SET_FILE_UPLOAD_MODAL_VISIBLE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      fileUploadModalVisible: action.payload,
    }
  },
  [QualityCheckViewerVisibility.QC_SET_SELECTED_FILES_FOR_REPORT]: (state: QualityCheckState, action: any) => {
    const selectedFilesCurrentPage = { [state.getFilesPageNumber]: action.payload }
    return {
      ...state,
      filesToReport: {
        ...state.filesToReport,
        ...selectedFilesCurrentPage
      },
      // isSelectAllFiles: false,
    }
  },
  [QualityCheckViewerVisibility.QC_CLEAR_SELECTED_FILES_FOR_REPORT]: (state: QualityCheckState) => {
    return {
      ...state,
      filesToReport: {1:[]},
    }
  },
  [QualityCheckViewerVisibility.QC_SET_GET_FILES_PAGE_NUMBER]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getFilesPageNumber: action.payload,
      ...(state.isSelectAllFiles ? {filesToReport: {[action.payload]: state.getFilesData?.map((file:any)=>file?.file_id) }} : {}),
    }
  },
  [QualityCheckViewerVisibility.QC_SET_GET_FILES_FILTERS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getFilesFilters: {...state.getFilesFilters,...action.payload},
    }
  },
  [QualityCheckViewerVisibility.QC_SET_GET_FILES_SEARCH_TERM]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getFilesSerachTerm: action.payload,
    }
  },
  [QualityCheckViewerVisibility.QC_SET_SELECT_ALL_FILES]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      isSelectAllFiles: action.payload,
      ...(action.payload ? { filesToIgnore:[] } : {filesToReport: {1:[]}}),
    }
  },
  [QualityCheckViewerVisibility.QC_ADD_IGNORE_FILES_FOR_REPORT]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      filesToIgnore: [...new Set([...(state?.filesToIgnore || []), action.payload])],
    }
  },
  [QualityCheckViewerVisibility.QC_REMOVE_IGNORE_FILES_FOR_REPORT]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      filesToIgnore: state?.filesToIgnore?.filter((file_id) => file_id !== action.payload),
    }
  },


  [QualityCheckGetFiles.QC_GET_FILES_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      getFilesDataStatus: AsyncStates.LOADING,
    }
  },

  [QualityCheckGetFiles.QC_GET_FILES_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getFilesData: action.payload.data,
      getFilesTotal: action.payload.total,
      getFilesStatusCount: {
        not_scanned: action.payload.not_scanned || 0,
        passed: action.payload.passed || 0,
        failed: action.payload.failed || 0,
        alert: action.payload.alert || 0,
        in_progress: action.payload.in_progress || 0,
        passed_with_remarks: action.payload.passed_with_remarks || 0
      },
      getFilesDataStatus: AsyncStates.SUCCESS,
    }
  },

  [QualityCheckGetFiles.QC_GET_FILES_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getFilesDataStatus: AsyncStates.ERROR,
      getFilesDataError: action.payload,
      getFilesPageNumber:1
    }
  },

  [QualityCheckGetFiles.QC_GET_FILES_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      getFilesData: [],
      getFilesTotal: 0,
      getFilesStatusCount: {},
      getFilesDataStatus: AsyncStates.INITIAL,
      getFilesDataError: null,
    }
  },

  [QualityCheckApplyFiles.QC_APPLY_FILES_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      applyFilesStatus: AsyncStates.LOADING,
    }
  },

  [QualityCheckApplyFiles.QC_APPLY_FILES_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      applyFilesStatus: AsyncStates.SUCCESS,
      applyFilesResponse: action.payload,
    }
  },

  [QualityCheckApplyFiles.QC_APPLY_FILES_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      applyFilesStatus: AsyncStates.ERROR,
      applyFilesError: action.payload,
    }
  },

  [QualityCheckApplyFiles.QC_APPLY_FILES_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      applyFilesStatus: AsyncStates.INITIAL,
      applyFilesError: null,
      applyFilesResponse: null,
    }
  },


  [QualityCheckFileExtractions.QC_FILE_EXTRACTIONS_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      fileExtractionsStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckFileExtractions.QC_FILE_EXTRACTIONS_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      fileExtractionsStatus: AsyncStates.SUCCESS,
      fileExtractionsResponse: action.payload,
    }
  },
  [QualityCheckFileExtractions.QC_FILE_EXTRACTIONS_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      fileExtractionsStatus: AsyncStates.ERROR,
      fileExtractionsError: action.payload,
    }
  },
  [QualityCheckFileExtractions.QC_FILE_EXTRACTIONS_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      fileExtractionsStatus: AsyncStates.INITIAL,
      fileExtractionsError: null,
      fileExtractionsResponse: null,
    }
  },

  [QualityCheckSaveChangesAndApply.QC_SAVE_CHANGES_AND_APPLY_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      saveChangedAndApplyStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckSaveChangesAndApply.QC_SAVE_CHANGES_AND_APPLY_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      saveChangedAndApplyStatus: AsyncStates.SUCCESS,
      saveChangedAndApplyResponse: action.payload,
      selectedFile: { ...state.selectedFile, qc_details: { ...action.payload } },
      getFilesData: state.getFilesData?.map((file: any) => {
        if (file.file_id === state.selectedFile?.file_id) {
          return {
            ...file,
            qc_details: { ...action.payload }
          }
        }
        return file
      })
    }
  },
  [QualityCheckSaveChangesAndApply.QC_SAVE_CHANGES_AND_APPLY_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      saveChangedAndApplyStatus: AsyncStates.ERROR,
      saveChangedAndApplyError: action.payload,
    }
  },
  [QualityCheckSaveChangesAndApply.QC_SAVE_CHANGES_AND_APPLY_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      saveChangedAndApplyStatus: AsyncStates.INITIAL,
      saveChangedAndApplyError: null,
      saveChangedAndApplyResponse: null,
    }
  },

  [QualityCheckDeleteFile.QC_DELETE_FILE_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      deleteFileStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckDeleteFile.QC_DELETE_FILE_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      deleteFileStatus: AsyncStates.SUCCESS,
      deleteFileResponse: action.payload,
      isSelectAllFiles: false,
    }
  },
  [QualityCheckDeleteFile.QC_DELETE_FILE_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      deleteFileStatus: AsyncStates.ERROR,
      deleteFileError: action.payload,
    }
  },
  [QualityCheckDeleteFile.QC_DELETE_FILE_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      deleteFileStatus: AsyncStates.INITIAL,
      deleteFileError: null,
      deleteFileResponse: null,
    }
  },

  [QualityCheckRegisterLinkLocalUpdate.QC_REGISTER_LINK_LOCAL_UPDATE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      registerLinkData: {
        ...state.registerLinkData,
        ...action.payload,
      }
    }
  },

  [QualityCheckGetRegisterLinkData.QC_GET_REGISTER_LINK_DATA_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      getRegisterLinkStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckGetRegisterLinkData.QC_GET_REGISTER_LINK_DATA_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getRegisterLinkStatus: AsyncStates.SUCCESS,
      registerLinkData: action.payload,
    }
  },
  [QualityCheckGetRegisterLinkData.QC_GET_REGISTER_LINK_DATA_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getRegisterLinkStatus: AsyncStates.ERROR,
      getRegisterLinkError: action.payload,
    }
  },
  [QualityCheckGetRegisterLinkData.QC_GET_REGISTER_LINK_DATA_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      getRegisterLinkStatus: AsyncStates.INITIAL,
      getRegisterLinkError: null,
    }
  },

  [QualityCheckValidateLink.QC_VALIDATE_LINK_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      validateLinkStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckValidateLink.QC_VALIDATE_LINK_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      validateLinkStatus: AsyncStates.SUCCESS,
      validateLinkResponse: action.payload,
    }
  },
  [QualityCheckValidateLink.QC_VALIDATE_LINK_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      validateLinkStatus: AsyncStates.ERROR,
      validateLinkError: action.payload,
    }
  },
  [QualityCheckValidateLink.QC_VALIDATE_LINK_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      validateLinkStatus: AsyncStates.INITIAL,
      validateLinkError: null,
      validateLinkResponse: null,
    }
  },


  [QualityCheckLinkRecurringCycle.QC_LINK_RECURRING_CYCLE_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      linkRecurringCycleStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckLinkRecurringCycle.QC_LINK_RECURRING_CYCLE_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      linkRecurringCycleStatus: AsyncStates.SUCCESS,
      linkRecurringCycleResponse: action.payload,
    }
  },
  [QualityCheckLinkRecurringCycle.QC_LINK_RECURRING_CYCLE_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      linkRecurringCycleStatus: AsyncStates.ERROR,
      linkRecurringCycleError: action.payload,
    }
  },
  [QualityCheckLinkRecurringCycle.QC_LINK_RECURRING_CYCLE_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      linkRecurringCycleStatus: AsyncStates.INITIAL,
      linkRecurringCycleError: null,
      linkRecurringCycleResponse: null,
    }
  },

  [QualityCheckSyncOnDemand.QC_SYNC_ON_DEMAND_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      syncOnDemandStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckSyncOnDemand.QC_SYNC_ON_DEMAND_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      syncOnDemandStatus: AsyncStates.SUCCESS,
      syncOnDemandResponse: action.payload,
    }
  },
  [QualityCheckSyncOnDemand.QC_SYNC_ON_DEMAND_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      syncOnDemandStatus: AsyncStates.ERROR,
      syncOnDemandError: action.payload,
    }
  },
  [QualityCheckSyncOnDemand.QC_SYNC_ON_DEMAND_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      syncOnDemandStatus: AsyncStates.INITIAL,
      syncOnDemandError: null,
      syncOnDemandResponse: null,
    }
  },


  [QualityCheckLocalUpdatesOnFiles.QC_LOCAL_UPDATE_FILE]: (state: QualityCheckState, action: any) => {
    const { file_id, to_update } = action.payload
    const updatedFiles = state?.getFilesData?.map((file: any) => {
      if (file.file_id === file_id) {
        return {
          ...file,
          ...to_update,
        }
      }
      return file
    })

    let updatedSelectedFile = state.selectedFile
    if (state.selectedFile?.file_id === file_id) {
      updatedSelectedFile = {
        ...updatedSelectedFile,
        ...to_update,
      }
    }

    return {
      ...state,
      getFilesData: updatedFiles,
      selectedFile: updatedSelectedFile,
    }
  },

  [QualityCheckGenerateReport.QC_GENERATE_REPORT_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      reportStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckGenerateReport.QC_GENERATE_REPORT_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      reportData: action.payload,
      reportStatus: AsyncStates.SUCCESS,
      isSelectAllFiles: false,
    }
  },
  [QualityCheckGenerateReport.QC_GENERATE_REPORT_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      reportError: action.payload,
      reportStatus: AsyncStates.ERROR,
    }
  },
  [QualityCheckGenerateReport.QC_GENERATE_REPORT_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      reportData: null,
      reportStatus: AsyncStates.INITIAL,
      reportError: null,
      filesToReport: {1:[]},
      isSelectAllFiles: false,
      filesToIgnore: [],
    }
  },

  [QualityCheckRevalidate.QC_REVALIDATE_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      revalidateStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckRevalidate.QC_REVALIDATE_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      revalidateStatus: AsyncStates.SUCCESS,
      revalidateResponse: action.payload,
      isSelectAllFiles: false,
    }
  },
  [QualityCheckRevalidate.QC_REVALIDATE_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      revalidateStatus: AsyncStates.ERROR,
      revalidateError: action.payload,
    }
  },
  [QualityCheckRevalidate.QC_REVALIDATE_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      revalidateStatus: AsyncStates.INITIAL,
      revalidateError: null,
      revalidateResponse: null,
      filesToReport: {1:[]},
      isSelectAllFiles: false,
      filesToIgnore: [],
    }
  },

  [QualityCheckMetadataList.QC_METADATA_LIST_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      metadataListStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckMetadataList.QC_METADATA_LIST_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      metadataList: action.payload.data,
      metadataListTotal: action.payload.total,
      metadataListStatus: AsyncStates.SUCCESS,
    }
  },
  [QualityCheckMetadataList.QC_METADATA_LIST_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      metadataListStatus: AsyncStates.ERROR,
      metadataListError: action.payload,
    }
  },
  [QualityCheckMetadataList.QC_METADATA_LIST_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      metadataList: [],
      metadataListTotal: 0,
      metadataListStatus: AsyncStates.INITIAL,
      metadataListError: null,
    }
  },

  [QualityCheckMetadataLocal.QC_METADATA_EDIT_DRAWER_VISIBLE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      metadataEditDrawerVisible: action.payload,
    }
  },
  [QualityCheckMetadataLocal.QC_METADATA_SET_PAGE_NUMBER]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      metadataPageNumber: action.payload,
    }
  },
  [QualityCheckMetadataLocal.QC_METADATA_SET_FILTERS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      metadataFilters: action.payload,
    }
  },
  [QualityCheckMetadataLocal.QC_METADATA_SET_SEARCH_TERM]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      metadataSearchTerm: action.payload,
    }
  },
  [QualityCheckMetadataLocal.QC_METADATA_UPDATE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getMetadataResponse: {
        ...state.getMetadataResponse,
        ...action.payload,
      }
    }
  },
  [QualityCheckMetadataLocal.QC_METADATA_STEP_ERRORS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      metadataStepErrors: {
        ...state.metadataStepErrors,
        ...action.payload,
      },
    }
  },


  [QualityCheckGetMetadata.QC_GET_METADATA_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      getMetadataStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckGetMetadata.QC_GET_METADATA_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getMetadataResponse: action.payload,
      getMetadataStatus: AsyncStates.SUCCESS,
    }
  },
  [QualityCheckGetMetadata.QC_GET_METADATA_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getMetadataStatus: AsyncStates.ERROR,
      getMetadataError: action.payload,
    }
  },
  [QualityCheckGetMetadata.QC_GET_METADATA_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      getMetadataResponse: null,
      getMetadataStatus: AsyncStates.INITIAL,
      getMetadataError: null,
    }
  },

  [QualityCheckCreateMetadata.QC_CREATE_METADATA_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      createMetadataStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckCreateMetadata.QC_CREATE_METADATA_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      createMetadataResponse: action.payload,
      createMetadataStatus: AsyncStates.SUCCESS,
      getMetadataResponse: {
        ...action.payload
      },
    }
  },
  [QualityCheckCreateMetadata.QC_CREATE_METADATA_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      createMetadataStatus: AsyncStates.ERROR,
      createMetadataError: action.payload,
    }
  },
  [QualityCheckCreateMetadata.QC_CREATE_METADATA_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      createMetadataResponse: null,
      createMetadataStatus: AsyncStates.INITIAL,
      createMetadataError: null,
    }
  },

  [QualityCheckUpdateMetadata.QC_UPDATE_METADATA_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      updateMetadataStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckUpdateMetadata.QC_UPDATE_METADATA_SUCCESS]: (state: QualityCheckState, action: any) => {
    const current_step = action?.payload?.current_step || state.getMetadataResponse?.current_step
    return {
      ...state,
      updateMetadataResponse: action.payload,
      updateMetadataStatus: AsyncStates.SUCCESS,
      getMetadataResponse: {
        ...state.getMetadataResponse,
        ...action.payload,
        current_step: current_step
      }
    }
  },
  [QualityCheckUpdateMetadata.QC_UPDATE_METADATA_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      updateMetadataStatus: AsyncStates.ERROR,
      updateMetadataError: action.payload,
    }
  },
  [QualityCheckUpdateMetadata.QC_UPDATE_METADATA_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      updateMetadataResponse: null,
      updateMetadataStatus: AsyncStates.INITIAL,
      updateMetadataError: null,
    }
  },

  [QualityCheckDeleteMetadata.QC_DELETE_METADATA_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      deleteMetadataStatus: AsyncStates.LOADING,
    }
  }
  ,
  [QualityCheckDeleteMetadata.QC_DELETE_METADATA_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      deleteMetadataResponse: action.payload,
      deleteMetadataStatus: AsyncStates.SUCCESS,
    }
  },
  [QualityCheckDeleteMetadata.QC_DELETE_METADATA_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      deleteMetadataStatus: AsyncStates.ERROR,
      deleteMetadataError: action.payload,
    }
  },
  [QualityCheckDeleteMetadata.QC_DELETE_METADATA_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      deleteMetadataResponse: null,
      deleteMetadataStatus: AsyncStates.INITIAL,
      deleteMetadataError: null,
    }
  },




  // Name details
  [QualityCheckGetNameDetails.QC_GET_NAME_DETAILS_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      getNameDetailsStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckGetNameDetails.QC_GET_NAME_DETAILS_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getNameDetailsResponse: action.payload,
      getNameDetailsStatus: AsyncStates.SUCCESS,
    }
  },
  [QualityCheckGetNameDetails.QC_GET_NAME_DETAILS_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getNameDetailsStatus: AsyncStates.ERROR,
      getNameDetailsError: action.payload,
    }
  },
  [QualityCheckGetNameDetails.QC_GET_NAME_DETAILS_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      getNameDetailsResponse: null,
      getNameDetailsStatus: AsyncStates.INITIAL,
      getNameDetailsError: null,
    }
  },

  [QualityCheckCreateNameDetails.QC_CREATE_NAME_DETAILS_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      createNameDetailsStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckCreateNameDetails.QC_CREATE_NAME_DETAILS_SUCCESS]: (state: QualityCheckState, action: any) => {
    const type = action.payload?.type
    return {
      ...state,
      createNameDetailsResponse: action.payload,
      createNameDetailsStatus: AsyncStates.SUCCESS,
      getNameDetailsResponse: {
        ...state.getNameDetailsResponse,
        [type]: [
          ...(state.getNameDetailsResponse?.[type] || []),
          action.payload
        ]
      }
    };
  },
  [QualityCheckCreateNameDetails.QC_CREATE_NAME_DETAILS_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      createNameDetailsStatus: AsyncStates.ERROR,
      createNameDetailsError: action.payload,
    }
  },
  [QualityCheckCreateNameDetails.QC_CREATE_NAME_DETAILS_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      createNameDetailsResponse: null,
      createNameDetailsStatus: AsyncStates.INITIAL,
      createNameDetailsError: null,
    }
  },

  [QualityCheckUpdateNameDetails.QC_UPDATE_NAME_DETAILS_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      updateNameDetailsStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckUpdateNameDetails.QC_UPDATE_NAME_DETAILS_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      updateNameDetailsResponse: action.payload,
      updateNameDetailsStatus: AsyncStates.SUCCESS,
      getNameDetailsResponse: {
        ...state.getNameDetailsResponse,
        [action.payload.type]: state.getNameDetailsResponse?.[action.payload.type]?.map((nameDetails: any) => {
          if (nameDetails.detail_id === action.payload.detail_id) {
            return {...nameDetails,...action.payload}
          }
          return nameDetails
        })
      }
    }
  },
  [QualityCheckUpdateNameDetails.QC_UPDATE_NAME_DETAILS_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      updateNameDetailsStatus: AsyncStates.ERROR,
      updateNameDetailsError: action.payload,
    }
  },
  [QualityCheckUpdateNameDetails.QC_UPDATE_NAME_DETAILS_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      updateNameDetailsResponse: null,
      updateNameDetailsStatus: AsyncStates.INITIAL,
      updateNameDetailsError: null,
    }
  },

  [QualityCheckDeleteNameDetails.QC_DELETE_NAME_DETAILS_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      deleteNameDetailsStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckDeleteNameDetails.QC_DELETE_NAME_DETAILS_SUCCESS]: (state: QualityCheckState, action: any) => {
    const updatedDetails = state.getNameDetailsResponse?.[action.payload.type]?.filter((nameDetails: any) => nameDetails.detail_id !== action.payload.detail_id)
    return {
      ...state,
      deleteNameDetailsResponse: action.payload,
      deleteNameDetailsStatus: AsyncStates.SUCCESS,
      getNameDetailsResponse: {
        ...state.getNameDetailsResponse,
        [action.payload.type]: updatedDetails
      }
    }
  },
  [QualityCheckDeleteNameDetails.QC_DELETE_NAME_DETAILS_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      deleteNameDetailsStatus: AsyncStates.ERROR,
      deleteNameDetailsError: action.payload,
    }
  },
  [QualityCheckDeleteNameDetails.QC_DELETE_NAME_DETAILS_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      deleteNameDetailsResponse: null,
      deleteNameDetailsStatus: AsyncStates.INITIAL,
      deleteNameDetailsError: null,
    }
  },

  [QualityCheckGetImportDataOptions.QC_GET_IMPORT_DATA_OPTIONS_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      importDataOptionsStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckGetImportDataOptions.QC_GET_IMPORT_DATA_OPTIONS_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      importDataOptionsResponse: action.payload,
      importDataOptionsStatus: AsyncStates.SUCCESS,
    }
  },
  [QualityCheckGetImportDataOptions.QC_GET_IMPORT_DATA_OPTIONS_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      importDataOptionsStatus: AsyncStates.ERROR,
      importDataOptionsError: action.payload,
    }
  },
  [QualityCheckGetImportDataOptions.QC_GET_IMPORT_DATA_OPTIONS_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      importDataOptionsResponse: [],
      importDataOptionsStatus: AsyncStates.INITIAL,
      importDataOptionsError: null,
    }
  },

  [QualityCheckImportData.QC_IMPORT_DATA_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      importDataStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckImportData.QC_IMPORT_DATA_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      importDataResponse: action.payload,
      importDataStatus: AsyncStates.SUCCESS,
    }
  },
  [QualityCheckImportData.QC_IMPORT_DATA_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      importDataStatus: AsyncStates.ERROR,
      importDataError: action.payload,
    }
  },
  [QualityCheckImportData.QC_IMPORT_DATA_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      importDataResponse: null,
      importDataStatus: AsyncStates.INITIAL,
      importDataError: null,
    }
  },

  [QualityCheckAddDetailsLocal.QC_ADD_DETAILS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      addDetails: action.payload,
    }
  }

}, defaultState)

export default qualityCheckReducer;