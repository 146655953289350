import React, {
  ChangeEvent,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./IngredientCompareChart.scss";
import { Space, Card, Select, Empty, Input, Row, Form, Col, Checkbox, Tooltip } from "antd";
import {
  CloseOutlined,
  UpOutlined,
  DownOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useTranslate from "src/utils/useTranslate";
import { colorCodes } from "src/components/Formulation/Compare/ZeonCompare/zeon-chart/utils";
import { useValue } from "src/utils/useValue";
import { isValidNumber } from "src/utils/decorator";
import { StoreState } from "src/store/configureStore";
import { antdTheme } from "src/constants";
import { StyledButton } from "src/styled_components/StyledButton";
const { Option } = Select;

export const IngredientCompareChart = React.memo(
  ({
    allPlots,
    plot,
    setPlots,
    chartIndex,
    properties,
    ingredientOptions,
    isChartLoading,
  }: {
    setPlots: any;
    plot: any;
    allPlots: any;
    ingredientOptions: any[];
    properties: any[];
    chartIndex: number;
    isChartLoading: boolean;
  }) => {
    const { id } = plot;
    const { getValue, convertValue } = useValue();
    const isSidebarCollapsed = useSelector(
      (state: StoreState) => state.sidebar.collapsed
    );
    useEffect(() => {
      setPlots((plots: any) =>
        plots.map((plot: any) =>
          plot.id === id ? { ...plot, plotType: "bar", description: "" } : plot
        )
      );
    }, [id, setPlots]);
    const propertyLabels = useSelector(
      (state: StoreState) => state.displayNames?.data?.properties || {}
    );
    const [selectedIngredients, setSelectedIngredients] = useState<any[]>(
      plot.selectedIngredients || []
    );
    const [xLabels, setxLabels] = useState<any[]>([]);
    const [description, setDescription] = useState<string>(
      plot.description || ""
    );
    const [title, setTitle] = useState<string>(plot.title || "");
    const [xLabel, setxLabel] = useState<string>(plot.xLabel || "");
    const [colorEntries, setColorEntries] = useState<{ [key: string]: string }>(
      {}
    );

    const [plotData, setPlotData] = useState<any[]>(plot.plotData || []);
    const highchartsRef = useMemo(
      () =>
        Array.from({ length: allPlots.length }).map(() =>
          createRef<HighchartsReact.RefObject>()
        ),
      [allPlots.length]
    );

    const redraw = useCallback(() => {
      if (highchartsRef.length) {
        for (let i = 0; i < highchartsRef.length; i++) {
          highchartsRef?.[i]?.current?.chart?.reflow();
        }
      }
    }, [highchartsRef]);

    useEffect(() => {
      redraw();
    }, [isSidebarCollapsed, redraw]);

    const propertyOptions = useMemo(() => {
      // Map the unique properties into the desired format
      return properties.map((p) => ({
        label: `${p.property_name_with_params} ${p.standard ?? ""} ${!Array.isArray(p.unit) ? `[${p.unit}]` : ""}`,
        value: `${p.property_id}__${p.method_name}__${p.property_name_with_params}__${p.standard ?? ""}`,
        key: `${p.property_id}__${p.method_name}__${p.property_name_with_params}__${p.standard ?? ""}`,
        property_name_with_params: p.property_name_with_params,
        method_name: p.method_name,
        standard: p.standard,
      }));
    }, [properties]);

    const [t] = useTranslate();
    const [stateData, setStateData] = useState<any[]>([]);

    const [form] = Form.useForm();

    useEffect(() => {
      setxLabels(selectedIngredients.map((propertyId) => propertyId));
    }, [selectedIngredients]);

    const setRandomColor = useCallback(
      (nameType: string) => {
        if (!colorEntries?.[nameType]) {
          const usedColors = Object.values(colorEntries);
          const uniqueColors = colorCodes.filter(
            (color) => !usedColors.includes(color)
          );
          setColorEntries((prev) => {
            return {
              ...prev,
              [nameType]: uniqueColors[0],
            };
          });
        }
      },
      [colorEntries]
    );

    const getPropertyIngredientMethodValue = useCallback(
      (
        inventory_id: string,
        property_id: string,
        method_name: string,
        standard: string,
        property_name_with_params: string
      ) => {
        const propIndex = properties.findIndex(
          (p: any) =>
            p.property_id === property_id &&
            p.method_name === method_name &&
            p.standard === standard &&
            p.property_name_with_params === property_name_with_params
        );
        if (propIndex !== -1) {
          const ingredientData = properties[propIndex]?.["ingredients"]?.[inventory_id]
          return (
            ingredientData?.value_type === "numerical" ? ingredientData?.value ?? null : null
          );
        }
        return null;
      },
      [properties]
    );

    const getPropertyIngredientMethodUnit = useCallback(
      (
        inventory_id: string,
        property_id: string,
        method_name: string,
        standard: string,
        property_name_with_params: string
      ) => {
        const propIndex = properties.findIndex(
          (p: any) =>
            p.property_id === property_id &&
            p.method_name === method_name &&
            p.standard === standard &&
            p.property_name_with_params === property_name_with_params
        );
        if (propIndex !== -1) {
          return properties[propIndex]?.["ingredients"]?.[inventory_id]?.unit;
        }
        return null;
      },
      [properties]
    );

    const totalSelectedProperties = useMemo(() => stateData?.flatMap((_data: any) => _data?.selected_properties)?.map((ele) => ele.value) ?? [], [stateData])

    useEffect(() => {
      let graphsData: any;
      let withOptionalGraphData = stateData?.map(
        ({ selected_properties, plotype }: any, index: any) => {
          const values = selected_properties?.map((propertyOption: any) => {
            const propertyKey = propertyOption.value
            const propertyId = propertyKey.split("__")[0];
            const property = propertyOptions.find(
              (pOption) => pOption.value === propertyKey
            );
            setRandomColor(propertyKey);
            return {
              name: property?.label,
              marker: {
                fillColor: colorEntries[propertyKey],
              },
              color: colorEntries[propertyKey],
              data: !!selectedIngredients?.length
                ? selectedIngredients?.map((inventory_id: any) => {
                  return {
                    y:
                      getChartPointValue(
                        convertValue(
                          getPropertyIngredientMethodValue(
                            inventory_id,
                            propertyId,
                            property?.method_name,
                            property?.standard,
                            property?.property_name_with_params
                          )
                        )
                      ) ?? null,
                    unit:
                      getPropertyIngredientMethodUnit(
                        inventory_id,
                        propertyId,
                        property?.method_name,
                        property?.standard,
                        property?.property_name_with_params
                      ) ?? "-",
                    method_name: property?.method_name,
                    standard: property?.standard,
                    color: colorEntries[propertyKey],
                  };
                })
                : [],
              yAxis: index,
              type: plotype ?? "column",
              chartType: "ingredients",
              unit: [],
              zIndex: plotype === "line" && 1000,
            };
          });
          return values ?? [];
        }
      );
      if (withOptionalGraphData !== undefined) {
        graphsData = withOptionalGraphData?.flat();
      }

      const plotData: any = {
        chart: {
          renderTo: "plot" + id,
          height: description ? "70%" : "50%",
        },
        title: {
          text: title,
        },
        caption: {
          text: description,
          style: {
            fontSize: antdTheme.fontSizeHeading5,
            color: "#000000",
            fontFamily: "Arial, Times New Roman",
          },
        },

        xAxis: {
          title: {
            text: xLabel,
          },
          categories: selectedIngredients.map(
            (inventory_id) =>
              ingredientOptions.find((ing: any) => ing.value === inventory_id)
                ?.label
          ),
        },
        yAxis:
          graphsData[graphsData?.length - 1] === undefined
            ? {}
            : stateData?.map(({ label }, key: any) => ({
              title: {
                text: label,
              },
              opposite: key % 2 === 0 ? false : true,
            })),
        credits: {
          enabled: false,
        },
        tooltip: {
          useHTML: true,
          headerFormat: `<b>{point.x}</b> <br/>`,
          pointFormatter: function () {
            const point = this as any;
            return `<b>Standard : ${point.standard ?? "-"}</b><br/>
            <b>${point.series.name} : ${getValue(point.y, 3)} ${point.unit ?? "-"
              }</b>`;
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                const point = this as any;
                return getValue(point.y, 3);
              },
              connectorShape: "crookedLine",
              crookDistance: "70%",
            },
            style: {
              fontWeight: "bold",
            },
          },
        },
        series:
          graphsData?.[graphsData?.length - 1] === undefined ? [] : graphsData,

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 800,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        },
      };

      setPlots((plots: any) =>
        plots.map((plot: any) =>
          plot.id === id ? { ...plot, data: plotData } : plot
        )
      );
      setPlotData(plotData);
    }, [
      selectedIngredients,
      stateData,
      title,
      propertyLabels,
      xLabels,
      id,
      setPlots,
      xLabel,
      setRandomColor,
      colorEntries,
      description,
      getValue,
      convertValue,
      getPropertyIngredientMethodUnit,
      getPropertyIngredientMethodValue,
      ingredientOptions,
      propertyOptions,
    ]);

    const selectIngredientIds = (value: any[]) => {
      setSelectedIngredients(value);
      setPlots((plots: any) =>
        plots.map((plot: any) =>
          plot.id === id ? { ...plot, selectedIngredients: value } : plot
        )
      );
    };

    const changeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const value = e.target.value;
      setDescription(value);
      setPlots((plots: any) =>
        plots.map((plot: any) =>
          plot.id === id ? { ...plot, description: value } : plot
        )
      );
    };
    const changeTitle = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setTitle(value);
      setPlots((plots: any) =>
        plots.map((plot: any) =>
          plot.id === id ? { ...plot, title: value } : plot
        )
      );
    };
    const changexLabel = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setxLabel(value);
      setPlots((plots: any) =>
        plots.map((plot: any) =>
          plot.id === id ? { ...plot, xLabel: value } : plot
        )
      );
    };

    const moveCardDown = () => {
      setPlots((prevState: any) => {
        const array = [...prevState];
        const element = array.filter((res: any) => res.id === id)[0];
        array[array.indexOf(element)] = array.splice(
          array.indexOf(element) + 1,
          1,
          array[array.indexOf(element)]
        )[0];
        return array;
      });
    };

    const moveCardUp = () => {
      setPlots((prevState: any) => {
        const array = [...prevState];
        const element = array.filter((res: any) => res.id === id)[0];
        array[array.indexOf(element)] = array.splice(
          array.indexOf(element) - 1,
          1,
          array[array.indexOf(element)]
        )[0];
        return array;
      });
    };

    const addData = (values: any, key: any) => {
      const { plotGraph } = form?.getFieldsValue();
      Object.assign(plotGraph[key], { selected_properties: values });
      form.setFieldsValue({ plotGraph });
      setStateData((prev) => {
        const newState = JSON.parse(JSON.stringify(prev))
        newState[key].selected_properties = values
        return newState
      })
    };

    const handleChartType = useCallback((value: string, key: any) => {
      const { plotGraph } = form.getFieldsValue();
      Object.assign(plotGraph[key], {
        plotype: value,
      });
      form.setFieldsValue({ plotGraph });
      const { plotGraph: data }: any = form.getFieldsValue();
      setStateData(data);
    }, [form])

    const handleChartLabel = useCallback((event: any, key: any) => {
      const { plotGraph } = form.getFieldsValue();
      Object.assign(plotGraph[key], {
        label: event.target.value,
      });
      form.setFieldsValue({ plotGraph });
      setStateData((prev) => {
        const newState = JSON.parse(JSON.stringify(prev))
        newState[key].label = event.target.value
        return newState
      })
    }, [form])

    const getChartPointValue = (pointValue: any) => {
      return isValidNumber(pointValue) ? pointValue : null;
    };

    return (
      <Card
        size="small"
        type="inner"
        key={id}
        style={{ width: "100%" }}
        loading={isChartLoading}
        extra={[
          <Space>
            {" "}
            {allPlots?.indexOf(plot) !== 0 && (
              <StyledButton onClick={moveCardUp} icon={<UpOutlined />} />
            )}
            {allPlots?.indexOf(plot) !== allPlots?.length - 1 && (
              <StyledButton onClick={moveCardDown} icon={<DownOutlined />} />
            )}
            <StyledButton
              danger
              onClick={() =>
                setPlots((plots: any) =>
                  plots.filter(({ id: _id }: any) => _id !== id)
                )
              }
              icon={<CloseOutlined />}
            />
          </Space>,
        ]}
      >
        <Space
          direction="vertical"
          size="small"
          style={{ width: "100%" }}
          id="charts"
        >
          <Space>
            <>
              <Checkbox
                checked={
                  ingredientOptions.length === selectedIngredients.length
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedIngredients(
                      ingredientOptions.map(
                        (property: any, index: any) => property.value
                      )
                    );
                    setPlots((plots: any) =>
                      plots.map((plot: any) =>
                        plot.id === id
                          ? {
                            ...plot,
                            selectedIngredients: ingredientOptions.map(
                              (property: any) => property.value
                            ),
                          }
                          : plot
                      )
                    );
                  } else {
                    setSelectedIngredients([]);
                    setPlots((plots: any) =>
                      plots.map((plot: any) =>
                        plot.id === id
                          ? { ...plot, selectedIngredients: [] }
                          : plot
                      )
                    );
                  }
                }}
              >
                {t("common.selectAllIngredients")}
              </Checkbox>
            </>
          </Space>
          <Select
            style={{ width: "100%" }}
            value={selectedIngredients}
            onChange={selectIngredientIds}
            mode="multiple"
            options={ingredientOptions}
            optionFilterProp="children"
            placeholder={t("common.selectIngredients")}
            filterOption={(
              input: string,
              option?: { label: string; value: string }
            ) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          ></Select>
          <Row gutter={12}>
            <Col span={12}>
              <Input
                placeholder={t("common.title")}
                value={title}
                onChange={changeTitle}
              />
            </Col>
            <Col span={12}>
              <Input
                placeholder={t("chart.placeholder.xAxisLabel")}
                value={xLabel}
                onChange={changexLabel}
              />
            </Col>
          </Row>
          <Input.TextArea
            placeholder={t("common.description")}
            value={description}
            onChange={changeDescription}
          />
          <Form form={form} requiredMark={false}>
            <Form.List name="plotGraph">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <Row justify="space-between" key={name}>
                      <Col>
                        <Form.Item name={[name, "selected_properties"]}>
                          <Select

                            style={{ width: 250 }}
                            mode="multiple"
                            labelInValue={true}
                            value={
                              !!stateData[name]?.selected_properties
                                ? stateData[name]?.selected_properties
                                : []
                            }
                            onChange={(e: any) => addData(e, name)}
                            onBlur={() => { }} // keeping onBlur as Empty to avoid selected_properties to set to string value instead of array by antd form
                            placeholder={t("common.selectProperties")}
                            options={[...propertyOptions
                              .sort((a: any, b: any) => a.label?.localeCompare(b.label))
                              .filter(
                                (pOption) =>
                                  !totalSelectedProperties?.includes(
                                    pOption.value
                                  )
                              )
                            ]}
                            // optionFilterProp="children"
                            filterOption={(
                              input: string,
                              option?: { label: string; value: string }
                            ) => {
                              return (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase());
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={5}>
                        <Form.Item name={[name, "plotype"]}>
                          <Select
                            defaultValue={"column"}
                            placeholder="Select a Chart Type"
                            onChange={(e: any) => handleChartType(e, name)}
                            disabled={!stateData[name]?.selected_properties.length}
                          >
                            <Option value="column">{t("chartType.Bar")}</Option>
                            <Option value="line">{t("chartType.Line")}</Option>
                            <Option value="scatter">
                              {t("chartType.Scatter")}
                            </Option>
                            <Option value="area">{t("chartType.Area")}</Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          name={[name, "label"]}
                          fieldKey={[key, "label"]}
                        >
                          <Input
                            placeholder={t("chart.placeholder.enterYAxisLabel")}
                            onChange={(e: any) => handleChartLabel(e, name)}
                            disabled={!stateData[name]?.selected_properties.length}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4.5}>
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => {
                            remove(name);
                            setStateData((prev) => prev.filter((_, plotIndex) => plotIndex !== name))
                          }}
                        />
                      </Col>
                    </Row>
                  ))}

                  <Space
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 10,
                    }}
                  >
                    {fields?.length < 4 && (
                      <Tooltip
                        title={
                          !selectedIngredients.length
                            ? `Please Select At least One Trial`
                            : null
                        }
                      >
                        <Form.Item>
                          <StyledButton
                            type="dashed"
                            onClick={() => {
                              const addAtIndex = fields?.length
                              const defaultValue = {
                                type: null,
                                selected_properties: [],
                                plottype: "column",
                                label: null,
                              };
                              add(defaultValue, addAtIndex)
                              setStateData((prev) => {
                                const newState = JSON.parse(JSON.stringify(prev))
                                newState[addAtIndex] = defaultValue
                                return newState
                              })
                            }}
                            style={{ margin: "auto", marginBottom: 10 }}
                            icon={<PlusOutlined />}
                            disabled={
                              !!stateData?.length ? !stateData[stateData?.length - 1]?.selected_properties?.length ? true : !selectedIngredients.length ? true : false : false
                            }
                          >
                            {t("chart.addYaxis")}
                          </StyledButton>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </Tooltip>
                    )}
                  </Space>
                </>
              )}
            </Form.List>
          </Form>
          {!!selectedIngredients.length && !!stateData.length ? (
            <Card style={{ marginTop: 20 }} className="graph-container">
              <HighchartsReact
                highcharts={Highcharts}
                options={plotData}
                ref={highchartsRef?.[chartIndex]}
              />
            </Card>
          ) : (
            <Empty
              description={
                !selectedIngredients.length
                  ? t("chart.pleaseSelectTrial!")
                  : "Please Select a Y-Axis"
              }
            />
          )}
        </Space>
      </Card>
    );
  }
);
