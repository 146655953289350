import { DownloadOutlined } from '@ant-design/icons'
import { Checkbox, Modal } from 'antd'
import React, { useCallback, useEffect } from 'react'
import { AsyncStates } from 'src/constants'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'
import type { CheckboxProps } from 'antd';
import { useDispatch } from 'react-redux'
import { exportPredictionClear } from 'src/store/actions/suggestedExp'

type ExportDataSheetModalPropsTypes = {
    exportDataSheetModalData: { [key: string]: boolean },
    onSubmit: () => void,
    loadingState: AsyncStates,
    setExportDataSheetModalData: any,
    from: "forward-prediction" | "inverse-prediction"
}

export const ExportDataSheetModal = ({ exportDataSheetModalData, setExportDataSheetModalData, onSubmit, loadingState, from }: ExportDataSheetModalPropsTypes) => {
    const dispatch = useDispatch()
    const [t] = useTranslate()

    const handleCancel = useCallback(() => {
        setExportDataSheetModalData({
            isModalVisible: false,
            include_range: true
        })
        dispatch(exportPredictionClear())

    }, [setExportDataSheetModalData, dispatch])

    useEffect(() => {
        if (loadingState === AsyncStates.SUCCESS) {
            handleCancel()
        }
    }, [handleCancel, loadingState])


    const onChange: CheckboxProps['onChange'] = (e) => {
        setExportDataSheetModalData((prev: any) => ({ ...prev, include_range: e.target.checked }));
    };

    return (
        <Modal
            open={exportDataSheetModalData.isModalVisible}
            onCancel={() => {
                handleCancel()
            }}
            title={t("workOrderDetails.exportDatasheet")}
            footer={null}
        >
            <div style={{
                display: "flex",
                gap: "1rem",
                flexDirection: "column"
            }}>
                <div>
                    <Checkbox checked={exportDataSheetModalData.include_range} onChange={onChange}>
                        {t("common.exportDatasheet.includeRange")}
                    </Checkbox>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                    <StyledButton
                        type="primary"
                        icon={<DownloadOutlined />}
                        disabled={loadingState === AsyncStates.LOADING}
                        onClick={onSubmit}
                    >{t("workOrderDetails.exportDatasheet")}</StyledButton>
                </div>
            </div>

        </Modal>
    )
}