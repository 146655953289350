import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Form,
  List,
  Menu,
  Modal,
  Popover,
  Row,
  Space,
  Spin,
  Steps,
  Switch,
  Table,
  Tabs,
  Tooltip,
  Typography,
  message,
  notification,
} from "antd";
import { motion } from "framer-motion";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  FilterOutlined,
  HistoryOutlined,
  InfoCircleFilled,
  LoadingOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { StyledPageHeader } from "src/styled_components/StyledPageHeader";
import useTranslate from "src/utils/useTranslate";
import { IngredientModal } from "./components/IngredientModal";
import { StyledButton } from "src/styled_components/StyledButton";
import { useDispatch, useSelector } from "react-redux";
import {
  bulkEditTdsIngredientsClear,
  bulkEditTdsIngredientsRequest,
  getIngredientGradesRequest,
  getIngredientPropertiesClear,
  getIngredientPropertiesRequest,
  getIngredientsMetaRequest,
  getIngredientsRequest,
  getSuppliersRequest,
  inventoryPreferencesRequest,
  saveInventoryFiltersClear,
  saveInventoryFiltersRequest,
  updateArchiveIngredientStatusClear,
  updateArchiveIngredientStatusRequest,
  tdsIngredientsUploadSuccessNotificationReceived,
  updateInventoryPreferencesRequest,
  validateDeleteMultipleIngredientsRequest,
  validateArchivingIngredientsClear,
  updateIngredientClear,
  exportIngredientsRequest,
  exportIngredientsClear,
  inventoryIngredientsPaginationUpdate,
  inventoryIngredientsPaginationReset,
} from "src/store/actions/inventoryV2";
import { SupplierModal } from "./components/SupplierModal";
import { StoreState } from "src/store/configureStore";
import { StyledCard } from "src/styled_components/StyledCard";
import { AsyncStates, defaultHeaders } from "src/constants";
import Filters from "./filters";
import { Ingredient } from "./types";
import { Link, useHistory } from "react-router-dom";
import { history } from "src";
import { FileUploadModal } from "src/components/Inventory/modals/FileUploadModal";
import ManageFieldsModal from "./components/ManageFieldsModal";
import dayjs from "dayjs";
import Search, { SearchProps } from "antd/es/input/Search";
import { useValue } from "src/utils/useValue";
import { NewFilterForm } from "src/components/Formulation/SavedFilters/NewFilterForm";
import { GetSavedFiltersResponse } from "src/store/sagas/saveFormulationsFilters";
import { AddUnitModal } from "src/components/UnitsConversion/modals";
import { unitListRequest } from "src/store/actions/conversion";
import InventoryIngredientPropertyDetail from "./components/InventoryIngredientPropertyDetail";
import "./InventoryV2.scss";
import { fetchAdditiveRequest, fetchCategoryClear, fetchCategoryRequest, fetchFieldRequest, fetchParametersRequest, fetchPropertyClear, fetchPropertyRequest } from "src/store/actions/repository";
import { getPropertyMethodsRequest } from "src/store/actions/inventoryV2";
import { CATEGORY_TYPES } from "./Repository/Common/Data";
import * as fuzzySearch from '@m31coding/fuzzy-search';
import { TabsProps } from "antd/lib";
import { IngredientSavedFiltersHistory } from "../IngredientSavedFiltersHistory";
import { useQuery } from "src/utils/useQuery";
import { getSavedFiltersRequest } from "src/store/actions/saveFormulationsFilters";
import { ArchiveIngredientsConfirmationModal, ArchiveIngredientsDrawer } from "./ArchiveIngredients/ArchiveIngredients";
import { MenuProps } from "antd/lib";
import Dragger from 'antd/es/upload/Dragger'
import { blue } from "@ant-design/colors";
import jwtManager from "src/utils/jwtManager";
import { Headers } from "src/services/interface";
import InventoryService from "src/services/inventory/v3";
import { displayNamesRequest } from "src/store/actions/displayNames";
import { DeleteIngredientsConfirmationModal } from "src/components/Inventory/modals/DeleteIngredientsModal";

const config = fuzzySearch.Config.createDefaultConfig();
config.normalizerConfig.allowCharacter = (_c) => true;

const searcher = fuzzySearch.SearcherFactory.createDefaultSearcher(config);

const { Text, Title } = Typography;

export type IngredientTabKeysType = "active" | "archived"

export type SelectedIngredientIdsType = {
  active: string[],
  archived: string[]
}

type ExportIngredientsSelectionStateTypes = {
  type: "all" | "selected" | null,
  isModalVisible: boolean,
  selectedKeys: ["active" | "archived", "active" | "archived"] | ["active" | "archived"] | [],
  selectedIngredients: SelectedIngredientIdsType
}

export const InventoryV2 = () => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const { push } = useHistory()
  const query = useQuery();

  const { getValue } = useValue()
  const [form] = Form.useForm();
  const tableRef = useRef<any>(null)
  const { currency } = useSelector(
    (store: StoreState) => store.login.loginResponse
  );
  const projectList = useSelector(
    (state: StoreState) => state.projects.projectList
  );
  const updateIngredientResponse = useSelector(
    (state: StoreState) => state.inventoryV2.updateIngredient
  );
  const [ingredientModalData, setIngredientModalData] = useState<any>({
    mode: "create",
    ingredient: null,
  });
  const { data: ingredientsList, status } = useSelector(
    (state: StoreState) => state.inventoryV2.getIngredients
  );
  const customFields = useSelector(
    (state: StoreState) => state.repository.fields.data
  );

  const archiveIngredientData = useSelector((state: StoreState) => state.inventoryV2.archiveIngredient);

  const paginationState = useSelector((state: StoreState) => state.inventoryV2.pagination);

  const [editMetaDataModalVisible, setEditMetaDataModalVisible] = useState(false)

  const [open, setOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<{ [key: string]: string }>({
    active: "",
    archived: ""
  });
  const [deleteValidateModalVisible, setDeleteValidateModalVisible] = useState<boolean>(false);

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [addIngredientModalOpen, setAddIngredientModalOpen] = useState<boolean>(false);
  const [outerSelector, setOuterSelector] = useState<{ [key: string]: string | null }>({
    active: null,
    archived: null
  })
  const [innerSelector, setInnerSelector] = useState<{ [key: string]: [] }>({
    active: [],
    archived: []
  })
  const [formState, setFormState] = useState<{ [key: string]: any }>({
    active: {},
    archived: {}
  })
  const [filters, setFilters] = useState<{ [key: string]: any }>({
    active: {},
    archived: {}
  })

  const [fileUploadVisible, setFileUploadVisible] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAddUnitModal, setAddUnitModal] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const [savedFiltersApplied, setSavedFiltersApplied] = useState(true);

  const [selectedIngredientIds, setSelectedIngredientIds] = useState<SelectedIngredientIdsType>({
    active: [],
    archived: []
  });
  const [filterHistoryDrawerVisible, setFilterHistoryDrawerVisible] = useState(false);
  const [createNewFilterModalVisible, setCreateNewFilterModalVisible] = useState(false);
  const [archiveIngredientModal, setArchiveIngredientModal] = useState(false);

  const [exportIngredientsSelectionState, setExportIngredientsSelectionState] = useState<ExportIngredientsSelectionStateTypes>({
    type: null,
    isModalVisible: false,
    selectedKeys: [],
    selectedIngredients: {
      active: [],
      archived: []
    }
  })

  useEffect(() => {
    return () => {
      if (!window.location.pathname?.includes("ingredients")) {
        dispatch(inventoryIngredientsPaginationReset())
      }
    }
  }, [dispatch])

  const getCurrentTab = () => {
    const tab = query.get("tab");
    if (!tab) {
      push(`?tab=active`)
    }
    return tab === "active" || tab === "archived" ? tab : "active";
  }

  const [activeTab, setActiveTab] = useState<"active" | "archived">(getCurrentTab())

  const getIngredientPropertyStatus = useSelector((state: StoreState) => state.inventoryV2.getIngredientPropertyStatus);

  const categories = useSelector((state: StoreState) => state.repository.categories.data);

  const categoriesStatus = useSelector((state: StoreState) => state.repository.categories.status);

  const methods = useSelector((state: StoreState) => state.inventoryV2.propertyMethods.data)

  const parameters = useSelector((state: StoreState) => state.repository.parameters.data);
  const property = useSelector((state: StoreState) => state.repository.properties.data);
  const propertyStatus = useSelector((state: StoreState) => state.repository.properties.status);

  const savedIngredientFilters = useSelector((state: StoreState) => state.inventoryV2.savedIngredientFilters);
  const isTdsIngredientsUploadSuccessNotificationRecieved = useSelector((state: StoreState) => state.inventoryV2.isTdsIngredientsUploadSuccessNotificationRecieved);

  const ingredientPreferences = useSelector((state: StoreState) => state.inventoryV2.preferences.data.ingredient_table);
  const userId = useSelector((state: StoreState) => state.login.loginResponse.user_id)
  const exportIngredientsStatus = useSelector((state: StoreState) => state.inventoryV2.exportIngredients.status);


  useEffect(() => {
    return () => {
      dispatch(fetchCategoryClear())
      dispatch(fetchPropertyClear());
      dispatch(getIngredientPropertiesClear())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(inventoryPreferencesRequest())
  }, [dispatch])


  useEffect(() => {
    return () => {
      if (!window.location?.pathname.includes("/inventory/ingredients") && savedIngredientFilters.activeTab) { // clear Previous applied filters when they visit other pages
        dispatch(saveInventoryFiltersClear())
      }
    }
  }, [savedIngredientFilters, dispatch])

  useEffect(() => {
    dispatch(fetchCategoryRequest({ type: CATEGORY_TYPES.INGREDIENT }));
    dispatch(fetchParametersRequest());
    dispatch(fetchPropertyRequest());
    dispatch(getPropertyMethodsRequest());
    dispatch(fetchFieldRequest());
    dispatch(fetchAdditiveRequest());
    dispatch(getSuppliersRequest());
    dispatch(getIngredientGradesRequest())
    dispatch(getSavedFiltersRequest({
      user_id: userId,
      filter_type: "inventory",
    }))
  }, [dispatch, userId]);

  const applyFilters = useCallback((values: any) => {
    const payload = {
      selector: values?.outer_selector || outerSelector[activeTab] || "and",
      data: values?.outer_conditions?.reduce(
        (array: any[], element: any, index: number) => [
          ...array,
          {
            selector: values?.inner_selector?.[index] || innerSelector[activeTab]?.[index] || element?.innerSelector || "and",
            data: element?.inner_conditions.map((innerCondition: any) => {
              if (innerCondition.parameter === "custom_fields") {
                const cField = customFields.find((cf: any) => cf.field_id === innerCondition.parameter_type);
                if (cField?.field_type === 'date') {
                  return {
                    ...innerCondition,
                    val: dayjs(innerCondition.val).format('YYYY-MM-DD'),
                    max: innerCondition?.max ? dayjs(innerCondition.max).format('YYYY-MM-DD') : null
                  }
                }
              }
              if (innerCondition.parameter === "ingredient_attachments") {
                if (innerCondition.operator) {
                  return {
                    ...innerCondition,
                    val: innerCondition.val?.flat()
                  }
                }
                return innerCondition
              }
              if (["family", "ingredient", "custom_fields", "supplier", "costing", "lot_no", "project_id", "grade", "responsible_person", "sap_no"].includes(innerCondition.parameter)) {
                if (innerCondition.operator === 'in') {
                  return {
                    ...innerCondition,
                    val: innerCondition.val?.flat()
                  }
                }
                return innerCondition
              }
              if (["category", "methods", "properties", "parameter"].includes(innerCondition.parameter)) {
                if (innerCondition.operator === 'in') {
                  // Value comes from cascader
                  const flattenedValueArray = innerCondition.val?.map((innerArray: []) => innerArray[(innerArray.length - 1)]);
                  return {
                    ...innerCondition,
                    val: flattenedValueArray
                  }
                }
                if (["properties", "parameter"].includes(innerCondition.parameter)) {
                  // Value comes from cascader
                  const flattenedParameterType = innerCondition.parameter_type?.[(innerCondition.parameter_type.length - 1)];
                  return {
                    ...innerCondition,
                    parameter_type: [flattenedParameterType]
                  }
                }
                return innerCondition
              }
              return innerCondition
            }),
          },
        ],
        []
      ),
    }
    setFilters((prev) => ({
      ...prev,
      [activeTab]: payload
    }))
    dispatch(inventoryIngredientsPaginationUpdate({
      type: activeTab,
      page: 1,
      pageSize: 30
    }))
    dispatch(getIngredientsRequest({
      type: activeTab,
      filters: payload,
      sorts: {}
    }))
    setShowFilters(false)
    setSavedFiltersApplied(false)
    dispatch(saveInventoryFiltersRequest({ activeTab, data: payload }))
  }, [customFields, dispatch, innerSelector, outerSelector, activeTab])


  const generateCategoryValueForCascader = useCallback((value: string[]) => {
    return value.map((val: string) => {
      // Find category match
      const category_index = categories.findIndex(c => c.category_id === val || c.name === val);
      if (category_index !== -1) return [categories[category_index]?.category_id];
      // Find subcategory match
      const sub_cat_index = categories.findIndex(c => c.sub_category && c.sub_category.includes(val));
      if (sub_cat_index !== -1) return [categories[sub_cat_index]?.category_id, val];

      return null
    }).filter(v => v);
  }, [categories])

  const generatePropertyValueForCascader = useCallback((parameter_type: string) => {
    const selectedProperty = property.find(p => p.property_id === parameter_type || p.name === parameter_type);
    if (selectedProperty) return [selectedProperty?.category_name, selectedProperty?.property_id]

    return null;
  }, [property])

  const generateParameterValueForCascader = useCallback((parameter_type: string) => {
    const selectedParameter = parameters.find(p => p.parameter_id === parameter_type || p.parameter === parameter_type);
    if (selectedParameter) return [selectedParameter?.category_name, selectedParameter?.property_name, selectedParameter?.parameter_id]

    return null;
  }, [parameters])

  const generateMethodValueForCascader = useCallback((value: string[]) => {
    return value.map((val: string) => {
      const selectedMethod = methods.find((m: any) => m.method_name === val);
      if (selectedMethod) return [selectedMethod.category_name, selectedMethod.property_name, val]

      return null
    }).filter(v => v);
  }, [methods])


  const generateOuterCondtions = useCallback((queryData: any[] = []) => {
    const outerConditions = queryData.map((outerCondition: any) => ({
      innerSelector: outerCondition.selector,
      inner_conditions: outerCondition.data.map((innerCondition: any) => {
        if (["version_date"].includes(innerCondition.parameter)) {
          if (innerCondition.operator === "eq") {
            return {
              ...innerCondition,
              val: innerCondition.val ? dayjs(innerCondition.val) : innerCondition.val
            }
          }

          if (innerCondition.operator === "range") {
            return {
              ...innerCondition,
              val: [innerCondition.val?.[0] ? dayjs(innerCondition.val[0]) : innerCondition.val[0], innerCondition.val?.[1] ? dayjs(innerCondition.val[1]) : innerCondition.val[1]]
            }
          }
        }
        if (innerCondition.parameter === "custom_fields") {
          const cField = customFields.find((cf: any) => cf.field_id === innerCondition.parameter_type);
          if (cField?.field_type === 'date') {
            return {
              ...innerCondition,
              val: dayjs(innerCondition.val, 'YYYY-MM-DD').toDate(),
              max: innerCondition?.max ? dayjs(innerCondition.max, 'YYYY-MM-DD').toDate() : null
            }
          }
        }
        if (["family", "ingredient", "custom_fields", "supplier", "costing", "lot_no", "project_id"].includes(innerCondition.parameter)) {
          if (innerCondition.operator === 'in') {
            return {
              ...innerCondition,
              val: innerCondition.val?.map((v: any) => [v])
            }
          }
          return innerCondition
        }
        if (innerCondition.parameter === "ingredient_attachments") {
          if (innerCondition.operator) {
            const values = innerCondition?.val ?? []
            return {
              ...innerCondition,
              val: values?.map((v: any) => [v])
            }
          }
          return innerCondition
        }
        if (["category", "methods"].includes(innerCondition.parameter)) {
          // Value for cascader
          let cascaderValue: any;
          const value = innerCondition.val;
          if (innerCondition.parameter === "category") cascaderValue = generateCategoryValueForCascader(value);
          if (innerCondition.parameter === "methods") cascaderValue = generateMethodValueForCascader(value);

          return {
            ...innerCondition,
            val: cascaderValue
          }
        }
        if (["properties", "parameter"].includes(innerCondition.parameter)) {
          // Value for cascader
          let cascaderValue: any;
          const parameterType = innerCondition.parameter_type[0];
          if (innerCondition.parameter === "properties") cascaderValue = generatePropertyValueForCascader(parameterType);
          if (innerCondition.parameter === "parameter") cascaderValue = generateParameterValueForCascader(parameterType);

          return {
            ...innerCondition,
            parameter_type: cascaderValue
          }
        }
        return innerCondition
      }),
    }));

    return outerConditions

  }, [customFields, generateCategoryValueForCascader, generateMethodValueForCascader, generateParameterValueForCascader, generatePropertyValueForCascader])

  const handleSelectFilter = useCallback((item: GetSavedFiltersResponse["result"]["data"][0]) => {
    const query = item?.query || {}
    if (Object.keys(query).length === 0) {
      form.resetFields()
    }
    const key = item?.is_archived ? "archived" : "active"

    if (Object.keys(query).length > 0) {
      setOuterSelector((prev) => ({
        ...prev,
        [key]: query?.selector
      }))

      setInnerSelector((prev) => ({
        ...prev,
        [key]: query?.data?.map((res: any) => res?.selector ?? "and")
      }))

      const outerConditions = generateOuterCondtions(query.data)

      form.setFieldValue(
        "outer_conditions", outerConditions
      )
      setFilters((prev) => ({
        ...prev,
        [key]: query
      }))

      setFormState((prev) => ({
        ...prev,
        [key]: {
          "outer_conditions": outerConditions
        }
      }))

      applyFilters({
        "outer_conditions": outerConditions,
        "outer_selector": query?.selector,
        "inner_selector": query?.data?.map((res: any) => res?.selector ?? "and")
      })
      setFilterHistoryDrawerVisible(false)
    }

  }, [applyFilters, form, generateOuterCondtions])

  useEffect(() => {
    if (!savedIngredientFilters?.activeTab) { // Makes an initial API call to fetch ingredient data when no filters have been applied or saved
      dispatch(
        getIngredientsRequest({
          type: "all",
          filters: {},
          sorts: {}
        })
      );
    }
  }, [dispatch, savedIngredientFilters])

  // const searchOnChange = (e: any) => {
  //   setSearchValue(e.target.value);
  // };

  const [searchResults, setSearchResults] = useState<{ [key: string]: any[] }>({
    active: ingredientsList?.["active"]?.data ?? [],
    archived: ingredientsList?.["archived"]?.data ?? []
  })

  useEffect(() => {
    if (archiveIngredientData.status === AsyncStates.SUCCESS) {
      const updatedTab = archiveIngredientData?.is_archived ? "active" : "archived"
      setSelectedIngredientIds((prev) => {
        return {
          ...prev,
          [updatedTab]: prev[updatedTab].filter((id) => !archiveIngredientData.inventory_ids.includes(id)),
        }
      })
      dispatch(validateArchivingIngredientsClear()) // Common Clear for both Active and Archived
      dispatch(updateArchiveIngredientStatusClear()) // Common Clear for both Active and Archived
      // setSearchResults((prev) => ({
      //   active: ingredientsList?.["active"]?.data ?? [],
      //   archived: ingredientsList?.["archived"]?.data ?? []
      // }))
    }
  }, [activeTab, archiveIngredientData.inventory_ids, archiveIngredientData?.is_archived, archiveIngredientData.status, dispatch, ingredientsList])

  useEffect(() => {
    const tabWiseIngredientsLists = ingredientsList?.[activeTab]?.data ?? []
    searcher.indexEntities(
      tabWiseIngredientsLists?.map((o: any) => ({ ...o, grade: o.grade ? String(o.grade) : "", category: o.category ? String(o.category) : "" })) || [],
      (e: any) => e?.inventory_id,
      (e: any) => [e?.name, e?.grade, e?.category]
    );
    // const result = searcher.getMatches(new fuzzySearch.Query(searchValue, Infinity, 0.1));
    // console.log("🚀 ~ useEffect ~ result:", result)

    const searchTerm = searchValue?.[activeTab]
    const result = tabWiseIngredientsLists
      ?.map((o: any) => ({ ...o, grade: o.grade ? String(o.grade) : "", category: o.category ? String(o.category) : "" }))
      ?.filter((o: any) => o.name?.toLowerCase()?.trim()?.replaceAll(/[™|®]/g, "")?.includes(searchTerm?.toLowerCase()?.trim()?.replaceAll(/[™|®]/g, ""))
        || o.grade?.toLowerCase()?.trim()?.replaceAll(/[™|®]/g, "")?.includes(searchTerm?.toLowerCase()?.trim()?.replaceAll(/[™|®]/g, ""))
        || o.category?.toLowerCase()?.trim()?.replaceAll(/[™|®]/g, "")?.includes(searchTerm?.toLowerCase()?.trim()?.replaceAll(/[™|®]/g, "")))
    // console.log("🚀 ~ useEffect ~ matches:", result)
    setSearchResults((prev) => ({
      ...prev,
      [activeTab]: result
    }))
  }, [activeTab, ingredientsList, searchValue])

  const tableData = useMemo(() => {
    // const searched = searchResults?.matches?.map(o => o?.entity)
    return searchResults[activeTab] ?? []
  }, [activeTab, searchResults])

  const searchOnChange: SearchProps['onSearch'] = (value) => {
    setSearchValue((prev) => ({
      ...prev,
      [activeTab]: value
    }))
    // value ? dispatch(getIngredientsSearchRequest({
    //   "search_term": value
    // })) : dispatch(getIngredientsRequest({
    //   filters,
    //   sorts: {}
    // }))

    dispatch(inventoryIngredientsPaginationUpdate({
      type: activeTab,
      page: 1,
      pageSize: 30
    }))
  }

  useEffect(() => {
    if (updateIngredientResponse.status === AsyncStates.SUCCESS) {
      // searchValue ? dispatch(getIngredientsSearchRequest({
      //   "search_term": searchValue
      // })) : 
      dispatch(getIngredientsRequest({
        type: activeTab,
        filters: filters[activeTab] ?? {},
        sorts: {}
      }))
      dispatch(updateIngredientClear());
    }
  }, [dispatch, filters, updateIngredientResponse.status, activeTab])

  useEffect(() => {
    dispatch(getIngredientsMetaRequest())
  }, [dispatch])

  // useEffect(() => {
  //   // searchValue ? dispatch(getIngredientsSearchRequest({
  //   //   "search_term": searchValue
  //   // })) : 
  //   dispatch(
  //     getIngredientsRequest({
  //       filters,
  //       sorts: {}
  //     })
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch]);


  useEffect(() => {
    if (status === AsyncStates.SUCCESS) {
      setExpandedRowKeys([])
    }
  }, [status])

  useEffect(() => {
    if (savedFiltersApplied && savedIngredientFilters.activeTab && categoriesStatus === AsyncStates.SUCCESS && propertyStatus === AsyncStates.SUCCESS) { // Apply Previous applied filters back
      setActiveTab(savedIngredientFilters.activeTab)
      handleSelectFilter({
        is_archived: savedIngredientFilters.activeTab === "archived" ? true : false,
        query: savedIngredientFilters?.data?.[savedIngredientFilters.activeTab] ?? {}
      } as any)
    }
  }, [savedFiltersApplied, savedIngredientFilters, handleSelectFilter, dispatch, categoriesStatus, propertyStatus])

  useEffect(() => {
    if (isTdsIngredientsUploadSuccessNotificationRecieved) {
      dispatch(
        getIngredientsRequest({
          filters: filters[activeTab] ?? {},
          sorts: {}
        })
      );
      dispatch(displayNamesRequest({ backgroundFetch: true }));
      dispatch(tdsIngredientsUploadSuccessNotificationReceived(false)) // Reset to Initial State
    }
  }, [dispatch, filters, handleSelectFilter, isTdsIngredientsUploadSuccessNotificationRecieved, savedFiltersApplied, savedIngredientFilters, activeTab])

  const clearFilters = () => {
    setFormState((prev) => ({
      ...prev,
      [activeTab]: {}
    }))
    setInnerSelector((prev) => ({
      ...prev,
      [activeTab]: []
    }))
    setOuterSelector((prev) => ({
      ...prev,
      [activeTab]: null
    }))
    form.resetFields()
    dispatch(inventoryIngredientsPaginationUpdate({
      type: activeTab,
      page: 1,
      pageSize: 30
    }))

    setFilters((prev) => ({
      ...prev,
      [activeTab]: {}
    }))
    dispatch(
      getIngredientsRequest({
        type: activeTab,
        filters: {},
        sorts: {}
      })
    );
    dispatch(saveInventoryFiltersClear())
    setSavedFiltersApplied(true)
  }

  const getProjectsContent = useCallback(
    (rowValue) => {
      return (
        <div style={{ maxWidth: 1000, maxHeight: 500, overflow: "auto" }}>
          <List
            size="small"
            dataSource={projectList.filter((project: any) =>
              !!rowValue?.length
                ? rowValue?.includes(project?.project_id)
                : false
            )}
            renderItem={(item: any) => (
              <List.Item>
                <Text strong>{item?.name}</Text>
              </List.Item>
            )}
          />
        </div>
      );
    },
    [projectList]
  );


  const handleDeleteValidate = useCallback((ingredients_ids: string[] = []) => {
    dispatch(validateDeleteMultipleIngredientsRequest({
      inventory_ids: ingredients_ids?.length ? ingredients_ids : selectedIngredientIds[activeTab]
    }))
    setDeleteValidateModalVisible(true)
  }, [activeTab, dispatch, selectedIngredientIds])

  const extractAlphaNum = (string?: string) => {
    if (!string || string === "" || string === "-") return "zzzzz";
    else return String(string).replace(/[^0-9A-Z]+/gi, "");
  }

  const columnVisibilityMap = useMemo(() => {
    return {
      category: t("common.category"),
      costing: t("inventory.costing"),
      grade: t("common.grade"),
      lot_no: t("inventory.lot_no"),
      sap_no: t("common.SAPNo"),
      project_id: t("projects.header.title"),
      responsible_person: t("common.responsiblePerson"),
      sub_category: t("inventory.subCategory"),
      supplier: t("inventory.supplier"),
      version_date: t("common.versionDate"),
    }
  }, [t])

  const calculateDynamicWidths = useCallback((tableRef, columns: any[]) => {
    if (!tableRef?.current) return {};

    const tableWidth = tableRef.current.offsetWidth;
    const prioritySum = columns.reduce((sum: number, col) => sum + (col.priority || 3), 0);
    let remainingWidth = tableWidth;

    // Assigning the widths based on priorities
    const calculatedWidths: any = {};
    columns.forEach((col: any) => {
      const priority = col.priority || 3;
      const minWidth = col.width;
      const dynamicWidth = Math.max(minWidth, (priority / prioritySum) * tableWidth);
      calculatedWidths[col.key] = dynamicWidth;
      remainingWidth -= dynamicWidth;
    });

    if (remainingWidth > 0) {
      Object.keys(calculatedWidths).forEach((key) => {
        const colIndex = columns.findIndex((col) => col.key === key);
        const priority = columns[colIndex].priority || 3;
        calculatedWidths[key] += (priority / prioritySum) * remainingWidth;
      });
    }

    return calculatedWidths;
  }, [])

  const columns: any = useMemo(() => {
    const allColumns = [
      {
        key: "name",
        title: t("common.Name"),
        dataIndex: "name",
        width: 200,
        priority: 1,
        fixed: "left",
        render: (name: string, record: Ingredient) => (
          <Link to={`/inventory/ingredients/${record.inventory_id}`}>
            <Text style={{ wordBreak: "break-word" }}>{name || "-"}</Text>
          </Link>
        ),
        sorter: (a: any, b: any) => extractAlphaNum(a.name).localeCompare(extractAlphaNum(b.name))
      },
      // {
      //   key: "family",
      //   title: t("common.family"),
      //   dataIndex: "family_id",
      //   width: 150,
      //   render: (family_id: string, record: any) => (
      //     <Text style={{ wordBreak: "break-word" }}>{families.find((fam: any) => fam.family_id === family_id)?.family_name ?? record.family ?? "-"}</Text>
      //   ),
      // },
      {
        key: "grade",
        title: t("common.grade"),
        dataIndex: "grade",
        width: 150,
        priority: 2,
        render: (gradeName: string | number) => {
          return <Text style={{ wordBreak: "break-word" }}> {gradeName === "" ? "-" : gradeName ?? "-"}</Text>
        },
        sorter: (a: any, b: any) => extractAlphaNum(a.grade).localeCompare(extractAlphaNum(b.grade))
      },
      {
        key: "category",
        title: t("common.category"),
        dataIndex: "category",
        width: 150,
        priority: 2,
        render: (text: string, record: any) => {
          return <Text style={{ wordBreak: "break-word" }}>{record?.category_name ?? text}</Text>
        },
        sorter: (a: any, b: any) => extractAlphaNum(a.category).localeCompare(extractAlphaNum(b.category))
      },
      {
        key: "sub_category",
        title: t("inventory.subCategory"),
        dataIndex: "sub_category",
        width: 150,
        priority: 2,
        render: (text: string, record: any) => (
          <Text style={{ wordBreak: "break-word" }}>
            {record?.sub_category}
          </Text>
        ),
        sorter: (a: any, b: any) => extractAlphaNum(a.sub_category).localeCompare(extractAlphaNum(b.sub_category))
      },
      // {
      //   key: "unit",
      //   title: (
      //     <div style={{ display: "flex", flexDirection: "column" }}>
      //       <span style={{ color: "#222" }}>{t("common.unit")}</span>
      //       {/* <Typography.Text
      //         style={{ cursor: "pointer", color: antdTheme.colorPrimary }}
      //       // onClick={() => setShowCard(true)}
      //       >
      //         {t("units.addUnit")}
      //       </Typography.Text> */}
      //     </div>
      //   ),
      //   dataIndex: "unit",
      //   width: 100,
      //   render: (rowValue: any, row: any, index: any) => {
      //     if (typeof rowValue === "string") return rowValue;
      //     const units = rowValue?.filter((value: any) => !!value);
      //     return units?.length > 0 ? units?.join(", ") : "-";
      //   },
      // },
      {
        key: "project_id",
        title: t("projects.header.title"),
        dataIndex: "project_id",
        width: 200,
        priority: 1,
        render: (rowValue: any, row: any, index: any) => {
          return (
            <Popover
              content={getProjectsContent(rowValue)}
              title={
                <Title level={5} type="secondary">
                  {t("projects.header.title")}
                </Title>
              }
              trigger="hover"
              placement="left"
            >
              <Text style={{ width: 200 }} ellipsis={{}}>
                {!!rowValue?.length
                  ? rowValue
                    ?.map(
                      (res: any) =>
                        projectList.find(
                          (project: any) => project?.project_id === res
                        )?.name || res
                    )
                    ?.join(", ")
                  : ""}
              </Text>
            </Popover>
          );
        },
        filterMultiple: true,
        filterSearch: true,
        onFilter: (value: any, record: any) => {
          return !value
            ? !record?.project_id?.length
            : record?.project_id?.includes(value);
        },
      },
      {
        key: "lot_no",
        dataIndex: "lot_no",
        title: t("inventory.lot_no"),
        width: 250,
        filterSearch: true,
        priority: 1,
        onFilter: (value: any, record: any) => record.lot_no === value,
      },
      {
        key: "sap_no",
        dataIndex: "sap_no",
        title: t("common.SAPNo"),
        width: 250,
        filterSearch: true,
        priority: 1,
        onFilter: (value: any, record: any) => record?.sap_no === value,
      },
      {
        key: "supplier",
        dataIndex: "supplier",
        title: t("inventory.supplier"),
        width: 150,
        filterSearch: true,
        priority: 2,

        onFilter: (value: any, record: any) => record.supplier === value,
      },
      {
        key: "responsible_person",
        dataIndex: "responsible_person",
        title: t("common.responsiblePerson"),
        width: 150,
        filterSearch: true,
        priority: 2,

        onFilter: (value: any, record: any) => record.responsible_person === value,
      },
      {
        key: "version_date",
        dataIndex: "version_date",
        title: t("common.versionDate"),
        width: 150,
        filterSearch: true,
        priority: 2,
        render: (text: string, record: any) => {
          if (record?.version_date) {
            const formattedDate = dayjs(record?.version_date).format("DD-MM-YYYY")
            if (formattedDate !== "Invalid Date") {
              return formattedDate
            } else {
              return record.version_date
            }
          }
          return null
        }
      },
      {
        key: "costing",
        dataIndex: "costing",
        title: (
          <Text>
            {t("inventory.costing")}{" "}{`(In ${currency?.currency_code})`}
          </Text>
        ),
        width: 200,
        priority: 1,
        render: (costing: any, record: any) => {
          const costingValue = !!costing && typeof costing === "object"
            ? costing?.amount ? `${costing?.currency ?? "-"} ${getValue(costing?.amount)} ${costing?.quantity && costing?.unit ? `per ${getValue(costing?.quantity) ?? "-"} ${costing?.unit ?? "-"}` : ''}` : null
            : null
          return (
            <Tooltip title={costingValue}>
              <Text style={{ width: "100px" }} ellipsis={{}}>
                {costingValue}
              </Text>
            </Tooltip>
          );
        },
      },
    ];

    // below logic is make the column(actions, select ingredient, and expand) icon to stay at a static positon and rest columns to get adjusted based on their priority and width 
    const data = allColumns.filter((column) => ["name"].includes(column.dataIndex) ? true : ingredientPreferences[column.dataIndex])
    const dynamicWidths = calculateDynamicWidths(tableRef, data);



    const adjustedColumns = data.map((col) => ({
      ...col,
      width: dynamicWidths[col.dataIndex] || col.width,
    }));

    return [
      ...adjustedColumns,
      {
        key: "actions",
        title: `${t("common.actions")}`,
        dataIndex: "actions",
        width: 100,
        priority: 3,
        align: "center",
        fixed: "right",
        render: (text: any, record: any) => {
          return (
            <Dropdown
              overlay={() => (
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      setIngredientModalData({
                        mode: "edit",
                        ingredient: record,
                      });
                      setAddIngredientModalOpen(true);
                    }}
                    key="edit"
                  >
                    <StyledButton type="text" icon={<EditOutlined />}>
                      {t("common.edit")}
                    </StyledButton>
                  </Menu.Item>
                  {/* <Menu.Item
                  // onClick={() => setModal(record, "view")}
                  key="view"
                >
                  <StyledButton type="text" icon={<ContainerOutlined />}>
                    {t("common.viewWorkOrders")}
                  </StyledButton>
                </Menu.Item> */}

                  {/* <Menu.Item
                  // onClick={() => setModal(record, "attachments")}
                  key="attachments"
                >
                  <StyledButton type="text" icon={<FileExcelOutlined />}>
                    {t("common.attachments")}
                  </StyledButton>
                </Menu.Item> */}
                  <Menu.Item
                    onClick={() => {
                      handleDeleteValidate([record.inventory_id])

                    }}
                    key="delete"
                  >
                    <StyledButton
                      type="text"
                      danger
                      icon={<DeleteOutlined />}
                      className={"project__dropdown__btn"}
                    >
                      {t("common.delete")}
                    </StyledButton>
                  </Menu.Item>
                </Menu>
              )}
            >
              {<MoreOutlined style={{ cursor: "pointer" }} />}
            </Dropdown>
          );
        },
      }]
  }, [currency?.currency_code, getProjectsContent, projectList, t, getValue, ingredientPreferences, tableRef, calculateDynamicWidths, handleDeleteValidate]);

  const addNewOption = (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "0.2rem" }}>
      <div
        style={{ padding: "0.5rem", cursor: "pointer" }}
        onClick={() => {
          setIngredientModalData({
            mode: "create",
            ingredient: undefined,
          });
          setAddIngredientModalOpen(true);
        }}
      >
        {t("inventory.Ingredient")}
      </div>
      <div
        style={{ padding: "0.5rem", cursor: "pointer" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {t("common.Supplier")}
      </div>

      <div
        style={{ padding: "0.5rem", cursor: "pointer" }}
        onClick={() => {
          alert("Coming soon");
        }}
      >
        {t("common.template")}
      </div>
    </div>
  );

  const hideColumnsOverlay = () => {
    return (
      <Menu style={{ padding: 10, width: "max-content" }}>
        {Object.keys(columnVisibilityMap).map((key) => (
          <Menu.Item key={key}>
            <Row
              justify="space-between"
              wrap={false}
              style={{ height: "100%", alignItems: "center" }}
            >
              <Text>{columnVisibilityMap?.[key as keyof typeof columnVisibilityMap]}</Text>
              <Switch
                style={{ marginLeft: 10 }}
                checked={ingredientPreferences?.[key]}
                onChange={(e: boolean) => {
                  const updatedPreferences = {
                    ...ingredientPreferences,
                    [key]: e
                  }
                  dispatch(updateInventoryPreferencesRequest({
                    "ingredient_table": updatedPreferences
                  }))
                }
                }
              />
            </Row>
          </Menu.Item>
        ))}
        <Divider style={{ margin: "10px 0" }} />
        <Row justify="space-between">
          <StyledButton
            disabled={Object.entries(ingredientPreferences).every(([key, value]) => !value)}
            onClick={() => {
              const updatedPreferences = Object.keys(columnVisibilityMap).reduce((acc: { [key: string]: boolean }, key) => {
                acc[key] = false;
                return acc;
              }, {});
              dispatch(updateInventoryPreferencesRequest({
                "ingredient_table": updatedPreferences
              }))
            }}
            type="default"
            size="small"
          >
            {t("common.hideAll")}
          </StyledButton>
          <StyledButton
            disabled={Object.entries(ingredientPreferences).every(([key, value]) => value)}
            onClick={() => {
              const updatedPreferences = Object.keys(columnVisibilityMap).reduce((acc: { [key: string]: boolean }, key) => {
                acc[key] = true;
                return acc;
              }, {});
              dispatch(updateInventoryPreferencesRequest({
                "ingredient_table": updatedPreferences
              }))
            }
            }
            type="default"
            size="small"
          >
            {t("common.showAll")}
          </StyledButton>
        </Row>
      </Menu>
    );
  };

  const tabItems: TabsProps['items'] = useMemo(() => [
    {
      key: "active",
      label: t("common.activeIngredients"),
    },
    {
      key: "archived",
      label: t("common.archivedIngredients"),
    },
  ], [t])

  const onTabChange = (value: string) => {
    setActiveTab(value as IngredientTabKeysType)
    if (!!filters?.[value]) {
      const outerConditions = generateOuterCondtions(filters?.[value]?.data ?? [])
      form.setFieldValue(
        "outer_conditions", outerConditions
      )
    }
    push(`?tab=${value}`)
  }

  const handleClear = () => {
    setSelectedIngredientIds((prev) => {
      return {
        ...prev,
        [activeTab]: []
      }
    });
  }

  const updateItemArchiveStatus = (ids: string[], is_archived: boolean) => {
    dispatch(updateArchiveIngredientStatusRequest({
      inventory_ids: ids,
      is_archived
    }))
  }

  const uploadFileOptions: MenuProps['items'] = useMemo(() => [
    {
      key: 'edit_metadata',
      label: t("inventory.ingredients.editMetaData"),
      onClick: () => setEditMetaDataModalVisible(true),
    },
    {
      key: 'add_new_ingredients',
      label: t("inventory.ingredients.addNewIngredients"),
      onClick: () => setFileUploadVisible(true),
    },
  ], [t])

  const exportIngredientsOptions: MenuProps['items'] = useMemo(() => [
    {
      key: 'select_all',
      label: t("common.exportAllIngredients"),
      onClick: () => {
        setExportIngredientsSelectionState({
          type: "all",
          isModalVisible: true,
          selectedKeys: ["active", "archived"],
          selectedIngredients: selectedIngredientIds
        })
      },
    },
    {
      key: 'selected_ingredients',
      label: <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem", alignItems: "center", cursor: "pointer" }}>{t("common.exportSelectedIngredients")} {" "} <Tooltip title={t("common.exportIngredientsDetailsNote")}><InfoCircleFilled /></Tooltip></div>,
      onClick: () => {
        if (!selectedIngredientIds["active"].length && !selectedIngredientIds["archived"].length) {
          message.error(t("common.exportIngredientsSelectedError"))
          return
        }
        if (!!selectedIngredientIds["active"].length && !!selectedIngredientIds["archived"].length) {
          setExportIngredientsSelectionState({
            type: "selected",
            isModalVisible: true,
            selectedKeys: ["active", "archived"],
            selectedIngredients: selectedIngredientIds
          })
          return
        }

        const payload = {
          type: selectedIngredientIds["active"].length ? "active" : "archived",
          inventory_ids: selectedIngredientIds[selectedIngredientIds["active"].length ? "active" : "archived"]
        }

        dispatch(exportIngredientsRequest(payload))
      },
    },
  ], [t, selectedIngredientIds, dispatch])

  useEffect(() => {
    if (exportIngredientsStatus === AsyncStates.SUCCESS) {
      dispatch(exportIngredientsClear())
    }
  }, [dispatch, exportIngredientsStatus])

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: "just" }}
    >
      <Space
        direction="vertical"
        size="small"
        style={{ width: "100%", overflowX: "auto", height: "100%" }}
      >
        <StyledPageHeader
          ghost={false}
          title={`${t("common.ingredients")}`}
          extra={
            <>
              <Dropdown menu={{ items: exportIngredientsOptions }}>
                <StyledButton loading={exportIngredientsStatus === AsyncStates.LOADING} onClick={e => e.stopPropagation()} size="small">{"Export Ingredients"} <DownOutlined /></StyledButton>
              </Dropdown>

              <Dropdown menu={{ items: uploadFileOptions }}>
                <StyledButton onClick={e => e.stopPropagation()} size="small">{t("common.uploadAFile")} <DownOutlined /></StyledButton>
              </Dropdown>

              <StyledButton
                size="small"
                onClick={() => {
                  setAddUnitModal(true);
                }}
                type="primary"
                icon={<PlusOutlined />}
              >
                {t("units.addUnit")}
              </StyledButton>

              <Popover arrow={false} content={addNewOption} title={null} trigger="hover" placement="bottom">
                <StyledButton size="small" type="primary">{t("common.Add New")} <DownOutlined /></StyledButton>
              </Popover>

              {/* <StyledButton
                size="small"
                onClick={() => {
                  setIsModalOpen(true);
                }}
                type="dashed"
                icon={<EditOutlined />}
              >
                Custom Fields
              </StyledButton> */}
            </>
          }
        />

        <Tabs
          activeKey={activeTab}
          onChange={onTabChange}
          items={tabItems}
          tabBarExtraContent={
            <>
              <Popover
                placement="left"
                content={hideColumnsOverlay}
                trigger="click"
              >
                <StyledButton
                  size="small"
                  style={!!Object.values(ingredientPreferences || {}).filter((res: any) => !res).length ? {
                    backgroundColor: "#E6F4FF"
                  } : {}
                  }
                  className="bordered" icon={<EyeInvisibleOutlined />} type="text">
                  <Text strong >
                    <span>
                      {`${Object.values(ingredientPreferences || {}).filter((res: any) => !res).length || ""} ${t("common.hiddenColumns")}`}
                    </span>
                  </Text>
                </StyledButton>
              </Popover>
            </>
          }
        />

        <StyledCard bodyStyle={{ padding: "8px 16px", height: "100%" }} key={activeTab}>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Table
              key={activeTab}
              ref={tableRef}
              rowSelection={{
                type: "checkbox",
                selectedRowKeys: selectedIngredientIds?.[activeTab] || [],
                onChange: (selectedRows: any[]) => {
                  setSelectedIngredientIds((prev) => {
                    return {
                      ...prev,
                      [activeTab]: selectedRows
                    }
                  });
                },
              }}
              title={() => (
                <Col className="hello__world">
                  <Row justify="space-between">
                    <div>
                      <Text style={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "24px",
                        textAlign: "left",
                        textUnderlinePosition: "from-font",
                        textDecorationSkipInk: "none",
                      }}>
                        {t("common.ingredientList")} ({searchResults[activeTab].length})
                      </Text>
                    </div>

                    <Space >
                      <div style={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center"
                      }}>
                        {!showFilters && !searchValue?.[activeTab] && (
                          <StyledButton
                            size="small"
                            icon={<FilterOutlined />}
                            style={{ borderRadius: 0 }}
                            onClick={() => {
                              setShowFilters(true);
                            }}
                          >
                            {t("common.addFilters")}
                          </StyledButton>)}
                        {Object.keys(filters?.[activeTab] ?? {}).length > 0 ? (
                          <>
                            <StyledButton
                              type="primary"
                              style={{ borderRadius: 0 }}
                              size="small"
                              icon={<FilterOutlined />}
                              onClick={() => setCreateNewFilterModalVisible(true)}
                            >
                              {t("common.saveFilter")}
                            </StyledButton>
                            <StyledButton
                              style={{ borderRadius: 0 }}
                              size="small"
                              icon={<FilterOutlined />}
                              onClick={clearFilters}
                            >
                              {t("common.clearFilter")}
                            </StyledButton>
                          </>
                        ) :
                          // <Input size="small" value={searchValue} placeholder={t("common.search")} allowClear onChange={searchOnChange}
                          // onPressEnter={ingredientSearchRequest} onBlur={ingredientSearchRequest}
                          // />
                          null
                        }
                      </div>

                      <Search size="small" key={activeTab} placeholder="Search"
                        defaultValue={searchValue[activeTab]}
                        onSearch={searchOnChange} allowClear
                      />
                      <Tooltip title={t("common.filtersHistory")} >
                        <StyledButton
                          size="small"
                          ghost
                          type="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            setFilterHistoryDrawerVisible(true);
                          }}
                          icon={<HistoryOutlined />}
                        />
                      </Tooltip>
                      {/* <Input.Search
                        style={{ width: 300 }}
                        placeholder={t("common.search")}
                        onChange={(e: any) => setSearchValue(e.target.value)}
                        allowClear
                        value={searchValue}
                        enterButton
                      /> */}
                    </Space>
                  </Row>
                  {showFilters && (
                    <Filters
                      key={`${activeTab}__filter`}
                      activeTab={activeTab}
                      visible={showFilters}
                      setVisible={setShowFilters}
                      form={form}
                      applyFilters={applyFilters}
                      clearFilters={clearFilters}
                      formState={formState[activeTab]}
                      setFormState={setFormState}
                      outerSelector={outerSelector[activeTab]}
                      setOuterSelector={setOuterSelector}
                      innerSelector={innerSelector[activeTab]}
                      setInnerSelector={setInnerSelector}
                    />
                  )}
                  <IngredientSavedFiltersHistory
                    visible={filterHistoryDrawerVisible}
                    setVisible={setFilterHistoryDrawerVisible}
                    filter_type="inventory"
                    onApplyFilter={handleSelectFilter}
                    activeTab={activeTab}
                  />
                  <NewFilterForm
                    visible={createNewFilterModalVisible}
                    setVisible={setCreateNewFilterModalVisible}
                    filters={filters[activeTab]}
                    filter_type="inventory"
                    activeTab={activeTab}
                  />
                </Col>
              )}
              style={{
                cursor: "pointer",
                height: "100%",
                ...(!!selectedIngredientIds[activeTab].length && { paddingBottom: "6.5rem" })
              }}
              bordered
              columns={columns}
              loading={status === AsyncStates.LOADING}
              dataSource={[...tableData]}
              rowKey={(record: any) => record?.inventory_id}
              className="inventory-v2-custom-table"
              // scroll={{ x: 1000 }}
              expandable={
                {
                  expandedRowKeys: expandedRowKeys,
                  onExpand: (expanded, record) => {
                    if (!!record?.inventory_id && expanded) {
                      dispatch(getIngredientPropertiesRequest({ inventory_id: record?.inventory_id }))
                    }
                    if (expanded) {
                      setExpandedRowKeys((prevKeys) => {
                        if (prevKeys.includes(record?.inventory_id)) return prevKeys
                        return [...prevKeys, record?.inventory_id]
                      })
                    } else {
                      setExpandedRowKeys((prevKeys) => prevKeys.filter((key) => key !== record?.inventory_id))
                    }
                  },
                  expandedRowRender: (record) => {
                    return (
                      <>
                        {getIngredientPropertyStatus?.[record?.inventory_id] === AsyncStates.ERROR && <div style={{ display: "flex", justifyContent: "space-between", padding: "0.5rem" }}>
                          <Text type="danger" strong>{t("common.errorFetchingProperties")}</Text>
                          <StyledButton
                            onClick={() => {
                              dispatch(getIngredientPropertiesRequest({ inventory_id: record?.inventory_id }))
                            }}>
                            {t("common.retry")}
                          </StyledButton>
                        </div>}
                        <Spin spinning={getIngredientPropertyStatus?.[record?.inventory_id] === AsyncStates.LOADING}
                          indicator={<LoadingOutlined />}
                        >
                          <div
                            style={{
                              padding: "0.5rem 0rem",
                              marginLeft: "1rem",
                              overflowX: "auto",
                            }}
                          >
                            <InventoryIngredientPropertyDetail propertiesByCategory={record?.properties} inventory_id={record?.inventory_id} />
                          </div>
                        </Spin>
                      </>
                    );
                  },
                }
              }
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: () => {
                    history.push(`/inventory/ingredients/${record.inventory_id}`)
                  },
                };
              }}
              pagination={{
                current: paginationState?.[activeTab]?.page,
                position: ["bottomRight"],
                pageSize: paginationState?.[activeTab]?.pageSize,
                pageSizeOptions: [30, 50, 70, 100],
                total: searchResults[activeTab]?.length,
                onChange: (current, size) => {
                  dispatch(inventoryIngredientsPaginationUpdate({
                    type: activeTab,
                    page: paginationState?.[activeTab].pageSize === size ? current : 1,
                    pageSize: size,
                  }))
                }
              }}
              locale={{
                triggerDesc: t("table.sort.ZA"),
                triggerAsc: t("table.sort.AZ"),
                cancelSort: t("table.sort.cancel")
              }}
            />
          </Space>
        </StyledCard>
      </Space >
      {
        addIngredientModalOpen && <IngredientModal
          open={addIngredientModalOpen}
          setOpen={setAddIngredientModalOpen}
          mode={ingredientModalData.mode}
          ingredient={ingredientModalData.ingredient}
        />
      }

      {open && <SupplierModal open={open} setOpen={setOpen} />}
      {fileUploadVisible && <FileUploadModal fileUploadVisible={fileUploadVisible} setFileUploadVisible={setFileUploadVisible} />}
      {isModalOpen && <ManageFieldsModal modalState={[isModalOpen, setIsModalOpen]} />}
      {showAddUnitModal && <AddUnitModal
        refetch={() => dispatch(unitListRequest({ "category": "" }))}
        isAddNewUnitModal={showAddUnitModal}
        closeModal={() => setAddUnitModal(false)}
      />}
      {editMetaDataModalVisible && <EditMetaDataModal open={editMetaDataModalVisible} setOpen={setEditMetaDataModalVisible} />}

      {!!selectedIngredientIds[activeTab].length && <ArchiveIngredientsDrawer archiveIngredientModal={archiveIngredientModal} setArchiveIngredientModal={setArchiveIngredientModal} selectedIngredientIds={selectedIngredientIds} activeTab={activeTab} handleClear={handleClear} updateItemArchiveStatus={updateItemArchiveStatus} handleDeleteValidate={handleDeleteValidate} />}
      {
        archiveIngredientModal && <ArchiveIngredientsConfirmationModal selectedIngredientIds={selectedIngredientIds["active"]} open={archiveIngredientModal} setOpen={setArchiveIngredientModal} updateItemArchiveStatus={updateItemArchiveStatus}
          setSelectedIngredientIds={setSelectedIngredientIds} />
      }

      {deleteValidateModalVisible && <DeleteIngredientsConfirmationModal open={deleteValidateModalVisible} setOpen={setDeleteValidateModalVisible} ingredientIds={selectedIngredientIds[activeTab]} activeTab={activeTab} setSelectedIngredientIds={setSelectedIngredientIds} />}

      {exportIngredientsSelectionState.isModalVisible && <ExportIngredientsConfirmationModal exportIngredientsSelectionState={exportIngredientsSelectionState} setExportIngredientsSelectionState={setExportIngredientsSelectionState} />}
    </motion.div >
  );
};


type ExportIngredientsConfirmationModalPropsType = {
  exportIngredientsSelectionState: ExportIngredientsSelectionStateTypes,
  setExportIngredientsSelectionState: Dispatch<SetStateAction<ExportIngredientsSelectionStateTypes>>
}


export const ExportIngredientsConfirmationModal = ({ exportIngredientsSelectionState, setExportIngredientsSelectionState }: ExportIngredientsConfirmationModalPropsType) => {
  const dispatch = useDispatch();
  const [t] = useTranslate();

  const exportIngredientsStatus = useSelector((state: StoreState) => state.inventoryV2.exportIngredients.status);


  const handleClose = useCallback(() => {
    setExportIngredientsSelectionState({
      isModalVisible: false,
      type: "all",
      selectedKeys: [],
      selectedIngredients: {
        active: [],
        archived: []
      }
    })
  }, [setExportIngredientsSelectionState])

  const onChange = (checkedValues: any) => {
    setExportIngredientsSelectionState((prev) => ({
      ...prev,
      selectedKeys: checkedValues
    }))
  };

  const handleSubmit = () => {
    if (exportIngredientsSelectionState.selectedKeys.length === 0) {
      return message.error("Please select atleast one option")
    }

    const payload = {
      type: exportIngredientsSelectionState.selectedKeys.length === 2 ? "all" : exportIngredientsSelectionState.selectedKeys[0],
      inventory_ids: exportIngredientsSelectionState.type === "all" ? [] : exportIngredientsSelectionState.selectedKeys.reduce((acc: string[], key) => {
        if (!!exportIngredientsSelectionState.selectedIngredients?.[key]?.length) {
          acc.push(...exportIngredientsSelectionState.selectedIngredients?.[key])
        }
        return acc
      }, [])
    }
    dispatch(exportIngredientsRequest(payload))
  }


  useEffect(() => {
    if (exportIngredientsStatus === AsyncStates.SUCCESS) {
      handleClose()
    }
  }, [dispatch, exportIngredientsStatus, handleClose])



  return <Modal
    open={exportIngredientsSelectionState.isModalVisible}
    maskClosable={false}
    width={800}
    footer={null}
    onCancel={handleClose}
    title={t("common.exportIngredients")}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}>

      <div>
        <Alert showIcon icon={<InfoCircleFilled />} message={t("common.exportIngredientsDetailsNote")} type="info" />
      </div>

      <div>
        {t("common.exportIngredientsNote")}
      </div>


      <Checkbox.Group
        options={[{
          label: t("common.activeIngredients"),
          value: "active",
          disabled: exportIngredientsSelectionState.type === "all" ? false : !exportIngredientsSelectionState.selectedIngredients?.["active"].length
        }, {
          label: t("common.archivedIngredients"),
          value: "archived",
          disabled: exportIngredientsSelectionState.type === "all" ? false : !exportIngredientsSelectionState.selectedIngredients?.["archived"].length
        }]}
        value={exportIngredientsSelectionState.selectedKeys}
        onChange={onChange}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1rem"
        }}
      >
        <Button loading={exportIngredientsStatus === AsyncStates.LOADING} type="primary" onClick={handleSubmit} disabled={!exportIngredientsSelectionState.selectedKeys.length}>
          {t("common.exportIngredients")}
        </Button>
      </div>
    </div>
  </Modal>
}


type EditMetaDataModalPropsTypes = {
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>
}


const allowedFileExtensions = ['xlsx', 'xls'];

const bulkMetaDataFileTemplate = `https://polymerize-misc.s3.ap-southeast-1.amazonaws.com/Bulk+Metadata+Update+Template+-+V2.xlsx`


export const EditMetaDataModal = ({ open, setOpen }: EditMetaDataModalPropsTypes) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [file, setFile] = useState<any>(null);

  const [validateBulkEditTdsIngredients, setValidateBulkEditTdsIngredients] = useState<{
    status: AsyncStates,
    data: null | { [key: string]: any }
  }>({
    status: AsyncStates.INITIAL,
    data: null,
  })

  const bulkEditTdsIngredientsStatus = useSelector((state: StoreState) => state.inventoryV2.bulkEditTdsIngredients.status);

  const handleClose = useCallback(() => {
    setOpen(false)
    setFile(null)
    dispatch(bulkEditTdsIngredientsClear())
    setValidateBulkEditTdsIngredients({
      status: AsyncStates.INITIAL,
      data: null,
    })
    setCurrentStep(0)
  }, [dispatch, setOpen])

  const uploadProps = {
    onRemove: (record: any) => {
      setFile(null);
    },
    accept: '.xlsx, .xls,',
    beforeUpload: async (record: any) => {
      if (allowedFileExtensions.includes(record.name.split('.').pop())) {
        setFile(record);
      } else message.error(t("ingredients.dataUpload.supportedFileDesc"));
      return false;
    },
    multiple: false,
    fileList: !!file ? [file] : [],
  };



  const handleValidate = async () => {
    if (!file) return
    const payload = new FormData();
    payload.append("file", file);

    const headers = {
      ...defaultHeaders,
      token: jwtManager.getToken(),
    } as Headers;

    try {
      setValidateBulkEditTdsIngredients((prev) => ({
        ...prev,
        status: AsyncStates.LOADING
      }))
      const response: any = await InventoryService.validateBulkEditTdsApi(
        payload,
        headers
      );
      const status = response?.data?.result?.status;
      if (status === "Success") {
        setValidateBulkEditTdsIngredients((prev) => ({
          ...prev,
          data: response?.data?.result?.data,
          status: AsyncStates.SUCCESS
        }))
        setCurrentStep(1)
      } else {
        setValidateBulkEditTdsIngredients((prev) => ({
          ...prev,
          status: AsyncStates.ERROR
        }))
        notification.error({
          message: response?.data?.result?.message ?? t("common.somethingWentWrong")
        });
      }
    } catch (error) {
      setValidateBulkEditTdsIngredients((prev) => ({
        ...prev,
        status: AsyncStates.ERROR
      }))
      return message.error(t("common.somethingWentWrong"));
    }
  }

  const reviewDataColumns = useMemo(() => [
    {
      title: t("inventory.IngredientName"),
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (text: string) => {
        return <Text
          style={{ width: 200 }}
          ellipsis={{ tooltip: text }}
        >
          {text}
        </Text>
      }
    },
    {
      title: t("inventory.Category"),
      dataIndex: "category",
      key: "category",
      width: 150,
      render: (text: string) => {
        return <Text
          style={{ width: 150 }}
          ellipsis={{ tooltip: text }}
        >
          {text}
        </Text>
      }
    },
    {
      title: t("inventory.Grade"),
      dataIndex: "grade",
      key: "grade",
      width: 150,
      render: (text: string) => {
        return <Text
          style={{ width: 150 }}
          ellipsis={{ tooltip: text }}
        >
          {text}
        </Text>
      }
    },
    {
      title: t("inventory.lot_no"),
      dataIndex: "lot_no",
      key: "lot_no",
    },
    {
      title: t("common.versionDate"),
      dataIndex: "version_date",
      key: "version_date",
      render: (_: any, record: any) => {
        return record?.version_date ? dayjs(record?.version_date).format("DD-MM-YYYY") : null
      }
    }
  ], [t])


  const handleUpdateProperties = () => {
    if (!file) return
    const payload = new FormData();
    payload.append("file", file);
    dispatch(bulkEditTdsIngredientsRequest(payload))
  }


  useEffect(() => {
    if (bulkEditTdsIngredientsStatus === AsyncStates.SUCCESS) {
      handleClose()
    }
  }, [bulkEditTdsIngredientsStatus, handleClose])


  return <Modal
    open={open}
    maskClosable={false}
    width={800}
    footer={null}
    onCancel={handleClose}
    title={
      <Row>
        <Col span={12}>
          <Steps
            size="default"
            current={currentStep}
            onChange={(curr) => {
              setCurrentStep(curr);
            }}
            items={[
              {
                title: `Upload file - Edit`,
              },
              {
                title: `Review Data`,
                disabled: !file || (validateBulkEditTdsIngredients.status !== AsyncStates.SUCCESS)
              },
            ]}
          />
        </Col>
      </Row>
    }
  >
    {currentStep === 0 && <>
      <Spin
        spinning={validateBulkEditTdsIngredients.status === AsyncStates.LOADING}
        indicator={<LoadingOutlined />}
      >
        <div style={{
          display: "flex",
          gap: "0.5rem",
          flexDirection: "column",
          paddingTop: "1rem",
          paddingBottom: "1rem"
        }}>
          <div>
            <Dragger {...uploadProps}>
              <p
                style={{ paddingTop: 30, paddingBottom: 30 }}
                className="ant-upload-text"
              >
                {t("dataUpload.dragnDrop")}
                <span style={{ color: blue[5] }}>
                  {" "}
                  {t("dataUpload.selectFile")}
                </span>
              </p>
            </Dragger>
          </div>

          <Row justify="start">
            <Text>
              {t("datamapper.needHelp?")}{" "}
              <a
                target={"_blank"}
                rel="noreferrer"
                href={bulkMetaDataFileTemplate}
              >
                {t("datamapper.downloadTemplate?")}{" "}
              </a>
              {t("datamapper.spreadsheet?")}
            </Text>
          </Row>

          <div>
            <Alert showIcon icon={<InfoCircleFilled />} message={
              <>
                <div><strong>{t("workOrderDetails.note")}:</strong> {t("ingredients.editMetaDataTemplateUpdatedNote")}</div>

                {!file &&
                  <div>
                    <i>{t("ingredients.editMetaDataTemplateUpdatedSubNote")}</i>
                  </div>
                }
              </>
            } type="info" />
          </div>
          <Row justify="end">
            <Tooltip
              title={
                !file ? t("common.uploadAFile") : 'Review Data'
              }
            >
              <StyledButton
                loading={validateBulkEditTdsIngredients.status === AsyncStates.LOADING}
                onClick={handleValidate}
                type="primary"
                htmlType="submit"
                disabled={!file}
              >
                {t("common.continue")}
              </StyledButton>
            </Tooltip>
          </Row>
        </div>
      </Spin>
    </>}
    {currentStep === 1 && <div style={{
      paddingTop: "1rem",
      paddingBottom: "1rem",
      display: "flex",
      gap: "1rem",
      flexDirection: "column"
    }}>

      <Table
        title={() => (
          <div style={{
            display: "flex",
            gap: "0.5rem",
            flexDirection: "column"
          }}>
            <div
              style={{
                display: "flex",
                gap: "0.25rem",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <div>
                {t("common.reviewData")}
              </div>
              <div>
                <StyledButton
                  onClick={() => {
                    window.open('/inventory/ingredients', "_blank")
                  }}
                >{t("common.viewIngredients")}</StyledButton>
              </div>
            </div>
            <span>{t("ingredients.editMetaDataSystemDetectedChanges")}</span>
          </div>
        )}
        columns={reviewDataColumns}
        dataSource={validateBulkEditTdsIngredients?.data?.ingredients ?? []}
      />

      <div
        style={{
          display: "flex",
          gap: "0.25rem",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div>
          <StyledButton onClick={() => setCurrentStep(0)}>{t("common.back")}</StyledButton>
        </div>
        <div>
          <StyledButton loading={bulkEditTdsIngredientsStatus === AsyncStates.LOADING} type="primary" onClick={() => handleUpdateProperties()}>{t("ingredients.updateProperties")}</StyledButton>
        </div>
      </div>
    </div>}
  </Modal>
}
