import { CloudUploadOutlined, LoadingOutlined } from '@ant-design/icons'
import {
    Collapse,
    Divider,
    Form,
    Spin,
    Drawer,
    Modal,
    Input,
    Row,
    Typography,
    Upload,
    List,
    Space,
    Popconfirm,
} from 'antd';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates, permissions, projectStatus } from 'src/constants'
import { deleteFileAttachmentRequest, editDisplayIdRequest, fetchFileListRequest, findAndUpdateSpecificExperimentDataClear, uploadFileAttachmentRequest } from 'src/store/actions/workOrderDetails'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'
import { TrialDetails } from '../TrialDetails/TrialDetails'
import { usePermission } from 'src/utils/usePermissions'
import EasyMDE from 'easymde'
import 'easymde/dist/easymde.min.css'
import { useRequiredFieldStar } from '../Common/useRequiredFieldStar'

const { Text } = Typography

export const TrialDetailsDrawer = ({ initialTrials, linkedTrials, modalTrial, trialType, setTrialType, isModalVisible, setIsModalVisible, workOrder, form }: any) => {
    const [t] = useTranslate()
    const dispatch = useDispatch()
    const requiredFieldStar = useRequiredFieldStar()

    const editDisplayIdStatus = useSelector((state: StoreState) => state.workOrderDetails.editDisplayIdStatus)
    const updateExperimentsStatus = useSelector((state: StoreState) => state.workOrderDetails.updateExperimentsStatus)
    const project_id = useSelector((state: StoreState) => state.projects.current)
    const attachments = useSelector((state: StoreState) => state.workOrderDetails.fileList)
    const fileListStatus = useSelector((state: StoreState) => state.workOrderDetails.fileListStatus)
    const deleteFileAttachmentStatus = useSelector((state: StoreState) => state.workOrderDetails.deleteFileAttachmentStatus)
    const uploadFileAttachmentStatus = useSelector((state: StoreState) => state.workOrderDetails.uploadFileAttachmentStatus)
    const [notesEditingState, setNotesEditingState] = useState(false)
    const [imageVisible, setImageVisible] = useState(false)
    const [image, setImage] = useState<any>({})
    const userAccess = usePermission()

    useEffect(() => {
        if (editDisplayIdStatus === AsyncStates.SUCCESS) {
            setNotesEditingState(false)
        }
    }, [editDisplayIdStatus])

    useEffect(() => {
        dispatch(
            fetchFileListRequest({
                work_order_id: workOrder.work_order_id,
            })
        );
    }, [dispatch, workOrder.work_order_id])

    const closeDrawer = useCallback(() => {
        if (notesEditingState) {
            Modal.confirm({
                width: 500,
                title: t("common.modal.unsavedwarning"),
                onOk() {
                    setTrialType("current")
                    setIsModalVisible(false)
                    setNotesEditingState(false)
                },
                okText: t("common.yes"),
                okType: 'danger',
                cancelText: t("common.no"),
                onCancel() { },
            })
        } else {
            setTrialType("current")
            setIsModalVisible(false)
            setNotesEditingState(false)
        }
        dispatch(findAndUpdateSpecificExperimentDataClear())
    }, [notesEditingState, setTrialType, setIsModalVisible, t, dispatch])

    const uploadProps = useMemo(() => ({
        multiple: false,
        showUploadList: false,
        name: 'fileAttachment',
        customRequest: ({ file }: any) => {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('payload', JSON.stringify({
                "work_order_id": workOrder.work_order_id,
                "formulation_id": modalTrial,
                "project_id": project_id,
                "work_order_stage": workOrder.work_order_stage,
                "file_type": (file as any).type,
                "file_name": (file as any).name
            }))
            dispatch(uploadFileAttachmentRequest({
                formData, details: { "work_order_id": workOrder.work_order_id }
            }))
        }
    }), [project_id, workOrder, modalTrial, dispatch])

    const easyMDE = useRef<any>(null)

    useEffect(() => {
        if (modalTrial) {
            if (!easyMDE.current) {
                easyMDE.current = new EasyMDE({
                    autosave: {
                        uniqueId: 'trial-note'
                    },
                    autoRefresh: true,
                    toolbar: ['bold', 'italic', 'strikethrough', '|', 'code', '|', 'heading-1', 'heading-2', '|', 'unordered-list', 'ordered-list', '|', 'quote', 'horizontal-rule', '|', 'link'],
                    element: document.getElementById('trial-note') ?? undefined,
                    placeholder: t("common.Addyournoteshere"),
                    initialValue: form.getFieldValue('comment')
                })
                easyMDE.current.codemirror.on('change', () => {
                    form.setFieldsValue({ comment: easyMDE.current.value() })
                })
            } else {
                easyMDE.current.value(form.getFieldValue('comment'))
            }
        }
        return () => {
            if (easyMDE.current) {
                easyMDE.current.cleanup()
            }
        }
    }, [form, modalTrial, t])

    return (
        <>
            <Drawer
                title={initialTrials.find((res: any) => res?.id_set?.formulation_id === modalTrial)?.meta?.display_id || linkedTrials.find((res: any) => res?.formulation_id === modalTrial)?.formulation_display_id || ""}
                closable
                onClose={closeDrawer}
                open={isModalVisible}
                width="50%"
                forceRender={true}
                afterOpenChange={() => {
                    easyMDE.current.codemirror.refresh()
                }}
            >
                <Spin spinning={editDisplayIdStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                    <Collapse onChange={() => {
                        setTimeout(() => { easyMDE.current.codemirror.refresh() }, 300)
                    }}
                    >
                        <Collapse.Panel forceRender={true} header={<Text strong>{t("workOrderDetails.note")}</Text>} key={"note"}>
                            <Form
                                disabled={userAccess.permission === permissions.viewer || userAccess.status !== projectStatus.in_progress || workOrder?.status === "closed"}
                                layout="vertical"
                                form={form}
                                requiredMark={false}
                                onChange={(e: any) => !notesEditingState && setNotesEditingState(true)}
                                onFinish={(e: any) => dispatch(editDisplayIdRequest({
                                    work_order_id: workOrder?.work_order_id, formulation_id: modalTrial, comment: e.comment
                                }))}>
                                <Form.Item name="comment" required tooltip={requiredFieldStar} rules={[{ required: true, message: t("common.required"), transform: (e: any) => e?.trim() }]}>
                                    <Input.TextArea id="trial-note" disabled={trialType === "linked"} rows={4} />
                                </Form.Item>
                                {(trialType === "current" && workOrder?.status === "open") &&
                                    <Row justify="end">
                                        <StyledButton htmlType="submit" type="primary" >{t("common.save")}</StyledButton>
                                    </Row>
                                }
                            </Form>
                        </Collapse.Panel>
                    </Collapse>
                </Spin>
                <Divider />
                <Collapse>
                    <Collapse.Panel key={"upload"} header={<Text strong>{t("common.upload")}</Text>}>
                        <Space direction="vertical" size="large" style={{ width: '100%' }}>
                            <List
                                size='small'
                                loading={{
                                    spinning: fileListStatus === AsyncStates.LOADING || deleteFileAttachmentStatus === AsyncStates.LOADING,
                                    indicator: <LoadingOutlined />
                                }}
                                itemLayout="vertical"
                                dataSource={attachments.filter((res: any) => res?.formulation_id === modalTrial)}
                                renderItem={(item: any) => (
                                    <List.Item>
                                        <Row justify="space-between">
                                            <Text>{item?.filename}</Text>
                                            <Space>
                                                <StyledButton
                                                    disabled={!["jpg", "jpeg", "gif", "png"].includes(item?.s3_file_key?.split(".").pop())}
                                                    type="link" onClick={() => {
                                                        setImage(item)
                                                        setImageVisible(true)
                                                    }}>{"view"}
                                                </StyledButton>
                                                <StyledButton type="link" href={item?.download_link}>{"download"}</StyledButton>
                                                {workOrder?.status === "open" &&
                                                    <Popconfirm title="Confirm delete ?" onConfirm={() => {
                                                        dispatch(deleteFileAttachmentRequest({
                                                            file_id: item?.file_id,
                                                            work_order_id: workOrder?.work_order_id,
                                                        }))
                                                    }}>
                                                        <StyledButton type="link" danger>{"delete"}</StyledButton>
                                                    </Popconfirm>
                                                }
                                            </Space>
                                        </Row>
                                    </List.Item>)}
                            />
                            {workOrder?.status === "open" &&
                                <Upload {...uploadProps}>
                                    <StyledButton icon={<CloudUploadOutlined />} size="small" type="primary" loading={uploadFileAttachmentStatus === AsyncStates.LOADING}>
                                        {t("common.uploadAFile")}
                                    </StyledButton>
                                </Upload>
                            }
                        </Space>
                    </Collapse.Panel>
                </Collapse>
                <Divider />
                <Spin spinning={updateExperimentsStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                    <TrialDetails trialId={modalTrial} trialType={trialType} from={"drawer"} />
                </Spin>
            </Drawer>
            <Modal title={image?.filename} width={1200} open={imageVisible} maskClosable={false} footer={null} onCancel={() => {
                setImageVisible(false)
                setImage({})
            }}>
                <img style={{ marginTop: 10 }} width={"100%"} height={"100%"} src={image?.url} alt={"Preview not available"} />
            </Modal>
        </>
    )
}
