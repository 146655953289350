// import { LoadingOutlined } from '@ant-design/icons'
import { ExceptionOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Modal, Radio, Row, Select, Space, Steps, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ArchivedParametersContent } from "src/components/Common/ArchivedParametersFoundModal";
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar";
import { AsyncStates } from "src/constants";
import { findArchivedParametersClear, findArchivedParametersRequest, updateArchivedParametersRequest } from "src/store/actions/common";
import { setProject } from "src/store/actions/projects";
import {
  editWorkOrderRequest,
  setIncludeAll,
  includePredictionExp,
  includeSuggestedExp,
  resetWorkOrder,
  setPredictionId,
  setGenerateType as setGenerateTypeRedux,
  addRemoveIngredientsKeyToWorkOrderRequest
} from "src/store/actions/workOrderDetails";
import { workOrdersRequest } from "src/store/actions/workOrders";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { useQuery } from "src/utils/useQuery";
import useTranslate from "src/utils/useTranslate";

const { Text } = Typography;
const { Option } = Select;

export const WorkOrderModal = ({
  workOrderVisible,
  setWorkOrderVisible,
  checkedExperiments,
  experiments,
  type,
  isSelectAll
}: any) => {
  const dispatch = useDispatch();
  const [t] = useTranslate();
  const { push } = useHistory();
  const requiredFieldStar = useRequiredFieldStar()

  let query = useQuery()

  const predId = query?.get("predId")
  const foundArchivedParameters = useSelector((state: StoreState) => state.common.foundArchivedParameters)


  const currentProject = useSelector(
    (state: StoreState) => state.projects.current
  );
  const workOrders = useSelector((state: StoreState) => state.workOrders.data);
  // const workOrdersStatus = useSelector((state: StoreState) => state.workOrders.status)
  const editWOStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.editWOStatus
  );
  const [workOrderType, setWorkOrderType] = useState("new");
  const [generateType, setGenerateType] = useState("classic");
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<any>({});
  const [selectedStage, setSelectedStage] = useState<any>({});
  const [formSubmit, setFormSubmit] = useState(false);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [removeIngredientIds, setRemoveIngredientIds] = useState<string[]>([])

  const [modalForm] = Form.useForm();

  useEffect(() => {
    if (workOrderVisible) {
      modalForm.setFieldsValue({ work_order_type: "new" });
      modalForm.setFieldsValue({ generate_type: "classic" });
      dispatch(workOrdersRequest({ project_id: currentProject }));
    } else {
      setFormSubmit(false);
      dispatch(
        workOrdersRequest({ project_id: currentProject, closed_stages: true })
      )
    }
  }, [workOrderVisible, modalForm, currentProject, dispatch]);

  useEffect(() => {
    if (editWOStatus === AsyncStates.SUCCESS && formSubmit) {
      dispatch(resetWorkOrder());
      dispatch(setProject(selectedWorkOrder?.project_id));
      push(`/work-orders/details/${selectedWorkOrder?.work_order_id}/`);
    }
  }, [editWOStatus, dispatch, selectedWorkOrder, push, formSubmit]);

  const generateWorkOrder = (values: any) => {
    if (workOrderType === "new") {
      dispatch(resetWorkOrder());
      if (type === "forward") {
        dispatch(includePredictionExp(checkedExperiments));
        dispatch(addRemoveIngredientsKeyToWorkOrderRequest({
          remove_ingredient_ids: removeIngredientIds
        }))
      } else {
        dispatch(
          includeSuggestedExp({
            suggested_experiment_id: checkedExperiments,
            version: experiments?.[0]?.version,
            remove_ingredient_ids: removeIngredientIds
          })
        );
      }
      if (isSelectAll) dispatch(setIncludeAll(isSelectAll));
      dispatch(setGenerateTypeRedux(generateType))
      dispatch(setPredictionId(experiments?.[0]?.prediction_id));
      dispatch(addRemoveIngredientsKeyToWorkOrderRequest({
        remove_ingredient_ids: removeIngredientIds
      }))
      push("/work-orders/create-workorder");
    } else {
      setFormSubmit(true);
      const { work_order_id, stage } = values;
      const expIdIndex = selectedWorkOrder.stages?.findIndex(
        (res: any) => res?.identifier === stage
      );
      dispatch(
        editWorkOrderRequest({
          work_order_id,
          ...(type === "forward" && {
            prediction_experiment_ids: checkedExperiments,
          }),
          ...(type === "inverse" && {
            suggested_experiment_id: checkedExperiments,
          }),
          ...(isSelectAll && {
            prediction_id: experiments?.[0]?.prediction_id,
          }),
          ...(!!removeIngredientIds.length && {
            remove_ingredient_ids: removeIngredientIds
          }),
          stage: stage,
          status: "open",
          experiment_id: selectedWorkOrder?.experiment_id?.[expIdIndex],
          data: {
            work_order_parent_stage: stage,
            work_order_stage: "formulation_stage",
            ...(type === "forward" && {
              prediction_experiment_ids: checkedExperiments,
            }),
            ...(type === "inverse" && {
              suggested_experiment_id: checkedExperiments,
            }),
            ...(isSelectAll && {
              prediction_id: experiments?.[0]?.prediction_id,
            }),
          },
          updateDisplayNames: true
        })
      );
    }
    dispatch(findArchivedParametersClear())
  };

  const closeWoModal = () => {
    dispatch(findArchivedParametersClear())
    modalForm.resetFields();
    setSelectedWorkOrder({});
    setSelectedStage({});
    setWorkOrderType("new");
    setWorkOrderVisible(false);
    setSelectedRowKeys([])
    setRemoveIngredientIds([])
    setCurrentStep(0)
  };


  useEffect(() => {
    if (predId && workOrderVisible) {
      dispatch(findArchivedParametersRequest({ id: predId, type: type }))
    }
  }, [dispatch, predId, type, workOrderVisible])


  const handleRemoveIngredients = () => {
    setRemoveIngredientIds(selectedRowKeys)
    if (type === foundArchivedParameters.type && predId === foundArchivedParameters.id) {
      dispatch(updateArchivedParametersRequest({ inventory_ids: selectedRowKeys }))
      setSelectedRowKeys([])
    }
  }

  const handleNext = () => {
    setCurrentStep(1)
  }

  return (
    <Modal
      open={workOrderVisible}
      onCancel={closeWoModal}
      footer={null}
      width={900}
      maskClosable={false}
      title={
        t("common.generateWorkOrder")
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem"
        }}>
        <div
          style={{
            width: "50%"
          }}>
          <Steps
            current={currentStep}
            onChange={(curr) => {
              if (curr === 1 && !!foundArchivedParameters.data.length) {
                return;
              } else if (curr === 0 && !foundArchivedParameters.data.length) {
                return;
              }
              setCurrentStep(curr);
            }}
            items={[
              {
                title: `Found Archived Parameter`,
                icon: <ExceptionOutlined />,
                disabled: !foundArchivedParameters.data.length && foundArchivedParameters.status === AsyncStates.SUCCESS
              },
              {
                title: t("common.generateWorkOrder"),
                icon: <PlusOutlined />,
                disabled: !!foundArchivedParameters.data.length && foundArchivedParameters.status === AsyncStates.SUCCESS
              },
            ]}
          />
        </div>
        <div
          style={{
            padding: "0.5rem",
          }}
        >
          {
            currentStep === 0 && (
              <ArchivedParametersContent selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} handleRemoveIngredients={handleRemoveIngredients} handleNext={handleNext} isMultiStage={!!experiments?.[0]?.is_multistage} />
            )
          }

          {
            currentStep === 1 && (
              <>
                {/* <Spin spinning={workOrdersStatus === AsyncStates.LOADING || editWOStatus === AsyncStates.LOADING}
            indicator={<LoadingOutlined />}>  */}
                <Form
                  form={modalForm}
                  name="generate-work-order"
                  layout="vertical"
                  onFinish={generateWorkOrder}
                  requiredMark={false}
                >
                  {/* <Form.Item
        label={<Text strong>{"Work Order"}</Text>}
        name="work_order_type"
        rules={[{ required: true, message: t("common.required") }]}
        required
        tooltip={requiredFieldStar}
      >
        <Radio.Group
          onChange={(e) => setWorkOrderType(e.target.value)}
          value={workOrderType}
        >
          <Radio value={"new"}>{"Generate New Work Order"}</Radio>
          <Radio value={"existing"}>{"Add in existing Work Order"}</Radio>
        </Radio.Group>
      </Form.Item> */}
                  <Form.Item
                    label={t("aiEngine.inverseModel.selectType")}
                    name="generate_type"
                    rules={[{ required: true, message: t("common.required") }]}
                    required
                    tooltip={requiredFieldStar}
                  >
                    <Radio.Group
                      onChange={(e) => setGenerateType(e.target.value)}
                      value={generateType}
                    >
                      <Radio value={"classic"}>{"Classic Work Order"}</Radio>
                      <Radio value={"ai_recommended"} disabled>{"AI Recommended"}</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Text style={{ color: "#878787" }}>
                    {generateType === 'ai_recommended' ? 'No changes to AI prediction data can be made once generated. Only property sub-stage can edited.' : t("common.fullyEditable")}
                  </Text>

                  {workOrderType === "existing" && (
                    <>
                      <Form.Item
                        label={<Text strong>{t("aiEngine.customInsights.selectWorkOrder")}</Text>}
                        name="work_order_id"
                        rules={[{ required: true, message: t("common.required") }]}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <Select
                          showSearch
                          onChange={(e: any) => {
                            setSelectedStage({});
                            modalForm.setFieldsValue({ stage: "" });
                            setSelectedWorkOrder(
                              workOrders.find((res: any) => res?.work_order_id === e)
                            );
                          }}
                        >
                          {workOrders.map((res: any) => (
                            <Option key={res?.work_order_id} value={res?.work_order_id}>
                              {res?.work_order_name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={<Text strong>{"Select Stage"}</Text>}
                        name="stage"
                        rules={[{ required: true, message: t("common.required") }]}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <Select
                          showSearch
                          onChange={(e: any) =>
                            setSelectedStage(
                              selectedWorkOrder?.stages?.find(
                                (res: any) => res?.identifier === e
                              )
                            )
                          }
                        >
                          {selectedWorkOrder?.stages?.map((res: any, index: number) => (
                            <Option key={res?.identifier} value={res?.identifier}>
                              {res?.name
                                ? `Stage ${index + 1}: ${res.name}`
                                : `Stage ${index + 1}`}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {selectedStage?.status === "closed" && (
                        <Form.Item>
                          <Text type="warning">
                            {
                              "This stage is closed. It will be reopened after adding data in this stage."
                            }
                          </Text>
                        </Form.Item>
                      )}
                    </>
                  )}
                  <Row justify={"end"} style={{ marginTop: 40 }}>
                    <Space>
                      <StyledButton onClick={closeWoModal}>
                        {t("common.cancel")}
                      </StyledButton>
                      <StyledButton type="primary" htmlType="submit">
                        {workOrderType === "new" ? "Generate" : "Add"}
                      </StyledButton>
                    </Space>
                  </Row>
                </Form>
                {/* </Spin> */}
              </>
            )
          }
        </div>
      </div>
    </Modal >
  );
};
