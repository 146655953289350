import { Col, Drawer, Form, Input, Row } from "antd";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import { useEffect, useState } from "react";
import "./Common.scss";
import MethodParameters from "./MethodParameters/MethodParameters";
import { useDispatch, useSelector } from "react-redux";
import {
    createMethodsRequest,
    updateMethodsRequest,
} from "src/store/actions/repository";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";

type TProps = {
    showMethodDrawer: boolean;
    setShowMethodDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    parameters: { [key: string]: string | boolean }[];
    selectedPropertyId: string;
    mode?: "create" | "edit";
    editMethodId?: string;
};

const AddEditMethodDrawer = ({
    showMethodDrawer,
    setShowMethodDrawer,
    selectedPropertyId,
    parameters,
    mode = "create",
    editMethodId,
}: TProps) => {
    const [t] = useTranslate();
    const methods = useSelector(
        (state: StoreState) => state.repository.methods.data
    );
    const methodsStatus = useSelector((state: StoreState) => state.repository.methods.status);

    const [rawTableData, setRawTableData] = useState<{ [key: string]: any }[]>(
        mode === "edit"
            ? methods.find((m: any) => m.method_id === editMethodId)?.parameters?.map((p: any) => ({ ...p, key: p.parameter_id }))
            : []
    );
    const [methodForm] = Form.useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        // Reset
        if (rawTableData.length === 0) methodForm.resetFields();
    }, [rawTableData, methodForm]);

    const submit = () => {
        const payload: { [key: string]: any } = {
            parameters: rawTableData,
            property_id: selectedPropertyId,
        };

        if (mode === "create") {
            payload.method_name = String(methodForm.getFieldValue("method_name") || "").trim();
            dispatch(createMethodsRequest(payload));
        }
        if (mode === "edit") {
            payload.method_id = editMethodId;
            dispatch(updateMethodsRequest(payload));
        }
    };

    const reset = () => {
        setRawTableData([]);
    };

    return (
        <>
            <Drawer
                open={showMethodDrawer}
                title={
                    <div className="drawer-title">
                        <div>
                            {mode === "create"
                                ? t("repository.createNewMethod")
                                : t("repository.editNewMethod")}
                        </div>
                    </div>
                }
                placement="right"
                width={600}
                rootClassName="edit-method-drawer"
                onClose={() => setShowMethodDrawer(false)}
                destroyOnClose
            >
                <Form
                    onFinish={submit}
                    form={methodForm}
                    layout="vertical"
                    scrollToFirstError
                    className="method-drawer-body"
                >
                    {mode === "create" && (
                        <Form.Item
                            name="method_name"
                            label={t("repository.newMethod")}
                            rules={[
                                {
                                    required: true,
                                },
                                {
                                    whitespace: true,
                                }
                            ]}
                        >
                            <Input placeholder={t("common.enterName")} />
                        </Form.Item>
                    )}

                    <MethodParameters
                        rawTableData={rawTableData}
                        setRawTableData={setRawTableData}
                        parameters={parameters}
                        editable={true}
                        methodForm={methodForm}
                        mode={mode}
                    />

                    <div className="method-drawer-empty"></div>
                    <Row gutter={[8, 8]} justify={"end"} style={{ marginTop: "20px" }}>
                        <Col>
                            <StyledButton type="default" onClick={reset}>
                                {t("common.reset")}
                            </StyledButton>
                        </Col>
                        <Col>
                            <StyledButton type="primary" htmlType="submit" loading={methodsStatus === AsyncStates.LOADING}>
                                {t("common.submit")}
                            </StyledButton>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};

export default AddEditMethodDrawer;
