import React, { useEffect, useState } from 'react'
import "./FirstTimeUpdatePassword.scss"
import { AsyncStates, currentPlatform, platformInfo } from 'src/constants'
import Title from 'antd/es/typography/Title'
import { Form, Input, message } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'
import useTranslate from 'src/utils/useTranslate'
import { useForm } from 'antd/es/form/Form'
// import { useDispatch } from 'react-redux'
import { red } from '@ant-design/colors'
import { StyledButton } from 'src/styled_components/StyledButton'
import { useDispatch, useSelector } from 'react-redux'
import { updatePasswordRequest } from 'src/store/actions/updatePassword'
import Sha1 from "js-sha1";
import { UpdatePasswordReducerState } from "src/store/reducers/updatePassword"
import { StoreState } from 'src/store/configureStore'
import { useRequiredFieldStar } from '../Common/useRequiredFieldStar'


type Data = {
    old_password: string
    new_password: string
    confirm_new_password: string
}

export const FirstTimeUpdatePassword = () => {

    const [t] = useTranslate()
    const [form] = useForm()
    const dispatch = useDispatch()
    const requiredFieldStar = useRequiredFieldStar()

    const updatePasswordResponse = useSelector<StoreState, UpdatePasswordReducerState>((state) => state.updatePassword)

    const [isPasswordNotMatch, setIsPasswordNotMatch] = useState(false)

    function submitForm(values: Data) {
        setIsPasswordNotMatch(false)
        if (values.new_password?.trim() !== values.confirm_new_password?.trim()) {
            setIsPasswordNotMatch(true)
            return
        }
        if (values.old_password?.trim() === values.new_password?.trim()) {
            message.error(t("updatePassword.message.passwordMatchedError"))
            return
        }

        dispatch(
            updatePasswordRequest({
                old_password: Sha1(values.old_password.trim()),
                new_password: Sha1(values.new_password.trim()),
            })
        )
    }

    useEffect(() => {
        if (updatePasswordResponse.status === AsyncStates.SUCCESS) {
            form.resetFields()
        }
    }, [updatePasswordResponse, form, dispatch])

    return (
        <div className='first__time__update__password__container'>
            <div className='first__time__update__password__form'>
                <a
                    target="_blank"
                    href={currentPlatform && platformInfo[currentPlatform].link}
                    rel="noopener noreferrer"
                    className='first__time__update__password__form__link__container'
                >
                    <div
                        className='first__time__update__password__company__info__container'
                    >
                        <img
                            src="/icons/New-Polymerize-logo-192x192@2x.png"
                            alt="Polymerize Logo"
                            height={"36"}
                            width={"36"}
                        />
                        <div className='first__time__update__password__form__title__container'>
                            <Title level={3} className='first__time__update__password__form__title'>{t('common.polymerize')}</Title>
                            <span className='first__time__update__password__form__title__desc'>{t("common.polymerizeSubtitle")}</span>
                        </div>
                    </div>
                </a>

                <div
                    className='update__password__container'
                >
                    <div className='update__password__header'>
                        {t("resetPassword.resetPassword")}
                    </div>
                    <Form
                        form={form}
                        name="update__password"
                        layout="vertical"
                        className='update__password__form__container'
                        onFinish={submitForm}
                        requiredMark={false}
                    >
                        <Form.Item
                            label={t("common.oldPassword")}
                            name="old_password"
                            required
                            tooltip={requiredFieldStar}
                            rules={[
                                {
                                    required: true,
                                    message: (
                                        <>
                                            <InfoCircleFilled
                                                color="red.primary"
                                                className='info__icon'
                                            />
                                            {t("firstTimeUpdatePassword.inputOldPassword")}
                                        </>
                                    ),
                                }
                            ]}
                        >
                            <Input.Password
                                type="password"
                                size="large"
                                placeholder={t("common.oldPassword")}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t("common.newPassword")}
                            name="new_password"
                            required tooltip={requiredFieldStar}
                            rules={[
                                {
                                    required: true,
                                    message: (
                                        <span>
                                            <InfoCircleFilled
                                                color="red.primary"
                                                className='info__icon'
                                            />
                                            {t("firstTimeUpdatePassword.inputNewPassword")}
                                        </span>
                                    ),
                                },
                                {
                                    pattern: new RegExp(
                                        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])([a-zA-Z0-9@!*#$%]{8,})*$/
                                    ),
                                    message: (
                                        <>
                                            <InfoCircleFilled
                                                color="red.primary"
                                                className='info__icon'
                                            />
                                            {t('firstTimeUpdatePassword.inputNewPasswordError')}
                                        </>
                                    ),
                                },
                            ]}
                        >
                            <Input.Password
                                type="password"
                                size="large"
                                placeholder={t("common.newPassword")}
                                minLength={8}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t("common.confirmNewPassword")}
                            name="confirm_new_password"
                            required tooltip={requiredFieldStar}
                            rules={[
                                {
                                    required: true,
                                    message: (
                                        <>
                                            <InfoCircleFilled
                                                color="red.primary"
                                                className='info__icon'
                                            />
                                            {t("firstTimeUpdatePassword.inputConfirmNewPassword")}
                                        </>
                                    ),
                                },
                                {
                                    pattern: new RegExp(
                                        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])([a-zA-Z0-9@!*#$%]{8,})*$/
                                    ),
                                    message: (
                                        <>
                                            <InfoCircleFilled
                                                color="red.primary"
                                                className='info__icon'
                                            />
                                            {t('firstTimeUpdatePassword.inputNewPasswordError')}
                                        </>
                                    ),
                                },
                            ]}
                        >
                            <Input.Password
                                type="password"
                                size="large"
                                placeholder={t("common.confirmNewPassword")}
                                minLength={8}
                            />
                        </Form.Item>

                        {isPasswordNotMatch && (
                            <Form.Item>
                                <span style={{ color: red.primary }}>
                                    {t("updatePassword.message.passwordMismatch")}
                                </span>
                            </Form.Item>
                        )}

                        <Form.Item>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    alignItems: "center",
                                }}
                            >
                                <StyledButton
                                    className={"update__password__btn"}
                                    size="large"
                                    type="primary"
                                    htmlType="submit"
                                    loading={updatePasswordResponse.status === AsyncStates.LOADING}
                                >
                                    {t("common.submit")}
                                </StyledButton>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <div
                className='first__time__update__password__footer__container'
            >
                <div className='first__time__update__password__footer'>
                    <div className='first__time__update__password__footer__links'>
                        <a
                            target="_blank"
                            href={"https://polymerize.io/privacy-policy"}
                            rel="noopener noreferrer"
                            className='first__time__update__password__footer__link'
                        >
                            {t("common.privacyPolicy")}
                        </a>
                        |

                        <a
                            target="_blank"
                            href={"https://polymerize.io/terms-of-use"}
                            rel="noopener noreferrer"
                            className='first__time__update__password__footer__link'
                        >
                            {t("footer.tc")}
                        </a>
                        |
                        <a
                            target="_blank"
                            href={"https://polymerize.freshstatus.io"}
                            rel="noopener noreferrer"
                            className='first__time__update__password__footer__link'
                        >
                            {t('footer.serviceStatus')}
                        </a>
                    </div>
                    <span className='first__time__update__password__footer__copyright'>
                        {t("navbar.footer.rightsReserved")} © 2024
                    </span>
                </div>
            </div>
        </div >
    )
}
