import { Modal, Spin, Tabs, Typography } from 'antd'
import { TabsProps } from 'antd/lib'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { SelectedIngredientIdsType } from 'src/modules/InventoryV2'
import { ArchiveOrDeleteIngredientsTable } from 'src/modules/InventoryV2/ArchiveIngredients/ArchiveIngredients'
import { deleteMultipleIngredientsClear, deleteMultipleIngredientsRequest, validateArchivingIngredientsRequest, validateDeleteMultipleIngredientsClear } from 'src/store/actions/inventoryV2'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'


type DeleteIngredientsModalPropsTypes = {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
    ingredientIds?: string[],
    activeTab: "active" | "archived",
    setSelectedIngredientIds: Dispatch<SetStateAction<SelectedIngredientIdsType>>
}


export type DeleteIngredientsConfirmationModalTabTypes = "able_to_delete" | "unable_to_delete"


export const DeleteIngredientsConfirmationModal = ({ open, setOpen, ingredientIds = [], activeTab, setSelectedIngredientIds }: DeleteIngredientsModalPropsTypes) => {
    const [t] = useTranslate()
    const dispatch = useDispatch()

    const deleteIngredientValidationStatus = useSelector((state: StoreState) => state.inventoryV2.validateDeleteMultipleIngredients.status);
    const deleteIngredientValidationData = useSelector((state: StoreState) => state.inventoryV2.validateDeleteMultipleIngredients.data);
    const selectedIngredientIds = useSelector((state: StoreState) => state.inventoryV2.validateDeleteMultipleIngredients.inventory_ids);
    const multipleDeleteIngredientStatus = useSelector((state: StoreState) => state.inventoryV2.multipleDeleteIngredient.status);
    const deletedIngredientIds = useSelector((state: StoreState) => state.inventoryV2.multipleDeleteIngredient.deleted_inventory_ids);

    const [currentTab, setCurrentTab] = useState<DeleteIngredientsConfirmationModalTabTypes>("able_to_delete")

    const items: TabsProps['items'] = useMemo(() => [
        {
            key: 'able_to_delete',
            label: `${t("deleteIngredients.tab.canBedeleted")} (${deleteIngredientValidationData?.able_to_delete?.length ?? 0})`,
        },
        {
            key: 'unable_to_delete',
            label: `${t("deleteIngredients.tab.cannotBedeleted")} (${deleteIngredientValidationData?.unable_to_delete?.length ?? 0})`,
        },
    ], [deleteIngredientValidationData?.able_to_delete?.length, deleteIngredientValidationData?.unable_to_delete?.length, t])


    const handleTabChange = (key: string) => {
        setCurrentTab(key as DeleteIngredientsConfirmationModalTabTypes);
    };

    const handleProceed = () => {
        if (!!deleteIngredientValidationData?.able_to_delete?.length) {
            const idsToDelete = deleteIngredientValidationData?.able_to_delete.map((ingredient) => ingredient.inventory_id)
            dispatch(deleteMultipleIngredientsRequest({
                inventory_ids: idsToDelete,
                activeTab: activeTab
            }))
        }
    }

    const handleClose = useCallback(() => {
        setOpen(false)
        dispatch(validateDeleteMultipleIngredientsClear())
        dispatch(deleteMultipleIngredientsClear())
    }, [dispatch, setOpen])


    useEffect(() => {
        if (multipleDeleteIngredientStatus === AsyncStates.SUCCESS) {
            if (deletedIngredientIds?.length) {
                setSelectedIngredientIds((prev) => {
                    return {
                        ...prev,
                        [activeTab]: prev[activeTab].filter((id) => !deletedIngredientIds.includes(id)),
                    }
                })
            }
            handleClose()
        }
    }, [activeTab, deletedIngredientIds, handleClose, multipleDeleteIngredientStatus, setSelectedIngredientIds])


    return (
        <Modal
            open={open}
            maskClosable={false}
            width={900}
            title={t("common.deleteIngredient")}
            onCancel={() => handleClose()}
            footer={<div style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "0.25rem"
            }}>
                <StyledButton onClick={() => handleClose()}>{t("common.cancel")}</StyledButton>
                <StyledButton
                    loading={multipleDeleteIngredientStatus === AsyncStates.LOADING}
                    disabled={deleteIngredientValidationStatus === AsyncStates.LOADING || deleteIngredientValidationStatus === AsyncStates.ERROR || !deleteIngredientValidationData?.able_to_delete?.length} type="primary" onClick={() => handleProceed()}>{t("common.proceed")}</StyledButton>
            </div>}
        >
            <Spin
                spinning={deleteIngredientValidationStatus === AsyncStates.LOADING}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem"
                }}>
                    <span>
                        {t("common.deleteIngredientsNote")}
                    </span>
                    <div
                        style={{
                            display: "flex",
                            gap: "2rem",
                            flexDirection: "column"
                        }}
                    >
                        <Tabs activeKey={currentTab} items={items} onChange={handleTabChange} />
                        <ArchiveOrDeleteIngredientsTable showWorkOrderColumn={currentTab === "unable_to_delete"} data={deleteIngredientValidationData?.[currentTab] ?? []} currentTab={currentTab} />
                    </div>
                </div>
                {deleteIngredientValidationStatus === AsyncStates.ERROR && <div style={{ display: "flex", justifyContent: "space-between", padding: "0.5rem" }}>
                    <Typography.Text type="danger" strong>{t("common.errorFetchingProperties")}</Typography.Text>
                    {!!selectedIngredientIds.length && <StyledButton
                        onClick={() => {
                            dispatch(validateArchivingIngredientsRequest({
                                inventory_ids: selectedIngredientIds
                            }))
                        }}>
                        {t("common.retry")}
                    </StyledButton>
                    }
                </div>
                }
            </Spin>
        </Modal>
    )
}
