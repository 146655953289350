import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useTranslate from 'src/utils/useTranslate'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { Divider, Input, Select, Typography } from 'antd'
import EmptyComponent from 'src/components/QualityCheckMetadata/EmptyComponent'
import _ from 'lodash'
import { antdTheme, AsyncStates } from 'src/constants'
import { EnterOutlined, DeleteOutlined } from '@ant-design/icons'
import { qcCreateNameDetailsRequest, qcDeleteNameDetailsRequest } from 'src/store/actions/qualityCheck'

const ReportMappingTable = ({ data, setData }: { data: any[]; setData: any }) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const [localData, setLocalData] = useState(data)
  const [newOptionName, setNewOptionName] = useState<string>('')

  useEffect(() => {
    setLocalData(data)
  }, [data])

  useEffect(() => {
    setData(localData)
  }, [localData, setData])

  const onChange = useCallback((key: string, idx: number, value: any) => {
    setLocalData((prev) => {
      const updatedData = _.cloneDeep(prev || [])
      updatedData[idx][key] = value
      return updatedData
    })
  }, [])

  const { getNameDetailsStatus, getNameDetailsResponse } = useSelector((state: StoreState) => state.qualityCheck)

  const columns = useMemo(() => {
    const textStyles: React.CSSProperties = {
      padding: '0.5rem 0rem'
    }

    return [
      {
        key: 'test',
        title: t('common.test'),
        width: 250,
        render: (record: any, idx: number) => (
          <Typography.Text strong style={textStyles}>
            {record['test']}
          </Typography.Text>
        )
      },
      {
        key: 'method',
        title: t('common.method'),
        width: 200,
        render: (record: any, idx: number) => (
          <Typography.Text strong style={textStyles}>
            {record['method']}
          </Typography.Text>
        )
      },
      {
        key: 'unit',
        title: t('common.unit'),
        width: 150,
        render: (record: any, idx: number) => (
          <Typography.Text strong style={textStyles}>
            {record['unit']}
          </Typography.Text>
        )
      },
      {
        key: 'mapping',
        title: t('qc.metadata.mappedTo'),
        width: 400,
        render: (record: any, idx: number) => (
          <Select
            style={{ width: '100%' }}
            placeholder={t('common.typeOrSelect')}
            dropdownStyle={{
              borderRadius: 2
            }}
            value={record['mapping'] || []}
            className="qc_add_details_select"
            loading={getNameDetailsStatus === AsyncStates.LOADING}
            showSearch
            onChange={(val) => {
              onChange('mapping', idx, val)
            }}
            notFoundContent={<EmptyComponent />}
            optionFilterProp="label"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: '0',
                    marginTop: 4
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.25rem',
                    padding: '0.5rem 1rem'
                  }}
                >
                  <Typography.Text
                    style={{
                      fontSize: antdTheme.fontSizeSM
                    }}
                  >
                    {t('common.addNew')}
                  </Typography.Text>
                  <Input
                    placeholder={`${t('common.add')} ${t('qc.testMapping')}`}
                    value={newOptionName}
                    onChange={(e) => {
                      setNewOptionName(e.target.value)
                    }}
                    suffix={
                      <EnterOutlined
                        style={{
                          color: 'rgba(0, 0, 0, 0.45)'
                        }}
                      />
                    }
                    onKeyDown={(e) => e.stopPropagation()}
                    onPressEnter={() => {
                      if (newOptionName) {
                        dispatch(
                          qcCreateNameDetailsRequest({
                            name: newOptionName,
                            type: 'report_mapping'
                          })
                        )
                        setNewOptionName('')
                      }
                    }}
                    style={{
                      borderRadius: 2
                    }}
                  />
                  <Typography.Text
                    type="secondary"
                    style={{
                      fontSize: antdTheme.fontSizeSM
                    }}
                  >
                    {t('common.tapEnterToAdd')}
                  </Typography.Text>
                </div>
              </>
            )}
            optionRender={(op: any) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  {op.label}
                  {!op?.data?.used_count ? (
                    <DeleteOutlined
                      style={{
                        color: antdTheme.colorError,
                        cursor: 'pointer',
                        outline: 'none'
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                        dispatch(
                          qcDeleteNameDetailsRequest({
                            detail_id: op.value,
                            type: 'report_mapping'
                          })
                        )
                      }}
                    />
                  ) : null}
                </div>
              )
            }}
            options={(getNameDetailsResponse?.report_mapping || []).map((item: any) => ({
              label: item.name,
              value: item.detail_id,
              name: item.name,
              ...item
            }))}
          />
        )
      }
    ]
  }, [t, getNameDetailsStatus, getNameDetailsResponse?.report_mapping, onChange, newOptionName, dispatch])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        height: '100%'
      }}
    >
      <TableTile columns={columns} />
      <TableBody data={localData} columns={columns} />
    </div>
  )
}

export default ReportMappingTable

const TableTile = ({ columns }: { columns: any[] }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: 'max-content',
        minWidth: '100%',
        border: '1px solid rgba(0, 0, 0, 0.06)',
        backgroundColor: '#f5f5f5'
        // For left indent
        // paddingLeft: 24
      }}
    >
      {columns.map((column) => (
        <React.Fragment key={column.key}>
          {/* Actual column name */}
          <div
            style={{
              minWidth: column.width,
              width: column.width,
              fontWeight: 600,
              padding: '0.5rem',
              ...column.style
            }}
          >
            {column.title}
          </div>
          {/* Right separator */}
          <div
            style={{
              borderLeft: '1px solid rgba(0, 0, 0, 0.06)',
              margin: '0.25rem 0rem'
            }}
          />
        </React.Fragment>
      ))}
    </div>
  )
}

const TableBody = ({ data, columns }: { data: any[]; columns: any[] }) => {
  return (
    <div
      style={{
        height: 'calc(100% - 2rem)',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: 'max-content',
        minWidth: '100%'
      }}
    >
      {data?.length ? (
        data?.map((row, idx) => (
          <div
            style={{
              display: 'flex',
              width: 'max-content',
              minWidth: '100%',
              borderBottom: '1px solid rgba(0, 0, 0, 0.06)'
            }}
            key={idx}
          >
            {columns.map((column) => (
              <div
                key={`${column.key}_${idx}`}
                style={{
                  minWidth: column.width,
                  width: column.width,
                  padding: '0.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  ...column.style
                }}
              >
                {column.render ? column.render(row, idx) : row[column.key]}
              </div>
            ))}
          </div>
        ))
      ) : (
        <EmptyComponent />
      )}
    </div>
  )
}
