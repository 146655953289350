import { Col, Form, Input, Modal, Row, Select, Switch, message } from "antd";
import { Dispatch, SetStateAction, useEffect } from "react";
import useTranslate from "src/utils/useTranslate";
import { useDispatch, useSelector } from "react-redux";
import {
  InventoryMethod,
  InventoryMethodParameter,
} from "../types";
import {
  createMethodParameterRequest,
  updateMethodParameterRequest,
} from "src/store/actions/inventoryV2";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import { useValue } from "src/utils/useValue";
import { isValidNumber } from "src/utils/decorator";
import { StyledButton } from "src/styled_components/StyledButton";

const { OptGroup, Option } = Select;

type InventoryMethodParameterModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  mode: "create" | "edit";
  method: InventoryMethod;
  methodParameter?: InventoryMethodParameter;
  from: string;
  ingredient: any
};

export const InventoryMethodParameterModal = ({
  open,
  setOpen,
  mode,
  method,
  methodParameter,
  from,
  ingredient
}: InventoryMethodParameterModalProps) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const { convertValue } = useValue()
  const unitsList = useSelector(
    (state: StoreState) => state.conversion.unitList
  );

  const createMethodParameterResponse = useSelector(
    (state: StoreState) => state.inventoryV2.createMethodParameter
  );

  const updateMethodParameterResponse = useSelector(
    (state: StoreState) => state.inventoryV2.updateMethodParameter
  );

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    if (mode === "create") {
      dispatch(
        createMethodParameterRequest({
          ...values,
          ...(from === 'family' ? { family_id: method?.family_id } : { inventory_id: method?.inventory_id }),
          ...(from === 'family' ? { property_id: method?.property_id } : { property_id: method?.inventory_property_id }),
          method_id: method.method_id,
          value: values.value ? convertValue(values.value) : undefined,
          value_max: values.value_max ? convertValue(values?.value_max) : null,
          from
        })
      );
    } else {
      dispatch(
        updateMethodParameterRequest({
          ...values,
          method_id: methodParameter?.method_id,
          ...(from === 'family' ? { methodParameter: method?.family_id } : { inventory_id: methodParameter?.inventory_id }),
          ...(from === 'family' ? { property_id: methodParameter?.property_id } : { property_id: methodParameter?.inventory_property_id }),
          method_parameter_id: methodParameter?.method_parameter_id,
          value: values.value ? convertValue(values.value) : undefined,
          value_max: values.value_max ? convertValue(values?.value_max) : null,
          from
        })
      );
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (
      createMethodParameterResponse.status === AsyncStates.SUCCESS ||
      updateMethodParameterResponse.status === AsyncStates.SUCCESS
    ) {
      if (mode === "create") {
        form.resetFields();
      }
      setOpen(false);
    }
  }, [
    createMethodParameterResponse,
    updateMethodParameterResponse,
    mode,
    setOpen,
    form,
  ]);

  useEffect(() => {
    if (mode === "create") {
      form.resetFields();
    } else {
      form.setFieldsValue(methodParameter);
    }
  }, [form, methodParameter, mode]);

  return (
    <Modal
      title={`${mode === "create" ? "Create" : "Update"} Method Parameter`}
      footer={null}
      width={460}
      open={open}
      onCancel={() => {
        if (mode === "create") {
          form.resetFields();
        }
        setOpen(false)
      }
      }
      maskClosable={false}
    >
      <Form
        size="small"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Parameter"
              name="parameter"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Unit"
              name={"unit"}
              rules={[{ required: true, message: t("common.required") }]}
              required
            >
              <Select showSearch placeholder={t("common.selectUnit")}>
                {Array.from(
                  new Set(
                    unitsList.map((res: any) => res.category.toLowerCase())
                  )
                ).map((category: any) => (
                  <OptGroup
                    label={
                      String(category).charAt(0).toLocaleUpperCase() +
                      String(category).slice(1)
                    }
                  >
                    {unitsList
                      .filter(
                        (res: any) => res.category.toLowerCase() === category
                      )
                      .map((res: any, index: any) => (
                        <Option value={res.name} key={res.name + index}>
                          {res.name}
                        </Option>
                      ))}
                  </OptGroup>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              label="Category"
              name="category"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {from !== 'family' && <Col span={8}>
            <Form.Item
              label="Range"
              name="has_range"
            >
              <Switch />
            </Form.Item>
          </Col>}
        </Row>
        {from !== 'family' && <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Value" name="value" rules={[{ required: true }]}>
              <Input onBlur={(event) => {
                const value = convertValue(event.target.value)
                if (!!value && !isValidNumber(value)) {
                  form.setFieldValue("value", null)
                  message.error("Invalid Input")
                }
                if (form.getFieldValue("has_range") &&
                  !!(value) && !!form.getFieldValue("value_max")
                  && (value > convertValue(form.getFieldValue("value_max")))) {
                  form.setFieldValue("value", null)
                  message.error(t("inventory.ValueisGreaterthanMaxValue"))
                }
              }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.has_range !== currentValues.has_range
              }
            // hidden={(form) => form.getFieldValue('has_range')}
            >
              {({ getFieldValue }) =>
                getFieldValue("has_range") === true ? (
                  <Form.Item
                    label={t("inventory.Maxvalue")}
                    name="value_max"
                    rules={[{ required: true }]}
                  >
                    <Input onBlur={(event) => {
                      const value = convertValue(event.target.value)
                      if (!!value && !isValidNumber(value)) {
                        form.setFieldValue("value_max", null)
                        message.error("Invalid Input")
                      }
                      if (!!form.getFieldValue("value") && !!value &&
                        convertValue(form.getFieldValue("value")) > value) {
                        form.setFieldValue("value_max", null)
                        message.error(t("inventory.Maxvalueislessthanvalue"))
                      }
                    }} />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Col>
        </Row>}

        <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
          <StyledButton
            type="primary"
            htmlType="submit"
            style={{
              float: "right",
            }}
            loading={createMethodParameterResponse.status === AsyncStates.LOADING || updateMethodParameterResponse.status === AsyncStates.LOADING}
          >
            {t("common.submit")}
          </StyledButton>
        </Form.Item>
      </Form>
    </Modal>
  );
};
