import { PlusOutlined } from "@ant-design/icons";
import { Cascader, Checkbox, Col, Form, Input, Modal, Row, Select, Space, Typography} from "antd";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import "./Common.scss";
import { StoreState } from "src/store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useMemo } from "react";
import { AsyncStates } from "src/constants";
import {
    createParametersRequest,
    fetchPropertyRequest,
    updateParametersRequest,
} from "src/store/actions/repository";
import { DefaultOptionType } from "antd/es/cascader";
import { trimWhiteSpaceFromValues } from "src/utils/general/trimmer";

const { Text } = Typography;

type TProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    showPropertyModal?: () => void;
    mode?: "edit" | "create" | "delete";
    selectedParameterId?: string;
    fetchProperties?: boolean;
    linkPropertyId?: string;
    showAddPropertyButton?: boolean
};

const AddEditParameterModal = ({
    showModal,
    setShowModal,
    showPropertyModal,
    mode = "create",
    selectedParameterId,
    fetchProperties = true,
    linkPropertyId,
    showAddPropertyButton = true
}: TProps) => {
    const [t] = useTranslate();
    const [parameterForm] = Form.useForm();
    const parameters = useSelector(
        (state: StoreState) => state.repository.parameters.data
    );
    const dispatch = useDispatch();
    const unitsList = useSelector(
        (state: StoreState) => state.conversion.unitList
    );
    const createParameterStatus = useSelector(
        (state: StoreState) => state.repository.parameters.status
    );
    const properties = useSelector(
        (state: StoreState) => state.repository.properties.data
    );

    useLayoutEffect(() => {
        if (fetchProperties) dispatch(fetchPropertyRequest());
    }, [dispatch, fetchProperties]);

    useEffect(() => {
        if (mode === "edit" && selectedParameterId) {
            const parameter = parameters.find(
                (a) => a.parameter_id === selectedParameterId
            );
            const categoryName = parameter?.category_name;
            const propertyId = parameter?.property_id;

            parameterForm.setFieldsValue({
                parameter: parameter?.parameter,
                property_id: categoryName ? [categoryName, propertyId] : [],
                units: parameter?.units ?? []
            });
        }
    }, [selectedParameterId, parameters, mode, parameterForm]);

    useEffect(() => {
        if (mode === "create") {
            parameterForm.resetFields();
            if (linkPropertyId) {
                const categoryName = properties.find(
                    (p) => p.property_id === linkPropertyId
                )?.category_name;

                parameterForm.setFieldsValue({
                    property_id: [categoryName, linkPropertyId],
                });
            }
        }
    }, [mode, linkPropertyId, properties, parameterForm, parameters]);

    useEffect(() => {
        if (createParameterStatus === AsyncStates.SUCCESS) {
            setShowModal(false);
        }
    }, [createParameterStatus, setShowModal, parameterForm, dispatch]);

    const propertyByCategories = useMemo(() => {
        const propertyCategories = [
            ...new Set(properties.map((p) => p.category_name)),
        ].filter(p => p).sort((a, b) => a?.localeCompare(b));

        return propertyCategories.map((category_name: any) => ({
            label: category_name,
            value: category_name,
            children: properties
                .filter((p) => p.category_name === category_name)
                .sort((a, b) => a.name?.localeCompare(b.name))
                .map((p) => ({
                    label: p.name,
                    value: p.property_id,
                })),
        }));
    }, [properties]);

    const unitOptions = useMemo(() => {
        return [...new Set(unitsList.map((unit) => unit.name))];
    }, [unitsList]);

    const submit = () => {
        const payload = trimWhiteSpaceFromValues(parameterForm.getFieldsValue());
        payload["property_id"] = payload["property_id"][1];

        if (mode === "create") dispatch(createParametersRequest(payload));
        if (mode === "edit")
            dispatch(
                updateParametersRequest({
                    ...payload,
                    parameter_id: selectedParameterId,
                })
            );
    };

    const filter = (inputValue: string, path: DefaultOptionType[]) => 
        path.some(
            (option) => String(option.label || "").toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
        );

    return (
        <Modal
            title={
                <Typography.Title level={4}>{`${mode === "create" ? t("repository.addNewParameter") : t("repository.editParameter")}`}</Typography.Title>
            }
            open={showModal}
            onOk={submit}
            onCancel={() => setShowModal(false)}
            footer={null}
            rootClassName="repository-custom-modal"
        >
            <Form
                onFinish={submit}
                form={parameterForm}
                layout="vertical"
                scrollToFirstError
            >
                <Form.Item
                    name="parameter"
                    label={t("common.parameter")}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            whitespace: true
                        }
                    ]}
                >
                    <Input placeholder={t("common.enterName")} />
                </Form.Item>

                <Form.Item
                    name="property_id"
                    label={t("repository.linkToProperty")}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Cascader
                        options={propertyByCategories}
                        rootClassName="repository-cascader"
                        placeholder={t("common.select")}
                        showSearch={{ filter }}
                        dropdownRender={(menus: React.ReactNode) => (
                            <div>
                                {menus}
                                {
                                    showAddPropertyButton && (
                                        <div className="add-button" onClick={showPropertyModal}>
                                            <PlusOutlined /> {t("common.addNewProperty")}
                                        </div>
                                    )
                                }
                            </div>
                        )}
                    />
                </Form.Item>

                <Form.Item
                    name={"units"}
                    label={t("common.units")}
                    // rules={[
                    //     {
                    //         required: true,
                    //     },
                    // ]}
                >
                    <Select
                        loading={false}
                        placeholder={t("common.select")}
                        showSearch={true}
                        mode="multiple"
                        maxTagCount="responsive"
                        optionRender={(option) => (
                            <Space direction="horizontal">
                                <Checkbox
                                    checked={parameterForm
                                        .getFieldValue("units")
                                        ?.includes(option.value)}
                                />
                                <Text style={{ whiteSpace: "initial" }}>{option.label}</Text>
                            </Space>
                        )}
                        options={unitOptions.map((item) => ({
                            label: item,
                            value: item,
                        }))}
                    />
                </Form.Item>
                <Row gutter={[8, 8]} justify={"end"} style={{ marginTop: "20px" }}>
                    <Col>
                        <StyledButton
                            type="default"
                            onClick={() => parameterForm.resetFields()}
                        >
                            {t("common.reset")}
                        </StyledButton>
                    </Col>
                    <Col>
                        <StyledButton
                            type="primary"
                            htmlType="submit"
                            disabled={createParameterStatus === AsyncStates.LOADING}
                            loading={createParameterStatus === AsyncStates.LOADING}
                        >
                            {t("common.submit")}
                        </StyledButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddEditParameterModal;
