import { FilterOutlined } from '@ant-design/icons'
import { Typography, Popover, Col, Row, Space, DatePicker, Input, Form, Select, Checkbox, Badge } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { qcSetGetFilesFilters, qcGetFilesRequest, qcSetGetFilesSearchTerm } from 'src/store/actions/qualityCheck'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { toTitleCase } from 'src/utils/general'
import useTranslate from 'src/utils/useTranslate'

import './TopHeader.scss'
import _ from 'lodash'
import { AsyncStates } from 'src/constants'
import { StatusIcon } from '.'

const initialFilters = {
  categories: [],
  suppliers: [],
  product_name: [],
  batch_number: '',
  status: [],
  created_at: null,
  production_date: null,
  operator: 'and'
}

const TopHeader = () => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)

  const [enableFiltersApply, setEnableFiltersApply] = useState<boolean>(false)
  const [enableFiltersClear, setEnableFiltersClear] = useState<boolean>(false)

  const [searchTerm, setSearchTerm] = useState<string>('')

  const { getFilesTotal, getFilesFilters, getFilesSerachTerm } = useSelector((state: StoreState) => state.qualityCheck)

  const [filterSelections, setFilterSelections] = useState<{ [key: string]: any }>(getFilesFilters)

  useEffect(() => {
    if (getFilesSerachTerm) setSearchTerm(getFilesSerachTerm)
  }, [getFilesSerachTerm])

  useEffect(() => {
    const noChange = _.isEqual(getFilesFilters, initialFilters)
    if (!noChange) {
      setEnableFiltersClear(true)
    }
  }, [getFilesFilters])

  return (
    <div
      style={{ height: '5%', display: 'flex', gap: 12, alignItems: 'center', justifyContent: 'space-between' }}
      className="quality-check-files-table-header"
    >
      <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}
 >
        <Typography.Title level={5}>{`${t('common.items')} (${getFilesTotal})`}</Typography.Title>

        <Popover
          content={
            <Space
              direction="vertical"
              size={'middle'}
              style={{
                width: 400,
                padding: 12
              }}
            >
              <FilterForm
                filterSelections={filterSelections}
                setFilterSelections={setFilterSelections}
                enableButtons={() => {
                  setEnableFiltersApply(true)
                  setEnableFiltersClear(true)
                }}
              />
              <Row justify="end" gutter={12}>
                <Col>
                  <StyledButton
                    type="primary"
                    onClick={() => {
                      dispatch(qcSetGetFilesFilters(filterSelections))
                      dispatch(qcGetFilesRequest({ page_num: 1 }))
                      setEnableFiltersApply(false)
                    }}
                    size="small"
                    disabled={!enableFiltersApply}
                  >
                    {t('aiEngine.button.apply')}
                  </StyledButton>
                </Col>
                <Col>
                  <StyledButton
                    onClick={() => {
                      setFilterSelections(initialFilters)
                      dispatch(qcSetGetFilesFilters(initialFilters))
                      dispatch(qcGetFilesRequest({ page_num: 1 }))
                      setEnableFiltersClear(false)
                      setEnableFiltersApply(false)
                    }}
                    size="small"
                    disabled={!enableFiltersClear}
                  >
                    {t('common.clear')}
                  </StyledButton>
                </Col>
              </Row>
            </Space>
          }
          placement="bottomLeft"
          trigger={'click'}
          open={isFilterOpen}
          onOpenChange={(visible) => setIsFilterOpen(visible)}
        >
          <Badge dot={enableFiltersClear}>
            <StyledButton icon={<FilterOutlined />} className={isFilterOpen ? 'action-button-active' : ''}>
              {t('common.filters')}
            </StyledButton>
          </Badge>
        </Popover>

        <Badge dot={getFilesSerachTerm !== ''}>
          <Input.Search
            placeholder={t('common.search')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onSearch={(value) => {
              dispatch(qcSetGetFilesSearchTerm(value))
              dispatch(qcGetFilesRequest({ page_num: 1 }))
            }}
            style={{ width: 200, justifySelf: 'flex-end' }}
            allowClear
          />
        </Badge>
      </div>
      <FileStatusCount />
    </div>
  )
}

export default TopHeader

const FilterForm = ({ setFilterSelections, filterSelections, enableButtons }: any) => {
  const [form] = useForm()
  const [t] = useTranslate()

  const [isCreatedAtDate, setIsCreatedAtDate] = useState<boolean>(!Array.isArray(filterSelections['created_at']))
  const [isProductionDate, setIsProductionDate] = useState<boolean>(!Array.isArray(filterSelections['production_date']))

  const { getNameDetailsResponse } = useSelector((state: StoreState) => state.qualityCheck)

  useEffect(() => {
    form.setFieldsValue(filterSelections)
  }, [filterSelections, form])

  return (
    <Form
      form={form}
      onFieldsChange={() => {
        setFilterSelections(form.getFieldsValue())
        enableButtons()
      }}
      labelAlign="left"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      size="small"
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 12
      }}
      className="qc-files-table-filter-form"
      initialValues={filterSelections}
    >
      <Form.Item name="operator" label={t('common.operator')}>
        <Select
          options={[
            { value: 'and', label: toTitleCase(t('common.and')) },
            { value: 'or', label: toTitleCase(t('common.or')) }
          ]}
        ></Select>
      </Form.Item>
      <Form.Item name="categories" label={t('common.Categories')}>
        <Select
          mode="multiple"
          placeholder={t('common.Categories')}
          allowClear
          showSearch
          optionFilterProp="label"
          maxTagCount={'responsive'}
          options={(getNameDetailsResponse?.category || []).map(({ detail_id, name }: any) => {
            return {
              value: detail_id,
              label: name
            }
          })}
        />
      </Form.Item>

      <Form.Item name="suppliers" label={t('common.Supplier')}>
        <Select
          mode="multiple"
          placeholder={t('common.Supplier')}
          allowClear
          showSearch
          optionFilterProp="label"
          maxTagCount={'responsive'}
          options={(getNameDetailsResponse?.supplier || []).map(({ detail_id, name }: any) => {
            return {
              value: detail_id,
              label: name
            }
          })}
        />
      </Form.Item>

      <Form.Item name="product_name" label={t('qc.productName')}>
        <Select
          mode="multiple"
          placeholder={t('qc.productName')}
          allowClear
          showSearch
          optionFilterProp="label"
          maxTagCount={'responsive'}
          options={(getNameDetailsResponse?.product || [])?.map(({ detail_id, name }: any) => {
            return {
              value: detail_id,
              label: name
            }
          })}
        />
      </Form.Item>

      <Form.Item name="batch_number" label={t('qc.batchNumber')}>
        <Input placeholder={t('qc.batchNumber')} />
      </Form.Item>

      <Form.Item name="status" label={t('common.status')}>
        <Select
          mode="multiple"
          placeholder={t('common.status')}
          allowClear
          showSearch
          optionFilterProp="label"
          maxTagCount={'responsive'}
          options={(getNameDetailsResponse?.status || [])?.map((status: any) => {
            return {
              value: status,
              label: status
            }
          })}
        />
      </Form.Item>

      <Form.Item label={t('common.addedOn')}>
        <div
          style={{
            display: 'flex',
            gap: 12,
            alignItems: 'center'
          }}
        >
          <Checkbox
            checked={isCreatedAtDate}
            onChange={(e) => {
              setIsCreatedAtDate(e.target.checked)
              form.setFieldValue('created_at', null)
              setFilterSelections(form.getFieldsValue())
              enableButtons()
            }}
          >
            {t('common.date')}
          </Checkbox>
          <Form.Item
            name="created_at"
            style={{
              marginBottom: 0,
              flexGrow: 1
            }}
          >
            {isCreatedAtDate ? (
              <DatePicker
                format={'DD-MM-YYYY'}
                style={{
                  width: '100%'
                }}
              />
            ) : (
              <DatePicker.RangePicker format={'DD-MM-YYYY'} />
            )}
          </Form.Item>
        </div>
      </Form.Item>

      <Form.Item label={t('qc.productionDate')}>
        <div
          style={{
            display: 'flex',
            gap: 12,
            alignItems: 'center'
          }}
        >
          <Checkbox
            checked={isProductionDate}
            onChange={(e) => {
              setIsProductionDate(e.target.checked)
              form.setFieldValue('production_date', null)
              setFilterSelections(form.getFieldsValue())
              enableButtons()
            }}
          >
            {t('common.date')}
          </Checkbox>

          <Form.Item
            name="production_date"
            style={{
              marginBottom: 0,
              flexGrow: 1
            }}
          >
            {isProductionDate ? (
              <DatePicker
                format={'DD-MM-YYYY'}
                style={{
                  width: '100%'
                }}
              />
            ) : (
              <DatePicker.RangePicker format={'DD-MM-YYYY'} />
            )}
          </Form.Item>
        </div>
      </Form.Item>
    </Form>
  )
}

const FileStatusCount = () => {
  const { getFilesStatusCount, getFilesDataStatus } = useSelector((state: StoreState) => state.qualityCheck)

  const statusToBeShown = useMemo(
    () =>
      Object.keys(getFilesStatusCount)
        .filter((status) => getFilesStatusCount[status] > 0)
        .sort((a, b) => getFilesStatusCount[b] - getFilesStatusCount[a]),
    [getFilesStatusCount]
  )

  return getFilesDataStatus === AsyncStates.SUCCESS ? (
    <Space size={'large'}>
      {statusToBeShown.map((status) => {
        return (
          <div style={{display:'flex', alignItems:'center', gap: '0.5rem'}} key={status} >
            <StatusIcon status={status} />
            <Typography.Text>{getFilesStatusCount[status]}</Typography.Text>
          </div>
        )
      })}
    </Space>
  ) : null
}
