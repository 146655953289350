import { Row, Space, Spin, Steps } from "antd"
import { useCallback, useEffect, useState } from "react"
import { DataUpload } from "./DataUpload"
import { MapSheets } from "./MapSheets"
import { MapColumns } from "./MapColumns"
import XLSX from "xlsx"
import { SelectExperiments } from "./SelectExperiments"
import { useDispatch, useSelector } from "react-redux"
import { uploadFileRequest } from "src/store/actions/files"
import { volcano } from "@ant-design/colors"
import { StoreState } from "src/store/configureStore"
import { AsyncStates } from "src/constants"
import { LoadingOutlined } from "@ant-design/icons"
import { UploadPreview } from "./UploadPreview"
import { StyledButton } from "src/styled_components/StyledButton"
import { Prompt } from "react-router-dom"
import { MapUnits } from "./MapUnits"
import useTranslate, { TranslationKey } from "src/utils/useTranslate"
import { ExcelMapper } from "./ExcelMapper"
import { useValue } from "src/utils/useValue"
import { convertToPrecision } from "src/utils/decorator"
import { fetchAllMethodsRequest, fetchPropertyRequest } from "src/store/actions/repository"
import { useVariableValue } from "@devcycle/devcycle-react-sdk"
import { addRemoveIngredientsKeyToWorkOrderClear } from "src/store/actions/workOrderDetails"
import { findArchivedParametersClear } from "src/store/actions/common"

const { Step } = Steps

const getTableHeadColTranslatedLabel = (
	input:
		| "ingredients"
		| "ingredients_category"
		| "ingredients_unit"
		| "processing_unit"
		| "processing_parameters"
		| "processing_category"
		| "property_parameters"
		| "property_category"
		| "property_unit"
		| "property_method"
		| "property_standard"
		| "property_specimen"
		| "characterisation_category"
		| "characterisation_parameters"
		| "characterisation_unit",
	t: (key: TranslationKey) => string
) => {
	if (input === "ingredients") return t("common.ingredients")
	if (input === "ingredients_category") return t("common.ingredientsCategory")
	if (input === "processing_unit") return t("mapColumns.processingUnit")
	if (input === "processing_parameters")
		return t("mapColumns.processingParameters")
	if (input === "processing_category") return t("mapColumns.processingCategory")
	if (input === "ingredients_unit") return t("common.ingredientsUnit")
	if (input === "property_category") return t("common.propertyCategory")
	if (input === "property_parameters") return t("inventory.propertyName")
	if (input === "property_unit") return t("common.propertyUnit")
	if (input === "property_method") return "Property Method"
	if (input === "property_standard") return "Property Standard"
	if (input === "property_specimen") return "Property Specimen"
	if (input === "characterisation_category")
		return t("mapColumns.characterisationCategory")
	if (input === "characterisation_parameters")
		return t("mapColumns.characterisationParameters")
	if (input === "characterisation_unit")
		return t("mapColumns.characterisationUnit")
	return input
}

export const DataMapper = ({ workOrder, currentExpIdIndex, unsaved, setUnsaved }: any) => {
	const dispatch = useDispatch()
	const enablePropertyStandards = useVariableValue('property-standards', false)

	const fileUploadStatus = useSelector((state: StoreState) => state.files.uploadFile.status)
	const unitList = useSelector((state: StoreState) => state.conversion.unitList)

	const [current, setCurrent] = useState(0)
	const [file, setFile] = useState<any>()
	const [parsedFile, setParsedFile] = useState<any>({})
	const [sheets, setSheets] = useState<any>([])
	const [selectedExps, setSelectedExps] = useState<any>([]);
	const [selectedExpsKeys, setSelectedExpsKeys] = useState<any>([])
	const [tableData, setTableData] = useState<any>({})
	const [sheetData, setSheetData] = useState<any>({})
	const [unitsData, setUnitsData] = useState<any>([])

	const [t] = useTranslate()
	const { convertValue } = useValue()

	useEffect(() => {
		if (enablePropertyStandards) {
			dispatch(fetchPropertyRequest())
			dispatch(fetchAllMethodsRequest())
		}
	}, [dispatch, enablePropertyStandards])

	const cleanValue = useCallback((value: any) => {
		if (typeof value === "boolean") {
			return String(value)?.[0]?.toUpperCase() + String(value).slice(1)
		} else {
			return convertToPrecision(value, 3)
		}
	}, [])

	useEffect(() => {
		if (fileUploadStatus === AsyncStates.SUCCESS) {
			setUnsaved(false)
			dispatch(addRemoveIngredientsKeyToWorkOrderClear())
			dispatch(findArchivedParametersClear())
		}
	}, [fileUploadStatus, setUnsaved, dispatch])

	useEffect(() => {
		!!file &&
			(async () => {
				const fileSheets = await file.arrayBuffer()
				const workbook = XLSX.read(fileSheets)
				setParsedFile(workbook)
				setSheets(workbook?.["SheetNames"]?.map((res: any) => ({ sheet_name: res })))
			})()
	}, [file])

	useEffect(() => {
		if (!!sheets.length) {
			const expWorkSheet =
				parsedFile.Sheets[
				sheets.find((res: any) => res.sheet_mapped_to === "experiments")
					?.sheet_name
				]
			const expJson: any[] = XLSX.utils.sheet_to_json(expWorkSheet, {
				defval: "-", raw: false
			})
			setTableData((prevState: any) => {
				const newState = { ...prevState }
				newState.experiments = Object.entries(expJson?.[0] || {})
					.filter(([key, value]: any) => !key.includes("EMPTY"))
					.map(([key, value]: any) => ({ columns: key, row_value: value }))
				newState?.experiments?.forEach((element: any) => {
					const trimmedElement = element?.columns?.trim()?.toLowerCase()
					if (trimmedElement?.includes("experiment")) {
						element.columns_mapped_to = "experiments"
					}
				})
				return newState
			})
			setSheetData((prevState: any) => {
				const newState = { ...prevState }
				newState.experiments = expJson
				return newState
			})
		}
	}, [parsedFile, sheets])

	useEffect(() => {
		if (!!sheets.length) {
			const formulationWorkSheet = parsedFile.Sheets[sheets.find((res: any) => res.sheet_mapped_to === "formulation")?.sheet_name]
			const formulationJson: any[] = XLSX.utils.sheet_to_json(formulationWorkSheet, { defval: "-", raw: false })
			const processWorkSheet = parsedFile.Sheets[sheets.find((res: any) => res.sheet_mapped_to === "process")?.sheet_name]
			const processJson: any[] = XLSX.utils.sheet_to_json(processWorkSheet, { defval: "-", raw: false })
			const propertyWorkSheet = parsedFile.Sheets[sheets.find((res: any) => res.sheet_mapped_to === "property")?.sheet_name]
			const propertyJson: any[] = XLSX.utils.sheet_to_json(propertyWorkSheet, { defval: "-", raw: false })
			const characterisationWorkSheet = parsedFile.Sheets[sheets.find((res: any) => res.sheet_mapped_to === "characterisation")?.sheet_name]
			const charterizationJsonJson: any[] = XLSX.utils.sheet_to_json(characterisationWorkSheet, { defval: "-", raw: false })
			setTableData((prevState: any) => {
				const newState = { ...prevState }
				let ids: any = []
				const key = newState?.experiments.find((res: any) => res.columns_mapped_to === "experiments")?.columns
				if (key) {
					ids = sheetData?.experiments?.map((res: any) => res[key])
				}

				if (workOrder.stages[currentExpIdIndex]?.data.find((res: any) => res?.type === "formulation_stage")) {
					newState.formulations = Object.entries(formulationJson?.[0] || {})
						.filter(
							([key, value]: any) => !ids.includes(key) && !key.includes("EMPTY")
						)
						.map(([key, value]: any) => ({ columns: key, row_value: value }))
					newState?.formulations?.forEach((element: any) => {
						const trimmedElement = element?.columns?.trim()?.toLowerCase()
						if (trimmedElement?.includes("category")) {
							element.columns_mapped_to = "ingredients_category"
						} else if (trimmedElement?.includes("unit")) {
							element.columns_mapped_to = "ingredients_unit"
						} else if (trimmedElement?.includes("ingredient")) {
							element.columns_mapped_to = "ingredients"
						}
					})
				}

				if (workOrder.stages[currentExpIdIndex]?.data.find((res: any) => res?.type === "processing_stage")) {
					newState.processing = Object.entries(processJson?.[0] || {})
						.filter(
							([key, value]: any) => !ids.includes(key) && !key.includes("EMPTY")
						)
						.map(([key, value]: any) => ({ columns: key, row_value: value }))
					newState?.processing?.forEach((element: any) => {
						const trimmedElement = element?.columns?.trim()?.toLowerCase()
						if (trimmedElement?.includes("category")) {
							element.columns_mapped_to = "processing_category"
						} else if (trimmedElement?.includes("unit")) {
							element.columns_mapped_to = "processing_unit"
						} else if (trimmedElement?.includes("process")) {
							element.columns_mapped_to = "processing_parameters"
						}
					})
				}

				if (workOrder.stages[currentExpIdIndex]?.data.find((res: any) => res?.type === "properties_stage")) {
					newState.properties = Object.entries(propertyJson?.[0] || {})
						.filter(
							([key, value]: any) => !ids.includes(key) && !key.includes("EMPTY")
						)
						.map(([key, value]: any) => ({ columns: key, row_value: value }))
					newState?.properties?.forEach((element: any) => {
						const trimmedElement = element?.columns?.trim()?.toLowerCase()
						if (trimmedElement?.includes("category")) {
							element.columns_mapped_to = "property_category"
						} else if (trimmedElement?.includes("unit")) {
							element.columns_mapped_to = "property_unit"
						} else if (trimmedElement?.includes("method")) {
							element.columns_mapped_to = "property_method"
						} else if (trimmedElement?.includes("standard")) {
							element.columns_mapped_to = "property_standard"
						} else if (trimmedElement?.includes("spec")) {
							element.columns_mapped_to = "property_specimen"
						} else if (trimmedElement?.includes("proper")) {
							element.columns_mapped_to = "property_parameters"
						}
					})
				}

				if (workOrder.stages[currentExpIdIndex]?.data.find((res: any) => res?.type === "characterization_stage")) {
					newState.characterisation = Object.entries(charterizationJsonJson?.[0] || {}).filter(
						([key, value]: any) => !ids.includes(key) && !key.includes("EMPTY")
					)
						.map(([key, value]: any) => ({ columns: key, row_value: value }))
					newState?.characterisation?.forEach((element: any) => {
						const trimmedElement = element?.columns?.trim()?.toLowerCase()
						if (trimmedElement?.includes("category")) {
							element.columns_mapped_to = "characterisation_category"
						} else if (trimmedElement?.includes("unit")) {
							element.columns_mapped_to = "characterisation_unit"
						}
						else if (trimmedElement?.includes("parameter")) {
							element.columns_mapped_to = "characterisation_parameters"
						}
					})
				}

				return newState
			})
			setSheetData((prevState: any) => {
				const newState = { ...prevState }
				newState.formulations = formulationJson
				newState.processing = processJson
				newState.properties = propertyJson
				newState.characterisation = charterizationJsonJson
				return newState
			})
		}
	}, [parsedFile, sheets, sheetData?.experiments, workOrder, currentExpIdIndex])

	const generateData = useCallback(() => {
		if (current >= 2) {
			const existingUnits = unitList.map((res: any) => res.name)
			const formulations: any = []
			sheetData.formulations.forEach((element: any) => {
				const obj: any = {}
				obj.ingredients = element[
					tableData.formulations.find(
						(res: any) => res?.columns_mapped_to === "ingredients"
					)?.columns
				]
				obj.ingredients_category =
					element[
					tableData.formulations.find(
						(res: any) => res?.columns_mapped_to === "ingredients_category"
					)?.columns
					]
				obj.ingredients_unit =
					element[
					tableData.formulations.find(
						(res: any) => res?.columns_mapped_to === "ingredients_unit"
					)?.columns
					]
				const experiment_ids = selectedExps.map((res: any) => res.experiments)
				Object.entries(element).forEach(([key, value]: any) => {
					if (experiment_ids.includes(key)) {
						obj[key] = cleanValue(value)
					}
				})
				formulations.push(obj)
			})
			const processing: any = []
			sheetData.processing.forEach((element: any) => {
				const obj: any = {}
				obj.processing_parameters =
					element[
					tableData.processing.find(
						(res: any) => res?.columns_mapped_to === "processing_parameters"
					)?.columns
					]
				obj.processing_category =
					element[
					tableData.processing.find(
						(res: any) => res?.columns_mapped_to === "processing_category"
					)?.columns
					]
				obj.processing_unit =
					element[
					tableData.processing.find(
						(res: any) => res?.columns_mapped_to === "processing_unit"
					)?.columns
					]
				const experiment_ids = selectedExps.map((res: any) => res.experiments)
				Object.entries(element).forEach(([key, value]: any) => {
					if (experiment_ids.includes(key)) {
						obj[key] = cleanValue(value)
					}
				})
				processing.push(obj)
			})
			const properties: any = []
			sheetData.properties.forEach((element: any) => {
				const obj: any = {}
				obj.property_parameters =
					element[
					tableData.properties.find(
						(res: any) => res?.columns_mapped_to === "property_parameters"
					)?.columns
					]
				obj.property_unit =
					element[
					tableData.properties.find(
						(res: any) => res?.columns_mapped_to === "property_unit"
					)?.columns
					]
				obj.property_category =
					element[
					tableData.properties.find(
						(res: any) => res?.columns_mapped_to === "property_category"
					)?.columns
					]
				obj.property_method =
					element[
					tableData.properties.find(
						(res: any) => res?.columns_mapped_to === "property_method"
					)?.columns
					]
				obj.property_standard =
					element[
					tableData.properties.find(
						(res: any) => res?.columns_mapped_to === "property_standard"
					)?.columns
					]
				obj.property_specimen =
					element[
					tableData.properties.find(
						(res: any) => res?.columns_mapped_to === "property_specimen"
					)?.columns
					]
				const experiment_ids = selectedExps.map((res: any) => res.experiments)
				Object.entries(element).forEach(([key, value]: any) => {
					if (experiment_ids.includes(key)) {
						obj[key] = cleanValue(value)
					}
				})
				properties.push(obj)
			})
			const characterisation: any = []
			sheetData.characterisation.forEach((element: any) => {
				const obj: any = {}
				obj.characterisation_type =
					element[
					tableData.characterisation.find(
						(res: any) => res?.columns_mapped_to === "characterisation_type"
					)?.columns
					]
				obj.characterisation_category =
					element[
					tableData.characterisation.find(
						(res: any) =>
							res?.columns_mapped_to === "characterisation_category"
					)?.columns
					]
				obj.characterisation_method =
					element[
					tableData.characterisation.find(
						(res: any) => res?.columns_mapped_to === "characterisation_method"
					)?.columns
					]
				obj.characterisation_parameters =
					element[
					tableData.characterisation.find(
						(res: any) =>
							res?.columns_mapped_to === "characterisation_parameters"
					)?.columns
					]
				obj.characterisation_unit =
					element[
					tableData.characterisation.find(
						(res: any) => res?.columns_mapped_to === "characterisation_unit"
					)?.columns
					]
				const experiment_ids = selectedExps.map((res: any) => res.experiments)
				Object.entries(element).forEach(([key, value]: any) => {
					if (experiment_ids.includes(key)) {
						obj[key] = cleanValue(value)
					}
				})
				characterisation.push(obj)
			})
			const unitsSet = [
				...new Set([
					...formulations.map((res: any) => res.ingredients_unit || "-"),
					...characterisation.map(
						(res: any) => res.characterisation_unit || "-"
					),
					...processing.map((res: any) => res.processing_unit || "-"),
					...properties.map((res: any) => res.property_unit || "-"),
				]),
			]
			if (current === 3) {
				setUnitsData(
					unitsSet
						.filter((res: any) => !existingUnits.includes(res))
						.map((res: any) => ({ unit_name: res }))
				)
			}

			return {
				data: { formulations, processing, properties, characterisation },
				columns: {
					formulations: Object.keys(formulations?.[0] || {}).map((res: any) => {
						const translatedKey = getTableHeadColTranslatedLabel(res, t)

						return {
							dataIndex: res,
							title: translatedKey,
							key: res,
							render: (text: any, record: any, index: any) => {
								if (res === "ingredients_unit") {
									if (
										new Set(formulations.map((res: any) => res?.ingredients_unit)).size !== 1 && !workOrder?.is_multiunit
									) {
										return {
											props: {
												style: { background: volcano[1] },
											},
											children: text,
										}
									}
								}
								if (
									(!["ingredients", "ingredients_category", "ingredients_unit"].includes(res) && isNaN(convertValue(text))) || text === "-"
								) {
									return {
										props: {
											style: { background: volcano[1] },
										},
										children: text,
									}
								}
								return {
									props: { style: { background: "white" } },
									children: text,
								}
							},
						}
					}),
					processing: Object.keys(processing?.[0] || {}).map((res: any) => ({
						dataIndex: res,
						title: getTableHeadColTranslatedLabel(res, t),
						key: res,
						render: (text: any, record: any, index: any) => {
							if (
								(![
									"processing_parameters",
									"processing_category",
									"processing_unit",
								].includes(res) &&
									isNaN(convertValue(text))) ||
								text === "-"
							) {
								return {
									props: { style: { background: volcano[1] } },
									children: text,
								}
							}
							return {
								props: { style: { background: "white" } },
								children: text,
							}
						},
					})),
					properties: Object.keys(properties?.[0] || {}).map((res: any) => ({
						dataIndex: res,
						title: getTableHeadColTranslatedLabel(res, t),
						key: res,
						render: (text: any, record: any, index: any) => {
							if (
								(![
									"property_parameters",
									"property_category",
									"property_unit",
									"property_method",
									"property_standard",
									"property_specimen",
								].includes(res) &&
									isNaN(convertValue(text))) ||
								text === "-"
							) {
								return {
									props: { style: { background: volcano[1] } },
									children: text,
								}
							}
							return {
								props: { style: { background: "white" } },
								children: text,
							}
						},
					})),
					characterisation: Object.keys(characterisation?.[0] || {}).map(
						(res: any) => ({
							dataIndex: res,
							title: getTableHeadColTranslatedLabel(res, t),
							key: res,
							render: (text: any, record: any, index: any) => {
								if (
									(![
										"characterisation_category",
										"characterisation_parameters",
										"characterisation_unit",
									].includes(res) &&
										isNaN(convertValue(text))) ||
									text === "-"
								) {
									return {
										props: { style: { background: volcano[1] } },
										children: text,
									}
								}
								return {
									props: { style: { background: "white" } },
									children: text,
								}
							},
						})
					),
				},
			}
		} else return []
	}, [current, selectedExps, sheetData, tableData, unitList, t, cleanValue, convertValue, workOrder?.is_multiunit])

	const companyId = useSelector((state: StoreState) => state.login.loginResponse.company_id)

	const getComponent = () => {
		switch (current) {
			case 0:
				return (companyId === "COMP351PS01HQE2023") ? (
					<ExcelMapper
						data={{ file, parsedFile }}
						callbacks={{ setFile, setCurrent, setUnsaved, setParsedFile, setSheets }}
					/>
				) : (
					<DataUpload
						data={{ file }}
						callbacks={{ setFile, setCurrent, setUnsaved }}
					/>
				)
			case 1:
				return (
					<MapSheets
						data={{ sheets, filename: file?.name, stage: workOrder.stages?.[currentExpIdIndex] }}
						callbacks={{ setCurrent, setSheets }}
					/>
				)
			case 2:
				return (
					<MapColumns
						data={{ tableData, workOrder }}
						callbacks={{ setCurrent, setTableData, generateData }}
					/>
				)
			case 3:
				return (
					<SelectExperiments
						data={{
							tableData,
							sheetData,
							selectedExpsKeys,
							selectedExps,
							unitsData,
							workOrder,
							current,
						}}
						callbacks={{
							setCurrent,
							setSelectedExps,
							setSelectedExpsKeys,
							setUnitsData,
							generateData,
							uploadData,
						}}
					/>
				)
			case 4:
				return (
					<MapUnits
						data={{ unitsData }}
						callbacks={{ setUnitsData, setCurrent, uploadData }}
					/>
				)
			default:
				return ""
		}
	}

	const uploadData = useCallback(() => {
		const payloadData: any = generateData()
		payloadData.data.formulations.forEach((obj: any) => {
			if (!!unitsData.find((res: any) => res.unit_name === obj.ingredients_unit)) {
				obj.ingredients_unit = unitsData.find((res: any) => res.unit_name === obj.ingredients_unit)?.unit_mapped_to
			}
		})
		payloadData.data.processing.forEach((obj: any) => {
			if (!!unitsData.find((res: any) => res.unit_name === obj.processing_unit)) {
				obj.processing_unit = unitsData.find((res: any) => res.unit_name === obj.processing_unit)?.unit_mapped_to
			}
		})
		payloadData.data.properties.forEach((obj: any) => {
			if (!!unitsData.find((res: any) => res.unit_name === obj.property_unit)) {
				obj.property_unit = unitsData.find((res: any) => res.unit_name === obj.property_unit)?.unit_mapped_to
			}
		})
		payloadData.data.characterisation.forEach((obj: any) => {
			if (!!unitsData.find((res: any) => res.unit_name === obj.characterisation_unit)) {
				obj.characterisation_unit = unitsData.find((res: any) => res.unit_name === obj.characterisation_unit)?.unit_mapped_to
			}
		})
		const payload = {
			experiments: selectedExps.map((res: any) => res.experiments),
			formulations: payloadData.data.formulations,
			processing: payloadData.data.processing,
			properties: payloadData.data.properties,
			characterizations: payloadData.data.characterisation,
		}
		const params = new FormData()
		params.append("file", file)
		params.append("work_order_id", workOrder.work_order_id)
		params.append("remove_ingredient_ids", workOrder?.remove_ingredient_ids ?? [])
		params.append("experiment_id", workOrder.experiment_id[currentExpIdIndex])
		params.append("stage", workOrder.stages[currentExpIdIndex]?.identifier)
		params.append("data", JSON.stringify(payload))
		dispatch(uploadFileRequest(params))
	}, [
		dispatch,
		file,
		generateData,
		selectedExps,
		unitsData,
		currentExpIdIndex,
		workOrder
	])


	return (
		<>
			<Prompt when={unsaved} message={t("dataMapper.fileNotUploadedYet")} />
			{current === 5 ? (
				<Space
					direction="vertical"
					style={{ width: "100%", padding: 20 }}
					size="large"
				>
					<UploadPreview />
					<Row justify="end">
						<StyledButton
							size="large"
							type="primary"
							onClick={() => {
								setFile(null)
								setUnitsData([])
								setCurrent(0)
							}}
						>
							{t("dataMapper.uploadAnotherFile")}
						</StyledButton>
					</Row>
				</Space>
			) : (
				<Spin
					spinning={fileUploadStatus === AsyncStates.LOADING}
					indicator={<LoadingOutlined />}
				>
					<Space
						direction="vertical"
						style={{ width: "100%", padding: 20 }}
						size="large"
					>
						{(companyId !== "COMP351PS01HQE2023") && (
							<Steps
								current={current}
								direction="horizontal"
							>
								<Step title={t("dataMapper.uploadFile")}></Step>
								<Step title={t("dataMapper.mapsheets")}></Step>
								<Step title={t("dataMapper.mapColumns")}></Step>
								<Step title={t("dataMapper.selectExperiments")}></Step>
								{!!unitsData?.length && (
									<Step title={t("common.mapUnits")}></Step>
								)}
							</Steps>
						)}
						{getComponent()}
					</Space>
				</Spin>
			)}
		</>
	)
}
