import Konva from "konva";

export const addZoomAndPan = (stage: Konva.Stage, SCALE_STEPS: number) => {
    let isDragging = false;
    let lastPointerPosition: { x: number; y: number } | null = null;

    // Zoom
    stage.on('wheel', (e) => {
        // Only zoom when Alt key is pressed
        if (!e.evt.altKey) {
            return;
        }
        e.evt.preventDefault();

        const oldScale = stage.scaleX();
        const pointer = stage.getPointerPosition();

        if(!pointer) return;

        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };

        // how to scale? Zoom in? Or zoom out?
        let direction = e.evt.deltaY > 0 ? 1 : -1;

        let newScale = direction > 0 ? oldScale / SCALE_STEPS : oldScale * SCALE_STEPS;

        // Scale limits
        const minScale = 0.5;
        const maxScale = 5;
        newScale = Math.min(Math.max(newScale, minScale), maxScale);

        stage.scale({ x: newScale, y: newScale });

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };
        stage.position(newPos);
        stage.batchDraw();
    });

    // Panning
    stage.on('contextmenu', (e) => {
        e.evt.preventDefault(); // Prevent default right-click menu
    });

    stage.on('mousedown', (e) => {
        if(e.evt.button === 2) { // Right click
            isDragging = true;
            lastPointerPosition = stage.getPointerPosition();
            stage.container().style.cursor = 'grabbing';
        }
    });

    stage.on('mousemove', (e) => {
        if (!isDragging || !lastPointerPosition) return;

        const currentPointerPosition = stage.getPointerPosition();
        if (!currentPointerPosition) return;

        const dx = currentPointerPosition.x - lastPointerPosition.x;
        const dy = currentPointerPosition.y - lastPointerPosition.y;

        const currentPosition = stage.position();
        stage.position({
            x: currentPosition.x + dx,
            y: currentPosition.y + dy
        });

        lastPointerPosition = currentPointerPosition;
        stage.batchDraw();
    });

    stage.on('mouseup mouseout', () => {
        isDragging = false;
        lastPointerPosition = null;
        stage.container().style.cursor = 'default';
    });
}