import { PlusOutlined, ContainerOutlined } from '@ant-design/icons'
import { Space, Popover, Checkbox, Modal, Typography, Input } from 'antd'
import { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { qcCreateNameDetailsRequest, qcMetadataStepErrors, qcMetadataUpdate } from 'src/store/actions/qualityCheck'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'
import StepTitle from './StepTitle'
import MappingTable from './MappingTable/MappingTable'
import _ from 'lodash'

const MapMetadataTitles = ({ step, openImportModal }: { step: number; openImportModal: () => void }) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const { getMetadataResponse, getNameDetailsResponse, importDataResponse } = useSelector((state: StoreState) => state.qualityCheck)

  const [selectedTitles, setSelectedTitles] = useState<any[]>(
    Object.keys(getMetadataResponse?.metadata_mapping || {})
  )
  const [selectedTitlesMap, setSelectedTitlesMap] = useState<Record<string, string[]>>({
    ...(getMetadataResponse?.metadata_mapping || {})
  })

  const options = useMemo(() => {
    return getNameDetailsResponse?.metadata_mapping?.map(({detail_id, name}:any) => {
      return {
        value: detail_id,
        label: name
      }
    }) || []
  }, [getNameDetailsResponse?.metadata_mapping])

  const selectedTitlesWithoutErrors = useMemo(() => {
    return selectedTitles.filter((key) => !key.startsWith('error_'))
  }, [selectedTitles])

  const isSelectAll = useMemo(() => selectedTitlesWithoutErrors.length === options.length, [options.length, selectedTitlesWithoutErrors.length])
  const indeterminate = useMemo(() => selectedTitlesWithoutErrors.length > 0 && selectedTitlesWithoutErrors.length < options.length, [options.length, selectedTitlesWithoutErrors.length])


  // Import data
  useEffect(() => {
    if(importDataResponse?.metadata_mapping) {
      setSelectedTitlesMap(importDataResponse.metadata_mapping)
      setSelectedTitles(Object.keys(importDataResponse.metadata_mapping))
    }
  }, [importDataResponse])

  // Updating entires
  useEffect(() => {
    setSelectedTitlesMap((prev) => {
      const dataToAdd = selectedTitles.reduce((acc, curr) => {
        const name  = getNameDetailsResponse?.metadata_mapping?.find((opt:any) => opt.detail_id === curr)?.name
        return {
          ...acc,
          [curr]: prev[curr] || [name]
        }
      }, {})
      dispatch(qcMetadataUpdate({ metadata_mapping: dataToAdd }))
      return {
        ...dataToAdd
      }
    })
  }, [dispatch, getNameDetailsResponse?.metadata_mapping, selectedTitles])

  // Updating tags
  useEffect(() => {
    dispatch(qcMetadataUpdate({ metadata_mapping: selectedTitlesMap }))
  }, [dispatch, selectedTitlesMap])


  const [titleModalOpen, setTitleModalOpen] = useState(false)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '90%',
        width: '100%',
        flexGrow: 1
      }}
    >
      <AddTitleModal
        open={titleModalOpen}
        setOpen={setTitleModalOpen}
      />
      {/* Action bar */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0.75rem 1.5rem',
          paddingTop: '1.5rem',
          borderBottom: '1px solid #f0f0f0'
        }}
      >
        <Space>
          <StepTitle step={step} />
          <Popover
            placement="bottomLeft"
            autoAdjustOverflow
            showArrow={false}
            arrow={false}
            arrowContent={null}
            style={{
              padding: 0
            }}
            overlayStyle={{
              padding: 0
            }}
            overlayInnerStyle={{
              padding: '0.25rem 0rem',
              maxHeight: '300px',
              overflowY: 'auto',
            }}
            content={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <StyledButton
                  icon={<PlusOutlined />}
                  size="small"
                  style={{
                    fontSize: '0.75rem',
                    fontWeight: 500,
                    margin: '0.25rem 1rem'
                  }}
                  onClick={() => {
                    setTitleModalOpen(true)
                  }}
                >
                  {t('common.New')}
                </StyledButton>
                <Checkbox
                  style={{
                    padding: '0.25rem 1rem'
                  }}
                  checked={isSelectAll}
                  indeterminate={indeterminate}
                  onChange={(e) => {
                    setSelectedTitles(e.target.checked ? options.map((opt:any) => opt.value) : [])
                  }}
                >
                  {t('common.selectAll')}
                </Checkbox>
                <Checkbox.Group
                  value={selectedTitles}
                  onChange={(values) => {
                    setSelectedTitles(values)
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                  }}
                >
                  {options.map((opt:any) => (
                    <Checkbox
                      key={opt.value}
                      value={opt.value}
                      style={{
                        padding: '0.25rem 1rem',
                      }}
                    >
                      <Typography.Text
                        ellipsis={{
                          tooltip: opt.label,
                        }}
                        style={{
                          maxWidth: '200px'
                        }}
                      >
                        {opt.label}
                      </Typography.Text>
                      
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </div>
            }
          >
            <StyledButton
              icon={<PlusOutlined />}
              size="small"
              style={{
                fontSize: '0.75rem',
                fontWeight: 500
              }}
              type={'link'}
            >
              {t('common.addNew')}
            </StyledButton>
          </Popover>
        </Space>
        <StyledButton
          size="small"
          style={{
            fontSize: '0.75rem',
            fontWeight: 500
          }}
          icon={<ContainerOutlined />}
          onClick={openImportModal}
        >
          {t('common.importData')}
        </StyledButton>
      </div>

      {/* Table/Mapper */}
      <div
        style={{
          height: 'calc(100% - 4rem)',
          overflow: 'auto',
          width: '100%',
          // dot pattern
          backgroundImage: 'radial-gradient(circle at 1px 1px, #b8b8b8 .1em, transparent .1em)',
          backgroundSize: '1.75rem 1.75rem',
          padding: '1.5rem 2rem'
        }}
      >
        <MappingTable
          data={selectedTitlesWithoutErrors.map((title_key) => {
            return {
              title_label: options.find((opt:any) => opt.value === title_key)?.label || '',
              title_key,
              tags: selectedTitlesMap[title_key] || []
            }
          })}
          setTags={(title_key: string, tags: string[]) => {
            setSelectedTitlesMap(() => {
              const newState = _.cloneDeep(getMetadataResponse?.metadata_mapping || {})
              delete newState[`error_${title_key}`]
              return { ...newState, [title_key]: tags }
            })
            dispatch(
              qcMetadataStepErrors({
                metadata_mapping: false
              })
            )
          }}
          removeTag={(title_key: string) => {
            setSelectedTitles((prev) => prev.filter((t) => t !== title_key))
            setSelectedTitlesMap((prev) => {
              const newState = { ...prev }
              delete newState[title_key]
              return newState
            })
          }}
          columnName={t('qc.metadata.mapMetadata.columnTitle')}
          tagPlaceholder={t('qc.metadata.mapMetadata.tagPlaceholder')}
          step={step}
        />
      </div>
    </div>
  )
}

export default MapMetadataTitles

const AddTitleModal = ({ open, setOpen }: { open: boolean; setOpen: any }) => {
  const [title, setTitle] = useState('')
  const [t] = useTranslate()
  const dispatch = useDispatch()

  return (
    <Modal
      title={t('qc.metadata.mapMetadata.columnTitle')}
      open={open}
      onOk={() => {
        dispatch(
          qcCreateNameDetailsRequest({
            name: title,
            type: 'metadata_mapping'
          })
        )
        setOpen(false)
        setTitle('')
      }}
      onCancel={() => {
        setOpen(false)
        setTitle('')
      }}
      styles={{
        body: {
          borderRadius: '0.5rem'
        }
      }}
      okButtonProps={{
        style: {
          outline: 'none',
        },
        disabled: !title
      }}
      cancelButtonProps={{
        style: {
          outline: 'none',
        }
      }}
      centered
    >
      <Input
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder={t('qc.metadata.mapMetadata.columnTitle')}
        style={{
          width: '100%',
          borderRadius: 0
        }}
      />
    </Modal>
  )
}