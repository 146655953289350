import { red } from "@ant-design/colors";
import { CloseOutlined, DeleteFilled, LineChartOutlined } from "@ant-design/icons";
import { Checkbox, Drawer, message, Modal, Popover, Space, Spin, Table, Tabs, Tooltip, Typography } from "antd";
import { TabsProps } from "antd/lib";
import dayjs from "dayjs";
import mixpanel from "mixpanel-browser";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DeleteIngredientsConfirmationModalTabTypes } from "src/components/Inventory/modals/DeleteIngredientsModal";
import { AsyncStates } from "src/constants";
import { validateArchivingIngredientsClear, validateArchivingIngredientsRequest } from "src/store/actions/inventoryV2";
import { setProject } from "src/store/actions/projects";
import { workOrderClear } from "src/store/actions/workOrderDetails";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import { SelectedIngredientIdsType } from "..";


export const ArchiveIngredientsDrawer = ({ selectedIngredientIds, activeTab, handleClear, updateItemArchiveStatus, archiveIngredientModal, setArchiveIngredientModal, handleDeleteValidate }: any) => {
    const [t] = useTranslate()
    const { push } = useHistory()
    const dispatch = useDispatch()

    const compareHandler = () => {
        const ids = selectedIngredientIds[activeTab]
        mixpanel.track("Compared ingredients");
        if (ids.length > 1) {
            push(`/inventory/ingredients/compare?ids=${ids.join(",")}`)
        } else {
            message.error(
                t("compareIngredient.selectMoreThanOneIngredients")
            );
        }
    };

    const onSelectionChange = (e: any) => {
        if (!e.target.checked) {
            handleClear()
        }
    }


    const handleArchiveSelection = () => {
        dispatch(validateArchivingIngredientsRequest({
            inventory_ids: selectedIngredientIds[activeTab]
        }))
        setArchiveIngredientModal(true)
    }

    return <Drawer
        open={!!selectedIngredientIds[activeTab].length}
        placement="bottom"
        style={{
            background: "#171717",
        }}
        height={125}
        mask={false}
        maskClosable={false}
        closeIcon={false}
        title={
            <div style={{ position: "absolute", top: "-12%", left: "50%" }}>
                <StyledButton
                    onClick={() => {
                        handleClear()
                    }}
                    style={{
                        background: "#262626",
                        color: "#fff",
                        borderColor: "#fff",
                    }}
                >
                    <Space>
                        <CloseOutlined />
                        {t("common.clear")}
                    </Space>
                </StyledButton>
            </div>
        }
    >
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",

            }}
        >
            <div
                style={{
                    width: "25%",
                }}
            >
                <Checkbox checked={!!selectedIngredientIds[activeTab].length} onChange={onSelectionChange}><Typography.Title
                    level={5}
                    style={{ margin: 0, color: "#fff" }}
                >{selectedIngredientIds[activeTab].length} {t("repository.itemsSelected")}</Typography.Title></Checkbox>
            </div>
            <div
                style={{
                    width: "75%",
                    display: "flex",
                    gap: "0.5rem",
                }}
            >
                {
                    activeTab === "active" ? (<>
                        <StyledButton
                            className="archive__ingredient__button"
                            onClick={() => handleArchiveSelection()}
                            disabled={selectedIngredientIds[activeTab].length === 0}
                        >
                            {"Archive Selected"}
                        </StyledButton>

                    </>
                    ) : <StyledButton
                        className="archive__ingredient__button"
                        onClick={() => updateItemArchiveStatus(selectedIngredientIds[activeTab], false)}
                        icon={<LineChartOutlined />}
                        disabled={selectedIngredientIds[activeTab].length === 0}
                    >
                        {"Unarchive Selected"}
                    </StyledButton>
                }

                <Tooltip
                    title={
                        selectedIngredientIds[activeTab].length === 0
                            ? t("common.compare.selectMoreThanOneIngredients")
                            : null
                    }
                    trigger={"hover"}
                >
                    <StyledButton
                        className="archive__ingredient__button"
                        onClick={compareHandler}
                        icon={<LineChartOutlined />}
                        disabled={selectedIngredientIds[activeTab].length === 0}
                    >
                        {t("common.compare")}
                    </StyledButton>
                </Tooltip>

                <Tooltip
                    title={t("common.delete")}
                    trigger={"hover"}
                >
                    <StyledButton
                        type="text"
                        onClick={handleDeleteValidate}
                        icon={<DeleteFilled style={{
                            color: red[5]
                        }} />}
                        disabled={selectedIngredientIds[activeTab].length === 0}
                    >
                    </StyledButton>
                </Tooltip>
            </div>
        </div>
    </Drawer>
}



type ArchiveIngredientsConfirmationModalPropsTypes = {
    open: boolean,
    setOpen: any,
    selectedIngredientIds: string[],
    updateItemArchiveStatus: (ids: string[], is_archived: boolean) => void,
    setSelectedIngredientIds: Dispatch<SetStateAction<SelectedIngredientIdsType>>
}



export type ArchiveIngredientsConfirmationModalTabTypes = "able_to_archive" | "unable_to_archive"


export const ArchiveIngredientsConfirmationModal = ({ open, setOpen, selectedIngredientIds, updateItemArchiveStatus, setSelectedIngredientIds }: ArchiveIngredientsConfirmationModalPropsTypes) => {
    const [t] = useTranslate()
    const dispatch = useDispatch()

    const archiveIngredientData = useSelector((state: StoreState) => state.inventoryV2.archiveIngredient);
    const archiveIngredientValidationStatus = useSelector((state: StoreState) => state.inventoryV2.validateArchivingIngredients.status);
    const archiveIngredientValidationData = useSelector((state: StoreState) => state.inventoryV2.validateArchivingIngredients.data);
    const [currentTab, setCurrentTab] = useState<ArchiveIngredientsConfirmationModalTabTypes>("able_to_archive")
    const items: TabsProps['items'] = useMemo(() => [
        {
            key: 'able_to_archive',
            label: `${t("archiveIngredients.tab.canBeArchived")} (${archiveIngredientValidationData?.able_to_archive?.length ?? 0})`,
        },
        {
            key: 'unable_to_archive',
            label: `${t("archiveIngredients.tab.cannotBeArchived")} (${archiveIngredientValidationData?.unable_to_archive?.length ?? 0})`,
        },
    ], [archiveIngredientValidationData, t])


    const handleTabChange = (key: string) => {
        setCurrentTab(key as ArchiveIngredientsConfirmationModalTabTypes);
    };

    const handleProceed = () => {
        if (!archiveIngredientValidationData?.able_to_archive?.length) return
        const archiveIngredientIds: string[] = archiveIngredientValidationData?.able_to_archive?.map((inventory: any) => inventory.inventory_id)
        updateItemArchiveStatus(archiveIngredientIds, true)
    }

    const handleClose = useCallback(() => {
        setOpen(false)
        dispatch(validateArchivingIngredientsClear())
        // Clear is handled in useEffect in index.tsx file along with other necessary actions
    }, [setOpen, dispatch])

    useEffect(() => {
        if (archiveIngredientData.status === AsyncStates.SUCCESS) {
            handleClose()
        }
    }, [archiveIngredientData.inventory_ids, archiveIngredientData.status, dispatch, handleClose, setSelectedIngredientIds])

    return <Modal
        title={t("common.archiveIngredient")}
        width={900}
        open={open}
        onCancel={() => handleClose()}
        footer={<div style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "0.25rem"
        }}>
            <StyledButton onClick={() => handleClose()}>{t("common.cancel")}</StyledButton>
            <StyledButton
                loading={archiveIngredientData.status === AsyncStates.LOADING}
                disabled={archiveIngredientValidationStatus === AsyncStates.LOADING || archiveIngredientValidationStatus === AsyncStates.ERROR || !archiveIngredientValidationData?.able_to_archive?.length} type="primary" onClick={() => handleProceed()}>{t("common.proceed")}</StyledButton>
        </div>}
    >
        <Spin spinning={archiveIngredientValidationStatus === AsyncStates.LOADING}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem"
            }}>
                <span>
                    {t("common.archivedIngredientsNote")}
                </span>
                <div
                    style={{
                        display: "flex",
                        gap: "2rem",
                        flexDirection: "column"
                    }}>
                    <Tabs activeKey={currentTab} items={items} onChange={handleTabChange} />
                    <ArchiveOrDeleteIngredientsTable showWorkOrderColumn={currentTab === "unable_to_archive"} data={archiveIngredientValidationData?.[currentTab] ?? []} currentTab={currentTab} />
                </div>
            </div>


            {archiveIngredientValidationStatus === AsyncStates.ERROR && <div style={{ display: "flex", justifyContent: "space-between", padding: "0.5rem" }}>
                <Typography.Text type="danger" strong>{t("common.errorFetchingProperties")}</Typography.Text>
                {!!selectedIngredientIds.length && <StyledButton
                    onClick={() => {
                        dispatch(validateArchivingIngredientsRequest({
                            inventory_ids: selectedIngredientIds
                        }))
                    }}>
                    {t("common.retry")}
                </StyledButton>
                }
            </div>
            }
        </Spin>
    </Modal>
}


type ArchiveIngredientsTablePropsTypes = {
    showWorkOrderColumn?: boolean,
    data: any[],
    currentTab: ArchiveIngredientsConfirmationModalTabTypes | DeleteIngredientsConfirmationModalTabTypes
}

export const ArchiveOrDeleteIngredientsTable = ({ showWorkOrderColumn = false, data = [], currentTab }: ArchiveIngredientsTablePropsTypes) => {
    const [t] = useTranslate()
    const dispatch = useDispatch()

    const [archiveIngredientTableCurrentPage, setArchiveIngredientTableCurrentPage] = useState<{ [key: string]: number }>({})

    const currentProject = useSelector((state: StoreState) => state.projects.current)

    useEffect(() => {
        return () => {
            setArchiveIngredientTableCurrentPage({})
        }
    }, [])

    const workOrderContent = useCallback((workOrderList: any[] = []) => {
        return <div style={{
            maxHeight: '25rem',
            overflowY: 'auto',
        }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.75rem"
                }}
            >
                {workOrderList.map((workOrder: any) => {
                    return <Typography.Text
                        key={workOrder.work_order_id}
                        onClick={() => {
                            dispatch(workOrderClear())
                            if ((currentProject !== workOrder?.project_id) && !!workOrder?.project_id) {
                                dispatch(setProject(workOrder.project_id));
                            }
                            window.open(`/work-orders/details/${workOrder.work_order_id}`, "_blank")
                        }}
                        style={{
                            width: "11rem",
                            color: "#1890FF",
                            cursor: "pointer",
                            textDecoration: "underline",
                        }}
                        ellipsis={{
                            tooltip: workOrder.work_order_name
                        }}
                    >
                        {workOrder.work_order_name}
                    </Typography.Text>
                })}
            </div>
        </div>
    }, [currentProject, dispatch])

    const archiveOrIngredientsColumns = useMemo(() => [
        {
            title: t("inventory.IngredientName"),
            dataIndex: "name",
            key: "name",
            width: 180,
            render: (text: string) => {
                return <Typography.Text
                    style={{ width: 180 }}
                    ellipsis={{ tooltip: text }}
                >
                    {text}
                </Typography.Text>
            }
        },
        {
            title: t("inventory.Category"),
            dataIndex: "category",
            key: "category",
            width: 120,
            render: (text: string) => {
                return <Typography.Text
                    style={{ width: 120 }}
                    ellipsis={{ tooltip: text }}
                >
                    {text}
                </Typography.Text>
            }
        },
        {
            title: t("inventory.Grade"),
            dataIndex: "grade",
            key: "grade",
            width: 120,
            render: (text: string) => {
                return <Typography.Text
                    style={{ width: 120 }}
                    ellipsis={{ tooltip: text }}
                >
                    {text}
                </Typography.Text>
            }
        },
        {
            title: t("inventory.lot_no"),
            dataIndex: "lot_no",
            key: "lot_no",
            width: 80,
            render: (_: any, record: any) => {
                return <Typography.Text
                    style={{
                        width: 80,
                    }}
                    ellipsis={{
                        tooltip: record?.lot_no ?? null
                    }}>
                    {record?.lot_no ?? null}
                </Typography.Text>
            }
        },
        {
            title: t("common.versionDate"),
            dataIndex: "version_date",
            key: "version_date",
            width: 80,
            render: (_: any, record: any) => {
                const text = record?.version_date ? dayjs(record?.version_date).format("DD-MM-YYYY") : null
                return <Typography.Text
                    style={{
                        width: 80,
                    }}
                    ellipsis={{
                        tooltip: text
                    }}>
                    {text}
                </Typography.Text>
            }
        },
        ...(showWorkOrderColumn ? [{
            title: "Work Order",
            dataIndex: "used_in_work_orders",
            key: "used_in_work_orders",
            width: 120,
            render: (_: any, record: any) => {
                if (!record?.used_in_work_orders?.length) return null
                const workOrderText = record?.used_in_work_orders.length === 1 ? `${record?.used_in_work_orders[0].work_order_name}` : `${record?.used_in_work_orders[0]?.work_order_name} and ${record?.used_in_work_orders?.length - 1} more`

                return record?.used_in_work_orders.length > 1 ? <Popover content={workOrderContent(record?.used_in_work_orders)} title={t("common.workOrders")} >
                    <Typography.Text
                        style={{
                            width: 120,
                            color: "#1890FF"
                        }}
                        ellipsis={true}
                    >
                        {workOrderText}
                    </Typography.Text>
                </Popover> : <Typography.Text
                    style={{
                        width: 120,
                        color: "#1890FF",
                        cursor: "pointer"
                    }}
                    onClick={() => {
                        const usedInworkOrder = record?.used_in_work_orders[0]
                        dispatch(workOrderClear())
                        if ((currentProject !== usedInworkOrder?.project_id) && !!usedInworkOrder?.project_id) {
                            dispatch(setProject(usedInworkOrder.project_id));
                        }
                        window.open(`/work-orders/details/${usedInworkOrder.work_order_id}`, "_blank")
                    }}
                    ellipsis={{
                        tooltip: workOrderText
                    }}
                >
                    {workOrderText}
                </Typography.Text>
            }
        }] : [])
    ], [t, showWorkOrderColumn, workOrderContent, dispatch, currentProject])

    return <Table
        columns={archiveOrIngredientsColumns}
        dataSource={data}
        pagination={{
            current: archiveIngredientTableCurrentPage?.[currentTab] ?? 1,
            onChange: (page) => {
                setArchiveIngredientTableCurrentPage((prev) => ({
                    ...prev,
                    [currentTab]: page
                }))
            }
        }}
    />
}