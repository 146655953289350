import { blue } from '@ant-design/colors';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Select, Space, Tag, message } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from "react";
import useTranslate from 'src/utils/useTranslate';
import {initialData} from './AddUnitConversion'

export const NameAndCategoryForm = ({ setUnit, unit, finish }) => {
    const [form] = Form.useForm();
    const [t] = useTranslate()
    const [items, setItems] = useState([
        'Angle',
        'Concentration',
        'Density',
        'Energy',
        'Force',
        'Frequency',
        'Weight',
        'Hardness',
        'Pressure',
        'Rate',
        'Temperature',
        'Thermophysical',
        'Cycles',
        'Length',
        'Percentage',
        'Power',
        'Quantity',
        'Time',
        'Torque',
    ]);

    const [name, setName] = useState('');
    const [error, setError] = useState(false)
    const inputRef = useRef(null);

    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const onCategoryChange = (event) => {
        setUnit(prev => ({ ...prev, category: event.toLowerCase() }))
    }
    const addItem = (e) => {
        e.preventDefault();
        // setItems([...items, name || `${t('common.newItem')} ${index++}`]);
        !name ? setError(true) : setError(false)
        !!name && setItems([...items, name]);
        setName('');
        setTimeout(() => {
        inputRef.current?.focus();
        }, 0);
    };
    const [alternativeValues, setAlternateValues] = useState(unit.alternative_values)
    useEffect(() => {
        setUnit(prev => ({ ...prev, alternative_values: alternativeValues }))
    }, [alternativeValues, setUnit])

    useEffect(() => {
        setUnit(initialData)
        form.resetFields()
    }, [form, setUnit])

    const handleFinishForm = useCallback(()=>{
        if (unit?.name.length < 1 || unit?.category.length < 1) return message.error(t('common.pleaseAddNameAndCategory'))
        finish()
        form.resetFields()
    },[finish, form, t, unit?.category.length, unit?.name.length])

    return (
        <Form form={form} layout="vertical" onFinish={handleFinishForm} requiredMark={false} >
            <Form.Item
                name="Name"
                label={t('common.name')}
                initialValue={unit.name}
                rules={[{ required: true }, { whitespace: true }]}
                required 
				tooltip={{ title: t("common.requiredField"), icon: <div style={{color:"#ff4d4f", fontSize:"14px"}}>*</div> }}
            >
                <Input
                    placeholder='eg: kg'
                    onChange={(e) => setUnit(prev => ({ ...prev, name: e.target.value }))}
                />
            </Form.Item>
            <Form.Item
                name="Category"
                label={t('common.category')}
                initialValue={unit.category}
                rules={[{ required: true }, { whitespace: true }]}
                required 
				tooltip={{ title: t("common.requiredField"), icon: <div style={{color:"#ff4d4f", fontSize:"14px"}}>*</div> }}
            >
                <Select
                    style={{ width: '100%' }}
                    placeholder="eg: weight"
                    showSearch={true}
                    onChange={(e) => onCategoryChange(e)}
                    dropdownRender={(menu) => (
                        <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                            <Input
                            placeholder={t("units.pleaseAddCategory")}
                            ref={inputRef}
                            value={name}
                            onChange={onNameChange}
                            status={error && 'error'}
                            />
                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                            {t('common.add')} {t('common.category')}
                            </Button>
                            {error && <p style={{color: 'red', marginBottom: 0}}>{t('units.addACategory')}</p>}
                        </Space>
                        </>
                    )}
                    options={items.map((item) => ({ label: item, value: item }))}
                    />
            </Form.Item>
            <Form.Item
                name="Alternative Values"
                label={t('units.alternativeValues')}
                style={{ marginBottom: '6px' }}
                onKeyDown={(e) => {
                    if (e.code === 'Enter' && !!e.target.value) {
                        setAlternateValues([...new Set([...alternativeValues, e.target.value.toLowerCase()])])
                        form.setFieldsValue({ 'Alternative Values': '' })
                    }
                }}
            >
                <Input
                    placeholder='eg: kilogram'
                />
            </Form.Item>
            {
                alternativeValues.map((value) => {
                    return (
                        <Tag
                            key={value}
                            onClose={() => {
                                setAlternateValues(alternativeValues.filter(item => item !== value))
                            }}
                            closable
                        >
                            {value}
                        </Tag>
                    )
                })
            }
            <Button htmlType='submit' style={{ background: blue.primary, color: '#fff' }}>
                {t('common.submit')}
            </Button>
        </Form>
    )
}