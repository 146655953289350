import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	getSavedFiltersRequest,
	getSavedFiltersSuccess,
	getSavedFiltersFailure,
	createNewFilterRequest,
	createNewFilterSuccess,
	createNewFilterFailure,
	deleteFiltersRequest,
	deleteFiltersSuccess,
	deleteFiltersFailure,
} from "src/store/actions/saveFormulationsFilters"
import {
	createNewFormulationFilter,
	CreateNewFilterParams,
	getSavedFormulationFilters,
	GetSavedFormulationFiltersParams,
	deleteFormulationsFilters,
	DeleteFormulationsFiltersParams,
} from "src/services/formulation/index"
import { APIError } from "src/typings"
import { messages } from "src/utils/hooks"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import { message } from "antd"
import jwtManager from "src/utils/jwtManager"

export type Response = {
	result: {
		status: "Success" | "Failed"
		message: string
		data: {
			name: string
			description: string
			access: boolean
			filter_id: string
			query: object
			user_id: string
		}
	}
}

function* createNewFilterSaga(params: {
	type: string
	payload: CreateNewFilterParams
}) {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const data: Response = yield call(
			createNewFormulationFilter,
			params.payload,
			headers
		)

		if (data.result.status === "Success") {
			if(params.payload?.filter_type === "inventory") {
				message.success(messages[ln].inventoryFilterSaved)
			} else {
				message.success(data.result.message)
			}
			yield put(createNewFilterSuccess(data.result.data))
		} else {
			yield put(createNewFilterFailure(data.result.message))
		}
	} catch (e) {
		const error = e as APIError

		message.error(
			error?.response?.data?.detail ?? messages[ln].internal_server_error
		)
		yield put(
			createNewFilterFailure(
				error?.response?.data?.detail ?? messages[ln].internal_server_error
			)
		)
	}
}

export type GetSavedFiltersResponse = {
	result: {
		status: "Success" | "Failed"
		message: string
		data: {
			name: string
			description: string
			access: boolean
			filter_id: string
			query: any
			user_id: string
			created: number,
			is_archived?: boolean
		}[]
	}
}

function* getSavedFiltersSaga(params: {
	type: string
	payload: GetSavedFormulationFiltersParams
}) {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const data: GetSavedFiltersResponse = yield call(
			getSavedFormulationFilters,
			params.payload,
			headers
		)

		if (data.result.status === "Success") {
			yield put(getSavedFiltersSuccess(data.result.data))
		} else {
			yield put(
				getSavedFiltersFailure(
					data?.result?.message ?? messages[ln].internal_server_error
				)
			)
		}
	} catch (e) {
		const error = e as APIError

		yield put(
			getSavedFiltersFailure(
				error?.response?.data?.detail ?? messages[ln].internal_server_error
			)
		)
	}
}

export type DeleteFiltersResponse = {
	result: {
		status: "Success" | "Failed"
		message: string
		data: any[]
	}
}
function* deleteFormulationsFiltersSage(params: {
	type: string
	payload: DeleteFormulationsFiltersParams
}) {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const data: DeleteFiltersResponse = yield call(
			deleteFormulationsFilters,
			params.payload,
			headers
		)

		if (data.result.status === "Success") {
			message.success(data.result.message)
			yield put(deleteFiltersSuccess(data.result.data))
		} else {
			message.error(data.result.message)
			yield put(deleteFiltersFailure(messages[ln].internal_server_error))
		}
	} catch (e) {
		const error = e as APIError

		message.error(
			error?.response?.data?.detail ?? messages[ln].internal_server_error
		)
		yield put(
			deleteFiltersFailure(
				error?.response?.data?.detail ?? messages[ln].internal_server_error
			)
		)
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(getSavedFiltersRequest, getSavedFiltersSaga)
	yield takeLatest(createNewFilterRequest, createNewFilterSaga)
	yield takeLatest(deleteFiltersRequest, deleteFormulationsFiltersSage)
}
