export const trimWhiteSpaceFromValues = (values: any) => {
  return Object.entries(values).reduce<Record<string, any>>(
    (acc, [key, value]) => {
      const trimmedValue = typeof value === "string" ? value.trim() : value;
      acc[key] = trimmedValue === "" ? undefined : trimmedValue;
      return acc;
    },
    {}
  );
}

export const trimWhiteSpacesAndSubmitForm = (submitForm: Function) => (values: any) => {
  const trimmedValues = trimWhiteSpaceFromValues(values);
  submitForm(trimmedValues);
}