import { LinkOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Select, Space, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import { updateIngredientRequest } from "src/store/actions/inventoryV2";
import { fetchFieldRequest } from "src/store/actions/repository";
import { StoreState } from "src/store/configureStore";
import { StyledCard } from "src/styled_components/StyledCard";
import { getExternalLink, mapFields } from "src/utils/decorator";
import useTranslate from "src/utils/useTranslate";
import { useValue } from "src/utils/useValue";
import { InventoryCustomFieldModal } from "./InventoryCustomFieldModal";
import { OPERATORS } from "../Repository/Common/Data";
import { StyledButton } from "src/styled_components/StyledButton";
import isURL from "validator/lib/isURL";

const { Text, Link } = Typography;

type InventoryCustomFieldsFormProps = {
  inventory_id?: string;
  ingredient: any;
  from: string;
};

export const InventoryCustomFieldsForm = ({
  inventory_id,
  ingredient,
  from,
}: InventoryCustomFieldsFormProps) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const [t] = useTranslate();
  const { formatter, parser } = useValue();

  const [isFormUpdated, setIsFormUpdated] = useState(false);

  const [customFields, setCustomFields] = useState<any[]>([]);
  const [customModalVisible, setCustomModalVisible] = useState(false);

  const fieldsResponse = useSelector(
    (state: StoreState) => state.repository.fields
  );

  useEffect(() => {
    if (fieldsResponse.status === AsyncStates.INITIAL) {
      dispatch(fetchFieldRequest());
    }
  }, [fieldsResponse.status, dispatch]);

  const getInitialValues = (field: any) => {
    if (field.field_type === "date") {
      if (!!field?.value || !!field.default_value) {
        return field?.value ? dayjs(field?.value) : null;
      } else return null;
    } else {
      return field?.value ?? field?.default_value;
    }
  };

  const onSaveCustomFields = (values: any) => {
    const custom_fields: any = customFields.map((res: any) => res);
    Object.entries(values || {}).forEach(([key, value]: any) => {
      custom_fields.forEach((field: any) => {
        if (field.field_name === key) {
          if (field.field_type === "range") {
            field.value = value?.value ?? "";
            field.value_max = value?.value_max ?? ""
          } else if (field.field_type === 'operator_value') {
            field.operator = value?.operator ?? field?.operator ?? "";
            field.value = value?.value ?? ""
          } else if (field?.field_type === "date") {
            field.value = value ? dayjs(value).format("YYYY-MM-DD") : null
          } else {
            field.value = value;
          }
        }
      });
    });

    dispatch(
      updateIngredientRequest({
        ...ingredient,
        custom_fields: custom_fields.map((res: any) => {
          return { field_id: res.field_id, value: res.value, value_max: res.field_type === 'range' ? res.value_max : null, operator: res.field_type === 'operator_value' ? res.operator : null };
        }),
      })
    );
    setIsFormUpdated(false);
  };

  useEffect(() => {
    if (!!fieldsResponse.data.length) {
      // .filter((cf: any) => from === 'family' ? cf.family_id === ingredient?.family_id:
      // (cf.family_id && cf.family_id === ingredient?.family_id)  || (cf.inventory_id && cf.inventory_id === ingredient?.inventory_id)
      // )
      const fields = from === "family" ? fieldsResponse.data?.filter((cf: any) => cf.family_id === ingredient?.family_id) : ingredient.custom_fields;
      const preparedFields = !!fields?.length ? fields?.reduce((acc: any[], valueField: any) => {
        const metaField = fieldsResponse.data.find((res: any) => res.field_id === valueField.field_id);
        if (metaField) {
          acc.push({
            ...valueField,
            ...metaField,
            value:
              metaField?.field_type === "date"
                ? valueField.value
                  ? dayjs(valueField.value)
                  : null
                : valueField?.value ?? metaField?.default_value ?? metaField?.value,
            value_max: metaField?.field_type === 'range' ?
              valueField.value_max : metaField?.value_max ?? null,
            operator: metaField?.field_type === 'operator_value' ?
              valueField.operator !== undefined ? valueField.operator : metaField?.operator ?? null : null
          })
        }
        return acc
      }, []) : []

      setCustomFields(preparedFields.sort((a: any, b: any) => a.field_name.localeCompare(b.field_name)))

      // from === "family"
      //   ? setCustomFields(preparedFields)
      //   : setCustomFields(
      //       preparedFields.filter((pf: any) =>
      //         pf.value === null || Array.isArray(pf.value)
      //           ? pf.value?.length > 0
      //           : pf.value !== ""
      //       )
      //     );
      preparedFields.forEach((field: any) => {
        if (field.field_type === "date") {
          form.setFieldValue(
            field.field_name,
            field.value ? dayjs(field.value) : null
          );
        } else if (field.field_type === "select") {
          form.setFieldValue(field.field_name, field.value || []);
        } else if (field.field_type === 'range') {
          form.setFieldValue(field.field_name, {
            value: field.value,
            value_max: field.value_max
          });
        } else if (field.field_type === 'operator_value') {
          form.setFieldValue(field.field_name, {
            value: field.value,
            operator: field.operator
          });
        }
        else {
          form.setFieldValue(field.field_name, field.value);
        }
      });
    }
  }, [ingredient, form, fieldsResponse, from]);

  const handleCreateCustomField = (newField: any) => {

  };


  return (
    <>
      <Form
        form={form}
        onFinish={onSaveCustomFields}
        size="small"
        onFieldsChange={() => {
          setIsFormUpdated(true);
        }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 20 }}
      >
        <StyledCard
          bordered={false}
          headStyle={{ padding: "0.5rem" }}
          bodyStyle={{
            padding: "0.5rem",
            marginLeft: "0.5rem",
          }}
          title={
            <Space>
              <Text className="highlighted">{t("projects.customFields")}</Text>
              {
                // fieldsResponse.data.length <= 30 &&
                !inventory_id && (
                  <StyledButton
                    type="dashed"
                    onClick={() => setCustomModalVisible(true)}
                    block
                    size="small"
                    style={{ width: "max-content", marginLeft: "0.5rem" }}
                    icon={<PlusOutlined />}
                    disabled={isFormUpdated}
                  >
                    <Text type="secondary">{t("common.Add New")}</Text>
                  </StyledButton>
                )
              }
            </Space>
          }
          extra={
            !inventory_id &&
            from !== "family" && (
              <Form.Item>
                <StyledButton
                  type="primary"
                  htmlType="submit"
                  size="small"
                  style={{ marginLeft: "auto" }}
                  disabled={!isFormUpdated}
                // loading={
                //   updateIngredientResponse.status ===
                //   AsyncStates.LOADING
                // }
                >
                  {t("common.save")}
                </StyledButton>
              </Form.Item>
            )
          }
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            {customFields.map((field: any) =>
              field.field_type === "range" ? (
                <Form.Item
                  label={
                    <Text ellipsis={{ tooltip: field.field_name }}>
                      {field.field_name}
                    </Text>
                  }
                  labelAlign={"left"}
                >
                  <Row justify="start" gutter={[8, 8]}>
                    <Col>
                      <Form.Item
                        help="min"
                        rules={[
                          {
                            required: field.required,
                            message: "required",
                            transform: (value: any) => value?.trim(),
                          },
                        ]}
                        name={[field.field_name, "value"]}
                      >
                        <Input disabled={!!inventory_id || from === "family"} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        help={"max"}
                        rules={[
                          {
                            required: field.required,
                            message: "required",
                            transform: (value: any) => value?.trim(),
                          },
                        ]}
                        name={[field.field_name, "value_max"]}
                      >
                        <Input disabled={!!inventory_id || from === "family"} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              ) : field.field_type === "operator_value" ? (
                <Form.Item
                  label={
                    <Text ellipsis={{ tooltip: field.field_name }}>
                      {field.field_name}
                    </Text>
                  }
                  labelAlign={"left"}
                >
                  <Row justify="start" gutter={[8, 8]}>
                    <Col>
                      <Form.Item name={[field.field_name, "operator"]}>
                        <Select placeholder="-" options={OPERATORS} disabled={!!inventory_id || from === "family"} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item name={[field.field_name, "value"]}>
                        <Input type="number" placeholder="-" disabled={!!inventory_id || from === "family"} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              ) : (
                <Form.Item
                  style={{
                    width: "100%"
                  }}
                  name={field.field_name}
                  label={
                    <Text ellipsis={{ tooltip: field.field_name }}>
                      {field.field_name}
                    </Text>
                  }
                  labelAlign={"left"}
                  initialValue={getInitialValues(field)}
                  rules={[
                    {
                      required: field.required,
                      message: "required",
                      transform: (value: any) => value?.trim(),
                    },
                    ...field.field_type === "link" ?
                      [
                        {
                          message: "Please enter a valid URL",
                          validator: (_: any, value: string) => {
                            if (!field.required && !value) return Promise.resolve();
                            if (isURL(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject();
                            }
                          },
                        },
                      ]
                      : []
                  ]}
                  extra={(!!field.value || !!field.default_value) && field.field_type === 'link' &&
                    <Link href={getExternalLink(field.value ?? field.default_value)} target="_blank" rel="noreferrer">
                      <LinkOutlined />
                    </Link>
                  }
                >
                  {mapFields(
                    field,
                    !!inventory_id || from === "family",
                    formatter, parser
                  )}
                </Form.Item>
              )
            )}
          </Space>

          <InventoryCustomFieldModal
            ingredient={ingredient}
            visible={customModalVisible}
            setVisible={setCustomModalVisible}
            customFields={customFields}
            setCustomFields={(newField) => {
              handleCreateCustomField(newField);
            }}
          />
        </StyledCard>
      </Form>
    </>
  );
};
