import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { Drawer, message, Modal, Row, Spin } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import {
    deleteFiltersCleanup,
    deleteFiltersRequest,
    getSavedFiltersRemove,
} from "src/store/actions/saveFormulationsFilters"
import { AsyncStates } from "src/constants"
import { useMesssage } from "src/utils/hooks"
import { red } from "@ant-design/colors"
import { StyledButton } from "src/styled_components/StyledButton"
import { GetSavedFiltersResponse } from "src/store/sagas/saveFormulationsFilters"
import { LoadingOutlined } from "@ant-design/icons";
import useTranslate from "src/utils/useTranslate"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon"
import { StyledCard } from "src/styled_components/StyledCard"
import { ScrollToTopInDrawer } from "src/components/Common"
import { Checkbox, Descriptions, List as AntdList, Popconfirm, Typography } from "antd"
import { useMemberName } from "src/utils/useMemberName"
import dayjs from "dayjs"
import utc from 'dayjs/plugin/utc'
import { IngredientTabKeysType } from "./InventoryV2";
dayjs.extend(utc)

const { Title } = Typography

type P = {
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
    filter_type: 'inventory'
    onApplyFilter: (item: GetSavedFiltersResponse["result"]["data"][0]) => void,
    activeTab: IngredientTabKeysType
}

export const IngredientSavedFiltersHistory = ({
    setVisible,
    visible,
    filter_type = 'inventory',
    onApplyFilter,
    activeTab,
}: P) => {
    const { m } = useMesssage()
    const [t] = useTranslate()
    const dispatch = useDispatch()
    const drawerRef = useRef<HTMLDivElement>(null)
    const [selectedFilters, setSelectedFilters] = useState<string[]>([])
    const savedFormulationsFiltersRes = useSelector((state: StoreState) => state.savedFormulationsFilters)
    const deleteFiltersRes = useSelector((state: StoreState) => state.deleteFormulationsFilters)

    const inventorySavedFiltersData = useMemo(() => {
        return savedFormulationsFiltersRes?.data?.filter((savedResult) => activeTab === "active" ? !Boolean(savedResult?.is_archived) : Boolean(savedResult?.is_archived)) ?? []
    }, [activeTab, savedFormulationsFiltersRes?.data])


    useEffect(() => {
        if (deleteFiltersRes.status === AsyncStates.ERROR) {
            dispatch(deleteFiltersCleanup())
        }
        if (deleteFiltersRes.status === AsyncStates.SUCCESS) {
            dispatch(getSavedFiltersRemove(selectedFilters))
            setSelectedFilters([])
            dispatch(deleteFiltersCleanup())
        }
    }, [deleteFiltersRes, dispatch, selectedFilters])

    function deleteSelectedFilters() {
        if (selectedFilters.length === 0)
            return message.error("Please select at least one filter to delete")

        dispatch(
            deleteFiltersRequest({
                filter_ids: selectedFilters,
            })
        )
    }

    function onClose() {
        setSelectedFilters([])
        setVisible(false)
    }

    return (
        <Drawer
            open={visible}
            width={600}
            onClose={onClose}
            styles={{ body: { padding: "0 30px" } }}
            mask={false}
            title={
                <Row justify="space-between">
                    <Title level={4} style={{ margin: 0 }}>
                        {t("projects.savedFilter")}
                    </Title>
                </Row>
            }
            extra={
                <StyledButton
                    onClick={() => {
                        Modal.confirm({
                            title: `${t("common.delete")} ${t("common.selectedFilters")} ?`,
                            icon: <StyledDeleteIcon />,
                            okText: t("common.confirm"),
                            cancelText: t("common.cancel"),
                            okButtonProps: { style: { borderRadius: "12px" } },
                            cancelButtonProps: { style: { borderRadius: "12px" } },
                            onOk: () => {
                                deleteSelectedFilters();
                            },
                        });
                    }}
                    loading={savedFormulationsFiltersRes.status === AsyncStates.LOADING}
                    danger
                    icon={<StyledDeleteIcon />}
                    disabled={!selectedFilters.length}
                >
                    {t("aiEngine.deleteRecords")}
                    {selectedFilters.length > 0 && `(${selectedFilters.length})`}
                </StyledButton>
            }
        >
            <Spin
                spinning={savedFormulationsFiltersRes.status === AsyncStates.LOADING}
                indicator={<LoadingOutlined />}
            >
                <div ref={drawerRef}>
                    {(savedFormulationsFiltersRes.status === AsyncStates.SUCCESS ||
                        savedFormulationsFiltersRes.status === AsyncStates.INITIAL) &&
                        inventorySavedFiltersData && (
                            <List
                                data={inventorySavedFiltersData}
                                applyFilter={onApplyFilter}
                                drawerRef={drawerRef}
                                selectedFilters={selectedFilters}
                                setSelectedFilters={setSelectedFilters}
                            />
                        )}

                    {savedFormulationsFiltersRes.status === AsyncStates.ERROR && (
                        <div style={{ color: red.primary }}>
                            {typeof savedFormulationsFiltersRes.error === "string"
                                ? savedFormulationsFiltersRes.error
                                : m("internal_server_error")}
                        </div>
                    )}
                </div>
            </Spin>
        </Drawer>
    )
}


type LISTPROPSTYPES = {
    data: GetSavedFiltersResponse["result"]["data"]
    applyFilter: (item: GetSavedFiltersResponse["result"]["data"][0]) => void
    drawerRef: React.RefObject<HTMLDivElement>
    selectedFilters: string[]
    setSelectedFilters: Dispatch<React.SetStateAction<string[]>>
}

export const List = ({
    data,
    applyFilter,
    drawerRef,
    selectedFilters,
    setSelectedFilters,
}: LISTPROPSTYPES) => {
    const [t] = useTranslate()
    const { getName } = useMemberName()

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
            }}
        >
            <AntdList
                pagination={{
                    pageSize: 10,
                    showSizeChanger: false,
                    responsive: true,
                    position: "top",
                    total: data.length,
                }}
                size="small"
                grid={{ column: 1, gutter: 16 }}
                dataSource={data}
                renderItem={(item) => (
                    <AntdList.Item style={{ marginTop: 10 }}>
                        <StyledCard
                            size="small"
                            title={
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                    }}
                                >
                                    <Checkbox
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedFilters((prev) => [...prev, item.filter_id])
                                            } else {
                                                setSelectedFilters((prev) =>
                                                    prev.filter((id) => id !== item.filter_id)
                                                )
                                            }
                                        }}
                                        checked={selectedFilters.includes(item.filter_id)}
                                    >
                                        <Typography.Title level={4} style={{ userSelect: "none" }}>
                                            {item.name}
                                        </Typography.Title>
                                    </Checkbox>
                                </div>
                            }
                            extra={
                                <Popconfirm
                                    okText={t("common.ok")}
                                    cancelText={t("common.cancel")}
                                    title={t('common.applyThisFilter')}
                                    onConfirm={() => applyFilter(item)}
                                >
                                    <StyledButton
                                        type="link"
                                        size="middle"
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        {t('common.applyFilter')}
                                    </StyledButton>
                                </Popconfirm>
                            }
                        >
                            <Descriptions
                                column={1}
                                bordered
                                size="small"
                                style={{ background: "white" }}
                            >
                                <Descriptions.Item label={t("common.description")}>
                                    {item.description}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("aiEngine.user")}>
                                    {getName(item.user_id)}
                                </Descriptions.Item>
                                {item.created && (
                                    <Descriptions.Item label={t("common.createdOn")}>
                                        {dayjs.utc(item.created).format("MMMM Do YYYY, h:mm A") +
                                            " (UTC)"}
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                        </StyledCard>
                    </AntdList.Item>
                )}
            />
            {data.length > 5 && <ScrollToTopInDrawer sectionRef={drawerRef} />}
        </div>
    )
}

