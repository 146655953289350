import { handleActions } from "redux-actions"

import {
	FormulateActionTypes,
	ModelsConfigActionTypes,
	ForwardPredictionHistoryActionTypes,
	GetForwardConfigsActionTypes,
	GetForwardConfigsZeonActionTypes,
	GetCharacterizationMethodsZeonActionTypes,
	AddFavoritesActionTypes,
	GetFavoritesActionTypes,
	DeleteFavoritesActionTypes,
	FavouriteModelActionTypes,
	ModelsConfigListActionTypes,
	ChangeTitleActionTypes,
	GetPredictionTrialActionTypes
} from "src/store/actions/formulate"
import { AsyncStates } from "src/constants"
import { FormulationDatum } from "src/services/formulation/interface"

export type FormulateState = {
	configStatus: AsyncStates
	configError: string
	configData: FormulationDatum
	formulateStatus: AsyncStates
	formulateResult: any
	metricData: any
	error: string
	forwardPredListStatus: AsyncStates
	forwardPredResultStatus: AsyncStates
	forwardPredDeleteStatus: AsyncStates
	forwardPredList: any
	forwardPredResult: {
		hidden_ings: string[],
		hidden_procs: string[],
		predictions: any[],
		missing_properties: string[]
	}
	forwardPredResultTotal: number
	forwardPredId: string
	forwardPredResultCurrentpage: number
	forwardPredResultFilters: any
	forwardPredResultObjective: string
	mlProjectSpecificData: Partial<{
		"ingredients": any
		"processing": any
		"properties": any
		"items_per_property": any
		"ingredient_names": any
	}>
	mlProjectSpecificDataZeon: Partial<{
		"ingredients": {
			[key: string]: {
				min: number
				max: number
			}
		}
		"processing": {
			[key: string]: {
				min: number
				max: number
			}
		}
		"properties": {
			[key: string]: {
				min: number
				max: number
			}
		}
		"items_per_property": {
			[key: string]: {
				ingredients: string[]
				processing: string[]
			}
		}
		"ingredient_names": {
			[key: string]: string
		}
	}>
	mlProjectSpecificDataStatus: AsyncStates
	mlProjectSpecificDataZeonStatus: AsyncStates
	mlProjectSpecificDataError: string
	mlProjectSpecificDataZeonError: string
	characterizationMethodsZeon: any
	characterizationMethodsZeonStatus: AsyncStates
	characterizationMethodsZeonError: string,
	addFavoriteStatus: AsyncStates
	addFavoriteError: string
	favoritesData: {
		favoritesList: any[],
		favoritesDisplayNames: any
		total_count: number,
	}
	getFavoritesStatus: AsyncStates,
	getFavoritesError: string,
	deleteFavoritesStatus: AsyncStates,
	deleteFavoritesError: string,
	favouriteModelStatus: AsyncStates
	modelConfigData: any[],
	changeTitleStatus: AsyncStates,
	changeTitleResponse: any,
	projectsUsedInPredictions: {
		forward: {
			project_id: string,
			project_name: string,
			category_name: string
		}[],
		inverse: {
			project_id: string,
			project_name: string,
			category_name: string
		}[]
	},
	linkedPredictionTrialStatus: { [key: string]: string },
	linkedPredictionTrial: { [key: string]: any },
	linkedPredictionTrialError: { [key: string]: string },
}

const defaultState: FormulateState = {
	configStatus: AsyncStates.INITIAL,
	configError: "",
	configData: [],
	formulateStatus: AsyncStates.INITIAL,
	formulateResult: {},
	metricData: {},
	error: "",
	forwardPredListStatus: AsyncStates.INITIAL,
	forwardPredResultStatus: AsyncStates.INITIAL,
	forwardPredDeleteStatus: AsyncStates.INITIAL,
	forwardPredList: { forwardData: [], forwardTotal: "" },
	forwardPredResult: {
		hidden_ings: [],
		hidden_procs: [],
		predictions: [],
		missing_properties: []
	},
	forwardPredId: "",
	forwardPredResultTotal: 0,
	forwardPredResultCurrentpage: 1,
	forwardPredResultFilters: {},
	forwardPredResultObjective: "",
	mlProjectSpecificData: {},
	mlProjectSpecificDataStatus: AsyncStates.INITIAL,
	mlProjectSpecificDataError: "",
	mlProjectSpecificDataZeon: {},
	mlProjectSpecificDataZeonStatus: AsyncStates.INITIAL,
	mlProjectSpecificDataZeonError: "",
	characterizationMethodsZeon: null,
	characterizationMethodsZeonStatus: AsyncStates.INITIAL,
	characterizationMethodsZeonError: "",
	addFavoriteStatus: AsyncStates.INITIAL,
	addFavoriteError: "",
	favoritesData: {
		favoritesList: [],
		favoritesDisplayNames: {},
		total_count: 0,
	},
	getFavoritesStatus: AsyncStates.INITIAL,
	getFavoritesError: "",
	deleteFavoritesStatus: AsyncStates.INITIAL,
	deleteFavoritesError: "",
	favouriteModelStatus: AsyncStates.INITIAL,
	modelConfigData: [],
	changeTitleStatus: AsyncStates.INITIAL,
	changeTitleResponse: {},
	projectsUsedInPredictions: {
		forward: [],
		inverse: []
	},
	linkedPredictionTrialStatus: {},
	linkedPredictionTrial: {},
	linkedPredictionTrialError: {},
}

const formulateReducer = handleActions(
	{
		[ModelsConfigListActionTypes.REQUEST]: (state) => ({
			...state,
			configStatus: AsyncStates.LOADING,
			configError: "",
		}),
		[ModelsConfigListActionTypes.SUCCESS]: (state, action) => {
			return {
				...state,
				configStatus: AsyncStates.SUCCESS,
				configError: "",
				configData: action.payload.configData.data,
				projectsUsedInPredictions: action.payload.configData?.projectsUsedInPredictions ?? []
			}
		},
		[ModelsConfigListActionTypes.FAILURE]: (state, action) => ({
			...state,
			configStatus: AsyncStates.ERROR,
			configError: action.payload.error,
		}),
		[ModelsConfigActionTypes.REQUEST]: (state) => ({
			...state,
			configStatus: AsyncStates.LOADING,
			configError: "",
		}),
		[ModelsConfigActionTypes.SUCCESS]: (state, action: any) => {
			const prevModelConfig = state.modelConfigData
			const apiResponse = action.payload.configData
			const modelConfigData = !prevModelConfig?.length ? action.payload.configData : (prevModelConfig[0]?.version === apiResponse[0].version) ? prevModelConfig.reduce((acc: any[], curr: any) => {
				const currIndex = prevModelConfig[0].all_stages?.findIndex((ele: string) => ele === apiResponse[0].stage_name) ?? 0 // Need A Api Level Fix for getting all Stages all the time.
				if (currIndex !== -1) {
					acc[currIndex] = apiResponse[0]
					return acc
				}
				return acc
			}, [...prevModelConfig]) : action.payload.configData
			return {
				...state,
				configStatus: AsyncStates.SUCCESS,
				configError: "",
				modelConfigData: modelConfigData
			}
		},
		[ModelsConfigActionTypes.FAILURE]: (state, action) => ({
			...state,
			configStatus: AsyncStates.ERROR,
			configError: action.payload.error,
			modelConfigData: []
		}),
		[ModelsConfigActionTypes.CLEANUP]: (state, action) => ({
			...state,
			configStatus: AsyncStates.INITIAL,
			configError: "",
			modelConfigData: []
		}),
		[FormulateActionTypes.REQUEST]: (state, action: any) => {
			return {
				...state,
				formulateStatus: AsyncStates.LOADING,
				error: "",
			}
		},
		[FormulateActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				formulateStatus: AsyncStates.SUCCESS,
				formulateResult: action.payload,
				error: "",
			}
		},
		[FormulateActionTypes.FAILURE]: (state, action) => ({
			...state,
			formulateStatus: AsyncStates.ERROR,
			error: action.payload.error,
			formulateResult: {}
		}),
		[FormulateActionTypes.CLEANUP]: (state) => ({
			...state,
			configStatus: AsyncStates.INITIAL,
			configError: "",
			configData: [],
			formulateStatus: AsyncStates.INITIAL,
			formulateResult: {},
			error: "",
			forwardPredListStatus: AsyncStates.INITIAL,
			forwardPredResultStatus: AsyncStates.INITIAL,
			forwardPredDeleteStatus: AsyncStates.INITIAL,
			forwardPredList: { forwardData: [], forwardTotal: "" },
			forwardPredResult: {
				hidden_ings: [],
				hidden_procs: [],
				predictions: []
			},
		}),
		[FormulateActionTypes.SET_METRIC]: (state, action) => ({
			...state,
			metricData: action.payload,
		}),
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_LIST_REQUEST]: (
			state
		) => ({
			...state,
			forwardPredListStatus: AsyncStates.LOADING,
			forwardPredList: { forwardData: [], forwardTotal: "" },
		}),
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_LIST_SUCCESS]: (
			state,
			action
		) => {
			return {
				...state,
				forwardPredListStatus: AsyncStates.SUCCESS,
				forwardPredList: action.payload,
			}
		},
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_LIST_FAILURE]: (
			state,
			action
		) => ({
			...state,
			forwardPredListStatus: AsyncStates.ERROR,
			forwardPredList: { forwardData: [], forwardTotal: "" },
		}),
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_REQUEST]: (
			state
		) => ({
			...state,
			forwardPredResultStatus: AsyncStates.LOADING,
		}),
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_SUCCESS]: (state, action) => {
			return {
				...state,
				forwardPredResultStatus: AsyncStates.SUCCESS,
				forwardPredResult: action.payload.data,
				forwardPredResultTotal: action.payload.total,
			}
		},
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_CLEAR]: (state, action) => {
			return {
				...state,
				forwardPredResult: {
					hidden_ings: [],
					hidden_procs: [],
					predictions: [],
					missing_properties: []
				},
				forwardPredResultStatus: AsyncStates.INITIAL,
				forwardPredResultTotal: 0,
			}
		},
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_FILTERS_REQUEST]: (
			state,
			action
		) => ({
			...state,
			forwardPredResultFilters: action.payload,
		}),
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_CURRENTPAGE_REQUEST]: (
			state,
			action
		) => ({
			...state,
			forwardPredResultCurrentpage: action.payload,
		}),
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_OBJECTIVE_REQUEST]: (
			state,
			action
		) => ({
			...state,
			forwardPredResultObjective: action.payload,
		}),
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_ID_REQUEST]: (
			state,
			action
		) => ({
			...state,
			forwardPredId: action.payload,
		}),
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_FAILURE]: (
			state
		) => ({
			...state,
			forwardPredResultStatus: AsyncStates.ERROR,
		}),
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_DELETE_REQUEST]: (
			state
		) => ({
			...state,
			forwardPredDeleteStatus: AsyncStates.LOADING,
		}),
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_DELETE_SUCCESS]: (
			state
		) => {
			return {
				...state,
				forwardPredDeleteStatus: AsyncStates.SUCCESS,
			}
		},
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_DELETE_FAILURE]: (
			state
		) => ({
			...state,
			forwardPredDeleteStatus: AsyncStates.ERROR,
		}),
		[GetForwardConfigsActionTypes.REQUEST]: (state, action: any) => {
			return {
				...state,
				mlProjectSpecificDataStatus: AsyncStates.LOADING,
				mlProjectSpecificDataError: "",
			}
		},
		[GetForwardConfigsActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				mlProjectSpecificDataStatus: AsyncStates.SUCCESS,
				mlProjectSpecificDataError: "",
				mlProjectSpecificData: action.payload,
			}
		},
		[GetForwardConfigsActionTypes.FAILURE]: (state, action) => ({
			...state,
			mlProjectSpecificDataStatus: AsyncStates.ERROR,
			mlProjectSpecificDataError: action.payload.error,
		}),
		[GetForwardConfigsZeonActionTypes.REQUEST]: (state) => {
			return {
				...state,
				mlProjectSpecificDataZeonStatus: AsyncStates.LOADING,
				mlProjectSpecificDataZeonError: "",
			}
		},
		[GetForwardConfigsZeonActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				mlProjectSpecificDataZeonStatus: AsyncStates.SUCCESS,
				mlProjectSpecificDataZeonError: "",
				mlProjectSpecificDataZeon: action.payload,
			}
		},
		[GetForwardConfigsZeonActionTypes.FAILURE]: (state, action) => ({
			...state,
			mlProjectSpecificDataZeonStatus: AsyncStates.ERROR,
			mlProjectSpecificDataZeonError: action.payload.error,
		}),
		[GetCharacterizationMethodsZeonActionTypes.REQUEST]: (state) => {
			return {
				...state,
				characterizationMethodsZeonStatus: AsyncStates.LOADING,
				characterizationMethodsZeonError: "",
			}
		},
		[GetCharacterizationMethodsZeonActionTypes.SUCCESS]: (
			state: any,
			action: any
		) => {
			const apiResponse = action.payload.response
			const firstKey = Object.keys(apiResponse || {})[0]

			if (!firstKey)
				return {
					...state,
					characterizationMethodsZeonStatus: AsyncStates.SUCCESS,
					characterizationMethodsZeonError: "",
					characterizationMethodsZeon: {
						...state.characterizationMethodsZeon,
						[action.payload.from]: {
							type: "variations",
							data: {
								characterization_id: "",
								characterization_name: "",
								variations: [],
							},
						},
					}
				}

			const data: FormulateState["characterizationMethodsZeon"] =
				firstKey && apiResponse?.[firstKey].variations
					? {
						type: "variations",
						data: {
							characterization_id: firstKey,
							characterization_name: apiResponse?.[firstKey].name,
							variations: apiResponse?.[firstKey].variations,
						},
					}
					: {
						type: "methods",
						data: Object.keys(apiResponse || {}).map((chracId) => {
							return {
								characterization_id: chracId,
								characterization_name: apiResponse?.[chracId].name as string,
							}
						}),
					}

			return {
				...state,
				characterizationMethodsZeonStatus: AsyncStates.SUCCESS,
				characterizationMethodsZeonError: "",
				characterizationMethodsZeon: {
					...state.characterizationMethodsZeon,
					[action.payload.from]: data
				},
			}
		},
		[GetCharacterizationMethodsZeonActionTypes.FAILURE]: (state, action) => ({
			...state,
			characterizationMethodsZeonStatus: AsyncStates.ERROR,
			characterizationMethodsZeonError: action.payload.error,
		}),
		[GetCharacterizationMethodsZeonActionTypes.CLEAN]: (state, action) => ({
			...state,
			characterizationMethodsZeonStatus: AsyncStates.INITIAL,
			characterizationMethodsZeonError: "",
			characterizationMethodsZeon: null,
		}),
		[AddFavoritesActionTypes.REQUEST]: (state) => {
			return {
				...state,
				addFavoriteStatus: AsyncStates.LOADING,
				addFavoriteError: "",
			}
		},
		[AddFavoritesActionTypes.SUCCESS]: (state, action) => ({
			...state,
			addFavoriteStatus: AsyncStates.SUCCESS,
			addFavoriteError: "",
		}),
		[AddFavoritesActionTypes.FAILURE]: (state, action) => ({
			...state,
			addFavoriteStatus: AsyncStates.ERROR,
			addFavoriteError: action.payload.error,
		}),
		[GetFavoritesActionTypes.REQUEST]: (state) => ({
			...state,
			getFavoritesStatus: AsyncStates.LOADING,
			getFavoritesError: "",
		}),
		[GetFavoritesActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				getFavoritesStatus: AsyncStates.SUCCESS,
				getFavoritesError: "",
				favoritesData: action.payload,
			}
		},
		[GetFavoritesActionTypes.FAILURE]: (state, action) => ({
			...state,
			getFavoritesStatus: AsyncStates.ERROR,
			getFavoritesError: action.payload.error,
		}),
		[DeleteFavoritesActionTypes.REQUEST]: (state) => ({
			...state,
			deleteFavoritesStatus: AsyncStates.LOADING,
			deleteFavoritesError: "",
		}),
		[DeleteFavoritesActionTypes.SUCCESS]: (state, action) => ({
			...state,
			deleteFavoritesStatus: AsyncStates.SUCCESS,
			deleteFavoritesError: "",
		}),
		[DeleteFavoritesActionTypes.FAILURE]: (state, action) => ({
			...state,
			deleteFavoritesStatus: AsyncStates.ERROR,
			deleteFavoritesError: action.payload.error,
		}),
		[FavouriteModelActionTypes.REQUEST]: (state) => ({
			...state,
			favouriteModelStatus: AsyncStates.LOADING,
		}),
		[FavouriteModelActionTypes.SUCCESS]: (state, action) => {
			const property = action?.payload?.property
			const fav = action.payload?.fav
			const modelName = action?.payload?.modelName
			const updatedResults = [...state.forwardPredResult?.predictions]
			updatedResults.forEach((res: any) => {
				if (!!Object.keys(res?.property_data || {}).length) {
					Object.entries(res.property_data[property] || {}).forEach(([key, value]: any) => {
						if (key === modelName && fav) {
							res.property_data[property][key] = { ...value, favourite: true }
						} else {
							res.property_data[property][key] = { ...value, favourite: false }
						}
					})
				}
			})
			return ({
				...state,
				forwardPredResult: {
					...state.forwardPredResult,
					predictions: updatedResults
				},
				favouriteModelStatus: AsyncStates.SUCCESS,
			})
		},
		[FavouriteModelActionTypes.FAILURE]: (state, action) => ({
			...state,
			favouriteModelStatus: AsyncStates.ERROR,
		}),
		[FavouriteModelActionTypes.CLEANUP]: (state, action) => ({
			...state,
			favouriteModelStatus: AsyncStates.INITIAL,
		}),
		[ChangeTitleActionTypes.REQUEST]: (state, action) => ({
			...state,
			changeTitleStatus: AsyncStates.LOADING,
		}),
		[ChangeTitleActionTypes.SUCCESS]: (state, action: any) => ({
			...state,
			changeTitleStatus: AsyncStates.SUCCESS,
			changeTitleResponse: action.payload
		}),
		[ChangeTitleActionTypes.FAILURE]: (state, action) => ({
			...state,
			changeTitleStatus: AsyncStates.ERROR,
			changeTitleResponse: action.payload.error
		}),
		[ChangeTitleActionTypes.CLEAR]: (state, action) => ({
			...state,
			changeTitleStatus: AsyncStates.INITIAL,
			changeTitleResponse: ''
		}),
		[GetPredictionTrialActionTypes.REQUEST]: (state, action) => ({
			...state,
			linkedPredictionTrialStatus: {
				...state.linkedPredictionTrialStatus,
				[action.payload?.formulation_id]: AsyncStates.LOADING
			},
		}),
		[GetPredictionTrialActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				linkedPredictionTrialStatus: {
					...state.linkedPredictionTrialStatus,
					[action.payload?.formulation_id]: AsyncStates.SUCCESS
				},
				linkedPredictionTrial: {
					...state.linkedPredictionTrial,
					[action.payload?.formulation_id]: action.payload.data
				},
				linkedPredictionTrialError: {
					...state.linkedPredictionTrialError,
					[action.payload?.formulation_id]: null
				},
			}
		},
		[GetPredictionTrialActionTypes.FAILURE]: (state, action) => ({
			...state,
			linkedPredictionTrialStatus: {
				...state.linkedPredictionTrialStatus,
				[action.payload?.formulation_id]: AsyncStates.ERROR
			},
			linkedPredictionTrialError: {
				...state.linkedPredictionTrialError,
				[action.payload?.formulation_id]: action.payload.message
			},
		}),
		[GetPredictionTrialActionTypes.CLEAR]: (state, action) => ({
			...state,
			linkedPredictionTrialStatus: {},
			linkedPredictionTrial: {},
			linkedPredictionTrialError: {},
		}),
	},
	defaultState
)

export default formulateReducer
