import { blue } from "@ant-design/colors";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Space,
  Upload,
  message,
  Row,
  Tooltip,
  Spin,
  Modal,
  Typography,
  Steps,
  Col,
  Tabs,
  Table,
  Checkbox,
  Alert,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates, inventoryUploadTemplateUrl } from "src/constants";
import { inventoryUploadClear, inventoryUploadRequest } from "src/store/actions/inventory";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import jwtManager from "src/utils/jwtManager";
import useTranslate from "src/utils/useTranslate";
import XLSX from "xlsx";
import { Headers } from "src/services/interface";
import InventoryService from "src/services/inventory/v3";
import UploadFileSvg from 'src/assets/svgs/uploadFile.svg';
import type { CheckboxProps } from 'antd';
import dayjs from "dayjs";

const { Dragger } = Upload;

const { Text, Title } = Typography;

export const FileUploadModal = ({
  fileUploadVisible,
  setFileUploadVisible,
}: any) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();

  const inventoryUploadStatus = useSelector(
    (state: StoreState) => state.inventory.inventoryUploadStatus
  );
  const [file, setFile] = useState<any>();
  const [sheetData, setSheetData] = useState<any>([]);

  const defaultHeaders = useSelector(
    (state: StoreState) => state.defaultHeaders
  );

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [validatingStatus, setValidatingStatus] = useState(AsyncStates.INITIAL);
  const [validationData, setValidationData] = useState<any | null>({
    has_new_attributes: true,
    property: [],
    custom_fields: [],
    additives: [],
    ingredients: [],
    duplicate_ingredients: []
  });
  const [isInvalidFile, setIsInvalidFile] = useState<boolean>(false);
  const [validationError, setValidationError] = useState("");
  const [removeDuplicates, setRemoveDuplicates] = useState<boolean>(true);

  const handleFileUpload = async () => {
    const params = new FormData();
    params.append("file", file);
    params.append("data", JSON.stringify(sheetData));
    params.append("remove_duplicates", JSON.stringify(removeDuplicates));
    dispatch(inventoryUploadRequest(params));
  };

  const handleValidate = async () => {
    setValidatingStatus(AsyncStates.LOADING);
    const payload = new FormData();
    payload.append("file", file);
    payload.append("data", JSON.stringify(sheetData));
    payload.append("check_duplicate_ingredients", "true");

    const headers = {
      ...defaultHeaders,
      token: jwtManager.getToken(),
    } as Headers;
    try {
      const response: any = await InventoryService.validateData(
        payload,
        headers
      );
      const status = response?.data?.result?.status;
      if (status === 'Failed') {
        setValidatingStatus(AsyncStates.ERROR);
        setIsInvalidFile(true);
        setValidationError(response?.data?.result?.message ?? t("common.somethingWentWrong"));
      } else {
        setValidatingStatus(AsyncStates.SUCCESS);
        setIsInvalidFile(false);
        setValidationData(response?.data?.result?.data);
        setCurrentStep(1);
      }
    } catch (error) {
      console.log(error);
      message.error(t("common.somethingWentWrong"));
      setValidatingStatus(AsyncStates.ERROR);
    }
  };

  const uploadProps = {
    onRemove: (record: any) => {
      setFile(null);
      setIsInvalidFile(false);
      setValidationError("");
      setValidatingStatus(AsyncStates.INITIAL);
      setRemoveDuplicates(true)
    },
    beforeUpload: (record: any) => {
      if (record.name.split(".").pop() === "xlsx") {
        setFile(record);
      } else message.error(t("dataUpload.selectedFileDesc"));
      return false;
    },
    multiple: false,
    fileList: !!file ? [file] : [],
  };

  const handleClose = useCallback(() => {
    setFile(null);
    setValidationData({
      has_new_attributes: false,
      property: [],
      custom_fields: [],
      additives: [],
      ingredients: [],
      duplicate_ingredients: []
    })
    dispatch(inventoryUploadClear());
    setCurrentStep(0);
    setFileUploadVisible(false);
    setValidatingStatus(AsyncStates.INITIAL);
    setRemoveDuplicates(true)
  }, [setFileUploadVisible, dispatch]);

  useEffect(() => {
    if (inventoryUploadStatus === AsyncStates.SUCCESS) {
      handleClose();
    }
  }, [inventoryUploadStatus, setFileUploadVisible, handleClose]);

  useEffect(() => {
    !!file &&
      (async () => {
        const fileSheets = await file.arrayBuffer();
        const workbook = XLSX.read(fileSheets);
        const sheets = workbook.SheetNames;
        const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheets?.[0]], {
          defval: "", raw: false
        });
        const valuesMapping = sheet?.[0];
        const responseData: any = [];
        sheet.forEach((res: any, index: number) => {
          if (index !== 0) {
            const row = Object.entries(valuesMapping || {}).reduce(
              (acc: any, [key, value]: any) => ({
                ...acc,
                [value]: res?.[key],
              }),
              {}
            );
            responseData.push(row);
          }
        });
        setSheetData(responseData);
      })();
  }, [file]);

  const toggleRemoveDuplicates: CheckboxProps['onChange'] = (e) => {
    setRemoveDuplicates(e.target.checked)
  }

  return (
    <Modal
      title={
        <Row>
          <Col span={12}>
            <Steps
              size="small"
              current={currentStep}
              onChange={(curr) => {
                setCurrentStep(curr);
              }}
              items={[
                {
                  title: t("common.uploadAFile"),
                },
                {
                  title: t("common.Datavalidation"),
                  disabled: !file || (validatingStatus !== AsyncStates.SUCCESS)
                },
                {
                  title: t("common.duplicate"),
                  disabled: !file || (validatingStatus !== AsyncStates.SUCCESS)
                },
              ]}
            />
          </Col>
        </Row>
      }
      open={fileUploadVisible}
      onCancel={() => {
        setCurrentStep(1);
        handleClose();
      }}
      width={800}
      footer={null}
    >
      {currentStep === 0 ? (
        <Spin
          spinning={inventoryUploadStatus === AsyncStates.LOADING}
          indicator={<LoadingOutlined />}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Dragger {...uploadProps}>
                <p
                  style={{ paddingTop: 30, paddingBottom: 30 }}
                  className="ant-upload-text"
                >
                  {t("dataUpload.dragnDrop")}
                  <span style={{ color: blue[5] }}>
                    {" "}
                    {t("dataUpload.selectFile")}
                  </span>
                </p>
              </Dragger>
              {
                isInvalidFile && !!file && <Text style={{ color: "red" }}>
                  {validationError}
                </Text>
              }
              <Row justify="start">
                <Text>
                  {t("datamapper.needHelp?")}{" "}
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={inventoryUploadTemplateUrl}
                  >
                    {t("datamapper.downloadTemplate?")}{" "}
                  </a>
                  {t("datamapper.spreadsheet?")}
                </Text>
              </Row>
              <Row justify="end">
                <Tooltip
                  title={
                    !file ? t("common.uploadAFile") : t("common.Datavalidation")
                  }
                >
                  <StyledButton
                    loading={validatingStatus === AsyncStates.LOADING}
                    onClick={handleValidate}
                    type="primary"
                    htmlType="submit"
                    disabled={!file}
                  >
                    {t("common.continue")}
                  </StyledButton>
                </Tooltip>
              </Row>
            </Space>
          </Space>
        </Spin>
      ) :
        currentStep === 1 ? (
          <Space direction="vertical" style={{ width: "100%" }}>
            {validationData?.has_new_attributes ? (
              <>
                <Row justify="space-between" align="middle">
                  <Title level={5}>{`${validationData.property.length + validationData.custom_fields.length + validationData.additives.length} ${t("inventory.newAttributesFound")}`}</Title>
                  <StyledButton
                    onClick={() => {
                      window.open('/inventory/repository/properties', "_blank")
                    }}
                    htmlType="submit"
                    size="small"
                  >
                    {`${t("common.view")} ${t("common.repository")}`}
                  </StyledButton>
                </Row>
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane
                    tab={`${t("common.properties")} (${validationData.property.length})`}
                    key={"1"}
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Table
                      size="small"
                      dataSource={validationData.property}
                      columns={[
                        {
                          title: t("common.category"),
                          dataIndex: "category",
                          key: "category",
                        },
                        {
                          title: t("common.name"),
                          dataIndex: "name",
                          key: "name",
                        },
                      ]}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={`${t("common.standards")} (${validationData?.standards?.length ?? 0})`}
                    key={"2"}
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Table
                      size="small"
                      dataSource={validationData?.standards}
                      columns={[
                        {
                          title: t("common.standard"),
                          dataIndex: "name",
                          key: "name",
                          width: 250,
                          render: (text: string) => {
                            return <Text
                              style={{ width: 250 }}
                              ellipsis={{ tooltip: text }}
                            >
                              {text}
                            </Text>
                          }
                        },
                        {
                          title: t("inventory.propertyName"),
                          dataIndex: "property_name",
                          key: "property_name",
                          width: 250,
                          render: (text: string) => {
                            return <Text
                              style={{ width: 250 }}
                              ellipsis={{ tooltip: text }}
                            >
                              {text}
                            </Text>
                          }
                        },
                        {
                          title: t("common.propertyCategory"),
                          dataIndex: "property_category",
                          key: "property_category",
                          width: 200,
                          render: (text: string) => {
                            return <Text
                              style={{ width: 200 }}
                              ellipsis={{ tooltip: text }}
                            >
                              {text}
                            </Text>
                          }
                        },
                      ]}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={`${t("common.methods")} (${validationData?.property_methods?.length})`}
                    key={"3"}
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Table
                      size="small"
                      dataSource={validationData?.property_methods}
                      columns={[
                        {
                          title: t("common.methodName"),
                          dataIndex: "name",
                          key: "name",
                          width: 250,
                          render: (text: string) => {
                            return <Text
                              style={{ width: 250 }}
                              ellipsis={{ tooltip: text }}
                            >
                              {text}
                            </Text>
                          }
                        },
                        {
                          title: t("inventory.propertyName"),
                          dataIndex: "property_name",
                          key: "property_name",
                          width: 250,
                          render: (text: string) => {
                            return <Text
                              style={{ width: 250 }}
                              ellipsis={{ tooltip: text }}
                            >
                              {text}
                            </Text>
                          }
                        },
                        {
                          title: t("common.propertyCategory"),
                          dataIndex: "property_category",
                          key: "property_category",
                          width: 200,
                          render: (text: string) => {
                            return <Text
                              style={{ width: 200 }}
                              ellipsis={{ tooltip: text }}
                            >
                              {text}
                            </Text>
                          }
                        },
                      ]}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={`${t("projects.customFields")} (${validationData.custom_fields.length})`}
                    key={"4"}
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Table
                      size="small"
                      dataSource={validationData.custom_fields}
                      columns={[
                        {
                          title: t("common.name"),
                          dataIndex: "name",
                          key: "name",
                        },
                        {
                          title: t("common.type"),
                          dataIndex: "type",
                          key: "type",
                        },
                      ]}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={`${t("inventory.Additives")} (${validationData.additives.length})`}
                    key={"5"}
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Table
                      size="small"
                      dataSource={validationData.additives}
                      columns={[
                        {
                          title: t("common.category"),
                          dataIndex: "additive_category",
                          key: "additive_category",
                        },
                        {
                          title: t("common.name"),
                          dataIndex: "additive_name",
                          key: "additive_name",
                        },
                      ]}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </>
            ) : (
              <>
                <Row justify="center">
                  <Col span={8}>
                    <Space direction="vertical" style={{ width: '100%', alignItems: 'center' }}>
                      <img src={UploadFileSvg} alt={t("common.upload")} />
                      <Text strong>{t("inventory.noNewAttributes")}</Text>
                      <Text strong style={{ textAlign: 'center' }}>{t("inventory.pleaseProceedWithUpload")}</Text>
                    </Space>
                  </Col>
                </Row>
              </>
            )}
            <Row justify="end">
              <StyledButton
                onClick={() => setCurrentStep(2)}
                type="primary"
                htmlType="submit"
                disabled={!file}
                loading={inventoryUploadStatus === AsyncStates.LOADING}
              >
                {t("common.next")}
              </StyledButton>
            </Row>
          </Space>
        ) :
          (
            <Space direction="vertical" style={{ width: "100%" }}>
              <div className="file__upload__duplicate__ingredients__container">
                <Row justify="space-between" align="middle">
                  <Title level={4}>{`${validationData.duplicate_ingredients.length ? validationData.duplicate_ingredients.length : t("common.no")} ${t("common.duplicate")} ${t("inventory.Ingredients")} ${t("common.found")}!`} </Title>
                  <StyledButton
                    onClick={() => {
                      window.open('/inventory/ingredients?tab=active', "_blank")
                    }}
                    htmlType="submit"
                    size="small"
                  >
                    {`${t("common.view")} ${t("common.ingredients")}`}
                  </StyledButton>
                </Row>

                <div>
                  <Alert
                    type="info"
                    message={t("inventory.removeDuplicatesLogicNote")}
                  />
                </div>

                <Table
                  size="small"
                  dataSource={validationData.duplicate_ingredients}
                  columns={[
                    {
                      title: t("inventory.IngredientName"),
                      dataIndex: "name",
                      key: "name",
                      width: 200,
                      render: (text: string) => {
                        return <Text
                          style={{ width: 200 }}
                          ellipsis={{ tooltip: text }}
                        >
                          {text}
                        </Text>
                      }
                    },
                    {
                      title: t("inventory.Category"),
                      dataIndex: "category",
                      key: "category",
                      width: 150,
                      render: (text: string) => {
                        return <Text
                          style={{ width: 150 }}
                          ellipsis={{ tooltip: text }}
                        >
                          {text}
                        </Text>
                      }
                    },
                    {
                      title: t("inventory.Grade"),
                      dataIndex: "grade",
                      key: "grade",
                      width: 150,
                      render: (text: string) => {
                        return <Text
                          style={{ width: 150 }}
                          ellipsis={{ tooltip: text }}
                        >
                          {text}
                        </Text>
                      }
                    },
                    {
                      title: t("inventory.lot_no"),
                      dataIndex: "lot_no",
                      key: "lot_no",
                    },
                    {
                      title: t("common.versionDate"),
                      dataIndex: "version_date",
                      key: "version_date",
                      render: (text, record) => {
                        return record?.version_date ? dayjs(record?.version_date).format("DD-MM-YYYY") : null
                      }
                    }
                  ]}
                />

                <div>
                  <Checkbox
                    disabled={!validationData?.duplicate_ingredients?.length}
                    checked={removeDuplicates}
                    onChange={toggleRemoveDuplicates}>{t("inventory.removeDuplicates")}</Checkbox>
                </div>

              </div>
              <Row justify="end">
                <StyledButton
                  onClick={handleFileUpload}
                  type="primary"
                  htmlType="submit"
                  disabled={!file}
                  loading={inventoryUploadStatus === AsyncStates.LOADING}
                >
                  {!!validationData?.duplicate_ingredients?.length && removeDuplicates ? t("inventory.removeDuplicatesAndUpload") : t("common.upload")}
                </StyledButton>
              </Row>
            </Space>
          )}
    </Modal>
  );
};
