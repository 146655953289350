import { put, takeLatest, call } from "redux-saga/effects";
import { signupRequest, signupSuccess, signupFailure } from "../actions/signup";
import {
  signupApi,
} from "../../services/login";
import { defaultHeaders } from "src/constants";
import { history } from "src";
import { messages } from "src/utils/hooks";
import { loginSuccess } from "../actions";

type signupAction = {
  type: string;
  payload: {
    user_name: string;
    user_location: string;
    company: string;
    user_email: string;
    pwd: string;
    user_loggedin_IP: string;
  };
};

function* signupSaga({ payload }: signupAction): Generator<any, any, any> {

  try {
    const {
      data: { result },
    } = yield call(signupApi, payload, defaultHeaders);
    if (result.status === "Success") {
      yield put(signupSuccess(result));
      if (result.data?.user_verified && result.data?.user_access) {
        yield put(loginSuccess(result.data))
        history.push("/formulations")
      } else {
        history.push("/access-denied", { user_email: payload.user_email, user_name: payload.user_name });
      }
    } else {
      yield put(signupFailure(result.message));
    }
  } catch (error: any) {
    yield (put(signupFailure(messages["en"].internal_server_error)))
  }
}

export default function* rootSaga() {
  yield takeLatest(signupRequest, signupSaga);
}
