//App.js File
import { blue } from "@ant-design/colors";
import {
  UpOutlined,
  DownOutlined,
  InfoCircleFilled,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Form,
  Col,
  Tag,
  Tooltip,
  Card,
  Row,
  Space,
  Descriptions,
  Input,
  AutoComplete,
  Select,
  DatePicker,
  Popconfirm,
  message,
  Typography,
  Switch,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AsyncStates,
  permissions,
  projectStatus,
  projectType,
} from "src/constants";
import { forwardPredIdRequest, forwardPredResultCurrentpageRequest, forwardPredResultFiltersRequest, forwardPredResultRequest } from "src/store/actions/formulate";
import { inverseNotificationClick } from "src/store/actions/inverseModel";
import { suggestedExperimentsRequest } from "src/store/actions/suggestedExp";
import { createWorkOrderRequest } from "src/store/actions/workOrderDetails";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { StyledCard } from "src/styled_components/StyledCard";
import { mapFields } from "src/utils/decorator";
import { useMemberName } from "src/utils/useMemberName";
import useTranslate from "src/utils/useTranslate";
import { Stages } from "src/utils/mapDataset";
import { usePermission } from "src/utils/usePermissions";
import { useHighlightOptions } from "src/utils/useHighlightOptions";
import { TemplatesOptionModal } from "../../Templates/TemplatesOptionModal";
import { listTemplateRequest } from "src/store/actions/templates";
import { CustomFieldsModal } from "../../Templates/CustomFieldsModal";
import { SaveTemplateModal } from "../../Templates/SaveTemplateModal";
import { ChooseWorkOrderModal } from "../new-word-order";
import { CustomStagesModal } from "../CustomStagesModal";
import CelsureWo from "./CelsureWo";
import { useHistory } from "react-router-dom";
import { labLocationInitialState } from "src/components/Settings/tabs/LabLocation/LabLocation";
import { LabLocationModal } from "src/components/Settings/tabs/LabLocation/LabLocationModal/LabLocationModal";
import { useValue } from "src/utils/useValue";
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar";
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";
import { defaultStages as baseStages } from "src/constants";

const { Text } = Typography;
const { Option } = Select;

type MoreDetailsProps = {
  selectedDate: any;
  setSelectedDate: any;
  workOrder: any;
  setLabLocationModalState: any
};

const MoreDetails = ({
  selectedDate,
  setSelectedDate,
  workOrder,
  setLabLocationModalState
}: MoreDetailsProps) => {
  const [t] = useTranslate();


  const { current, projectList: projects } = useSelector((state: StoreState) => state.projects);
  const currentProject = useMemo(() => projects.find((project: any) => project.project_id === current), [projects, current]);
  const labLocationListOptions = useMemo(() => !!currentProject?.lab_locations?.length ? currentProject?.lab_locations?.map((labLocation: any) => ({ label: `${labLocation.name} (${labLocation.city}, ${labLocation.country})`, value: labLocation.lab_location_id, key: labLocation.lab_location_id })) : [], [currentProject?.lab_locations])

  return (
    <>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item
            label={
              <Text className="highlighted">
                {t("workOrderDetails.orderType")}
              </Text>
            }
            labelCol={{ flex: "120px" }}
            labelAlign="left"
            name="work_order_type"
            rules={[
              {
                required: false,
                min: 2,
                whitespace: true,
                type: "string",
                transform: (value) => value?.trim(),
              },
            ]}
          >
            <Input
              bordered={false}
              placeholder={t("workOrderDetails.orderType")}
            ></Input>
          </Form.Item>
          <Form.Item
            label={<Text className="highlighted">{t("common.grade")}</Text>}
            labelCol={{ flex: "120px" }}
            labelAlign="left"
            name="grade"
            rules={[
              {
                required: false,
                min: 2,
                whitespace: true,
                type: "string",
                transform: (value) => value?.trim(),
              },
            ]}
          >
            <Input
              bordered={false}
              placeholder={t("workOrderDetails.selectGrade")}
            ></Input>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <Text className="highlighted">
                {t("workOrderDetails.closeDate")}
              </Text>
            }
            labelCol={{ flex: "120px" }}
            labelAlign="left"
            name="close_date"
          >
            <DatePicker
              bordered={false}
              disabledDate={(dates) => {
                if (selectedDate?.startDate && dates < selectedDate.startDate) {
                  return true;
                }
                return false;
              }}
              onChange={(value) => {
                setSelectedDate((prev: any) => ({
                  ...prev,
                  closeDate: value,
                }));
              }}
            />
          </Form.Item>
          {!!Object.keys(workOrder || {}).length && (
            <Form.Item
              label={<Text className="highlighted">{t("common.createdOn")}</Text>}
              labelCol={{ flex: "120px" }}
              labelAlign="left"
              name="created"
            >
              <DatePicker disabled={true} bordered={false} />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item
            required={false}
            label={
              <Text className="highlighted">{t("common.labLocation")}</Text>
            }
            labelCol={{ flex: "120px" }}
            labelAlign="left"
            name="lab_location"
          >
            <Select
              placeholder={t("common.selectLabLocation")}
              allowClear
              optionFilterProp='label'
              showSearch
              style={{ maxWidth: 300 }}
              bordered={false}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {/* <Divider style={{ margin: '8px 0' }} />
                  <Space style={{ padding: '0 8px 4px', float: "right" }}>
                    <StyledButton size='small' type="text" icon={<PlusOutlined />} onClick={() => setLabLocationModalState((prev: any) => ({
                      ...prev,
                      mode: "create",
                      isModalVisible: true,
                      project_id: current
                    }))}>
                      Add Lab Location
                    </StyledButton>
                  </Space> */}
                </>
              )}
              options={labLocationListOptions}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_multiunit" label={<Text className="highlighted">{t("workOrderDetails.multiUnit")}</Text>}>
            <Switch />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const CreateWo = () => {
  const [form] = Form.useForm();
  const { push } = useHistory();
  const [t] = useTranslate();
  const { getName, getId } = useMemberName();
  const requiredFieldStar = useRequiredFieldStar()

  const { formatter, parser } = useValue()
  const [customFields, setCustomFields] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { material, application } = useSelector(
    (state: StoreState) => state.displayNames.data
  );
  const userId = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id
  );
  const [materialStr, setmaterialStr] = useState("");
  const [stages, setStages] = useState(() => JSON.parse(JSON.stringify(baseStages)));
  const [expand, setExpand] = useState(false);
  const [copyCopyWorkOrderModal, setCopyWorkOrderModal] = useState(false);
  const [stagesModalVisisble, setStagesModalVisible] = useState(false);
  const [templateOptionsModal, setTemplateOptionsModal] = useState(false);
  const [saveTemplateModalVisible, setSaveTemplateModalVisible] =
    useState(false);
  const [customModalVisible, setCustomModalVisible] = useState(false);
  const materialOptions = useHighlightOptions({
    data: Object.entries(material || {}).reduce(
      (acc: any, [key, value]: any) => ({ ...acc, [key]: value?.name }),
      {}
    ) as any,
    searchStr: materialStr,
  });

  const [applicationStr, setapplicationStr] = useState("");
  const applicationOptions = useHighlightOptions({
    data: application?.reduce(
      (acc: any, res: any) => ({ ...acc, [res]: res }),
      {}
    ) as any,
    searchStr: applicationStr,
  });

  const project_type = useSelector(
    (state: StoreState) => state.login.loginResponse.project_type
  );

  const workOrder = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder || {}
  );
  const prediction_id = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder.prediction_id
  );
  const workOrdersList = useSelector(
    (state: StoreState) => state.workOrders.data
  );
  const userAccess = usePermission();
  const disabled = useMemo(
    () =>
      userAccess?.permission === permissions.viewer ||
      userAccess?.status !== projectStatus.in_progress,
    [userAccess]
  );

  const { current, projectList: projects } = useSelector(
    (state: StoreState) => state.projects
  );
  const currentProject = useMemo(
    () => projects.find((project: any) => project.project_id === current),
    [projects, current]
  );
  const [workOrderToCopyParams, setWorkOrderToCopyParam] = useState<any | null>(
    {
      workOrder: null,
      use_ingredients: true,
      use_processing: true,
      use_characterization: true,
      use_properties: true,
    }
  );
  const createWOStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.createWOStatus
  );
  const [selectedDate, setSelectedDate] = useState({
    startDate: workOrder.start_date ? dayjs(workOrder.start_date) : null,
    closeDate: workOrder.close_date ? dayjs(workOrder.close_date) : null,
  });
  const labsLocationList = useSelector((state: StoreState) => state.settings.labsLocationList);

  const [labLocationModalState, setLabLocationModalState] = useState(labLocationInitialState);

  const getInitialValues = (field: any) => {
    if (!workOrder?.work_order_id) {
      if (field.field_type === "date") {
        if (!!field?.value || !!field.default_value) {
          return dayjs(field.default_value);
        } else {
          return null;
        }
      } else {
        return field?.value ?? field?.default_value;
      }
    } else {
      if (field.field_type === "date") {
        if (!!field?.value || !!field.default_value) {
          return dayjs(field?.value ?? field.default_value);
        } else return null;
      } else {
        return field?.value ?? field?.default_value;
      }
    }
  };

  const submit = useCallback(
    (values: any) => {
      if (
        !!values?.close_date &&
        !!values?.start_date &&
        dayjs(values?.start_date).isSameOrAfter(dayjs(values?.close_date))
      ) {
        return message.error(
          t("common.message.closeDateGreaterEqualStartDate")
        );
      }
      const additional_fields: any = [];
      Object.entries(values || {}).forEach(([key, value]: any) => {
        const record = customFields.find((res: any) => res.field_name === key);
        if (!!record) {
          additional_fields.push({
            ...record,
            value:
              record?.field_type === "date" &&
                (record?.value ?? record?.default_value)
                ? dayjs(value).toISOString()
                : value,
          });
        }
      });
      dispatch(
        createWorkOrderRequest({
          ...values,
          work_order_dri: !!values.work_order_dri?.length
            ? values.work_order_dri.map((res: any) => getId(res))
            : [],
          ...(!!values?.start_date && {
            start_date: dayjs(values?.start_date).toISOString(),
          }),
          lab_location: labsLocationList.find((lablocation) => lablocation.lab_location_id === values?.lab_location) ?? null,
          ...(!!values?.close_date && {
            close_date: dayjs(values?.close_date).toISOString(),
          }),
          ...(workOrder?.includeAll && {
            prediction_id: prediction_id,
          }),
          generate_type: workOrder?.generateType,
          work_order_seq: -1,
          work_order_stage: Stages.work_order_initiated,
          project_id: currentProject.project_id,
          stages,
          ...(!!additional_fields.length && { additional_fields }),
          ...(!!workOrderToCopyParams?.workOrder?.work_order_id && {
            duplicate_parameters: {
              work_order_id: workOrderToCopyParams?.workOrder?.work_order_id,
              use_ingredients: workOrderToCopyParams?.use_ingredients,
              use_processing: workOrderToCopyParams?.use_processing,
              use_characterization: workOrderToCopyParams.use_characterization,
              use_properties: workOrderToCopyParams?.use_properties,
            },
          }),
        })
      );
    },
    [dispatch, labsLocationList, workOrder?.includeAll, workOrder?.generateType, prediction_id, currentProject.project_id, stages, workOrderToCopyParams?.workOrder?.work_order_id, workOrderToCopyParams?.use_ingredients, workOrderToCopyParams?.use_processing, workOrderToCopyParams.use_characterization, workOrderToCopyParams?.use_properties, t, customFields, getId]
  );

  useEffect(() => {
    dispatch(listTemplateRequest());
  }, [dispatch]);


  useEffect(() => {
    if (createWOStatus === AsyncStates.SUCCESS) {
      setStages(JSON.parse(JSON.stringify(baseStages)))
    }
  }, [createWOStatus])

  return (
    <>
      {project_type === projectType.celsure ? (
        <CelsureWo />
      ) : (
        <>
          <StyledPageHeader
            ghost={false}
            title={t("common.createWorkOrder")}
            onBack={() => window.history.back()}
          />
          <Form
            size="small"
            form={form}
            requiredMark={false}
            onFinish={submit}
            initialValues={{
              work_order_name: `WO-${workOrdersList.length + 1} - ${new Date().toLocaleString("default", {
                day: "numeric",
                month: "long",
              })}`,
            }}
          >
            <Card
              title={"Details"}
              actions={[
                expand ? (
                  <StyledButton
                    size="small"
                    type="link"
                    icon={<UpOutlined />}
                    onClick={() => setExpand(false)}
                  >
                    {t("workOrderDetails.lessDetails")}
                  </StyledButton>
                ) : (
                  <StyledButton
                    type="link"
                    icon={<DownOutlined />}
                    onClick={() => setExpand(true)}
                  >
                    {t("common.moreDetails")}
                  </StyledButton>
                ),
                <StyledButton
                  htmlType="reset"
                  size="small"
                  onClick={() => form.resetFields()}
                >
                  {t("common.clear")}
                </StyledButton>,
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  {!!workOrderToCopyParams?.workOrder?.work_order_name && (
                    <Tag
                      style={{ zIndex: 10 }}
                      closable
                      onClose={() => {
                        setWorkOrderToCopyParam({
                          workOrder: null,
                          use_ingredients: true,
                          use_processing: true,
                          use_characterization: true,
                          use_properties: true,
                        });
                      }}
                      color={"orange"}
                    >
                      {workOrderToCopyParams?.workOrder.work_order_name}
                    </Tag>
                  )}
                  {workOrdersList.length > 0 && (
                    <StyledButton
                      size="small"
                      style={{ maxWidth: "max-content" }}
                      onClick={() => setCopyWorkOrderModal(true)}
                    >
                      {t("workOrder.addDuplicateParameters")}
                    </StyledButton>
                  )}
                </Col>,
                <StyledButton
                  htmlType="submit"
                  size="small"
                  type="primary"
                  loading={createWOStatus === AsyncStates.LOADING}
                >
                  {t("workOrderDetails.create")}
                </StyledButton>,
              ]}
              bodyStyle={{ padding: "12px 28px" }}
              headStyle={{ padding: 4, paddingInline: 16 }}
              extra={
                !workOrder.work_order_id &&
                !workOrder?.suggested_experiment_id?.length &&
                !workOrder?.prediction_experiment_ids && (
                  <Tooltip title={t("stages.addStageToTheWO")}>
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <StyledButton
                        disabled={disabled}
                        style={{
                          pointerEvents: "auto",
                        }}
                        onClick={() => setStagesModalVisible(true)}
                        type="link"
                        size="middle"
                      >
                        {t("stages.addStages")}
                      </StyledButton>
                    </span>
                  </Tooltip>
                )
              }
            >
              {!!workOrder?.includeAll && (
                <>
                  <Card type="inner" bodyStyle={{ borderLeft: "3px solid" }}>
                    <Row justify="space-between">
                      <Col>
                        {t("workOrderDetails.basedOnAiPrediction")}
                        <Typography.Paragraph strong style={{ display: 'inline' }} >
                          {prediction_id}
                        </Typography.Paragraph>
                      </Col>
                    </Row>
                  </Card>
                  <br />
                </>
              )}
              {!!workOrder?.suggested_experiment_id?.length && (
                <>
                  <Card type="inner" bodyStyle={{ borderLeft: "3px solid" }}>
                    <Row justify="space-between">
                      <Col>
                        {t("workOrderDetails.basedOnAi")}
                        <Typography.Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }} strong style={{ display: 'inline' }} >
                          {workOrder.suggested_experiment_id.join(", ")}
                        </Typography.Paragraph>
                      </Col>
                      {!workOrder.work_order_id && (
                        <Col>
                          <StyledButton
                            size="small"
                            onClick={() => {
                              dispatch(
                                suggestedExperimentsRequest({
                                  prediction_id,
                                  pageNum: 1,
                                  version: workOrder.version,
                                })
                              );
                              dispatch(inverseNotificationClick(true));
                              push(`/ai-engine/history/formulation_prediction?predId=${prediction_id}`);
                            }}
                          >
                            {t("workOrderDetails.viewAll")}
                          </StyledButton>
                        </Col>
                      )}
                    </Row>
                  </Card>
                  <br />
                </>
              )}
              {!!workOrder?.prediction_experiment_ids?.length && (
                <>
                  <Card
                    type="inner"
                    bodyStyle={{ borderLeft: "3px solid" + blue.primary }}
                  >
                    <Row justify="space-between">
                      <Col>
                        {t("workOrderDetails.basedOnAi")}
                        <Typography.Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }} strong style={{ display: 'inline' }} >
                          {workOrder.prediction_experiment_ids.join(", ")}
                        </Typography.Paragraph>
                      </Col>
                      {!workOrder.work_order_id && (
                        <Col>
                          <StyledButton
                            size="small"
                            onClick={() => {

                              dispatch(
                                forwardPredResultRequest({
                                  prediction_id,
                                  type: "history"
                                })
                              );
                              dispatch(forwardPredIdRequest(prediction_id))
                              dispatch(forwardPredResultCurrentpageRequest(1))
                              dispatch(forwardPredResultFiltersRequest({}))
                              push(`/ai-engine/history/properties_prediction?predId=${prediction_id}`);
                            }}
                          >
                            {t("workOrderDetails.viewAll")}
                          </StyledButton>
                        </Col>
                      )}
                    </Row>
                  </Card>
                  <br />
                </>
              )}
              {!!workOrder?.doe_id?.length && (
                <>
                  <Card
                    type="inner"
                    bodyStyle={{ borderLeft: "3px solid" + blue.primary }}
                  >
                    <Row justify="space-between">
                      <Col>
                        {`${t("workOrder.basedOnDoe")} - `}
                        <Typography.Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }} strong style={{ display: 'inline' }} >
                          {workOrder.doe_id.join(", ")}
                        </Typography.Paragraph>
                      </Col>
                    </Row>
                  </Card>
                  <br />
                </>
              )}
              {!!Object.keys(workOrder?.meta || {}).length && (
                <>
                  <Card
                    type="inner"
                    bodyStyle={{ borderLeft: "3px solid" + blue.primary }}
                  >
                    <Space
                      direction="vertical"
                      size="small"
                      style={{ width: "100%" }}
                    >
                      <Text strong> {t("woDetails.reopened")}</Text>
                      <Descriptions bordered column={2}>
                        <Descriptions.Item label={t("common.reopenedBy")}>
                          {getName(workOrder?.meta?.user_id)}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("common.reopenedOn")}>
                          {new Date(workOrder?.updated).toLocaleDateString()}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={t("common.reasonForReopening")}
                        >
                          {workOrder?.meta?.comment}
                        </Descriptions.Item>
                      </Descriptions>
                    </Space>
                  </Card>
                  <br />
                </>
              )}
              <Row gutter={32}>
                <Col span={12} offset={1}></Col>
              </Row>
              <Row gutter={32}>
                <Col span={12}>
                  <Form.Item
                    required={true}
                    label={
                      <Text className="highlighted">{t("common.title")}</Text>
                    }
                    labelCol={{ flex: "120px" }}
                    labelAlign="left"
                    name="work_order_name"
                    rules={[
                      {
                        required: true,
                        message: t("common.enterValidWorkOrderName"),
                        transform: (value) => value?.trim(),
                      },
                    ]}
                    tooltip={requiredFieldStar}
                  >
                    <Input
                      bordered={false}
                      placeholder={t("workOrderDetails.placeholder.title")}
                    />
                  </Form.Item>
                  <Form.Item
                    required={false}
                    label={
                      <Text className="highlighted">
                        {t("common.description")}
                      </Text>
                    }
                    labelCol={{ flex: "120px" }}
                    labelAlign="left"
                    name="work_order_desc"
                  >
                    <Input.TextArea
                      bordered={false}
                      placeholder={t("common.description")}
                    ></Input.TextArea>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <Text className="highlighted">
                        {t("common.material")}
                      </Text>
                    }
                    labelCol={{ flex: "120px" }}
                    labelAlign="left"
                    name="material"
                    rules={[
                      {
                        required: true,
                        min: 2,
                        whitespace: true,
                        type: "string",
                        transform: (value) => value?.trim(),
                      },
                    ]}
                    required
                    tooltip={requiredFieldStar}
                  >
                    <AutoComplete
                      bordered={false}
                      options={materialOptions}
                      onSearch={setmaterialStr}
                      filterOption={true}
                      placeholder={t("common.selectMaterial")}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <Text className="highlighted">
                        {t("common.application")}
                      </Text>
                    }
                    labelCol={{ flex: "120px" }}
                    labelAlign="left"
                    name="application"
                    rules={[
                      {
                        required: true,
                        min: 2,
                        whitespace: true,
                        type: "string",
                        transform: (value) => value?.trim(),
                      },
                    ]}
                    required
                    tooltip={requiredFieldStar}
                  >
                    <AutoComplete
                      bordered={false}
                      options={applicationOptions}
                      onSearch={setapplicationStr}
                      value={applicationStr}
                      filterOption={true}
                      placeholder={t("common.selectApplication")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={12}>
                  <Form.Item
                    required={false}
                    label={
                      <Text className="highlighted">{t("common.members")}</Text>
                    }
                    labelCol={{ flex: "120px" }}
                    labelAlign="left"
                    name="work_order_dri"
                  >
                    <Select
                      style={{ maxWidth: 300 }}
                      mode="multiple"
                      showSearch
                      placeholder={t(
                        "workOrderDetails.placeholder.selectAPerson"
                      )}
                      bordered={false}
                    >
                      {currentProject?.members
                        ?.map(({ user_id }: any) => user_id)
                        ?.concat(currentProject?.created_by)
                        ?.filter((res: any) => res !== userId)
                        ?.map((member: any) => (
                          <Option value={getName(member)} key={member}>
                            {getName(member)}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    required={false}
                    label={
                      <Text className="highlighted">
                        {t("workOrderDetails.startDate")}
                      </Text>
                    }
                    labelCol={{ flex: "120px" }}
                    labelAlign="left"
                    name="start_date"
                  >
                    <DatePicker
                      placeholder={t("common.selectDate")}
                      bordered={false}
                      disabledDate={(dates) => {
                        if (
                          selectedDate.closeDate &&
                          dayjs(selectedDate.closeDate)
                            .startOf("day")
                            .add(1, "day") < dates
                        ) {
                          return true;
                        }
                        return false;
                      }}
                      onChange={(value) => {
                        setSelectedDate((prev: any) => ({
                          ...prev,
                          startDate: value,
                        }));
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {expand && (
                <MoreDetails
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  workOrder={workOrder}
                  setLabLocationModalState={setLabLocationModalState}
                />
              )}
              <StyledCard
                bordered={false}
                headStyle={{ padding: "0" }}
                bodyStyle={{ padding: "8px 0" }}
                title={
                  <Space>
                    <Text className="highlighted">
                      {t("projects.customFields")}
                    </Text>
                    <Tooltip title={t("project.customFields.message")}>
                      <InfoCircleFilled />
                    </Tooltip>
                  </Space>
                }
                extra={
                  <Space>
                    {!workOrder?.work_order_id && (
                      <StyledButton
                        onClick={() => setTemplateOptionsModal(true)}
                        size="middle"
                      >
                        {t("template.create")}
                      </StyledButton>
                    )}
                    <StyledButton
                      size="middle"
                      type="primary"
                      disabled={!customFields?.length}
                      onClick={() => setSaveTemplateModalVisible(true)}
                    >
                      {t("templates.saveNew")}
                    </StyledButton>
                  </Space>
                }
              >
                <Row>
                  <Col span={24}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      {customFields.map((field: any) => (
                        <div className="work__order__custom__field">
                          <Popconfirm
                            title={t("common.removeField")}
                            onConfirm={() => {
                              setCustomFields((prevState: any) =>
                                prevState.filter(
                                  (res: any) =>
                                    res?.field_name !== field?.field_name
                                )
                              );
                            }}
                          >
                            <StyledButton
                              disabled={disabled}
                              icon={<MinusCircleOutlined />}
                              type="text"
                            ></StyledButton>
                          </Popconfirm>
                          <Form.Item
                            style={{
                              width: "100%"
                            }}
                            name={field.field_name}
                            label={field.field_name}
                            initialValue={getInitialValues(field)}
                            rules={[
                              {
                                required: field.required,
                                message: "required",
                                transform: (value: any) => value?.trim(),
                              },
                            ]}
                          >
                            {mapFields(field, disabled, formatter, parser)}
                          </Form.Item>
                        </div>
                      ))}
                    </Space>
                  </Col>
                </Row>
                <Tooltip title="This set of custom fields will be saved as a template for re-use in other Work Orders">
                  <StyledButton
                    disabled={disabled}
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => setCustomModalVisible(true)}
                  >
                    <Text type="secondary">{t("common.AddCustomField")}</Text>
                  </StyledButton>
                </Tooltip>
              </StyledCard>
            </Card>
          </Form>
          <ChooseWorkOrderModal
            workOrder={workOrderToCopyParams}
            setWorkOrder={setWorkOrderToCopyParam}
            setVisible={setCopyWorkOrderModal}
            visible={copyCopyWorkOrderModal}
          />
          <TemplatesOptionModal
            templateOptionsModal={templateOptionsModal}
            setTemplateOptionsModal={setTemplateOptionsModal}
            setCustomFields={setCustomFields}
            type="work_order"
          />
          <SaveTemplateModal
            saveTemplateModalVisible={saveTemplateModalVisible}
            setSaveTemplateModalVisible={setSaveTemplateModalVisible}
            customFields={customFields}
            type="work_order"
          />
          <CustomFieldsModal
            customModalVisible={customModalVisible}
            setCustomModalVisible={setCustomModalVisible}
            customFields={customFields}
            setCustomFields={setCustomFields}
            type="work_order"
          />
          <CustomStagesModal
            stagesModalVisisble={stagesModalVisisble}
            x
            setStagesModalVisible={setStagesModalVisible}
            stages={stages}
            setStages={setStages}
            workOrder={workOrder}
          />
          {labLocationModalState?.isModalVisible &&
            <LabLocationModal labLocationModalState={labLocationModalState} handleClose={() => {
              setLabLocationModalState(labLocationInitialState)
            }} />
          }
        </>
      )}
    </>
  );
};
export default CreateWo;
