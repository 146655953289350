import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons"
import { Modal, Spin, Table, Typography } from "antd"
import dayjs from "dayjs"
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { AsyncStates } from "src/constants"
import { findArchivedParametersClear, findArchivedParametersRequest, updateArchivedParametersRequest } from "src/store/actions/common"
import { updateArchiveIngredientStatusClear, updateArchiveIngredientStatusRequest } from "src/store/actions/inventoryV2"
import { includeDoeExp } from "src/store/actions/workOrderDetails"
import { StoreState } from "src/store/configureStore"
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate"

export type ArchivedParametersFoundModalStateTypes = {
    isOpen: boolean,
    type: string | null,
    expId: any
}

export type ArchivedParametersFoundModalTypesProps = {
    from: string,
    archivedParametersFoundModalState: ArchivedParametersFoundModalStateTypes,
    setArchivedParametersFoundModalState: Dispatch<SetStateAction<ArchivedParametersFoundModalStateTypes>>
}

export const ArchivedParametersFoundModal = ({ from, archivedParametersFoundModalState, setArchivedParametersFoundModalState }: ArchivedParametersFoundModalTypesProps) => {
    const dispatch = useDispatch()
    const { push } = useHistory()
    const [t] = useTranslate()

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

    const [removeIngredientIds, setRemoveIngredientIds] = useState<string[]>([])

    const foundArchivedParameters = useSelector((state: StoreState) => state.common.foundArchivedParameters)

    useEffect(() => {
        if (archivedParametersFoundModalState.type && archivedParametersFoundModalState.expId) {
            dispatch(findArchivedParametersRequest({ id: archivedParametersFoundModalState.expId, type: archivedParametersFoundModalState.type }))
        }
    }, [archivedParametersFoundModalState.expId, archivedParametersFoundModalState.type, dispatch])

    const handleClose = () => {
        setArchivedParametersFoundModalState({ isOpen: false, type: null, expId: null })
        dispatch(findArchivedParametersClear())
    }

    const handleRemoveIngredients = () => {
        setRemoveIngredientIds(selectedRowKeys)
        if (from === foundArchivedParameters.type && archivedParametersFoundModalState.expId === foundArchivedParameters.id) {
            dispatch(updateArchivedParametersRequest({ inventory_ids: selectedRowKeys }))
            setSelectedRowKeys([])
        }
    }

    const handleWorkOrderGeneration = () => {
        if (from === "doe") {
            dispatch(includeDoeExp({
                from,
                ids: [foundArchivedParameters.id],
                remove_ingredient_ids: removeIngredientIds
            }))
            push('/work-orders/create-workorder')
        }
    }


    return <Modal
        title={t("common.foundArchivedParametersInData")}
        width={900}
        open={archivedParametersFoundModalState.isOpen}
        onCancel={() => handleClose()}
        footer={null}
    >
        <ArchivedParametersContent selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} handleWorkOrderGeneration={handleWorkOrderGeneration} handleRemoveIngredients={handleRemoveIngredients} />
    </Modal>
}


type ArchivedParametersContentPropsTypes = {
    selectedRowKeys: string[],
    setSelectedRowKeys: Dispatch<SetStateAction<string[]>>,
    handleWorkOrderGeneration?: () => void,
    handleRemoveIngredients: () => void
    handleNext?: () => void,
    hideHandleNext?: boolean,
    isMultiStage?: boolean
}

export const ArchivedParametersContent = ({ selectedRowKeys, setSelectedRowKeys, handleWorkOrderGeneration, handleRemoveIngredients, handleNext, hideHandleNext = false, isMultiStage = false }: ArchivedParametersContentPropsTypes) => {
    const [t] = useTranslate()
    const dispatch = useDispatch()

    const foundArchivedParameters = useSelector((state: StoreState) => state.common.foundArchivedParameters)
    const archiveIngredientData = useSelector((state: StoreState) => state.inventoryV2.archiveIngredient);


    const updateItemArchiveStatus = () => {
        dispatch(updateArchiveIngredientStatusRequest({
            inventory_ids: selectedRowKeys,
            is_archived: false
        }))
    }

    useEffect(() => {
        if (archiveIngredientData.status === AsyncStates.SUCCESS) {
            setSelectedRowKeys([])
            dispatch(updateArchiveIngredientStatusClear())
        }
    }, [archiveIngredientData.status, setSelectedRowKeys, dispatch])

    const archiveIngredientsColumns = useMemo(() => [
        {
            title: t("inventory.IngredientName"),
            dataIndex: "name",
            key: "name",
            width: 150,
            render: (text: string) => {
                return <Typography.Text
                    style={{ width: 200 }}
                    ellipsis={{ tooltip: text }}
                >
                    {text}
                </Typography.Text>
            }
        },
        {
            title: t("inventory.Category"),
            dataIndex: "category",
            key: "category",
            width: 150,
            render: (text: string) => {
                return <Typography.Text
                    style={{ width: 150 }}
                    ellipsis={{ tooltip: text }}
                >
                    {text}
                </Typography.Text>
            }
        },
        {
            title: t("inventory.Grade"),
            dataIndex: "grade",
            key: "grade",
            width: 150,
            render: (text: string) => {
                return <Typography.Text
                    style={{ width: 150 }}
                    ellipsis={{ tooltip: text }}
                >
                    {text}
                </Typography.Text>
            }
        },
        {
            title: t("inventory.lot_no"),
            dataIndex: "lot_no",
            key: "lot_no",
        },
        {
            title: t("common.versionDate"),
            dataIndex: "version_date",
            key: "version_date",
            render: (_: any, record: any) => {
                return record?.version_date ? dayjs(record?.version_date).format("DD-MM-YYYY") : null
            }
        },
    ], [t])


    const handleProceed = () => {
        if (foundArchivedParameters.type === "doe" && handleWorkOrderGeneration) {
            handleWorkOrderGeneration()
        } else if (handleNext) {
            handleNext()
        }
    }

    return <>
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
            }}>
                <Spin tip={t("common.checkingForArchivedParameter")} spinning={foundArchivedParameters.status === AsyncStates.LOADING} indicator={<LoadingOutlined />} >
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.5rem"
                    }}>
                        <span>
                            {t("common.containsArchivedIngredientsNote")}
                        </span>

                        <div>
                            <strong>{t("common.note")}:{" "}</strong> {t("common.multiStageWorkOrderArchivedIngredientsNote")}
                        </div>
                        <div>
                            <Table
                                size='small'
                                columns={archiveIngredientsColumns}
                                rowKey={(record) => record.inventory_id}
                                dataSource={foundArchivedParameters.data ?? []}
                                rowSelection={{
                                    onChange: (ids) => {
                                        setSelectedRowKeys(ids as string[])
                                    },
                                    selectedRowKeys,
                                    type: "checkbox",
                                }}
                            />
                        </div>
                    </div>
                </Spin>

                {foundArchivedParameters.status === AsyncStates.ERROR && <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography.Text type="danger" strong>{t("common.errorFetchingProperties")}</Typography.Text>
                    <StyledButton
                        icon={<ReloadOutlined />}
                        onClick={() => {
                            dispatch(findArchivedParametersRequest({
                                id: foundArchivedParameters.id,
                                type: foundArchivedParameters.type,
                                ...(foundArchivedParameters.type === "work-order-fileupload" && {
                                    ingredients: foundArchivedParameters?.ingredients ?? []
                                })
                            }))
                        }}>
                        {t("common.retry")}
                    </StyledButton>
                </div>
                }
            </div>

            <div className="footer">
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "0.25rem"
                }}>
                    {
                        !!foundArchivedParameters.data.length ? <>
                            <StyledButton disabled={!selectedRowKeys?.length || foundArchivedParameters.status === AsyncStates.LOADING} type='dashed' danger onClick={() => handleRemoveIngredients()}>{t("common.removeIngredientFromWorkOrder")}</StyledButton>
                            <StyledButton loading={archiveIngredientData.status === AsyncStates.LOADING} disabled={!selectedRowKeys?.length || foundArchivedParameters.status === AsyncStates.LOADING} type="primary" onClick={() => updateItemArchiveStatus()}>{t("common.unarchiveIngredient")}</StyledButton>
                        </>
                            : <StyledButton disabled={!!foundArchivedParameters.data?.length || foundArchivedParameters.status === AsyncStates.LOADING} type="primary" onClick={() => handleProceed()}>{t("common.next")}</StyledButton>
                    }

                </div>
            </div>
        </div>
    </>
}