import {
  Checkbox,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import { useMemo, useState } from "react";
import { StyledButton } from "src/styled_components/StyledButton";
import jwtManager from "src/utils/jwtManager";
import useTranslate from "src/utils/useTranslate";
import { Headers } from "src/services/interface";
import { StoreState } from "src/store/configureStore";
import { useSelector } from "react-redux";
import RepositoryService from "src/services/repository/v3";

const { Text } = Typography;

type TProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPropertyId: string;
  standards: any[];
  setStandards: React.Dispatch<React.SetStateAction<any[]>>;
};

const PropertyStandardModal = ({
  showModal,
  setShowModal,
  selectedPropertyId,
  standards,
  setStandards,
}: TProps) => {
  const [t] = useTranslate();
  const [propertyStandardForm] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [newStandardOptions, setNewStandardOptions] = useState<any[]>([]);
  const defaultHeaders = useSelector(
    (state: StoreState) => state.defaultHeaders
  );

  const saveStandards = async (payload: any) => {
    setLoading(true);
    const headers = {
      ...defaultHeaders,
      token: jwtManager.getToken(),
    } as Headers;
    const standardsResponse: any = await RepositoryService.updateStandards(
      selectedPropertyId,
      payload,
      headers
    );
    setStandards(payload.standards);
    setLoading(false);
    propertyStandardForm.resetFields();
    setShowModal(false);
    console.log(standardsResponse.data);
  };

  // useEffect(() => {
  //   propertyStandardForm.setFieldValue("standards", standards);
  // }, [selectedPropertyId]);

  const standardsOptions = useMemo(() => {
    if (searchValue.length > 0) return [...new Set([...standards, searchValue, ...newStandardOptions])];
    else return [...new Set([...standards, ...newStandardOptions])];
  }, [searchValue, standards, newStandardOptions])

  const submit = () => {
    const standards = propertyStandardForm.getFieldValue("standards").map((v: string) => v.trim());
    const payload = {
      property_id: selectedPropertyId,
      ...propertyStandardForm.getFieldsValue(),
      standards
    };

    saveStandards(payload);

    // setStandards(standardsResponse.data?.result?.data?.standards ?? []);
  };

  const handleChange = (value: any) => {
    setSearchValue("");
    setNewStandardOptions([...new Set([...newStandardOptions, ...value])]);
  };

  return (
    <Modal
      title={
        <Typography.Title level={4}>
          {t("repository.updateStandards")}
        </Typography.Title>
      }
      open={showModal}
      onOk={submit}
      onCancel={() => {
        propertyStandardForm.resetFields();
        setShowModal(false);
      }}
      footer={null}
      destroyOnClose
    >
      <Form
        onFinish={submit}
        form={propertyStandardForm}
        layout="vertical"
        scrollToFirstError
        initialValues={{
          standards,
        }}
      >
        <Form.Item
          name={"standards"}
          label={t("repository.updateStandards")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            mode="multiple"
            loading={false}
            placeholder={t("common.select")}
            showSearch={true}
            filterOption={false}
            onSearch={(value) => {
              if (!value.startsWith(' ')) {
                setSearchValue(value);
              }
            }}
            searchValue={searchValue}
            maxTagCount="responsive"
            onChange={handleChange}
            onInputKeyDown={(event) => {
              if (event.key === "Backspace") {
                return event.stopPropagation();
              }
            }}
            tagRender={(props) => (
              <Tag {...props} closable={!standards?.includes(props.value)}>
                {props.label}
              </Tag>
            )}

            onDeselect={(value) => {
              if (standards?.includes(value)) {
                const updatedStandards = [...new Set([...standards, ...propertyStandardForm.getFieldValue("standards")])]
                propertyStandardForm.setFieldValue("standards", updatedStandards);
              }
            }}
            optionRender={(option) => (
              <Space
                direction="horizontal"
              >
                <Checkbox
                  disabled={standards?.includes(option.value)}
                  checked={
                    propertyStandardForm
                      .getFieldValue("standards")
                      ?.includes(option.value) ||
                    standards?.includes(option.value)
                  }
                />
                <Text style={{ whiteSpace: "initial" }}>{option.label}</Text>
              </Space>
            )}
            options={standardsOptions.map((item) => {
              return {
                label: item,
                value: item,
              };
            })}
          />
        </Form.Item>

        <Row gutter={[8, 8]} justify={"end"} style={{ marginTop: "20px" }}>
          <Col>
            <StyledButton
              type="default"
              onClick={() => {
                propertyStandardForm.resetFields();
              }}
            >
              {t("common.reset")}
            </StyledButton>
          </Col>
          <Col>
            <StyledButton disabled={loading} type="primary" htmlType="submit">
              {t("common.submit")}
            </StyledButton>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PropertyStandardModal;
