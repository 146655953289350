import { DownOutlined, DownloadOutlined } from "@ant-design/icons";
import { Row, Col, Dropdown, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import InverseAddToFavoritesModal from "../catwise/InverseAddToFavouriteModal";

const TopAction = ({
  generateWoMenuItems,
  predictionDataExportStatus,
  setExportDataSheetModalData,
}: any) => {
  const [t] = useTranslate();
  const configs = useSelector((state: StoreState) => state.configs.features);
  return (
    <Row justify="end" gutter={8}>
      <Col>
        <InverseAddToFavoritesModal fromState="success" />
      </Col>
      <Col>
        <Dropdown menu={generateWoMenuItems} placement="bottomRight">
          <StyledButton type="primary">
            {t("common.generateWorkOrder")}
            <DownOutlined />
          </StyledButton>
        </Dropdown>
      </Col>
      {!Boolean(configs?.ai_engine_with_methods) && (
        <Col>
          <Tooltip
            placement="top"
            title={t("workOrderDetails.exportDatasheet")}
          >
            <StyledButton
              type="default"
              icon={<DownloadOutlined />}
              disabled={predictionDataExportStatus === AsyncStates.LOADING}
              onClick={() => {
                setExportDataSheetModalData({
                  isModalVisible: true,
                  include_range: true,
                });
              }}
            />
          </Tooltip>
        </Col>
      )}
    </Row>
  );
};

export default TopAction;
