import { FilterOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Form, Row, Col, Space, Cascader, Select, Input, Tag, Tooltip } from 'antd'
import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux'
import _ from "lodash";
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'
import useTranslate from 'src/utils/useTranslate'
import { DefaultOptionType } from 'antd/es/cascader';

const FilterAndSorting = ({ form, applyFilters, filterData, operatorStates, setOperatorStates, filters, setFilters, pageChange, isMultistage, currentSelectedStage, suggestedExpFilters, metaInputIngredients }: any) => {
  const [t] = useTranslate()
  const requiredFieldStar = useRequiredFieldStar()
  const {
    data: { experiments },
  } = useSelector((state: StoreState) => state.suggestedExp)
  const configs = useSelector((state: StoreState) => state.configs.features)

  const cascaderData = useMemo(() => {
    const clonedFilterData = _.cloneDeep(filterData);

    clonedFilterData.forEach((parameterType: any) => {
      parameterType.children?.forEach((parameter: any) => {
        if(Object.keys(metaInputIngredients || {}).includes(String(parameter?.value)) || Object.values(metaInputIngredients || {}).includes(String(parameter?.value))) {
          parameter.title = parameter.label;
          parameter.label = (
          <>
            {parameter.label}&nbsp;&nbsp;
            <Tag style={{ padding: '2px 6px', fontSize: '10px', lineHeight: "12px" }} color="processing">{t("common.New")}</Tag>
          </>); 
        }
      })
    })

    return clonedFilterData;
  }, [filterData, metaInputIngredients, t])

  const renderSelectedOptions = (label: string[]) => {
    const interlacedLabels = label.reduce((acc: string[], curr, i) => {
      if (i === label.length - 1) return [...acc, curr];
      return [...acc, curr, " / "];
    }, []);

    const renderLabels = interlacedLabels.map((label, index) => (
      <Fragment key={index}>{label}</Fragment>
    ))
    
    return (
      <Tooltip title={<>{renderLabels}</>} overlayInnerStyle={{ fontSize: "11px" }} arrow={false} mouseEnterDelay={1}>
        {renderLabels}
      </Tooltip>
    );
  }

  const cascaderFilter = (inputValue: string, path: DefaultOptionType[]) => {
    return path.some(
      (option) => String((typeof option.label === "object" ? option.title : option.label) || "").toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    );
  }

  return (
    <Form
      name="dynamic_form_nest_item"
      onFinish={applyFilters}
      form={form}
      requiredMark={false}
      className="prediction_result_filter_sorting_form"
    >
      <Row gutter={24} style={{ flexWrap: "nowrap" }}>
        <Col span={12}>
          <Form.List name="expFilters">
            {(fields, { add, remove }) => (
              <>
                <Form.Item>
                  <StyledButton
                    type="primary"
                    onClick={() => add()}
                    ghost
                    icon={<FilterOutlined />}
                  >
                    {t("formulations.button.addFilter")}
                  </StyledButton>
                </Form.Item>
                {fields.map(
                  ({ key, name, fieldKey, ...restField }) => (
                    <Row
                      key={key}
                      style={{
                        display: "flex",
                        alignItems: 'baseline'
                      }}
                      gutter={12}
                    >
                    <Col span={9}>
                      <Form.Item
                        {...restField}
                        name={[name, "property"]}
                        fieldKey={[key, "property"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "aiEngine.form.properties.error",
                            ),
                          },
                        ]}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <Cascader
                          placeholder={t(
                            "aiEngine.form.properties.placeholder",
                          )}
                          options={cascaderData as any}
                          showSearch={{ filter: cascaderFilter }}
                          displayRender={renderSelectedOptions}
                          onChange={(value) => {
                            const { expFilters } =
                              form.getFieldsValue()

                            if (
                              Boolean(
                                configs?.processing_profiles,
                              ) &&
                              value?.[1] === "Profile"
                            ) {
                              setOperatorStates(
                                (state: any) => {
                                  const array = [...state]
                                  array[key] = "="
                                  return array
                                },
                              )
                              if (expFilters?.[name]) {
                                Object.assign(
                                  expFilters?.[name],
                                  {
                                    operand: "=",
                                    minVal: [],
                                    maxVal: null,
                                  },
                                )
                              }
                            } else {
                              setOperatorStates(
                                (state: any) => {
                                  const array = [...state]
                                  array[key] = null
                                  return array
                                },
                              )
                              if (expFilters?.[name]) {
                                Object.assign(
                                  expFilters?.[name],
                                  {
                                    operand: null,
                                    minVal: null,
                                    maxVal: null,
                                  },
                                )
                              }
                            }
                            form.setFieldsValue({ expFilters })
                          }}
                        />
                      </Form.Item>
                      </Col>
                      <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, "operand"]}
                        fieldKey={[key, "operand"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "aiEngine.form.operator.error",
                            ),
                          },
                        ]}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <Select
                          placeholder={t(
                            "formulations.placeholder.operator",
                          )}
                          onChange={(operator) => {
                            setOperatorStates((state: any) => {
                              const array = [...state]
                              array[name] = operator
                              return array
                            })
                            const { expFilters } =
                              form.getFieldsValue()
                            if (expFilters?.[name]) {
                              Object.assign(
                                expFilters?.[name],
                                {
                                  minVal: "",
                                  maxVal: "",
                                },
                              )
                            }
                            form.setFieldsValue({ expFilters })
                          }}
                        >
                          <Select.Option value="=">
                            =
                          </Select.Option>
                          {form?.getFieldsValue()?.expFilters?.[
                            name
                          ]?.property?.[1] !== "Profile" && (
                              <>
                                <Select.Option value="<=">
                                  {"<="}
                                </Select.Option>
                                <Select.Option value=">=">
                                  {">="}
                                </Select.Option>
                                {form?.getFieldsValue()
                                  ?.expFilters?.[name]
                                  ?.property?.[0] !== "cost" && (
                                    <>
                                      <Select.Option value="range">
                                        {t("formulations.operator")}
                                      </Select.Option>
                                      <Select.Option value="exists">
                                        {t("common.exists")}
                                      </Select.Option>
                                    </>
                                  )}
                              </>
                            )}
                        </Select>
                      </Form.Item>
                      </Col>
                      <Col span={9}>
                      {
                        operatorStates[name] === "range" ? (
                          <Space
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "minVal"]}
                              fieldKey={[key, "minVal"]}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "aiEngine.form.minVal.error",
                                  ),
                                },
                              ]}
                              required
                              tooltip={requiredFieldStar}
                            >
                              <Input
                                placeholder={t(
                                  "formulations.placeholder.minimum",
                                )}
                              />
                            </Form.Item>
                            ~
                            <Form.Item
                              {...restField}
                              name={[name, "maxVal"]}
                              fieldKey={[key, "maxVal"]}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "aiEngine.form.maxVal.error",
                                  ),
                                },
                              ]}
                              required
                              tooltip={requiredFieldStar}
                            >
                              <Input
                                className="site-input-right"
                                placeholder={t(
                                  "formulations.placeholder.maximum",
                                )}
                              />
                            </Form.Item>
                          </Space>
                        ) : operatorStates[name] ===
                          "exists" ? (
                          <Form.Item
                            {...restField}
                            name={[name, "minVal"]}
                            fieldKey={[key, "minVal"]}
                            style={{ flex: 1, marginBottom: 0 }}
                          >
                            {" "}
                            <Select
                              placeholder={t(
                                "formulations.placeholder.value",
                              )}
                              onChange={(value) => {
                                const { expFilters } =
                                  form?.getFieldsValue()
                                Object.assign(
                                  expFilters?.[name],
                                  {
                                    minVal: value,
                                  },
                                )
                                form.setFieldsValue({
                                  expFilters,
                                })
                              }}
                            >
                              <Select.Option value={1}>
                                {t("common.yes")}
                              </Select.Option>
                              <Select.Option value={0}>
                                {t("common.no")}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        ) : Boolean(
                          configs?.processing_profiles,
                        ) &&
                          form?.getFieldsValue()?.expFilters[
                            name
                          ]?.property?.[1] === "Profile" ? (
                          <Form.Item
                            {...restField}
                            name={[name, "minVal"]}
                            fieldKey={[key, "minVal"]}
                            rules={[
                              {
                                required: true,
                                message: t(
                                  "aiEngine.form.operator.error",
                                ),
                              },
                            ]}
                            required
                            tooltip={requiredFieldStar}
                          >
                            <Select
                              placeholder={t(
                                "formulations.placeholder.value",
                              )}
                              style={{ width: "10em" }}
                              onChange={(value) => {
                                const { expFilters } =
                                  form?.getFieldsValue()
                                Object.assign(
                                  expFilters?.[name],
                                  {
                                    minVal: value,
                                  },
                                )
                                form.setFieldsValue({
                                  expFilters,
                                })
                              }}
                              mode="multiple"
                              options={[
                                ...new Set(
                                  experiments.map(
                                    (value: any) =>
                                      value.processing[
                                        "Profile"
                                      ].value,
                                  ),
                                ),
                              ].map((pro: any) => ({
                                label: pro,
                                value: pro,
                              }))}
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            {...restField}
                            name={[name, "minVal"]}
                            fieldKey={[key, "minVal"]}
                            style={{ flex: 1, marginBottom: 0 }}
                            rules={[
                              {
                                required: true,
                                message: t("common.required"),
                              },
                            ]}
                            required
                            tooltip={requiredFieldStar}
                          >
                            <Input
                              placeholder={t(
                                "formulations.placeholder.value",
                              )}
                            />
                          </Form.Item>
                        )
                      }
                      </Col>
                      <Col span={2}>

                      <StyledDeleteIcon
                        onClick={() => {
                          setOperatorStates((state: any[]) =>
                            state.filter(
                              (o, idx) => idx !== name,
                            ),
                          )
                          remove(name)
                        }}
                      />
                      </Col>
                    </Row>
                  ),
                )}

              </>
            )}
          </Form.List>
        </Col>

        <Col span={12}>
          <Form.List name="sorting">
            {(fields, { add, remove }) => (
              <>
                <Form.Item>
                  <StyledButton
                    type="primary"
                    onClick={() => add()}
                    ghost
                    icon={<SortDescendingOutlined />}
                  >
                    {t("aiEngine.button.sorting")}
                  </StyledButton>
                </Form.Item>
                {fields.map(
                  ({ key, name, fieldKey, ...restField }) => (
                    <Row
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: 'baseline'
                    }}
                    gutter={12}
                    >
                      <Col span={9}>
                      <Form.Item
                        {...restField}
                        name={[name, "property"]}
                        fieldKey={[key, "property"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "aiEngine.form.properties.error",
                            ),
                          },
                        ]}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <Cascader
                          placeholder={t(
                            "aiEngine.form.properties.placeholder",
                          )}
                          options={cascaderData as any}
                          showSearch={{ filter: cascaderFilter }}
                          displayRender={renderSelectedOptions}
                          onChange={() => {
                            const { sorting } =
                              form.getFieldsValue()
                            if (sorting?.[name]) {
                              Object.assign(sorting?.[name], {
                                sort_order: null,
                              })
                            }
                            form.setFieldsValue({ sorting })
                          }}
                        />
                      </Form.Item>
                      </Col>
                      <Col span={9}>
                      <Form.Item
                        {...restField}
                        name={[name, "sort_order"]}
                        fieldKey={[key, "sort_order"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "aiEngine.form.sort.error",
                            ),
                          },
                        ]}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <Select
                          placeholder={t(
                            "aiEngine.form.sort.placeholder",
                          )}
                        >
                          <Select.Option value="ascending">
                            {t(
                              "aiEngine.form.select.ascending",
                            )}
                          </Select.Option>
                          <Select.Option value="descending">
                            {t(
                              "aiEngine.form.select.descending",
                            )}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      </Col>

                      <Col span={2}>
                      <StyledDeleteIcon
                        onClick={() => {
                          remove(name)
                        }}
                      />
                      </Col>
                    </Row>
                  ),
                )}

              </>
            )}
          </Form.List>
        </Col>
      </Row>

      <Space>
        <Form.Item>
          <StyledButton type="primary" htmlType="submit">
            {t("aiEngine.button.apply")}
          </StyledButton>
        </Form.Item>
        <Form.Item>
          <StyledButton
            type="primary"
            ghost
            disabled={!Object.keys(filters).length}
            onClick={() => {
              form.resetFields()
              setOperatorStates([])
              pageChange(1, "experiments", {
                expFilters: [], sorts: [],
                ...(isMultistage ? { stage: experiments?.[0]?.all_stages?.[currentSelectedStage - 1] ?? suggestedExpFilters?.all_stages?.[currentSelectedStage - 1] } : {}),
              })
              setFilters([])
            }}
          >
            {t("common.clear")}
          </StyledButton>
        </Form.Item>
      </Space>
    </Form>
  )
}

export default FilterAndSorting