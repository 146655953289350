import { Button, Form, Image, Tooltip, Typography } from "antd";
import useTranslate from "src/utils/useTranslate";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { nanoid } from "nanoid";
import { doc, setDoc } from "firebase/firestore";
import { FB_COLLECTION_NAME_COMMENTS, firestoreDb } from "src/utils/firebase";
import { useWOCommentsSocket } from "src/utils/hooks/useWOCommentsSocket";
import { StyledButton } from "src/styled_components/StyledButton";
import { BaseEditor, createEditor, Descendant, Editor, Range, Transforms } from 'slate'
import { Editable, ReactEditor, Slate, useFocused, useSelected, useSlateStatic, withReact } from 'slate-react'
import ReactDOM from 'react-dom'
import { withHistory } from 'slate-history'
import { DeleteOutlined } from "@ant-design/icons";
import { defaultHeaders } from "src/constants";
import jwtManager from "src/utils/jwtManager";
import imageExtensions from 'image-extensions'
import isUrl from 'is-url'

const isImageUrl = url => {
  if (!url) return false
  if (!isUrl(url)) return false
  const ext = new URL(url).pathname.split('.').pop()
  return imageExtensions.includes(ext)
}

type CustomElement = { type: 'paragraph'; children: CustomText[] }
export type AssetElement = {
  type: 'asset'
  url: string
  children: CustomText[]
}
export type MentionElement = {
  type: 'mention'
  character: string
  children: CustomText[]
}
type CustomText = { text: string }

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor
    Element: CustomElement | MentionElement | AssetElement
    Text: CustomText
  }
}


// const commentEditorMutation = graphql`
//   mutation CommentEditorMutation(
//     $comment: String
//     $work_order_id: String
//     $tags: [String]
//     $key: String
//     $token: String
//   ) {
//     addWoComment(
//       comment: $comment
//       work_order_id: $work_order_id
//       tags: $tags
//       key: $key
//       token: $token
//     ) {
//       comment
//     }
//   }
// `;

export const Portal = ({ children }: { children?: ReactNode }) => {
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null
}

export const withMentions = editor => {
  const { isInline, isVoid, markableVoid, insertData } = editor

  editor.isInline = element => {
    return element.type === 'mention' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'mention' ? true : isVoid(element)
  }

  editor.markableVoid = element => {
    return element.type === 'mention' || markableVoid(element)
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')
    if (text.includes("/asset-library/")) {
      insertAsset(editor, text)
    } else if (isImageUrl(text)) {
      insertImage(editor, text)
    } else if (isUrl(text)) {
      insertLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

export const Element = props => {
  const { attributes, children, element } = props
  switch (element.type) {
    case 'mention':
      return <Mention {...props} />
    case 'asset':
      return <Asset {...props} />
    case 'image':
      return <ImageComp {...props} />
    case 'link':
      return <span><Typography.Link {...attributes} href={element.url} target="_blank">{element.url}</Typography.Link>{children}</span>
    default:
      return <p {...attributes}>{children}</p>
  }
}

const ImageComp = ({ attributes, children, element }) => {
  const editor = useSlateStatic()
  const path = ReactEditor.findPath(editor, element)

  const selected = useSelected()
  const focused = useFocused()
  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        style={{
          position: "relative"
        }}
      >
        <Image
          src={element.url}
          style={{
            display: "block",
            maxWidth: "100%",
            maxHeight: "20em",
            boxShadow: (selected && focused) ? '0 0 0 3px #B4D5FF' : 'none'
          }}
          alt="preview"
        />
        <Button
          active
          onClick={() => Transforms.removeNodes(editor, { at: path })}
          style={{
            display: (selected && focused) ? 'inline' : 'none',
            position: "absolute",
            bottom: "1em",
            left: "0.5em",
            backgroundColor: "white"
          }}
        >
          <DeleteOutlined />
        </Button>
      </div>
    </div>
  )
}

export const Asset = ({ attributes, children, element }) => {
  const editor = useSlateStatic()
  const path = ReactEditor.findPath(editor, element)

  const selected = useSelected()
  const focused = useFocused()
  const [fileName, setFileName] = useState("")
  const [fileUrl, setFileUrl] = useState("")

  useEffect(() => {
    const data = new URL(element.url)

    if (data.searchParams.has("folder")) {
      fetch(`${process.env.REACT_APP_API_URL}v1/data/asset_library/get_content?folder_id=${data.searchParams.get("folder")}`, {
        headers: {
          ...defaultHeaders,
          token: jwtManager.getToken() ?? "",
        },
      })
        .then((req) => req.json())
        .then(({ result }) => {
          if (data.searchParams.has("file")) {
            const asset_id = data.searchParams.get("file")
            const asset = result.data.find(asset => {
              return asset?.asset_id === asset_id
            })
            setFileUrl(asset.thumbnailUrl)
            setFileName(asset.name)
          }
        })
        .catch(err => {
          console.log("🚀 ~ useEffect ~ err:", err)
        })
    }
  }, [element.url])
  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        style={{
          position: "relative"
        }}
      >
        <Tooltip title={element.url}>
          <div style={{ padding: 4, paddingBlock: 2, width: "fit-content", margin: 6, background: '#0000001a', borderRadius: 4 }}>
            <Typography.Link href={element.url} target="_blank">{fileName}</Typography.Link>
          </div>
        </Tooltip>
        {isImageUrl(fileUrl) && <>
          <Image
            src={fileUrl}
            style={{
              display: "block",
              maxWidth: "100%",
              maxHeight: "20em",
              boxShadow: (selected && focused) ? '0 0 0 3px #B4D5FF' : 'none'
            }}
          />
          <Button
            onClick={() => Transforms.removeNodes(editor, { at: path })}
            style={{
              display: (selected && focused) ? 'inline' : 'none',
              position: "absolute",
              bottom: "1em",
              left: "0.5em",
              backgroundColor: "white"
            }}
          >
            <DeleteOutlined />
          </Button>
        </>}
      </div>
    </div>
  )
}

const Mention = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  const style: React.CSSProperties = {
    padding: '3px 3px 2px',
    margin: '0 1px',
    verticalAlign: 'baseline',
    display: 'inline-block',
    borderRadius: '4px',
    backgroundColor: '#eee',
    fontSize: '0.9em',
    boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
  }
  // See if our empty text child has any styling marks applied and apply those
  if (element.children[0].bold) {
    style.fontWeight = 'bold'
  }
  if (element.children[0].italic) {
    style.fontStyle = 'italic'
  }
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element.character.replace(' ', '-')}`}
      style={style}
    >
      {/* Prevent Chromium from interrupting IME when moving the cursor */}
      {/* 1. span + inline-block 2. div + contenteditable=false */}
      <div contentEditable={false}>
        {false ? (
          // Mac OS IME https://github.com/ianstormtaylor/slate/issues/3490
          <Fragment>
            {children}@{element.character}
          </Fragment>
        ) : (
          // Others like Android https://github.com/ianstormtaylor/slate/pull/5360
          <Fragment>
            @{element.character}
            {children}
          </Fragment>
        )}
      </div>
    </span>
  )
}

const insertAsset = (editor, url) => {
  const asset: AssetElement = {
    type: 'asset',
    url,
    children: [{ text: '' }],
  }
  Transforms.insertNodes(editor, asset)
  Transforms.move(editor)
}

const insertImage = (editor, url) => {
  const asset: AssetElement = {
    type: 'image',
    url,
    children: [{ text: '' }],
  }
  Transforms.insertNodes(editor, asset)
  Transforms.move(editor)
}

const insertLink = (editor, url) => {
  const asset: AssetElement = {
    type: 'link',
    url,
    children: [{ text: '' }],
  }
  Transforms.insertNodes(editor, asset)
  Transforms.move(editor)
}

export const CommentEditor = (props: any) => {
  const [t] = useTranslate();
  const work_order_id = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder.work_order_id
  );
  const { user_id, user_name, image_url } = useSelector(
    (state: StoreState) => state.login.loginResponse
  );
  const mentions = useSelector((state: StoreState) => state.teams.data);
  //   const [commit, isInFlight] = useMutation(commentEditorMutation);
  const [isAddingComment, setIsAddingComment] = useState<boolean>(false);
  const initialValue: Descendant[] = [
    {
      type: 'paragraph',
      children: [{ text: "" }],
    },
  ]
  const [value, setValue] = useState(JSON.stringify(initialValue));

  const socket = useWOCommentsSocket('comments', work_order_id)

  const clearEditor = (editor) => {
    // Delete everything in the document
    editor.children = initialValue;

    // Set the selection to the very beginning (offset 0)
    const point = { path: [0, 0], offset: 0 };
    editor.selection = { anchor: point, focus: point };

    // Optionally, clear undo/redo history:
    editor.history = { undos: [], redos: [] };
  };

  const addComment = async () => {
    // Validate not empty
    let commentValue = JSON.parse(value);
    if(Array.isArray(commentValue) && commentValue.length === 1 
      && commentValue?.[0]?.type === "paragraph" && Array.isArray(commentValue?.[0]?.children) && commentValue?.[0]?.children.length === 1 
      && String(commentValue?.[0]?.children?.[0]?.text || "").trim().length === 0) return

    setIsAddingComment(true);
    const userIdsTags = mentions.reduce((acc, curr) => {
      if (value.trim()?.includes(curr.user_name)) {
        acc.push(curr.user_id);
      }
      return [...new Set(acc)];
    }, []);
    const commentId = `PlCom_${nanoid(10)}`;
    const commentPayload = {
      user_name: user_name,
      user_id: user_id,
      image_url: image_url,
      comment_id: commentId,
      work_order_id: work_order_id,
      comment: value?.trim(),
      status: "active",
      tags: userIdsTags,
      created: new Date().toISOString(),
    };
    await socket?.emit("create_comment", { comment: value?.trim(), tags: userIdsTags })
    await setDoc(
      doc(firestoreDb, FB_COLLECTION_NAME_COMMENTS, commentId),
      commentPayload
    );
    clearEditor(editor);
    setIsAddingComment(false);
    setValue(JSON.stringify(initialValue))
  };


  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(
    () => withMentions(withReact(withHistory(createEditor()))),
    []
  )
  const [target, setTarget] = useState<Range | undefined>()
  const ref = useRef<HTMLDivElement | null>()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')

  const chars = mentions.map(member => member.user_name).filter(c =>
    c.toLowerCase().startsWith(search.toLowerCase())
  ).slice(0, 10)

  useEffect(() => {
    if (target && chars.length > 0) {
      const el = ref.current
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()
      if (el) {
        el.style.top = `${rect.top + window.pageYOffset + 24}px`
        el.style.left = `${rect.left + window.pageXOffset}px`
      }
    }
  }, [chars.length, editor, index, search, target])

  const insertMention = (editor, character) => {
    const mention: MentionElement = {
      type: 'mention',
      character,
      children: [{ text: '' }],
    }
    Transforms.insertNodes(editor, mention)
    Transforms.move(editor)
  }

  const onKeyDown = useCallback(
    event => {
      if (target && chars.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault()
            const prevIndex = index >= chars.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          case 'ArrowUp':
            event.preventDefault()
            const nextIndex = index <= 0 ? chars.length - 1 : index - 1
            setIndex(nextIndex)
            break
          case 'Tab':
          case 'Enter':
            event.preventDefault()
            Transforms.select(editor, target)
            insertMention(editor, chars[index])
            setTarget(null)
            break
          case 'Escape':
            event.preventDefault()
            setTarget(null)
            break
        }
      }
    },
    [chars, editor, index, target]
  )

  return (
    <div style={{ border: '1px solid #f0f0f0', borderRadius: 4, padding: 8 }}>
      <Slate
        editor={editor}
        initialValue={JSON.parse(value)}
        onChange={(value) => {
          const { selection } = editor
          // mention
          if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection)
            const wordBefore = Editor.before(editor, start, { unit: 'word' })
            const before = wordBefore && Editor.before(editor, wordBefore)
            const beforeRange = before && Editor.range(editor, before, start)
            const beforeText = beforeRange && Editor.string(editor, beforeRange)
            const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
            const after = Editor.after(editor, start)
            const afterRange = Editor.range(editor, start, after)
            const afterText = Editor.string(editor, afterRange)
            const afterMatch = afterText.match(/^(\s|$)/)

            if (beforeMatch && afterMatch) {
              setTarget(beforeRange)
              setSearch(beforeMatch[1])
              setIndex(0)
              return
            }

          }

          setTarget(null)

          const isAstChange = editor.operations.some(
            (op: any) => 'set_selection' !== op.type
          )
          if (isAstChange) {
            setValue(JSON.stringify(value))
          }
        }}
      >
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={onKeyDown}
          placeholder={t("commentsSection.leaveAComment")}
        />
        {target && chars.length > 0 && (
          <Portal>
            <div
              ref={ref}
              style={{
                top: '-9999px',
                left: '-9999px',
                position: 'absolute',
                zIndex: 1,
                padding: '3px',
                background: 'white',
                borderRadius: '4px',
                boxShadow: '0 1px 5px rgba(0,0,0,.2)',
              }}
              data-cy="mentions-portal"
            >
              {chars.map((char, i) => (
                <div
                  key={char}
                  onClick={() => {
                    Transforms.select(editor, target)
                    insertMention(editor, char)
                    setTarget(null)
                  }}
                  style={{
                    padding: '1px 3px',
                    borderRadius: '3px',
                    cursor: 'pointer',
                    background: i === index ? '#B4D5FF' : 'transparent',
                  }}
                >
                  {char}
                </div>
              ))}
            </div>
          </Portal>
        )}
      </Slate><br />
      {/* <Form.Item>
        <Mentions
          autoSize={{ minRows: 4 }}
          onChange={setValue}
          placeholder={t("commentsSection.leaveAComment")}
          value={value}
        >
          {mentions.map((member: any) => (
            <Mentions.Option value={member.user_name} key={member.user_name}>
              {member.user_name}
            </Mentions.Option>
          ))}
        </Mentions>
      </Form.Item> */}
      <Form.Item>
        <StyledButton
          loading={isAddingComment}
          onClick={
            addComment
          }
          type="primary"
          disabled={!Boolean(value?.trim())}
          size="small"
        >
          {t("common.comment")}
        </StyledButton>
      </Form.Item>
    </div>
  );
};
