export function escapeLaTeX(str: string): string {
    const replacements = {
        '\\': '\\textbackslash{}',
        '{': '\\{',
        '}': '\\}',
        '$': '\\$',
        '&': '\\&',
        '#': '\\#',
        '%': '\\%',
        '_': '\\_',
        '^': '\\^{}',
        '~': '\\~{}'
    };
    return str.replace(/([\\{}$&#%_^~])/g, match => replacements[match as keyof typeof replacements]);
}