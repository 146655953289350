import { Col, Form, Input, Modal, Row, Select, Typography } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import {
    createPropertyRequest,
    fetchCategoryRequest,
    updatePropertyRequest,
} from "src/store/actions/repository";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import { CATEGORY_TYPES } from "./Data";
import { trimWhiteSpaceFromValues } from "src/utils/general/trimmer";

type TProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    mode?: "edit" | "create";
    selectedPropertyId?: string;
};

const AddEditPropertyModel = ({
    showModal,
    setShowModal,
    mode = "create",
    selectedPropertyId,
}: TProps) => {
    const [t] = useTranslate();
    const [propertyForm] = Form.useForm();
    const categories = useSelector(
        (state: StoreState) => state.repository.categories
    );
    const dispatch = useDispatch();
    const properties = useSelector(
        (state: StoreState) => state.repository.properties.data
    );
    const propertyStatus = useSelector(
        (state: StoreState) => state.repository.properties.status
    );

    useEffect(() => {
        dispatch(fetchCategoryRequest({ type: CATEGORY_TYPES.PROPERTY }));
    }, [dispatch]);

    useEffect(() => {
        if (mode === "edit" && selectedPropertyId) {
            propertyForm.setFieldsValue({
                name: properties.find((p) => p.property_id === selectedPropertyId)
                    ?.name,
                category_id: properties.find(
                    (p) => p.property_id === selectedPropertyId
                )?.category_id,
            });
        } else if (mode === "create") propertyForm.resetFields();
    }, [selectedPropertyId, properties, mode, propertyForm]);

    useEffect(() => {
        if (propertyStatus === AsyncStates.SUCCESS) {
            setShowModal(false);
        }
    }, [propertyStatus, setShowModal]);

    const submit = () => {
        const payload = trimWhiteSpaceFromValues(propertyForm.getFieldsValue());

        if (mode === "create") dispatch(createPropertyRequest(payload));
        if (mode === "edit")
            dispatch(
                updatePropertyRequest({ ...payload, property_id: selectedPropertyId })
            );
    };

    return (
        <Modal
            title={
                <Typography.Title level={4}>{`${mode === "create" ? t("repository.addNewProperty") : t("repository.editProperty")}`}</Typography.Title>
            }
            open={showModal}
            onOk={submit}
            onCancel={() => setShowModal(false)}
            footer={null}
        >
            <Form
                onFinish={submit}
                form={propertyForm}
                layout="vertical"
                scrollToFirstError
            >
                <Form.Item
                    name="name"
                    label={t("common.property")}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            whitespace: true,
                        }
                    ]}
                >
                    <Input placeholder={t("common.enterName")} />
                </Form.Item>

                <Form.Item
                    name="category_id"
                    label={t("common.category")}
                    rules={[
                        {
                            required: true,
                        }
                    ]}
                >
                    <Select
                        loading={categories.status === AsyncStates.LOADING}
                        placeholder={t("common.category")}
                        allowClear
                        showSearch={true}
                        optionFilterProp="label"
                        options={categories.data.filter(c => c.name).map((c) => ({
                            label: c.name,
                            value: c.category_id,
                        })).sort((a, b) => a.label?.localeCompare(b.label))}
                    />
                </Form.Item>

                <Row gutter={[8, 8]} justify={"end"} style={{ marginTop: "20px" }}>
                    <Col>
                        <StyledButton
                            type="default"
                            onClick={() => propertyForm.resetFields()}
                        >
                            {t("common.reset")}
                        </StyledButton>
                    </Col>
                    <Col>
                        <StyledButton
                            type="primary"
                            htmlType="submit"
                            disabled={propertyStatus === AsyncStates.LOADING}
                            loading={propertyStatus === AsyncStates.LOADING}
                        >
                            {t("common.submit")}
                        </StyledButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddEditPropertyModel;
