import { Empty, Form, Input, Modal, Row, Select, Space, Spin, Steps, Tooltip, Typography } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates, permissions, projectStatus } from "src/constants";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";
import { displayTextPermissions } from "src/utils/decorator";
import { usePermission } from "src/utils/usePermissions";
import useTranslate from "src/utils/useTranslate";
import { setIsEditing } from "src/store/actions/isEditing";
import {
  ClockCircleOutlined,
  CopyOutlined,
  ExceptionOutlined,
  LoadingOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  addRemoveIngredientsKeyToWorkOrderClear,
  addRemoveIngredientsKeyToWorkOrderRequest,
  setEditingState,
  woStatusChangeRequest,
} from "src/store/actions/workOrderDetails";
import { orange } from "@ant-design/colors";
import { CreateWoModal } from "src/components/Datasheet/CreateWoModal";
import { Link } from "react-router-dom";
import { Location } from "src/assets/svgs";
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar";
import { findArchivedParametersClear, findArchivedParametersRequest, updateArchivedParametersRequest } from "src/store/actions/common";
import { ArchivedParametersContent } from "src/components/Common/ArchivedParametersFoundModal";

const { confirm }: any = Modal;
const { Text } = Typography;

interface WOPageHeaderProps {
  workOrder: any;
}

export const WOPageHeader = (props: WOPageHeaderProps) => {
  const [t] = useTranslate();
  const requiredFieldStar = useRequiredFieldStar()

  const { workOrder } = props;
  const userAccess = usePermission();
  const disabled = useMemo(
    () =>
      userAccess.permission === permissions.viewer ||
      userAccess?.status !== projectStatus.in_progress,
    [userAccess]
  );
  const dispatch = useDispatch();

  const isEditing = useSelector((state: StoreState) => state.isEditing);
  const woStatusChange = useSelector(
    (state: StoreState) => state.workOrderDetails.woStatusChange
  );
  const [visible, setVisible] = useState(false);

  const [createWoModalVisible, setCreateWoModalVisible] = useState<boolean>(false);
  // const [reopenModalVisible, setReopenModalVisible] = useState(false);

  //Save WO details and changes
  const [WOChanges, setWOChanges] = useState<any>({});

  // unsaved changes tracking
  const editStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.editFormulationsStatus
  );

  useEffect(() => {
    if (editStatus === AsyncStates.SUCCESS) {
      dispatch(setEditingState(false));
    }
  }, [editStatus, dispatch]);

  const editWOStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.editWOStatus
  );

  const createWOStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.createWOStatus
  );

  const foundArchivedParameters = useSelector((state: StoreState) => state.common.foundArchivedParameters)

  const [reOpenWorkOrderCurrentStep, setReOpenWorkOrderCurrentStep] = useState<0 | 1 | null>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);


  useEffect(() => {
    if (foundArchivedParameters.status === AsyncStates.SUCCESS) {
      setReOpenWorkOrderCurrentStep((prev) => {
        return prev === null ? foundArchivedParameters.archiveParametersFoundInitially ? 0 : 1 : prev
      })
    }
  }, [foundArchivedParameters.archiveParametersFoundInitially, foundArchivedParameters.status]);

  //clear wo details changes on edit success
  useEffect(() => {
    if (
      editWOStatus === AsyncStates.SUCCESS ||
      createWOStatus === AsyncStates.SUCCESS
    ) {
      setWOChanges({});
      dispatch(setEditingState(false));
    }
  }, [editWOStatus, createWOStatus, dispatch]);

  const [form] = Form.useForm();

  const openConfirmationPrompt = () => {
    confirm({
      width: 500,
      title: t("common.modal.unsavedwarning"),
      onOk() {
        form.resetFields();
        if (workOrder?.status === "closed") {
          setVisible(false)
        }
        dispatch(setIsEditing(false));
        setReOpenWorkOrderCurrentStep(null)
        dispatch(addRemoveIngredientsKeyToWorkOrderClear())
        dispatch(findArchivedParametersClear())
        setSelectedRowKeys([])
      },
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.no"),
      onCancel() { },
    });
  };

  const handleClose = () => {
    if (isEditing) {
      openConfirmationPrompt();
    } else {
      if (workOrder?.status === "closed") {
        setVisible(false)
      }
      dispatch(setIsEditing(false));
      setReOpenWorkOrderCurrentStep(null)
      dispatch(addRemoveIngredientsKeyToWorkOrderClear())
      dispatch(findArchivedParametersClear())
      setSelectedRowKeys([])
    }
  };


  useEffect(() => {
    if (visible) {
      dispatch(findArchivedParametersRequest({ id: workOrder.work_order_id, type: "work_order" }))
    }
  }, [dispatch, workOrder.work_order_id, visible])

  const reopenWorkOrder = (values: any) => {
    const { stage, comment } = values;
    const [work_order_parent_stage, work_order_stage] = stage.split(".");
    dispatch(
      woStatusChangeRequest({
        workOrderId: workOrder.work_order_id,
        remove_ingredient_ids: workOrder?.remove_ingredient_ids ?? [],
        status: "open",
        comment,
        description: workOrder?.meta?.description || "",
        work_order_stage,
        work_order_parent_stage,
      })
    );
  };

  const editingState = useSelector(
    (state: StoreState) => state.workOrderDetails.editingState
  );

  // const users = useSelector((state: StoreState) => state.teams.data) as Team[];
  // const user_id = useSelector(
  //   (state: StoreState) => state.login.loginResponse.user_id
  // );

  const callback = useCallback(
    (e: any) => {
      e.preventDefault();
      e.returnValue = t("workOrderDetails.warn.unsavedChanges");
    },
    [t]
  );

  useEffect(() => {
    (editingState || !!Object.keys(WOChanges).length) &&
      window.addEventListener("beforeunload", callback);
    return () => {
      window.removeEventListener("beforeunload", callback);
    };
  }, [editingState, callback, WOChanges]);

  const stageOptions = useMemo(() => {
    return [
      {
        value: "work_order_initiated.work_order_initiated",
        label: t("common.WorkOrderInitiated"),
        key: "work_order_initiated",
      },
      ...(workOrder?.status === "closed"
        ? workOrder?.stages?.map((stage: any, index: number) => ({
          key: `Stage ${index + 1}: ${stage.name}`,
          label: `${t("common.stage")} ${index + 1}: ${stage.name}`,
          options: stage.data.map((res: any) => ({
            label: res.name,
            value: `${stage.identifier}.${res.type}`,
            key: res.name + String(index),
          })),
        }))
        : []),
      {
        value: "report_preview.report_preview",
        label: t("common.WorkOrderPreview"),
        key: "report_preview",
      },
      {
        value: "work_order_status.work_order_status",
        label: t("common.WorkOrderCompleted"),
        key: "work_order_status",
      },
    ];
  }, [workOrder?.stages, workOrder?.status, t]);

  const getLabLocation = () => {
    return `${workOrder.lab_location.name} (${workOrder.lab_location.city}, ${workOrder.lab_location.country})`;
  };

  const handleRemoveIngredients = () => {
    if (foundArchivedParameters.type === "work_order" && workOrder?.work_order_id === foundArchivedParameters.id) {
      dispatch(addRemoveIngredientsKeyToWorkOrderRequest({
        remove_ingredient_ids: selectedRowKeys
      }))
      dispatch(updateArchivedParametersRequest({ inventory_ids: selectedRowKeys }))
      setSelectedRowKeys([])
    }
  }

  const handleNext = () => {
    setReOpenWorkOrderCurrentStep(1)
  }

  return <>
    <StyledPageHeader
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {workOrder?.work_order_id && (
            <Space>
              <Link
                to={`/work-orders?closed=${workOrder?.status === "closed" ? "true" : "false"
                  }`}
                style={{ color: "#000000", opacity: "45%", outline: 'none' }}
              >
                <Typography.Title level={5}>{`${t("common.workOrders")}`}</Typography.Title>
              </Link>

              <Typography.Title level={5}>/</Typography.Title>

              <Typography.Title level={5}>
                {workOrder?.work_order_name}
              </Typography.Title>
            </Space>
          )}
          {!!Object.keys(workOrder?.lab_location || {}).length && (
            <Typography.Text
              style={{
                color: "#8C8C8C",
                marginLeft: "1rem",
              }}
            >
              <Location size={10} color={"#8C8C8C"} />
              <Typography.Text style={{ marginLeft: "0.1rem" }}>
                {getLabLocation() && getLabLocation().length > 30 ? (
                  <Tooltip title={getLabLocation()}>
                    {getLabLocation().slice(0, 30) + "..."}
                  </Tooltip>
                ) : (
                  getLabLocation()
                )}
              </Typography.Text>
            </Typography.Text>
          )}
          {workOrder?.work_order_id && (
            <Typography.Text
              style={{
                color: "#8C8C8C",
                marginLeft: "1rem",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <ClockCircleOutlined />
              <Typography.Text style={{ marginLeft: "0.1rem" }}>
                {t("common.lastModifiedOn")}
              </Typography.Text>{" "}
              {new Date(workOrder.updated).toLocaleString([], {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </Typography.Text>
          )}
        </div>
      }
      ghost={false}
      extra={
        <>
          {disabled ? (
            <Space>
              {disabled && displayTextPermissions(userAccess)}
              {!disabled &&
                userAccess?.stages?.includes("work_order_initiated") &&
                !!workOrder?.work_order_id ? (
                <Tooltip
                  placement="top"
                  title={t("common.createDuplicateWO")}
                >
                  <StyledButton
                    icon={<CopyOutlined />}
                    onClick={() => setCreateWoModalVisible(true)}
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </Space>
          ) : (
            <Space>
              {workOrder?.status === "closed" && (
                <Tooltip placement="top" title={t("common.reopenWorkOrder")}>
                  <StyledButton
                    icon={<ReloadOutlined />}
                    type="primary"
                    onClick={() => setVisible(true)}
                  />
                </Tooltip>
              )}
              <Tooltip placement="top" title={t("common.createDuplicateWO")}>
                <StyledButton
                  icon={<CopyOutlined />}
                  onClick={() => setCreateWoModalVisible(true)}
                />
              </Tooltip>
            </Space>
          )}
        </>
      }
    />
    {/* <Modal
      okText={t("common.ok")}
      cancelText={t("common.cancel")}
      width={600}
      visible={reopenModalVisible}
      onCancel={handleClose}
      footer={null}
      title={
        <Space style={{ textAlign: "center" }}>
          {t("common.reopenWorkOrder")}{" "}
          {isEditing && (
            <Text style={{ color: orange.primary }}>
              {t("common.unsavedChanges")}
            </Text>
          )}
        </Space>
      }
    >
      <Spin
        spinning={woStatusChange === AsyncStates.LOADING}
        indicator={<LoadingOutlined />}
      >
        <Space style={{ width: "100%" }} direction="vertical" size="large">
          <Form
            onFinish={reopenWorkOrder}
            form={form}
            onFieldsChange={() => dispatch(setIsEditing(true))}
            layout="vertical"
            requiredMark={false}
          >
            <Form.Item
              name={"comment"}
              label={t("closedWO.reasonToOpen")}
              rules={[
                { required: true, message: t("common.required") },
                {
                  pattern: new RegExp(/^[^\s]+(\s+[^\s]+)*$/),
                  message: <>Reason cannot start and/or end with spaces.</>,
                },
              ]}
              required
              tooltip={requiredFieldStar}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
            <Form.Item
              name={"stage"}
              label={t("closedWO.reopenInStage")}
              rules={[{ required: true, message: t("common.required") }]}
              required
              tooltip={requiredFieldStar}
            >
              <Select options={stageOptions} />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => (
                <Row justify="end">
                  <StyledButton
                    htmlType="submit"
                    type="primary"
                    ghost
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }: any) => errors.length).length
                    }
                  >
                    {t("common.confirm")}
                  </StyledButton>
                </Row>
              )}
            </Form.Item>
          </Form>
        </Space>
      </Spin>
    </Modal> */}
    <Modal
      okText={t("common.ok")}
      cancelText={t("common.cancel")}
      width={900}
      open={visible}
      onCancel={handleClose}
      footer={null}
      title={
        <Space style={{ textAlign: "center" }}>
          {t("common.reopenWorkOrder")}{" "}
          {isEditing && (
            <Text style={{ color: orange.primary }}>
              {t("common.unsavedChanges")}
            </Text>
          )}
        </Space>
      }
    >
      {
        foundArchivedParameters.status === AsyncStates.LOADING && <Spin
          tip={"Checking for Archived Parameter in the datasheet."}
          spinning={foundArchivedParameters.status === AsyncStates.LOADING}
          indicator={<LoadingOutlined />}
        >
          <Empty description={null} />
        </Spin>
      }

      {foundArchivedParameters.status === AsyncStates.ERROR && <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography.Text type="danger" strong>{t("common.errorFetchingProperties")}</Typography.Text>
        <StyledButton
          icon={<ReloadOutlined />}
          onClick={() => {
            dispatch(findArchivedParametersRequest({
              id: foundArchivedParameters?.id ?? workOrder.work_order_id,
              type: foundArchivedParameters?.type ?? "work_order",
            }))
          }}>
          {t("common.retry")}
        </StyledButton>
      </div>
      }

      {
        foundArchivedParameters.status === AsyncStates.SUCCESS && <Spin
          spinning={woStatusChange === AsyncStates.LOADING}
          indicator={<LoadingOutlined />}
        >
          <div style={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column"
          }}>
            {foundArchivedParameters.archiveParametersFoundInitially && <Steps
              current={reOpenWorkOrderCurrentStep ?? 0}
              onChange={(curr) => {
                if (curr === 1 && !!foundArchivedParameters.data.length) {
                  return;
                } else if (curr === 0 && !foundArchivedParameters.data.length) {
                  return;
                }
                setReOpenWorkOrderCurrentStep(curr as 0 | 1 | null);
              }}
              items={[
                ...(foundArchivedParameters.archiveParametersFoundInitially ? [{
                  title: `Found Archived Parameter`,
                  icon: <ExceptionOutlined />,
                  disabled: !foundArchivedParameters.data.length
                }] : []),
                {
                  title: <Text>{t("common.createNewWorkOrder")}</Text>,
                  icon: <PlusOutlined />,
                  disabled: !!foundArchivedParameters.data.length
                },
              ]} />
            }
            {
              reOpenWorkOrderCurrentStep === 0 && <ArchivedParametersContent selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} handleRemoveIngredients={handleRemoveIngredients} handleNext={handleNext} hideHandleNext={false} isMultiStage={true} />
            }

            {reOpenWorkOrderCurrentStep === 1 &&
              <Space style={{ width: "100%" }} direction="vertical" size="large">
                <Form
                  onFinish={reopenWorkOrder}
                  form={form}
                  onFieldsChange={() => dispatch(setIsEditing(true))}
                  layout="vertical"
                  requiredMark={false}
                >
                  <Form.Item
                    name={"comment"}
                    label={t("closedWO.reasonToOpen")}
                    rules={[
                      { required: true, message: t("common.required") },
                      {
                        pattern: new RegExp(/^[^\s]+(\s+[^\s]+)*$/),
                        message: <>Reason cannot start and/or end with spaces.</>,
                      },
                    ]}
                    required
                    tooltip={requiredFieldStar}
                  >
                    <Input.TextArea rows={5} />
                  </Form.Item>
                  <Form.Item
                    name={"stage"}
                    label={t("closedWO.reopenInStage")}
                    rules={[{ required: true, message: t("common.required") }]}
                    required
                    tooltip={requiredFieldStar}
                  >
                    <Select options={stageOptions} />
                  </Form.Item>
                  <Form.Item shouldUpdate>
                    {() => (
                      <Row justify="end">
                        <StyledButton
                          htmlType="submit"
                          type="primary"
                          ghost
                          disabled={
                            !form.isFieldsTouched(true) ||
                            !!form
                              .getFieldsError()
                              .filter(({ errors }: any) => errors.length).length
                          }
                        >
                          {t("common.confirm")}
                        </StyledButton>
                      </Row>
                    )}
                  </Form.Item>
                </Form>
              </Space>
            }
          </div>
        </Spin>
      }
    </Modal>
    <CreateWoModal
      createWoModalVisible={createWoModalVisible}
      setCreateWoModalVisible={setCreateWoModalVisible}
    />
  </>;
};
