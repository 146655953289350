import { Typography } from 'antd'
import { motion } from 'framer-motion'
import React from 'react'
import { StyledPageHeader } from 'src/styled_components/StyledPageHeader'
import useTranslate from 'src/utils/useTranslate'



export const Interpolation = () => {
    const [t] = useTranslate()
    return (
        <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            transition={{ type: 'just' }}
            style={{
                height: 'calc(-2.5rem + 100vh)',
                overflowY: 'hidden',
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <StyledPageHeader
                ghost={false}
                title={
                    <Typography.Title level={3}>
                        {t("common.interpolation")}
                    </Typography.Title>
                }
                style={{ height: '5%' }}
            />
            <div
                style={{
                    width: "100%",
                    height: '95%',
                    padding: "5px",
                    marginTop: "10px"
                }}
            >
                <iframe
                    sandbox="allow-same-origin allow-scripts"
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                    title="pcm tabel 3d visualisation"
                    src={"https://interpolation_analysis.deployments.polymerize.io/"}
                    frameBorder="0"
                ></iframe>
            </div>
        </motion.div>
    )
}
