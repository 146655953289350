import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Collapse,
  Form,
  Row,
  Select,
  Table,
  Col,
  Drawer,
  message,
  Typography,
  Space,
  Tooltip,
} from "antd";
import { antdTheme, AsyncStates } from "src/constants";
import { StyledButton } from "src/styled_components/StyledButton";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import {
  compareEquationClear,
  compareEquationRequest,
  equationModelListRequest,
  equationRequest,
} from "src/store/actions/customML";
import { StyledCard } from "src/styled_components/StyledCard";
import "mathlive";
import "./CustomML.scss";
import { MathJax } from "better-react-mathjax"
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { InfoCircleOutlined } from "@ant-design/icons";
import { EQ_TYPES } from "./EquationModelResults";
import { useValue } from "src/utils/useValue";
import useTranslate from "src/utils/useTranslate";

export const CompareDrawer = ({ open, onClose, equationModelData, runId: _runId }: any) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm();


  const { equationModelListData, compareEquationData, equationData, compareEquationStatus } = useSelector(
    (state: StoreState) => state.CustomML
  );

  const woList = useSelector(
    (state: StoreState) => state.workOrders.data
  );

  useEffect(() => {
    dispatch(equationModelListRequest())
  }, [dispatch])

  const allWoOpts = useMemo(() =>
    woList?.map((wo: any) => ({ value: wo.work_order_id, label: wo.work_order_name })) || []
    , [woList])
  const currentProject = useSelector(
    (state: StoreState) => state.projects?.current
  )
  const runOpts = useMemo(() =>
    equationModelListData.filter(run => run.project_id === currentProject).filter(run => !run.Type).map(run => ({ label: run.title, value: run.run_id }))
    , [currentProject, equationModelListData])
  const [runId, setRunId] = useState(_runId)

  useEffect(() => {
    setRunId(_runId)
    form.setFieldValue("runId", _runId)
  }, [_runId, form])

  const woOpts = useMemo(() =>
    Object.entries(equationModelListData.find(run => run.run_id === runId)?.work_orders || {}).map(([value, label]) => ({ label, value }))
    , [equationModelListData, runId])
  const [wo, setWo] = useState(woOpts[0]?.value)
  useEffect(() => { setWo(woOpts[0]?.value) }, [woOpts])

  const propertyOpts = useMemo(() =>
    wo ? Array.from(new Set(Object.keys({ ...EQ_TYPES.reduce((acc, { value }) => ({ ...acc, ...equationModelData?.[wo]?.[value] }), {}) }).map(wo => ({ label: wo, value: wo })))) : []
    , [equationModelData, wo])

  const [property, setProperty] = useState(propertyOpts[0]?.value)

  useEffect(() => {
    if (runId) {
      dispatch(equationRequest({
        run_id: runId,
        run_work_order_id: wo,
      }))
      setProperty(propertyOpts[0]?.value)
    }
  }, [dispatch, propertyOpts, runId, wo])

  const loadEquation = () => {
    dispatch(equationRequest({
      run_id: runId,
      run_work_order_id: wo,
    }))
    setProperty(propertyOpts[0]?.value)
  }

  const [extWo, setExtWo] = useState()
  const compare = () => {
    if (extWo) {
      dispatch(compareEquationRequest({
        run_id: runId,
        run_work_order_id: wo,
        external_work_order_id: extWo,
        property
      }))
    } else {
      message.warning({ content: "Please select External Work Order" })
    }
  }

  const [plotEqType, setPlotEqType] = useState("linear")
  const [plotEqType2, setPlotEqType2] = useState("linear")

  const [t] = useTranslate()
  const { getValue: getEUValue } = useValue();

  const options = useMemo(() => {
    const options = {
      chart: { type: "scatter" },
      title: { text: null },
      legend: {
        verticalAlign: 'top',
      },
      xAxis: {
        title: { text: "Actual" },
      },
      yAxis: {
        title: { text: "Predicted" },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: "line",
          name: "Ideal fit line",
          data: Object.entries(compareEquationData?.[wo as any]?.[plotEqType]?.actual_property_data?.[property] || {}).map(([trial, data]) => [data, data]) || [],
          marker: {
            enabled: false
          },
          tooltip: {
            headerFormat: '',
            pointFormatter: function (this: any) {
              return `<b>${t("aiEngine.modelAnalysis.predictedValue")}: ${getEUValue(Object.values(compareEquationData?.[wo as any]?.[plotEqType]?.calculated_property_data?.[property] || {})?.[this.index] as any)}</b><br><b>${t("aiEngine.modelAnalysis.actualValue")}: ${getEUValue(this.x)}</b><br>`
            },
          }
        },
        {
          name: property,
          data: Object.entries(compareEquationData?.[wo as any]?.[plotEqType]?.calculated_property_data?.[property] || {}).map(([trial, data]) => [compareEquationData?.[wo as any]?.[plotEqType]?.actual_property_data?.[property]?.[trial], data]) || [],
          tooltip: {
            pointFormatter: function (this: any) {
              return `<b>${t("aiEngine.modelAnalysis.predictedValue")}: ${getEUValue(this.y)}</b><br><b>${t("aiEngine.modelAnalysis.actualValue")}: ${getEUValue(this.x)}</b><br>`
            },
          },
        },
      ],
    }
    return options
  }, [compareEquationData, getEUValue, plotEqType, property, t, wo])

  const options2 = useMemo(() => {
    const options = {
      chart: { type: "scatter" },
      title: { text: null },
      legend: {
        verticalAlign: 'top',
      },
      xAxis: {
        title: { text: "Actual" },
      },
      yAxis: {
        title: { text: "Predicted" },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: "line",
          name: "Ideal fit line",
          data: Object.entries(compareEquationData?.[extWo as any]?.[plotEqType2]?.actual_property_data?.[property] || {}).map(([trial, data]) => [data, data]) || [],
          marker: {
            enabled: false
          },
          tooltip: {
            headerFormat: '',
            pointFormatter: function (this: any) {
              return `<b>${t("aiEngine.modelAnalysis.predictedValue")}: ${getEUValue(Object.values(compareEquationData?.[wo as any]?.[plotEqType]?.calculated_property_data?.[property] || {})?.[this.index] as any)}</b><br><b>${t("aiEngine.modelAnalysis.actualValue")}: ${getEUValue(this.x)}</b><br>`
            },
          }
        },
        {
          name: property,
          data: Object.entries(compareEquationData?.[extWo as any]?.[plotEqType2]?.calculated_property_data?.[property] || {}).map(([trial, data]) => [compareEquationData?.[extWo as any]?.[plotEqType2]?.actual_property_data?.[property]?.[trial], data]) || [],
          tooltip: {
            pointFormatter: function (this: any) {
              return `<b>${t("aiEngine.modelAnalysis.predictedValue")}: ${getEUValue(this.y)}</b><br><b>${t("aiEngine.modelAnalysis.actualValue")}: ${getEUValue(this.x)}</b><br>`
            },
          },
        },
      ],
    }
    return options
  }, [compareEquationData, extWo, getEUValue, plotEqType, plotEqType2, property, t, wo])

  const getFit = useCallback((fit: string, error: string) => {
    switch (fit) {
      case "Good fit":
        return <Space>
          <Typography.Text style={{ color: antdTheme.colorSuccess }}>{fit}</Typography.Text>
          <Tooltip title={error}>
            <InfoCircleOutlined />
          </Tooltip>
        </Space>

      case "Moderate fit":
        return <Space>
          <Typography.Text style={{ color: antdTheme.colorWarning }}>{fit}</Typography.Text>
          <Tooltip title={error}>
            <InfoCircleOutlined />
          </Tooltip>
        </Space>

      case "Bad fit":
        return <Space>
          <Typography.Text style={{ color: antdTheme.colorError }}>{fit}</Typography.Text>
          <Tooltip title={error}>
            <InfoCircleOutlined />
          </Tooltip>
        </Space>


      default:
        return "-"
    }
  }, [])

  const whereVariables = useMemo(() => Object.entries(
    Object.values(equationData || {})
      ?.flatMap((properties: any) => Object.values(properties))
      ?.reduce((a: any, o: any) => ({ ...a, ...o?.dummy }), {}) || {}
  )
    ?.map(([parameter, variable]) => ({ key: variable, variable, parameter })),
    [equationData])

  return <Drawer
    destroyOnClose={true}
    title="Compare Work orders"
    placement="bottom"
    closable={true}
    onClose={onClose}
    open={open}
    height="99vh"
    footer={<StyledButton loading={compareEquationStatus === AsyncStates.LOADING} type='primary' onClick={compare}>{t("common.compare")}</StyledButton>}
  >
    <Collapse
      destroyInactivePanel
      items={[
        {
          key: "model",
          label: <Typography.Title level={5}>{runOpts.find(opt => opt.value === runId)?.label ?? "-"}</Typography.Title>,
          children:
            <Row gutter={16}>
              {/* <Col span={8}>
                <StyledCard>
                  <Form form={form}>
                    <Row justify={"center"}>
                      <Col span={24}>
                        <Form.Item
                          label="Model Name"
                          name="modelName"
                        // rules={[{ required: true, message: 'Please select a Run Title' }]}
                        >
                          <Select
                            disabled={true}
                            value={runId}
                            defaultValue={_runId}
                            style={{ width: 120 }}
                            onChange={value => { setRunId(value) }}
                            options={runOpts}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </StyledCard>
              </Col> */}
              <Col span={16}>
                <Row>
                  <Col span={24}>
                    <Collapse
                      destroyInactivePanel
                      items={EQ_TYPES.map(({ label, value }) => (
                        {
                          key: value,
                          label,
                          children: (<>
                            <>
                              <Row justify="center">
                                {/* <Spin
                          style={{ marginTop: "30px" }}
                          spinning={filterDataStatus === AsyncStates.LOADING}
                          indicator={<LoadingOutlined />}
                        ></Spin> */}
                              </Row>
                              {/* {!filterData?.dataframe?.length && filterDataStatus !== AsyncStates.LOADING && (<Empty style={{ marginTop: "30px" }} />)} */}
                              {true && (
                                <Table
                                  style={{ marginTop: 24, position: "relative" }}
                                  size="large"
                                  columns={[{
                                    title: "Properties Equations",
                                    dataIndex: "equation_dummy",
                                    key: "Properties Equations",
                                    render: (text: any, record: any, index: number) => {
                                      return (
                                        <MathJax style={{ fontSize: antdTheme.fontSizeLG }} dynamic={true}>
                                          {`${record.Properties} = \\(${text}\\)`}
                                        </MathJax>
                                      )
                                    }
                                  },
                                  {
                                    title: "Error (MAPE %)",
                                    dataIndex: "Error",
                                    key: "Error",
                                  }
                                  ]}
                                  dataSource={Object.entries(equationData?.[value] || {}).filter(([Properties, Cols]) => Properties === property).map(([Properties, Cols]: any) => ({ Properties, equation_dummy: Cols.equation_dummy, Error: Cols.mape }))} pagination={false} />
                              )}
                            </>
                          </>),
                        }
                      ))}
                      onChange={(key) => {
                      }}
                      defaultActiveKey={[]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Table
                  title={() => "Where,"}
                  bordered={true}
                  style={{ maxWidth: 500 }}
                  size="small"
                  columns={[
                    {
                      key: "variable", dataIndex: "variable", title: "Variable",
                      render: (text: any, record: any, index: number) => {
                        return (
                          <MathJax style={{ fontSize: antdTheme.fontSizeLG }} dynamic={true}>
                            {`\\(${text}\\)`}
                          </MathJax>
                        )
                      }
                    },
                    { key: "parameter", dataIndex: "parameter", title: "Parameter" }
                  ]}
                  dataSource={whereVariables}
                  pagination={false}
                />
              </Col>
            </Row>
        }]} />
    <br />
    <Row>
      {Boolean(Object.keys(equationData)?.length) && <Col span={24}>
        {<>
          <Row>
            <Col span={24}>
              <Table
                columns={[
                  {
                    key: "model_type",
                    title: (
                      <div>
                        <p>{t("inventory.propertyName")}</p>
                        <Select
                          value={property}
                          defaultValue={property}
                          style={{ width: 120 }}
                          onChange={value => { setProperty(value) }}
                          options={propertyOpts}
                        />
                        <br />
                        <br />
                        <p>{t("aiEngine.modelType")}</p>
                      </div>
                    ),
                    dataIndex: "modelType",
                    render: (text: any) => EQ_TYPES.find(eq => eq.value === text)?.label || text 
                  },
                  {
                    key: "model_fit1",
                    title: (
                      <div>
                        <p>{t("common.workOrder")}</p>
                        <Select
                          defaultValue={wo}
                          style={{ width: 160 }}
                          onChange={value => { setWo(value); dispatch(compareEquationClear()); loadEquation() }}
                          options={woOpts}
                          value={wo}
                        />
                        <br />
                        <br />
                        <p>{t("modelFit")}</p>
                      </div>
                    ),
                    dataIndex: "modelFit1",
                  },
                  {
                    key: "model_fit2",
                    title: (
                      <div>
                        <p>*{t("extWO")}</p>
                        <Select
                          defaultValue=""
                          style={{ width: 160 }}
                          onChange={(value) => { setExtWo(value) }}
                          options={allWoOpts}
                        />
                        <br />
                        <br />
                        <p>{t("modelFit")}</p>
                      </div>
                    ),
                    dataIndex: "modelFit2",
                  },
                ]}
                dataSource={Boolean(Object.keys(compareEquationData)?.length) ? EQ_TYPES.map(({ value }) => value).map(eq => ({ modelType: eq, modelFit1: getFit(compareEquationData?.[wo]?.[eq].mape[property]?.equation_fit, compareEquationData?.[wo]?.[eq].mape[property]?.error), modelFit2: getFit(compareEquationData?.[extWo as any]?.[eq].mape[property]?.equation_fit, compareEquationData?.[extWo as any]?.[eq].mape[property]?.error) })) : []}
                pagination={false}
              />
            </Col>
          </Row><br />
          {Boolean(Object.keys(compareEquationData)?.length) && <Row gutter={32}>
            <Col span={12}>
              <StyledCard
                title={woOpts.find(_wo => _wo.value === wo)?.label ?? wo}
                extra={<Select
                  defaultValue={plotEqType}
                  style={{ width: 120 }}
                  onChange={value => { setPlotEqType(value) }}
                  options={EQ_TYPES}
                />}
              >
                <HighchartsReact highcharts={Highcharts} options={options} />
              </StyledCard>
            </Col>
            <Col span={12}>
              <StyledCard
                title={allWoOpts.find(_wo => _wo.value === extWo)?.label ?? extWo}
                extra={<Select
                  defaultValue={plotEqType2}
                  style={{ width: 120 }}
                  onChange={value => { setPlotEqType2(value) }}
                  options={EQ_TYPES}
                />
                }>
                <HighchartsReact highcharts={Highcharts} options={options2} />
              </StyledCard>
            </Col>
          </Row>}
        </>}
      </Col>}
    </Row>
  </Drawer>
}
