import {
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Space,
    Switch,
    Typography,
} from "antd";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import "./Common.scss";
import { FIELD_TYPES, OPERATORS } from "./Data";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { useCallback, useEffect, useState } from "react";
import { antdTheme, AsyncStates } from "src/constants";
import {
    createFieldRequest,
    updateFieldRequest,
} from "src/store/actions/repository";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import isURL from "validator/lib/isURL";
import { trimWhiteSpaceFromValues } from "src/utils/general/trimmer";

type TProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    mode?: "edit" | "create" | "delete";
    selectedFieldId?: string;
};

const AddEditCustomFieldModal = ({
    showModal,
    setShowModal,
    mode = "create",
    selectedFieldId,
}: TProps) => {
    const [t] = useTranslate();
    const [customFieldForm] = Form.useForm();
    const fields = useSelector(
        (state: StoreState) => state.repository.fields.data
    );
    const dispatch = useDispatch();
    const createFieldStatus = useSelector(
        (state: StoreState) => state.repository.fields.status
    );
    const [options, setOptions] = useState<string[]>([]);
    const [optionName, setOptionName] = useState<string>();
    const [, setRerender] = useState(false);
    const [requiredField, setRequiredField] = useState(false);

    const resetForm = useCallback(() => {
        customFieldForm.resetFields();
        setOptions([]);
        setOptionName(undefined);
        setRerender((prev) => !prev);
        setRequiredField(false);
    }, [customFieldForm]);

    useEffect(() => {
        if (mode === "edit" && selectedFieldId) {
            const selectedField = fields.find((a) => a.field_id === selectedFieldId);
            customFieldForm.setFieldsValue({
                field_name: selectedField?.field_name,
                field_type: selectedField?.field_type,
                default_value:
                    selectedField?.field_type === "date" && selectedField?.default_value
                        ? dayjs(selectedField?.default_value, "YYYY-MM-DD")
                        : selectedField?.default_value,
                options: selectedField?.options,
                value: selectedField?.value,
                value_max: selectedField?.value_max,
                date_format: selectedField?.date_format,
                operator: selectedField?.operator,
            });
            setOptions(selectedField?.options || []);
            setRequiredField(selectedField?.required || false);
            setRerender((prev) => !prev);
        } else if (mode === "create") {
            resetForm();
        }
    }, [selectedFieldId, fields, mode, customFieldForm, resetForm]);

    useEffect(() => {
        if (createFieldStatus === AsyncStates.SUCCESS) {
            setShowModal(false);
        }
    }, [createFieldStatus, setShowModal]);

    useEffect(() => {
        customFieldForm.setFieldValue("options", options);
    }, [options, customFieldForm]);

    const submit = () => {
        const payload = trimWhiteSpaceFromValues(customFieldForm.getFieldsValue());

        payload["entity_system_name"] = "inventory.ingredients";
        payload["required"] = requiredField;

        if (payload.field_type === "date" && payload.default_value)
            payload.default_value = payload.default_value.format("YYYY-MM-DD");

        if (mode === "create") dispatch(createFieldRequest(payload));
        if (mode === "edit")
            dispatch(updateFieldRequest({ ...payload, field_id: selectedFieldId }));
    };

    const addOption = () => {
        if (optionName) {
            const newOptions = new Set([...options, optionName.trim()]);
            setOptions([...newOptions]);
            setOptionName(undefined);
        }
    };

    const removeOption = (optionToRemove: string) =>
        setOptions((prev) => prev.filter((p) => p !== optionToRemove));

    const fieldTypeChange = () => {
        customFieldForm.setFieldsValue({
            default_value: undefined,
            date_format: undefined,
            operator: undefined,
            value: undefined,
            value_max: undefined,
        });

        setOptions([]);
        setOptionName(undefined);
        setRerender((prev) => !prev);
    };

    return (
        <Modal
            title={
                <Typography.Title level={4}>{`${mode === "create"
                    ? t("repository.addNewField")
                    : t("repository.editField")
                    }`}</Typography.Title>
            }
            open={showModal}
            onOk={submit}
            onCancel={() => setShowModal(false)}
            footer={null}
            rootClassName="repository-custom-modal"
        >
            <Form
                onFinish={submit}
                form={customFieldForm}
                layout="vertical"
                scrollToFirstError
            >
                <Form.Item
                    name="field_name"
                    label={t("common.fieldName")}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            whitespace: true
                        }
                    ]}
                >
                    <Input placeholder={t("common.enterName")} />
                </Form.Item>

                <Form.Item
                    name="field_type"
                    label={t("common.fieldType")}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        loading={false}
                        placeholder={t("common.select")}
                        allowClear
                        onChange={() => fieldTypeChange()}
                        options={FIELD_TYPES}
                    />
                </Form.Item>

                <Form.Item>
                    <Space>
                        <div>{t("common.requiredField")}</div>
                        <Switch onChange={setRequiredField} checked={requiredField} />
                    </Space>
                </Form.Item>

                {customFieldForm.getFieldValue("field_type") === "numerical" && (
                    <Form.Item name="default_value" label={t("common.DefaultValue")}>
                        <Input type="number" placeholder="-" />
                    </Form.Item>
                )}

                {customFieldForm.getFieldValue("field_type") === "alpha_numeric" && (
                    <Form.Item name="default_value" label={t("common.DefaultValue")} rules={[{ whitespace: true }]}>
                        <Input placeholder="-" />
                    </Form.Item>
                )}

                {customFieldForm.getFieldValue("field_type")  === "select" && (
                    <>
                        <Form.Item
                            name="options"
                            label={t("common.Options")}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                loading={false}
                                placeholder={t("common.select")}
                                allowClear
                                mode="multiple"
                                maxTagCount="responsive"
                                onChange={(v) => {
                                    setOptions(v);
                                    customFieldForm.setFieldValue("default_value", undefined);
                                }}
                                dropdownRender={() => (
                                    <>
                                        {options.map((op) => (
                                            <div className="subcategory-options">
                                                <span>{op}</span>
                                                <CloseOutlined
                                                    onClick={() => removeOption(op)}
                                                    className="subcategory-options-delete"
                                                />
                                            </div>
                                        ))}
                                        <Divider style={{ margin: "8px 0" }} />
                                        <Space style={{ padding: "0 8px 4px" }}>
                                            <Input
                                                placeholder="Option name"
                                                value={optionName}
                                                onChange={(e) => { 
                                                    const inputValue = e.target.value;
                                                    if (inputValue.trim().length === 0 && inputValue.length > 0) return;
                                                    setOptionName(inputValue);
                                                }}
                                                onKeyDown={(e) => e.stopPropagation()}
                                            />
                                            <StyledButton
                                                type="text"
                                                icon={<PlusOutlined />}
                                                onClick={addOption}
                                                style={{ color: antdTheme.colorPrimary }}
                                            >
                                                {t("common.Addoption")}
                                            </StyledButton>
                                        </Space>
                                    </>
                                )}
                                options={options.map((item) => ({ label: item, value: item }))}
                            />
                        </Form.Item>
                        <Form.Item name="default_value" label={t("common.DefaultValue")}>
                            <Select
                                loading={false}
                                placeholder={t("common.select")}
                                allowClear
                                mode="multiple"
                                maxTagCount="responsive"
                                options={options.map((item) => ({ label: item, value: item }))}
                            />
                        </Form.Item>
                    </>
                )}

                {customFieldForm.getFieldValue("field_type") === "date" && (
                    <>
                        <Form.Item
                            name="date_format"
                            label={t("common.dateFormat")}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                placeholder={t("project.selectDateFormat")}
                                allowClear
                                options={[{ label: "YYYY-MM-DD", value: "YYYY-MM-DD" }]}
                            />
                        </Form.Item>
                        <Form.Item name="default_value" label={t("common.DefaultValue")}>
                            <DatePicker />
                        </Form.Item>
                    </>
                )}

                {customFieldForm.getFieldValue("field_type") === "range" && (
                    <>
                        <div style={{ fontWeight: "500" }}>{t("common.DefaultValue")}</div>
                        <Row justify="start" gutter={[8, 8]}>
                            <Col>
                                <Form.Item name="value" label={t("common.value")} rules={[{ whitespace: true }]}>
                                    <Input placeholder="-" />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="value_max" label={t("inventory.Maxvalue")} rules={[{ whitespace: true }]}>
                                    <Input placeholder="-" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}

                {customFieldForm.getFieldValue("field_type") === "operator_value" && (
                    <>
                        <div style={{ fontWeight: "500" }}>{t("common.DefaultValue")}</div>
                        <Row justify="start" gutter={[8, 8]}>
                            <Col>
                                <Form.Item name="operator" label={t("common.selectOperator")}>
                                    <Select
                                        placeholder={t("common.selectOperator")}
                                        allowClear
                                        options={OPERATORS}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="value" label={t("common.value")} rules={[{ whitespace: true }]}>
                                    <Input placeholder="-" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}

                {customFieldForm.getFieldValue("field_type") === "link" && (
                    <Form.Item name="default_value" label={t("common.DefaultValue")}
                    rules={
                        [
                            {
                                required: requiredField,
                                message: "required",
                                transform: (value: any) => value?.trim(),
                            },
                          ...(customFieldForm.getFieldValue("field_type") === "link"
                            ? [
                                {
                                  message: "Please enter a valid URL",
                                  validator: (_: any, value: string) => {
                                    if (!requiredField && !value) return Promise.resolve();
                                    if (isURL(value)) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject();
                                    }
                                  },
                                },
                              ]
                            : []),
                        ]
                      } 
                    >
                        <Input placeholder="-" />
                    </Form.Item>
                )}

                <Row gutter={[8, 8]} justify={"end"} style={{ marginTop: "20px" }}>
                    <Col>
                        <StyledButton type="default" onClick={resetForm}>
                            {t("common.reset")}
                        </StyledButton>
                    </Col>
                    <Col>
                        <StyledButton
                            type="primary"
                            htmlType="submit"
                            disabled={createFieldStatus === AsyncStates.LOADING}
                            loading={createFieldStatus === AsyncStates.LOADING}
                        >
                            {t("common.submit")}
                        </StyledButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddEditCustomFieldModal;
