import { call, put, select, takeLatest } from "redux-saga/effects"
import jwtManager from "src/utils/jwtManager"
import { getFormulationDetails } from "src/services/formulation"
import {
    findArchivedParametersFailure,
    findArchivedParametersRequest,
    findArchivedParametersSuccess,
    linkedFormulationsAiEngineFailure,
    linkedFormulationsAiEngineRequest,
    linkedFormulationsAiEngineSuccess,
} from "../actions/common"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import { notification } from "antd"
import { messages } from "src/utils/hooks"
import { findArchivedParametersApi, findArchivedParametersInWorkOrderApi } from "src/services/inventory/v2"

function* linkedFormulationsSaga({ payload }: any) {
    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const {
            data: {
                result: { data, status },
            },
        } = yield call(getFormulationDetails, payload, headers)
        if (status === "Success") {
            yield put(
                linkedFormulationsAiEngineSuccess({
                    formulation_id: payload.formulation_id?.[0],
                    data: data.reduce((acc: any, curr: any) => {
                        return { ...acc, [curr?.id_set?.formulation_id]: curr }
                    }, {})
                }),
            )
        } else {
            yield put(linkedFormulationsAiEngineFailure())
        }
    } catch (error) {
        yield put(linkedFormulationsAiEngineFailure())
    }
}


function* findArchivedParametersSaga({ payload }: any): Generator<any, any, any> {
    const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);

    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };

        const apiEndPoint = payload.type === "work-order-fileupload" ? findArchivedParametersInWorkOrderApi : findArchivedParametersApi

        const { data: { result: { status, message, data } } } = yield call(apiEndPoint, payload, headers);

        if (status === "Success") {
            yield put(findArchivedParametersSuccess({ data, apiPayload: payload }))
        } else {
            yield put(findArchivedParametersFailure(message));
            notification.error({ message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(findArchivedParametersFailure(err));
        notification.error({ message: err });
    }
}

export default function* rootSaga(): Generator<any, any, any> {
    yield takeLatest(linkedFormulationsAiEngineRequest, linkedFormulationsSaga)
    yield takeLatest(findArchivedParametersRequest, findArchivedParametersSaga)
}
