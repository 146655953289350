import React, { useCallback, useEffect, useRef, useState } from "react"
import { Box, PCM, PayloadElement } from "./types"
import { boxInitialValue, isPCMOverflowing, preparePayload } from "./utils"
import { VisualisationControls } from "./controls"
import { message } from "antd"

type Environment = "production" | "development"
const environment: Environment = (process.env.REACT_APP_ENV ??
	"production") as Environment

export const VisualisationFrameUrl = "https://celsure-visualization.web.app/"

type P = {
	data: PCM[]
	box?: Box
}

export const View3D = ({ data, box: boxDimen }: P) => {
	const frameRef = useRef<HTMLIFrameElement>(null)
	const [box, setBox] = useState<Box>(boxDimen ? boxDimen : boxInitialValue)
	const [payloadElement, setPayloadElement] = useState<PayloadElement | null>(
		null
	)
	const [iFrameLoaded, setIFrameLoaded] = useState(false)

	const sendMessage = useCallback(
		(pcmData: PCM[], boxData: Box) => {
			if (frameRef.current?.contentWindow) {
				const { data, box } = preparePayload(pcmData, boxData, payloadElement)
				frameRef.current.contentWindow.postMessage(
					{ data, box },
					VisualisationFrameUrl
				)
			}
		},
		[payloadElement]
	)

	useEffect(() => {
		if (isPCMOverflowing(data, box))
			return message.error(
				"Casset length and width can not be greater than the outer eps box length and width."
			)
		if (iFrameLoaded) sendMessage(data, box)
	}, [data, box, sendMessage, iFrameLoaded])

	return (
		<section>
			<VisualisationControls
				frameRef={frameRef}
				data={data}
				box={box}
				sendMessage={sendMessage}
				setBox={setBox}
				payloadElement={payloadElement}
				setPayloadElement={setPayloadElement}
			/>

			<div
				style={{
					width: "100%",
					height: "560px",
					margin: "20px 0 50px 0",
				}}
			>
				<iframe
					ref={frameRef}
					onLoad={() => setIFrameLoaded(true)}
					sandbox="allow-same-origin allow-scripts"
					style={{
						width: "100%",
						height: "100%",
					}}
					title="pcm tabel 3d visualisation"
					src={VisualisationFrameUrl}
					frameBorder="0"
				></iframe>
			</div>
		</section>
	)
}
