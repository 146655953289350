import { Dropdown, Menu, Tag, Space } from 'antd';
import { CloseSquareOutlined, DownOutlined, EditOutlined, MoreOutlined, PushpinOutlined } from '@ant-design/icons'
import { ProjectModal } from '../ProjectModal/ProjectModal'
import { EditNote } from '../Overview/EditNote'
import { DeleteNote } from '../Overview/DeleteNote'
import { StoreState } from 'src/store/configureStore'
import { useDispatch, useSelector } from 'react-redux'
import { setEditProjectId, updateProjectRequest } from 'src/store/actions/projects'
import { useHistory } from 'react-router-dom'
import { usePermission } from 'src/utils/usePermissions'
import { antdTheme, permissions, projectStatus } from 'src/constants'
import useTranslate from 'src/utils/useTranslate'
import { blue } from '@ant-design/colors'
import CloseAllWorkOrdersModal from 'src/components/WorkOrders/CloseAllWorkOrdersModal'
import { StyledButton } from 'src/styled_components/StyledButton'


export const DropdownMenu = ({ id, type, note_id, comment }: { id: string, type: string, note_id?: any, comment?: any }) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const { push } = useHistory()

  const userAccess = usePermission(id)
  const currentProject = useSelector((state: StoreState) => state.projects.projectList.find((project) => project.project_id === id))

  const setPinned = (e: any) => {
    e.stopPropagation()
    dispatch(updateProjectRequest({ ...currentProject, project_id: currentProject?.project_id, favourite: !currentProject.favourite }))
  }

  const getProjectStatus = (status: string) => {
    switch (status) {
      case "in_progress":
        return <Tag color="orange"> <Space>{t("aiEngine.inProgress")}{<DownOutlined />}</Space></Tag>
      case "completed":
        return <Tag color="green"><Space>{t("projects.complete")} {<DownOutlined />}</Space></Tag>
      case "archived":
        return <Tag color="blue"> <Space>{t("projects.archived")} {<DownOutlined />}</Space></Tag>
    }
  }

  const editProject = (e: any) => {
    e.stopPropagation()
    dispatch(setEditProjectId(id))
    push("/project/update")
  }

  const getMenu = () => {
    switch (type) {
      case "notes": return (
        <Menu>
          <Menu.Item key={1} style={{ color: 'bule' }}>
            <EditNote project_id={id} note_id={note_id} comment={comment} />
          </Menu.Item>
          <Menu.Item key={2}>
            <DeleteNote note_id={note_id} project_id={id} />
          </Menu.Item>
        </Menu>
      )
      case "projects": case "project_details": return (
        <Menu onClick={(e: any) => { }}>
          <Menu.Item key="edit" className="default__menu__item">
            <StyledButton onClick={editProject} disabled={(userAccess?.permission === permissions.viewer) || userAccess?.status !== projectStatus.in_progress} icon={<EditOutlined />} type="text" className={"project__dropdown__btn"}>
              {t("editProject.editProjectDetails")}
            </StyledButton>
          </Menu.Item>
          <Menu.Item key="pin" className="default__menu__item">
            <StyledButton type="text" onClick={setPinned} disabled={userAccess?.permission !== permissions.projectAdmin}
              className={"project__dropdown__btn"}
              icon={<PushpinOutlined style={{ color: currentProject?.favourite ? blue[4] : "" }} />}>
              {currentProject?.favourite ? t("projects.unpin") : t("projects.pin")}
            </StyledButton>
          </Menu.Item>
          <Menu.Item key="close_all_wo" className="default__menu__item">
            <CloseAllWorkOrdersModal projectIds={[id]} buttonType={"text"} icon={<CloseSquareOutlined />} />
          </Menu.Item>
          {type === "projects" &&
            <Menu.Item key="archived" className="default__menu__item">
              <ProjectModal type="archived" currentProject={currentProject} disabled={userAccess?.permission !== permissions.projectAdmin} />
            </Menu.Item>}
          <Menu.Item key="delete" className="default__menu__item">
            <ProjectModal type="delete" currentProject={currentProject} disabled={userAccess?.permission !== permissions.projectAdmin} />
          </Menu.Item>
          <Menu.Item key="update_tags" className="default__menu__item">
            <ProjectModal type="update_tags" currentProject={currentProject} disabled={(userAccess?.permission === permissions.viewer) || userAccess?.status !== projectStatus.in_progress} />
          </Menu.Item>
        </Menu>
      )
      case "project_status": return (
        <Menu onClick={e => { }} disabled={userAccess?.permission !== permissions.projectAdmin}>
          <Menu.Item key="in_progress">
            <ProjectModal type="in_progress" currentProject={currentProject} disabled={userAccess?.permission !== permissions.projectAdmin || userAccess?.status === projectStatus.in_progress} />
          </Menu.Item>
          <Menu.Item key="completed">
            <ProjectModal type="completed" currentProject={currentProject} disabled={userAccess?.permission !== permissions.projectAdmin || userAccess?.status === projectStatus.completed} />
          </Menu.Item>
          <Menu.Item key="archived">
            <ProjectModal type="archived" currentProject={currentProject} disabled={userAccess?.permission !== permissions.projectAdmin || userAccess?.status === projectStatus.archived} />
          </Menu.Item>
        </Menu>
      )
    }
  }


  return (
    <Dropdown key="more" overlay={getMenu() as any}>
      <StyledButton
        type={"text"}
        style={{
          border: type !== "project_details" ? "none" : "1px solid #ccc",
          padding: type !== "project_details" ? 0 : "auto",
        }}
        icon={type === "project_details" ? <EditOutlined /> : null}
        onClick={e => e.stopPropagation()}
      >{type === "project_status" ? getProjectStatus(currentProject?.status) :
        type === "project_details" ? t("projects.update_details") : <MoreOutlined
          style={{
            fontSize: antdTheme.fontSizeHeading3,
            verticalAlign: "top",
          }}
        />
        }
      </StyledButton>
    </Dropdown>
  )
}