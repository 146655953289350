import { Button, Checkbox, Empty, Input, Table, Typography } from "antd"
import { useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Headers } from "src/services/interface"
import InventoryService from "src/services/inventory/v3"
import { StoreState } from "src/store/configureStore"
import { StyledButton } from "src/styled_components/StyledButton"
import jwtManager from "src/utils/jwtManager"
import useTranslate from "src/utils/useTranslate"
import { useValue } from "src/utils/useValue"
import { FixedSizeList as List } from "react-window";
import debounce from "lodash.debounce";
import type { FilterDropdownProps } from "antd/es/table/interface";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

type MolecularDescriptorsPropsTypes = {
    ingredient: any
}

const MolecularDescriptors = ({ ingredient }: MolecularDescriptorsPropsTypes) => {
    const [t] = useTranslate();
    const [exporting, setExporting] = useState<boolean>(false);

    const molecularDescriptors = useMemo(() => ingredient?.chemical_information?.molecular_descriptors ?? {}, [ingredient])

    const { getValue: getEUValue } = useValue();

    const tableData = useMemo(() => Object.keys(molecularDescriptors).map(key => ({
        key,
        name: key,
        value: molecularDescriptors[key],
    })), [molecularDescriptors])

    const filterOptions = useMemo(() => {
        return tableData.map((item: any) => ({
            text: item.name,
            value: item.name,
            key: item.name,
        }))
    }, [tableData])


    const columns = useMemo(() => [
        {
            title: t("common.molecularDescriptors"),
            dataIndex: 'name',
            key: 'name',
            filterMultiple: true,
            filterSearch: true,
            onFilter: (value: any, record: any) => record.name === value,
            filters: filterOptions,
            render: (text: any, record: any) => {
                return <Typography.Text
                    ellipsis={{
                        tooltip: text,
                    }}
                    style={{ width: 100 }}
                >
                    {text}
                </Typography.Text>
            },
            filterDropdown: (props: FilterDropdownProps) => <AntdCustomFilterDropdown {...props} />,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (value: any) => getEUValue(value),
        },
    ], [filterOptions, getEUValue, t])

    const defaultHeaders = useSelector((state: StoreState) => state.defaultHeaders);

    const exportIngredients = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setExporting(true);
        e.stopPropagation();
        const headers = {
            ...defaultHeaders,
            token: jwtManager.getToken(),
        } as Headers;
        const exportResponse: any = await InventoryService.exportIngredients(
            {
                inventory_ids: [ingredient.inventory_id],
                molecular_descriptors: true,
                ingredients_metadata: false
            },
            headers
        );
        setExporting(false);
        window.open(exportResponse.data?.result?.data?.download_link, "_blank");
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                height: "90vh",
            }}
        >
            <div
                style={{
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "flex-end"
                }}
            >
                <StyledButton
                    onClick={(e) => {
                        if (!Object.keys(molecularDescriptors).length) return;
                        exportIngredients(e)
                    }}
                    loading={exporting}
                    disabled={!Object.keys(molecularDescriptors).length}
                >
                    {t("common.exportSpreadsheet")}
                </StyledButton>
            </div>
            <div>
                <Table
                    columns={columns}
                    dataSource={tableData}
                    sticky={true}
                    pagination={{
                        pageSize: 20,
                        showSizeChanger: false,
                    }}
                />
            </div>
        </div>
    )
}



type FilterOption = {
    text: string;
    value: string | number;
};

export const AntdCustomFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filters = [],
    visible,
}: FilterDropdownProps) => {
    const [t] = useTranslate();
    const [searchText, setSearchText] = useState<string>("");

    const dropdownRef = useRef<HTMLDivElement>(null);
    const resetTriggeredRef = useRef<boolean>(false);

    const debouncedSetSearchText = useMemo(() => debounce((value: string) => {
        setSearchText(value);
    }, 300), []);

    const filteredOptions = useMemo(() => {
        if (!searchText) {
            return filters as FilterOption[];
        }
        return (filters as FilterOption[]).filter((filter) => {
            const filterText: string = filter?.text ?? "";
            return filterText.toLowerCase().includes(searchText.toLowerCase());
        });
    }, [searchText, filters]);

    const onChange = (e: CheckboxChangeEvent) => {
        const { value, checked } = e.target;
        if (checked) {
            setSelectedKeys([...selectedKeys, value]);
        } else {
            setSelectedKeys(selectedKeys.filter((key) => key !== value));
        }
    };

    const handleReset = () => {
        setSelectedKeys([]);
        setSearchText("");
        clearFilters?.();
        resetTriggeredRef.current = true;
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                if (selectedKeys.length > 0 || resetTriggeredRef.current) {
                    confirm();
                    resetTriggeredRef.current = false;
                }
            }
        };

        if (visible) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [visible, selectedKeys, confirm, clearFilters]);

    const renderRow = ({ index, style }: { index: number; style: React.CSSProperties }) => {
        const item = filteredOptions[index];
        const itemKey = item?.value ?? index;
        return (
            <div style={style} key={itemKey}>
                <Checkbox
                    value={item.value}
                    checked={selectedKeys.includes(item.value)}
                    onChange={onChange}
                >
                    {item.text}
                </Checkbox>
            </div>
        );
    };

    return (
        <div ref={dropdownRef} style={{ padding: 8, width: 300 }}>
            <Input
                placeholder={t("common.search")}
                allowClear
                onChange={(e) => debouncedSetSearchText(e.target.value)}
                style={{ marginBottom: 8 }}
            />

            <div style={{ maxHeight: 250, overflowY: "auto" }}>
                {!filteredOptions.length ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={null} />
                ) : (
                    <List height={250} itemCount={filteredOptions.length} itemSize={35} width="100%">
                        {renderRow}
                    </List>
                )}
            </div>

            <div style={{ marginTop: 8, textAlign: "right" }}>
                <Button
                    onClick={handleReset}
                    size="small"
                    disabled={!selectedKeys.length}
                    style={{ marginRight: 8 }}
                >
                    {t("common.reset")}
                </Button>
                <Button type="primary" onClick={() => {
                    confirm()
                    resetTriggeredRef.current = false;
                }}
                    size="small">
                    {t("common.apply")}
                </Button>
            </div>
        </div>
    );
};

export default MolecularDescriptors