import { Typography, Space, Row, Divider } from "antd";
import Footer from "./Footer";
import "./SignUp.scss";
import { ConnectLoginImg, ConnectLoginInfoText } from "src/assets/svgs";
import LabsSecurityCertificaitonSvg from "src/assets/svgs/labs_security_certificaiton.svg"
import { currentPlatform, platformInfo } from "src/constants";
import useTranslate from "src/utils/useTranslate";

const { Title } = Typography;

const LeftSideOnboarding = ({ minWidth, maxWidth }: any) => {
  const [t] = useTranslate();
  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        minWidth: `${minWidth}`,
        maxWidth: `${maxWidth}`,
        height: "100vh",
      }}
      align="start"
      className="fontWorkSans mainLoginLayout"
    >
      <a
        target="_blank"
        href={currentPlatform && platformInfo[currentPlatform].link}
        rel="noopener noreferrer"
      >
        <Title level={1} style={{ margin: "0 0 3rem 0", display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <img
            src="/icons/New-Polymerize-logo-192x192@2x.png"
            alt="Polymerize Logo"
            height={"48"}
            width={"48"}
          />{" "}
          <span>{t('common.polymerize')}</span>
        </Title>
      </a>
      {currentPlatform === "connect" ?
        <h1 style={{ maxWidth: "570px", textAlign: "left", fontWeight: 500 }}>
          {t("leftSideOnboarding.sentence1")}{" "}
          <a
            target="_blank"
            href="https://polymerize.io/connect/"
            rel="noopener noreferrer"
          >
            <span style={{ color: "#F39244", fontWeight: "bold" }}>
              {t('common.polymerizeConnect')}
            </span>
          </a>
        </h1>
        :
        <h1 style={{ maxWidth: "570px", textAlign: "left", fontWeight: 500 }}>
          {t('leftSideOnboarding.sentence2')}{" "}
          <a
            target="_blank"
            href={currentPlatform && platformInfo[currentPlatform].link}
            rel="noopener noreferrer"
          >
            <span style={{ color: "#F39244", fontWeight: "bold" }}>
              {t('common.polymerizeLabs')}
            </span>
          </a>
          {t('leftSideOnboarding.sentence3')}
        </h1>
      }
      <Divider
        orientation="left"
        style={{ width: "3rem", borderWidth: "2px", margin: 0 }}
      />
      <h1
        style={{
          maxWidth: "560px",
          textAlign: "left",
          color: "#1753E2",
          fontWeight: 500,
        }}
      >
        {t('leftSideOnboarding.sentence4')}{" "}
        <strong style={{ fontWeight: 600 }}>
          {t('leftSideOnboarding.sentence5')}{" "}
        </strong>
      </h1>
      <Row style={{ gap: currentPlatform === "labs" ? "1.5em" : "4em", minWidth: currentPlatform === "labs" ? "46vw" : "auto", maxWidth }}>
        {currentPlatform === "connect" ?
          <ConnectLoginInfoText style={{ marginTop: "3em" }} />
          :
          <div className="labs__feature__info__contaiener">
            <ul className="labs__feature__info__points">
              <li className="labs__feature__info__point">
                <h3 className="labs__feature__info__point__text">{t('leftSideOnboarding.feature1')}</h3>
              </li>
              <li className="labs__feature__info__point">
                <h3 className="labs__feature__info__point__text">{t('leftSideOnboarding.feature2')}</h3>
              </li>
              <li className="labs__feature__info__point">
                <h3 className="labs__feature__info__point__text">{t('leftSideOnboarding.feature3')}</h3>
              </li>
              <li className="labs__feature__info__point">
                <h3 className="labs__feature__info__point__text">{t('leftSideOnboarding.feature4')}</h3>
              </li>
              <li className="labs__feature__info__point">
                <h3 className="labs__feature__info__point__text">{t('leftSideOnboarding.feature5')}</h3>
              </li>
            </ul>
            <div className="certification__container">
              <img src={LabsSecurityCertificaitonSvg} alt="Polymerize Labs Security Certificates" />
            </div>
          </div>
        }
        <ConnectLoginImg className="leftSideOnboardingIllustration" />
      </Row>
      <Footer />
    </Space>
  );
};
export default LeftSideOnboarding;
