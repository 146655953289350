import { Modal, Row, Space, Steps, Table, Tabs, Typography } from "antd"
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate"
import { ShowNewParametersOrArchiveFoundStateTypes } from "./SelectExperiments"
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { ExceptionOutlined, PlusOutlined } from "@ant-design/icons"
import { ArchivedParametersContent } from "src/components/Common/ArchivedParametersFoundModal"
import { updateArchivedParametersRequest } from "src/store/actions/common"
import { AsyncStates } from "src/constants"
import { addRemoveIngredientsKeyToWorkOrderRequest } from "src/store/actions/workOrderDetails"


const { TabPane } = Tabs
const { Text } = Typography


type NewParametersOrArchiveFoundModalPropsType = {
    newParameters: { [key: string]: any[] },
    showNewParametersOrArchiveFoundModal: ShowNewParametersOrArchiveFoundStateTypes,
    setShowNewParametersOrArchiveFoundModal: Dispatch<SetStateAction<ShowNewParametersOrArchiveFoundStateTypes>>,
    setSkipPrompt: Dispatch<SetStateAction<boolean>>,
    // setArchivedIngredientsValidationDone: Dispatch<SetStateAction<boolean>>
}


export const NewParametersOrArchiveFoundModal = ({ newParameters, showNewParametersOrArchiveFoundModal, setShowNewParametersOrArchiveFoundModal, setSkipPrompt }: NewParametersOrArchiveFoundModalPropsType) => {
    const dispatch = useDispatch()
    const [t] = useTranslate()

    const foundArchivedParameters = useSelector((state: StoreState) => state.common.foundArchivedParameters)
    const [currentStep, setCurrentStep] = useState(0)
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const workOrder = useSelector((state: StoreState) => state.workOrderDetails.workOrder || {});

    const handleRemoveIngredients = () => {
        if (foundArchivedParameters.type === "work-order-fileupload" && workOrder?.work_order_id === foundArchivedParameters.id) {
            dispatch(addRemoveIngredientsKeyToWorkOrderRequest({
                remove_ingredient_ids: selectedRowKeys
            }))
            dispatch(updateArchivedParametersRequest({ inventory_ids: selectedRowKeys }))
            setSelectedRowKeys([])
        }
    }

    const handleClose = useCallback(() => {
        setSkipPrompt(true)
        setShowNewParametersOrArchiveFoundModal({
            newParametersFound: false,
            archivedParametersFound: false
        })
        setSelectedRowKeys([])
    }, [setShowNewParametersOrArchiveFoundModal, setSkipPrompt])

    useEffect(() => {
        if (!showNewParametersOrArchiveFoundModal.newParametersFound && !foundArchivedParameters.data.length) {
            handleClose()
        }
    }, [foundArchivedParameters.data.length, handleClose, showNewParametersOrArchiveFoundModal.newParametersFound])


    const handleNext = () => {
        if (showNewParametersOrArchiveFoundModal.newParametersFound) {
            setCurrentStep(1)
        }
    }


    const columns = [{
        dataIndex: "name",
        title: t("common.name"),
        key: "name",
    },
    {
        dataIndex: "category",
        title: t("common.category"),
        key: "category",
    }]


    const ArchivedParameterStep = () => {
        return <ArchivedParametersContent selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} handleRemoveIngredients={handleRemoveIngredients} handleNext={handleNext} hideHandleNext={!showNewParametersOrArchiveFoundModal.newParametersFound} />
    }

    const NewParameterFoundStep = () => {
        return <Space direction="vertical" style={{ width: "100%", marginTop: 25 }} size="large">
            <Space direction="vertical" style={{ width: "100%" }} size="small">
                <Text>
                    {t("dataUpload.NewParametersDescription")}
                </Text>
            </Space>
            <Tabs size="large">
                {!!newParameters?.ingredients?.length && (
                    <TabPane
                        tab={`${t("common.formulations")} (${newParameters?.ingredients.length})`}
                        key="formulations"
                        style={{ width: "100%", overflow: "auto" }}
                    >
                        <Table
                            style={{ marginTop: 20 }}
                            columns={columns}
                            dataSource={newParameters.ingredients}
                            bordered
                        // pagination={false}
                        />
                    </TabPane>
                )}
                {!!newParameters?.processing?.length && (
                    <TabPane
                        tab={`${t("common.processing")} (${newParameters?.processing.length})`}
                        key="processing"
                        style={{ width: "100%", overflow: "auto" }}
                    >
                        <Table
                            style={{ marginTop: 20 }}
                            columns={columns}
                            dataSource={newParameters.processing}
                            bordered
                        // pagination={false}
                        />
                    </TabPane>
                )}
                {!!newParameters?.characterisation?.length && (
                    <TabPane
                        tab={`${t("common.characterisation")} (${newParameters?.characterisation.length})`}
                        key="characterisation"
                        style={{ width: "100%", overflow: "auto" }}
                    >
                        <Table
                            style={{ marginTop: 20 }}
                            columns={columns}
                            dataSource={newParameters.characterisation}
                            bordered
                        // pagination={false}
                        />
                    </TabPane>
                )}
                {!!newParameters?.properties?.length && (
                    <TabPane
                        tab={`${t("formulations.type.properties")} (${newParameters?.properties.length})`}
                        key="properties"
                        style={{ width: "100%", overflow: "auto" }}
                    >
                        <Table
                            style={{ marginTop: 20 }}
                            columns={columns}
                            dataSource={newParameters.properties}
                            bordered
                        // pagination={false}
                        />
                    </TabPane>
                )}
            </Tabs>
            <Row justify={"end"} style={{ marginTop: 20 }}>
                <StyledButton onClick={() => {
                    handleClose()
                }} type="primary">{t("dataUpload.NewParametersIgnore")}</StyledButton>
            </Row>
        </Space>
    }

    const getCurrentStepContent = () => {
        if (showNewParametersOrArchiveFoundModal.newParametersFound && showNewParametersOrArchiveFoundModal.archivedParametersFound) {
            if (currentStep === 0) {
                return <ArchivedParameterStep />
            }
            if (currentStep === 1) {
                return <NewParameterFoundStep />
            }
        } else if (showNewParametersOrArchiveFoundModal.archivedParametersFound) {
            return <ArchivedParameterStep />
        } else if (showNewParametersOrArchiveFoundModal.newParametersFound) {
            return <NewParameterFoundStep />
        }
    }


    return (
        <Modal
            open={showNewParametersOrArchiveFoundModal.newParametersFound || showNewParametersOrArchiveFoundModal.archivedParametersFound}
            onCancel={() => {
                setSelectedRowKeys([])
                setShowNewParametersOrArchiveFoundModal({
                    newParametersFound: false,
                    archivedParametersFound: false
                })
            }
            }
            width={1000}
            maskClosable={false}
            title={
                <div
                    style={{
                        width: "75%"
                    }}>
                    <Steps
                        current={currentStep}
                        onChange={(curr) => {
                            setCurrentStep(curr);
                        }}
                        items={[
                            ...(showNewParametersOrArchiveFoundModal.archivedParametersFound ? [{
                                title: `Found Archived Parameter`,
                                icon: <ExceptionOutlined />,
                                disabled: !foundArchivedParameters.data.length && foundArchivedParameters.status === AsyncStates.SUCCESS
                            }] : []),
                            ...(showNewParametersOrArchiveFoundModal.newParametersFound ? [{
                                title: t("dataUpload.NewParametersTitle"),
                                icon: <PlusOutlined />,
                                disabled: !!foundArchivedParameters.data.length && foundArchivedParameters.status === AsyncStates.SUCCESS
                            }] : []),
                        ]}
                    />
                </div>
            }
            footer={null}
        >
            <div>
                <div>{getCurrentStepContent()}</div>
            </div>
        </Modal>
    )
}
