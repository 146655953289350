import { Card, Checkbox, Col, Collapse, DatePicker, Form, Input, message, Row, Space, Spin } from "antd"
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font, Image, pdf } from '@react-pdf/renderer';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { antdTheme, AsyncStates } from "src/constants";
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { StyledButton } from "src/styled_components/StyledButton";
import { reportUploadRequest } from "src/store/actions/compareFormulations";
import { blue } from "@ant-design/colors";

dayjs.extend(isSameOrAfter)

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf' }
    ]
})
Font.register({
    family: 'Helvetica-Bold',
    src: 'https://fonts.gstatic.com/s/helveticaneue/v2/HelveticaNeueBold.ttf',
});

const PAYLOAD = [
    { label: 'Full payload', value: 'full_payload' },
    { label: 'Half payload', value: 'half_payload' },
    { label: 'Min payload', value: 'min_payload' },
]

const AMBIENT_LANES = [
    { label: 'ISTA 7D summer profile (average 30 °C)', value: 'ista_30' },
    { label: 'Constant 30 °C', value: 'constant_30' },
    { label: 'Constant 15 °C', value: 'constant_15' },
    { label: 'ISTA 7D summer profile (average 5 °C)', value: 'ista_5' },
    { label: 'Constant 5 °C', value: 'constant_5' },
    { label: 'Custom Lane', value: 'custom_lane' },
]


const styles = StyleSheet.create({
    page: { padding: 20, fontSize: 12, fontFamily: 'Helvetica' },
    image: { position: 'absolute', top: 2, right: 16, height: 150, width: 150 },
    header: {
        textAlign: "center",
        marginTop: 30,
        marginBottom: 20,
        fontFamily: 'Helvetica-Bold',
        color: blue.primary
    },
    title: {
        fontSize: antdTheme.fontSizeHeading4,
        fontFamily: 'Helvetica-Bold',
        color: blue.primary
    },
    // Table Styling with Borders
    table: { width: '100%', borderWidth: 1, borderColor: 'black', borderStyle: 'solid' },
    row: { flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: 'black', borderStyle: 'solid' },
    cellLeft: {
        width: '50%', padding: 5,
        borderRightWidth: 1, borderRightColor: 'black', borderStyle: 'solid',
        fontFamily: 'Helvetica-Bold'
    },
    cellRight: { width: '50%', padding: 5 },

    // Checkbox Styling with Borders
    checkboxRow: {
        flexDirection: 'row', alignItems: 'center', padding: 5,
        borderBottomWidth: 1, borderBottomColor: 'black', borderStyle: 'solid',
    },
    checkboxBox: {
        width: 10, height: 10,
        borderWidth: 1, borderColor: 'black',
        marginRight: 5, textAlign: 'center', fontSize: 10, fontFamily: 'Helvetica',
    },
    checkboxChecked: {
        color: 'black', backgroundColor: "black", textAlign: 'center', fontSize: 12, fontFamily: 'Helvetica',
    },
    checkboxUnChecked: {
        backgroundColor: "white", color: 'white', textAlign: 'center', fontSize: 10, fontFamily: 'Helvetica',
    },
});


export const NewProductDevelopment = () => {
    const dispatch = useDispatch()
    const reportUploadStatus = useSelector((state: StoreState) => state.compareFormulations.uploadStatus)
    const logo = useSelector((state: StoreState) => state.login.loginResponse.logo)
    const [formState, setFormState] = useState<any>({})
    const [generate, setGenerate] = useState<boolean>(false)
    const [fileName, setFileName] = useState<string>("")
    const [form] = Form.useForm()

    const pdfDocument = (
        <Document title={"New Product Development"} >
            <Page size="A4" style={styles.page} orientation="portrait" key={'page_'} wrap>
                <View style={styles.image} fixed>
                    <Image fixed={true} cache src={logo + "?not-from-cache-please"} />
                </View>
                <View style={styles.header}>
                    <Text style={styles.title}>{formState?.title}</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.row}>
                        <View style={styles.cellLeft}><Text>{"Date"}</Text></View>
                        <View style={styles.cellRight}><Text>{formState?.date ? new Date(formState?.date).toLocaleDateString() : ""}</Text></View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.cellLeft}><Text>{"Target customer/s"}</Text></View>
                        <View style={styles.cellRight}><Text>{formState?.target_customers}</Text></View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.cellLeft}><Text>{"Payload dimensions (mm)"}</Text></View>
                        <View style={styles.cellRight}><Text>{formState?.payload_dimensions}</Text></View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.cellLeft}>
                            <Text>{"Payload loading capacity required for testing"}</Text>
                            <Text style={{ fontFamily: 'Helvetica', fontSize: 10, marginTop: 4 }}>
                                {"(21.5 g water as a dummy payload is used for 1 liter of payload volume as per Celsure® standrad protocol)"}
                            </Text>
                        </View>
                        <View style={{ width: "50%" }}>
                            {PAYLOAD.map(({ label, value }) => (
                                <View style={styles.checkboxRow} key={value}>
                                    <Text style={[styles.checkboxBox, formState?.payload_loading_capacity?.includes(value) ? styles.checkboxChecked : styles.checkboxUnChecked]}>
                                        {formState?.payload_loading_capacity?.includes(value) ? 'x' : ''}
                                    </Text>
                                    <Text>{label}</Text>
                                </View>
                            ))}
                            <View style={{ padding: 5 }}>
                                <View><Text>{"Dummy payload amount:"}</Text></View>
                                <View><Text>{formState?.dummy_payload_amount}</Text></View>
                            </View>
                            <View style={{ padding: 5 }}>
                                <View><Text>{"Payload Description:"}</Text></View>
                                <View><Text>{formState?.payload_description}</Text></View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.cellLeft}><Text>{"Payload initial temperature (°C)"}</Text></View>
                        <View style={styles.cellRight}><Text>{formState?.payload_initial_temperature}</Text></View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.cellLeft}><Text>{"Payload controlled temperature requirement (°C)"}</Text></View>
                        <View style={styles.cellRight}><Text>{formState?.payload_control_temperature}</Text></View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.cellLeft}><Text>{"Type of PCM to be used (if applicable)"}</Text></View>
                        <View style={styles.cellRight}><Text>{formState?.type_of_pcm}</Text></View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.cellLeft}>
                            <Text>{"Ambient lanes"}</Text>
                        </View>
                        <View style={{ width: "50%" }}>
                            {AMBIENT_LANES.map(({ label, value }) => (
                                <View style={styles.checkboxRow} key={value}>
                                    <Text style={[styles.checkboxBox, formState?.ambient_lane?.includes(value) ? styles.checkboxChecked : styles.checkboxUnChecked]}>
                                        {formState?.ambient_lane?.includes(value) ? "x" : ''}
                                    </Text>
                                    <Text>{label}</Text>
                                </View>
                            ))}
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.cellLeft}><Text>{"Payload-controlled temperature backup (hours)"}</Text></View>
                        <View style={styles.cellRight}><Text>{formState?.payload_controlled_temperature_backup}</Text></View>
                    </View>

                </View>
            </Page>
        </Document>
    )


    return (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Row justify={"space-between"}>
                <Col span={16}>
                    <Spin spinning={false}
                        indicator={<LoadingOutlined />}
                    >
                        <Form name="new_product_development" layout="vertical" form={form}
                            onFieldsChange={(_, allFields) => {
                                setFormState(allFields
                                    .reduce((obj: any, field: any) => ({ ...obj, [field.name[0]]: field?.value }), {})
                                )
                            }}>
                            <Form.Item label={"Title"} name={"title"}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={"Date"} name={"date"}>
                                <DatePicker />
                            </Form.Item>
                            <Form.Item label={"Target customer/s"} name={"target_customers"}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={"Payload dimensions (mm)"} name={"payload_dimensions"}>
                                <Input />
                            </Form.Item>
                            <Card type="inner" title="Payload loading capacity required for testing"
                                style={{ marginBottom: 20, width: "100%" }}>
                                <Form.Item name={"payload_loading_capacity"}>
                                    <Checkbox.Group options={PAYLOAD} />
                                </Form.Item>
                                <Form.Item label={"Dummy payload amount"} name={"dummy_payload_amount"}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label={"Payload Description"} name={"payload_description"}>
                                    <Input />
                                </Form.Item>
                            </Card>
                            <Form.Item label={"Payload initial temperature (°C)"} name={"payload_initial_temperature"}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={"Payload control temperature requirement (°C)"} name={"payload_control_temperature"}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={"Type of PCM to be used (if applicable)"} name={"type_of_pcm"}>
                                <Input />
                            </Form.Item>
                            <Card type="inner" title="Ambient lanes" style={{ marginBottom: 20, width: "100%" }}>
                                <Form.Item name={"ambient_lane"}>
                                    <Checkbox.Group options={AMBIENT_LANES} />
                                </Form.Item>
                            </Card>
                            <Form.Item label={"Payload-controlled temperature backup (hours)"} name={"payload_controlled_temperature_backup"}>
                                <Input />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Col>
            </Row>
            <Collapse onChange={(e) => setGenerate(Boolean(e?.length))}>
                <Collapse.Panel header={"Preview"} key={"preview"} extra={
                    <Space onClick={(e) => e.stopPropagation()}>
                        <Input style={{ width: 250 }} value={fileName} onChange={e => setFileName(e.target.value)} />
                        <StyledButton loading={reportUploadStatus === AsyncStates.LOADING} onClick={() => {
                            if (!fileName) {
                                message.error("Please add a file name")
                                return
                            }
                            pdf(pdfDocument).toBlob().then((report) => {
                                dispatch(reportUploadRequest({
                                    report,
                                    type: "new_product_development",
                                    file_name: fileName,
                                    data: formState,
                                    from: "modularReports"
                                }))
                            })
                        }} type="primary">{"Save"}</StyledButton>
                    </Space>
                }>
                    {generate &&
                        <PDFViewer style={{ width: "100%", height: 1280 }}>
                            {pdfDocument}
                        </PDFViewer>
                    }
                </Collapse.Panel>
            </Collapse>
        </Space>
    )
}