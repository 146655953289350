import { useEffect, useState } from 'react'
import AddDetails from './AddDetails'
import InputCriteria from './InputCriteria'
import MapMetadataTitles from './MapMetadataTitles'
import MapTestTitles from './MapTestTitles'
import ImportDataModal from './ImportDataModal'
import { clearMetadataErrors, stepReverseMapping } from '../MetadataDrawer'
import ReportMapping from './ReportMapping'
import { StoreState } from 'src/store/configureStore'
import { useDispatch, useSelector } from 'react-redux'
import { qcUpdateMetadataRequest } from 'src/store/actions/qualityCheck'

const StepContent = ({ step }: { step: number }) => {
  const dispatch = useDispatch()
  const [importDataModalVisible, setImportDataModalVisible] = useState<boolean>(false)

  const { getMetadataResponse } = useSelector((state: StoreState) => state.qualityCheck)

  useEffect(() => {
    if (importDataModalVisible) {
      const updatedMetadata = clearMetadataErrors(getMetadataResponse)
      dispatch(
        qcUpdateMetadataRequest({
          profile_id: getMetadataResponse?.profile_id,
          ...updatedMetadata,
          is_draft: true
        })
      )
    }
    // Updating only once the modal is opened
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, importDataModalVisible])

  const renderSteps = () => {
    if (step === 0) {
      return <AddDetails />
    }
    if (step === 1) {
      return (
        <MapMetadataTitles
          step={step}
          openImportModal={() => {
            setImportDataModalVisible(true)
          }}
        />
      )
    }
    if (step === 2) {
      return (
        <MapTestTitles
          step={step}
          openImportModal={() => {
            setImportDataModalVisible(true)
          }}
        />
      )
    }
    if (step === 3) {
      return (
        <InputCriteria
          step={step}
          openImportModal={() => {
            setImportDataModalVisible(true)
          }}
        />
      )
    }

    if (step === 4) {
      return <ReportMapping step={step} />
    }
    return null
  }
  return (
    <>
      {renderSteps()}
      {step > 0 && step < 4 && (
        <ImportDataModal step={stepReverseMapping[step]} isOpen={importDataModalVisible} setIsOpen={setImportDataModalVisible} />
      )}
    </>
  )
}

export default StepContent
