import { Space } from 'antd'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { qcMetadataUpdate } from 'src/store/actions/qualityCheck';
import { StoreState } from 'src/store/configureStore'
import StepTitle from './StepTitle'
import ReportMappingTable from './ReportMappingTable';
import { StyledButton } from 'src/styled_components/StyledButton';
import useTranslate from 'src/utils/useTranslate';
import { ContainerOutlined } from '@ant-design/icons';
import Fuse from 'fuse.js'
import { AsyncStates } from 'src/constants';

const ReportMapping = ({ step }: { step: number; }) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const { getMetadataResponse, getNameDetailsResponse, getNameDetailsStatus } = useSelector((state: StoreState) => state.qualityCheck)

  const [reportMapping, setReportMapping] = useState<any[]>([...(getMetadataResponse?.input_criteria || [])]) 

  // Updating criteria
  useEffect(() => {
    dispatch(qcMetadataUpdate({ input_criteria: reportMapping }))
  }, [dispatch, reportMapping])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '90%',
        width: '100%',
        flexGrow: 1
      }}
    >
      {/* Action bar */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0.75rem 1.5rem',
          paddingTop: '1.5rem',
          borderBottom: '1px solid #f0f0f0'
        }}
      >
        <Space>
          <StepTitle step={step} />
        </Space>
        <StyledButton
          size="small"
          style={{
            fontSize: '0.75rem',
            fontWeight: 500
          }}
          icon={<ContainerOutlined />}
          loading={getNameDetailsStatus === AsyncStates.LOADING}
          onClick={()=>{
            // Auto populate logic
            const fuse = new Fuse((getNameDetailsResponse?.report_mapping || []).map((item: any) => ({
              label: item.name,
              value: item.detail_id,
            })), {
              keys: ['label'],
              threshold: 0.2
            })

            setReportMapping((prev: any) => {
              const newState = [...prev]

              newState.forEach((item: any) => {
                if (!item.mapping) {
                  const result = fuse.search(item.test)

                  if (result.length) {
                    item.mapping = (result[0]?.item as {label:string, value:string})?.value
                  }
                }
              })

              return newState
            })
          }}
        >
          {t('common.autoPopulate')}
        </StyledButton>
      </div>

      {/* Table/Mapper */}
      <div
        style={{
          height: 'calc(100% - 4rem)',
          width: '100%',
          padding: '1.5rem 2rem'
        }}
      >
        <ReportMappingTable
          data={reportMapping}
          setData={setReportMapping}
        />
      </div>
    </div>
  )
}

export default ReportMapping
