import {
  ArrowLeftOutlined,
  LoadingOutlined,
  MenuOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import {
  Space,
  Tabs,
  Tooltip,
  Avatar,
  Typography,
  Spin,
  Statistic,
  Radio,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { StoreState } from "src/store/configureStore";
import { useCallback, useEffect, useState } from "react";
import { blue } from "@ant-design/colors";
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";
import { Overview } from "./Overview";
import { DropdownMenu } from "./DropdDownMenu/DropDownMenu";
import { antdTheme, AsyncStates, permissions, projectType } from "src/constants";
import { useMemberName } from "src/utils/useMemberName";
import { workOrdersFilterOptionsRequest, workOrdersRequest } from "src/store/actions/workOrders";
// import { AllClosedWorkOrders } from "../WorkOrders/AllClosedWorkOrders";
import { projectOverviewRequest, updateProjectRequest } from "src/store/actions/projects";
import { WorkOrdersPage } from "../WorkOrders/WorkOrdersPage";
import { usePermission } from "src/utils/usePermissions";
import useTranslate from "src/utils/useTranslate";
import { FileUploads } from "../FileUploads";
import { StyledButton } from "src/styled_components/StyledButton";
import { resetWorkOrder } from "src/store/actions/workOrderDetails";
import "./project.scss"
import { WorkOrdersPage as CelsureWorkOrders } from "src/components/Celsure/WorkOrders/WorkOrdersPage";
import { ProjectModal } from "./ProjectModal/ProjectModal";
import { parseSearchQuery } from "src/utils/general";
import { Reports } from "../Reports/Reports";

enum Layout {
  vertical,
  horizontal,
}

const { Title } = Typography;
const { TabPane } = Tabs;

export const Project = () => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { id }: { id: string } = useParams();
  const { getName } = useMemberName();
  // const workOrders = useSelector((state: StoreState) => state.workOrders.data);
  const project_type = useSelector(
    (state: StoreState) => state.login.loginResponse.project_type
  );
  const currentProject = useSelector((state: StoreState) =>
    state.projects.projectList.find((project) => project.project_id === id)
  );
  const configs = useSelector((state: StoreState) => state.configs.features)
  const updateProjectStatus = useSelector(
    (state: StoreState) => state.projects.updateProjectStatus
  );
  const [tab, setTab] = useState("overview");
  const [layout, setLayout] = useState(Layout.vertical);
  const [tabKey, setTabKey] = useState<"open" | "closed">("open")
  const userAccess = usePermission(id);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [woFilters, setWoFilters] = useState({});
  const [tabFilter, setTabFilter] = useState({});
  const [sortBy, setSortBy] = useState({});

  useEffect(() => window.scrollTo(0, 0), []);

  // useEffect(() => {
  //   dispatch(workOrdersRequest({ project_id: id }));
  // }, [id, dispatch]);

  useEffect(() => {
    dispatch(workOrdersFilterOptionsRequest({ project_ids: [id] }));
    dispatch(projectOverviewRequest({ project_id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    const payload: Record<string, any> = { project_id: id, page_num: pageNumber, page_size: pageSize };
    if (Object.keys(woFilters || {}).length > 0 || Object.keys(tabFilter || {}).length > 0) payload.filters = { ...(woFilters || {}), ...(tabFilter || {}) }
    if (Object.keys(sortBy || {}).length > 0) payload.sort = { ...sortBy }
    dispatch(workOrdersRequest(payload));
  }, [dispatch, id, pageNumber, pageSize, tabFilter, woFilters, sortBy]);

  const setWorkOrderFilters = useCallback((filter) => {
    if (Object.keys(filter || {}).length > 0) {
      setPageNumber(1);
    }
    setWoFilters(filter)
  }, []);

  const setTabFilters = useCallback((filter) => {
    if (Object.keys(filter || {}).length > 0) {
      setPageNumber(1);
    }
    setTabFilter(filter)
  }, []);

  useEffect(() => {
    const query = window.location.search.split("?")[1];
    const searchParams = parseSearchQuery<{
      closed: string;
    }>(query);
    setTabKey(searchParams.closed === "true" ? "closed" : "open");
  }, []);

  const [showAll, setShowAll] = useState<boolean>(true)

  const getComponentsFromTabs = () => {
    switch (tab) {
      case "overview":
        return <Overview currentProject={currentProject} />;
      case "active_work_orders":
        return project_type === projectType.celsure ? (
          <CelsureWorkOrders />
        ) : (
          <WorkOrdersPage
            layout={layout}
            tabKey={tabKey}
            setTabKey={setTabKey}
            projects={true}
            showAll={showAll}
            setShowAll={setShowAll}
            projectIds={[id]}
            showPagination={true}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            woFilters={woFilters}
            setWorkOrderFilters={setWorkOrderFilters}
            tabFilter={tabFilter}
            setTabFilters={setTabFilters}
            setSortBy={setSortBy}
          />
        );
      // case "closed_work_orders":
      //   return <AllClosedWorkOrders workOrders={workOrders} />;
      case "file_uploads":
        return <FileUploads project={true} />;
      // case "data_summary":
      //   return <DataSummary />;
      case "reports":
        return <Reports currentProject={currentProject} />;
    }
  };

  const newOrder = () => {
    dispatch(resetWorkOrder());
    push("/work-orders/create-workorder");
  };

  useEffect(() => {
    if (updateProjectStatus === AsyncStates.SUCCESS) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1);
    }
  }, [updateProjectStatus]);

  return (
    <>
      <Spin
        spinning={updateProjectStatus === AsyncStates.LOADING}
        indicator={<LoadingOutlined />}
      >
        <Space
          direction="vertical"
          size="large"
          style={{ width: "100%" }}
          className="project-details-index"
        >
          <StyledPageHeader
            className="project-details-header"
            ghost={false}
            title={
              <div
                style={{ display: "flex", gap: "1rem", alignItems: "baseline" }}
              >
                <h4
                  style={{ marginTop: 5, cursor: "pointer" }}
                  onClick={() => push("/projects")}
                >
                  <ArrowLeftOutlined />
                </h4>
                <div className="project-details-header-container">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.75rem",
                    }}
                  >
                    <Tooltip
                      style={{ marginTop: 0 }}
                      placement="top"
                      title={currentProject?.name}
                    >
                      <Title
                        ellipsis={{ tooltip: currentProject.name }}
                        style={{ margin: 0, maxWidth: "400px" }}
                        level={3}
                      >
                        {currentProject.name}
                      </Title>
                    </Tooltip>
                    <Space>
                      <Tooltip
                        title={
                          userAccess?.permission !== permissions.viewer
                            ? `Click to  ${!currentProject?.favourite ? "pin" : "unpin"
                            } this project`
                            : ""
                        }
                      >
                        <PushpinOutlined
                          disabled={
                            userAccess?.permission === permissions.viewer
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            if (userAccess?.permission !== permissions.viewer) {
                              dispatch(
                                updateProjectRequest({
                                  project_id: currentProject.project_id,
                                  ...currentProject,
                                  favourite: !currentProject?.favourite,
                                })
                              );
                            }
                          }}
                          style={{
                            fontSize: antdTheme.fontSizeHeading3,
                            color: currentProject?.favourite ? blue[4] : "",
                            cursor:
                              userAccess?.permission !== permissions.viewer
                                ? "pointer"
                                : "auto",
                          }}
                        />
                      </Tooltip>
                    </Space>
                    <DropdownMenu id={id} type={"project_status"} />
                  </div>

                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    <DropdownMenu id={id} type={"project_details"} />
                    <ProjectModal
                      type="archived"
                      currentProject={currentProject}
                      disabled={
                        userAccess?.permission !== permissions.projectAdmin
                      }
                    />
                  </div>
                </div>
              </div>
            }
            extra={
              <Space size={"large"}>
                <Statistic
                  title={t("common.createdBy")}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  value={currentProject?.created_by}
                  formatter={(value: any) => (
                    <Avatar.Group maxCount={4} size="large">
                      <Tooltip title={getName(value)} placement="top">
                        <Avatar style={{ background: blue[1], color: blue[5] }}>
                          {getName(value)?.[0]}
                        </Avatar>
                      </Tooltip>
                    </Avatar.Group>
                  )}
                />
                {!!currentProject?.members?.length && (
                  <Statistic
                    title={t("common.members")}
                    value={currentProject?.members}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    formatter={(value: any) => (
                      <Avatar.Group
                        maxCount={2}
                        size="large"
                        maxStyle={{
                          color: "#f56a00",
                          backgroundColor: "#fde3cf",
                        }}
                      >
                        {value?.map((member: any) => (
                          <Tooltip
                            title={getName(member?.user_id)}
                            placement="top"
                          >
                            <Avatar
                              style={{ background: blue[1], color: blue[5] }}
                            >
                              {getName(member?.user_id)?.[0]}
                            </Avatar>
                          </Tooltip>
                        ))}
                      </Avatar.Group>
                    )}
                  />
                )}
              </Space>
            }
          >
            <>
              <Tabs
                size="large"
                tabBarExtraContent={
                  tab === "active_work_orders" && (
                    <Space>
                      <Radio.Group
                        onChange={(e) => setLayout(e.target.value)}
                        defaultValue={layout}
                      >
                        <Radio.Button value={Layout.horizontal}>
                          <MenuOutlined rotate={90} />
                        </Radio.Button>
                        <Radio.Button value={Layout.vertical}>
                          <MenuOutlined />
                        </Radio.Button>
                      </Radio.Group>
                      <StyledButton
                        type="primary"
                        size="middle"
                        onClick={newOrder}
                      >
                        {t("workOrders.button.newWorkOrder")}
                      </StyledButton>
                    </Space>
                  )
                }
                defaultActiveKey="overview"
                onChange={setTab}
              >
                <TabPane
                  key={"overview"}
                  tab={t("projects.overview")}
                ></TabPane>
                <TabPane
                  key={"active_work_orders"}
                  tab={t("common.workOrders")}
                ></TabPane>
                {/* {project_type !== projectType.celsure && (
                  <TabPane
                    key={"closed_work_orders"}
                    tab={t("stages.closedWorkOrders")}
                  ></TabPane>
                )} */}
                <TabPane
                  key={"file_uploads"}
                  tab={t("project.attachments")}
                ></TabPane>
                {(Boolean(configs?.pcm_graphs) || Boolean(configs?.polymers_graphs)) && (
                  <TabPane
                    key={"reports"}
                    tab={"Reports"}
                  ></TabPane>
                )}
              </Tabs>
            </>
          </StyledPageHeader>
          {getComponentsFromTabs()}
        </Space>
      </Spin>
    </>
  );
};
