import { Card, Checkbox, Col, Collapse, Form, Row, Select, Space, Tag, Tooltip, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { getCustomModelDataClear } from 'src/store/actions/modelAnalysis'
import { AsyncStates } from 'src/constants'
import { ExplainableAIResult } from './ExplainableAIResult'
import useTranslate from 'src/utils/useTranslate'
import { getExplainabilityClear, getExplainabilityRequest } from 'src/store/actions/explainableAI'
import { ExplainableAIViewData } from './ExplainableAIViewData'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'

type Props = {
  propertyData: any[],
  version: number,
  currentSelectedStage: number
}

const { Title, Text } = Typography
const { Panel } = Collapse;
const { Option } = Select

export const ExplainableAI = ({ propertyData, version, currentSelectedStage }: Props) => {
  const [t] = useTranslate()
  const requiredFieldStar = useRequiredFieldStar()

  const dispatch = useDispatch()
  const { customModelDataFormulations, modelInfo, modelData } = useSelector((state: StoreState) => state.modelAnalysis)
  const { explainableAIData, getExplainabiltyStatus } = useSelector((state: StoreState) => state.explainableAI)
  const [chartOptions] = useState(
    [
      {
        label: t("chartType.Bar"), value: "bar"
      },
      {
        label: t("chartType.beeswarm"), value: "beeswarm"
      },
      {
        label: t("chartType.waterfall"), value: "waterfall"
      },
      // {
      //   label: "Dependence Chart", value: "dependence"
      // },
      // {
      //   label: "Interaction Chart", value: "interaction"
      // }
    ]
  )

  const experimentOptions = useMemo(() => Object.keys(customModelDataFormulations).map((fid: any) => ({
    key: fid,
    value: fid,
    label: customModelDataFormulations[fid]
  })), [customModelDataFormulations])

  const [explainableAIFormData, setExplainableAIFormData] = useState({
    selectedExperiments: [],
    selectedProperties: [],
    selectedChartType: undefined,
    formUpdated: false,
    currentChartType: ""
  })

  const [explainableAIForm] = useForm()

  useEffect(() => {
    setExplainableAIFormData({
      selectedExperiments: [],
      selectedProperties: [],
      selectedChartType: undefined,
      formUpdated: false,
      currentChartType: ""
    })
    dispatch(getExplainabilityClear())
    explainableAIForm.resetFields()
  }, [currentSelectedStage, dispatch, explainableAIForm])


  const displayNames = useSelector((state: StoreState) => state.displayNames.data)

  useEffect(() => {
    return () => {
      dispatch(getExplainabilityClear())
      dispatch(getCustomModelDataClear())
    }
  }, [dispatch])

  const onPropertyChange = (value: any) => {
    setExplainableAIFormData((prev: any) => {
      return {
        ...prev,
        selectedProperties: value,
        selectedExperiments: [],
        formUpdated: true
      }
    })
    explainableAIForm.setFieldsValue({ selectExperiments: [] })
  }

  const handleExperimentsChange = (values: string[]) => {
    setExplainableAIFormData((prev: any) => {
      return {
        ...prev,
        selectedExperiments: values,
        formUpdated: true,
      }
    })
  }

  const handleSelectChartType = (value: string) => {
    setExplainableAIFormData((prev: any) => {
      return {
        ...prev,
        formUpdated: true,
        selectedChartType: value,
        selectedExperiments: [],
      }
    })
  }

  const handleSubmit = (values: any) => {
    const payload = { model_types: explainableAIFormData.selectedProperties, chart_type: explainableAIFormData.selectedChartType, formulation_ids: explainableAIFormData.selectedExperiments, version, stage_version: modelData?.stages_meta?.[`Stage ${currentSelectedStage}`]?.version }
    dispatch(getExplainabilityRequest(payload))
    setExplainableAIFormData((prev: any) => {
      return {
        ...prev,
        formUpdated: false,
        currentChartType: explainableAIFormData.selectedChartType,
      }
    })
  }

  return (
    <Card
      title={
        <Space direction="vertical">
          <Title level={4}>{t("models.explainableAi")}</Title>
        </Space>
      }
    >
      <>
        <div style={{ marginBottom: "1rem" }}>
          <Collapse accordion>
            <Panel
              header={<Text strong>{t("models.viewData")}</Text>}
              key="1"
            >
              <ExplainableAIViewData selectedFormulations={[]} version={version} currentSelectedStage={currentSelectedStage} />
            </Panel>
          </Collapse>
        </div>

        <Form
          form={explainableAIForm}
          onFinish={handleSubmit}
          style={{ marginBottom: "1rem" }}
          requiredMark={false}
        >
          <Row gutter={24}>

            <Col span={7}>
              <Form.Item label={t("common.property")} >
                <Select
                  placeholder={t("aiEngine.modelAnalysis.selectProperty")}
                  showSearch
                  value={explainableAIFormData.selectedProperties}
                  mode="multiple"
                  optionFilterProp={"children"}
                  onChange={onPropertyChange}
                  dropdownRender={(menu) => {
                    return (
                      <div>
                        {propertyData.length > 0 && (
                          <Checkbox
                            style={{ padding: 10 }}
                            checked={
                              propertyData.length ===
                              explainableAIFormData.selectedProperties.length
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                setExplainableAIFormData((prev: any) => {
                                  return {
                                    ...prev,
                                    selectedProperties: propertyData.map(
                                      (prop) => prop.property
                                    ),
                                    selectedExperiments: [],
                                    formUpdated: true,
                                  };
                                });
                              } else {
                                setExplainableAIFormData((prev: any) => {
                                  return {
                                    ...prev,
                                    selectedProperties: [],
                                    selectedExperiments: [],
                                    formUpdated: true,
                                  };
                                });
                              }
                            }}
                          >{`${t("common.selectAll")} ${t("common.properties")}`}</Checkbox>
                        )}
                        {menu}
                      </div>
                    );
                  }}
                  dropdownStyle={{ minWidth: 200, maxWidth: 400 }}
                >
                  {propertyData.map((prop: any) => 
                    {
                      const isClassifier = prop.property_type === "CLASSIFIER"
                      const nameOfProperty = displayNames.properties?.[prop.property]?.name ?? prop.property
                      return (<Option value={prop.property} key={prop.property}>
                      <div style={{ display: "flex", alignItems: "center", gap: '1rem' }}>
                        <Typography.Text ellipsis={{tooltip:nameOfProperty}}>
                          {nameOfProperty}
                        </Typography.Text>
                        {isClassifier && <Tag color="blue">{prop.property_type}</Tag>}
                      </div>
                    </Option>)}
                  )}
                </Select>
              </Form.Item>
            </Col>

            <Col span={7}>
              <Form.Item
                name={"chart_type"}
                rules={[
                  { required: true, message: t("aiEngine.modelAnalysis.selectChartType") },
                ]}
                label={t("common.addChart")}
                required tooltip={requiredFieldStar}
              >
                <Select
                  placeholder={t("aiEngine.modelAnalysis.selectAChart")}
                  showSearch
                  allowClear
                  value={explainableAIFormData.selectedChartType}
                  defaultValue={explainableAIFormData.selectedChartType}
                  optionFilterProp={"children"}
                  onChange={(value: string) => handleSelectChartType(value)}
                >
                  {chartOptions.map((chart: any) => (
                    <Option value={chart.value}>{chart.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={7}>
              <Form.Item
                name='selectExperiments'
                label={t("common.experiments")}
              >
                <Select
                  mode="multiple"
                  disabled={
                    explainableAIFormData.selectedChartType !== "waterfall"
                  }
                  placeholder={t("dataMapper.selectExperiments")}
                  optionFilterProp="children"
                  value={explainableAIFormData.selectedExperiments}
                  notFoundContent={<Text>{t("common.notFound")}</Text>}
                  allowClear
                  onChange={handleExperimentsChange}
                >
                  {experimentOptions.map((experiment: any) => (
                    <Option key={experiment.key} value={experiment.key}>
                      {experiment.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Tooltip
                title={
                  !explainableAIFormData.selectedProperties.length
                    ? t("aiEngine.modelAnalysis.selectOneProperty")
                    : explainableAIFormData.selectedChartType === "waterfall" &&
                      explainableAIFormData.selectedExperiments.length === 0
                      ? t("aiEngine.modelAnalysis.Selectupto4experiements")
                      : explainableAIFormData.selectedExperiments.length > 4
                        ? t("aiEngine.modelAnalysis.Selectupto4experiements")
                        : modelInfo.xai_status === "in_progress" ? "Explainable AI running" : t("aiEngine.modelAnalysis.getExplainability")
                } 
              >
                <Form.Item>
                  <StyledButton
                    type="primary"
                    htmlType="submit"
                    loading={getExplainabiltyStatus === AsyncStates.LOADING}
                    disabled={
                      !explainableAIFormData.selectedProperties.length ||
                      explainableAIFormData.selectedExperiments.length > 4 ||
                      modelInfo.xai_status === "in_progress" ||
                      (explainableAIFormData.selectedChartType ===
                        "waterfall" &&
                        explainableAIFormData.selectedExperiments.length === 0)
                    }
                  >
                    {t("common.submit")}
                  </StyledButton>
                </Form.Item>
              </Tooltip>
            </Col>

          </Row>
        </Form>

        {explainableAIFormData.formUpdated &&
          getExplainabiltyStatus === AsyncStates.SUCCESS && (
            <Space>
              <Text type="warning">
                {" "}{t("aiEngine.modelAnalysis.submitForExplaination")}
              </Text>
            </Space>
          )}

        {getExplainabiltyStatus === AsyncStates.SUCCESS &&
          Object.keys(explainableAIData || {}).length > 0 && (
            <div
              id="explainbleAIResult"
              style={{ display: "flex", flexDirection: "column" }}
            >
              {Object.keys(explainableAIData || {}).map(
                (property, index) => (
                  <ExplainableAIResult
                    index={index}
                    property={property}
                    property_type={propertyData.find(
                      (prop) => prop.property === property
                    )?.property_type}
                    version={version}
                    currentChartType={
                      explainableAIFormData.currentChartType
                    }
                    currentSelectedStage={currentSelectedStage}
                  />
                )
              )}
            </div>
          )}
      </>
    </Card>
  );
}
