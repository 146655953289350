import React, { Suspense } from "react";
import { Link } from "react-router-dom";
// import { DoE } from "src/components/DoE";
import { Result, Space } from "antd";
import { SectionLoader } from "./AppRouter";
import { AccessDenied } from "src/components/AccessDenied";
import { AfterForgotPassword } from "src/components/AfterForgotPassword/AfterForgotPassword";
import { AfterResetPassword } from "src/components/AfterResetPassword/AfterResetPassword";
import { Verify } from "src/components/Verify";
import Login from "src/components/Login/Login";
import SignUp from "src/components/SignUp/SignUp";
import ForgotPassword from "src/components/ForgotPassword/ForgotPassword";
import ResetPassword from "src/components/ResetPassword/ResetPassword";
import Formulation from "src/components/Formulation/Formulation";
import { AddData } from "src/components/Inventory/AddData";
import Profile from "src/components/Profile";
import { AIEngine } from "src/components/AIEngine";
import { WorkOrders } from "src/components/WorkOrders";
import { WorkOrderDetails } from "src/components/WorkOrderDetails";
import { ConnectTeams } from "src/components/ConnectTeams";
import { Teams } from "src/components/Teams";
import { Notifications } from "src/components/Notifications";
import { Error } from "src/components/Error";
import { Feedback } from "src/components/Feedback";
import Settings from "src/components/Settings/Settings";
import { Projects } from "src/components/Projects";
import { Project } from "src/components/Project";
import { ExpHistory } from "src/components/DoE/ExpHistory";
import { ModelDetails } from "src/components/AIEngine/ModelAnalysis/ModelDetails";
import { DataImport } from "src/components/DataManagement/DataMapper/DataImport";
import { DataHistory } from "src/components/DataManagement/DataHistory/DataHistory";
import { AuditLog } from "src/components/AuditLog";
import { CreateProject } from "src/components/Project/CreateProject/CreateProject";
import { Templates } from "src/components/Templates/Templates";
import { UserVerified } from "src/components/UserVerified/UserVerified";
import NewExperimentAlgo from "src/components/DoE/NewExperimentAlgo";
import InventoryPage from "src/components/Inventory/InventoryPage";
import { Procedures } from "src/components/Procedures/Procedures";
import { ChemDraw } from "src/components/WorkOrderDetails/ChemDraw";
import StagesWO from "src/components/WorkOrderDetails/WoStages/Stages";
import Attachments from "src/components/WorkOrderDetails/Attachments/Attachments";
import CreateWo from "src/components/WorkOrderDetails/CreateWo/CreateWO";
import { currentPlatform } from "src/constants";
import { DataAnalytics } from "src/components/Analytics/DataAnalytics/DataAnalytics";
import { PolyGPT } from "src/modules/PolyGPT";
// import { InventoryV2 } from "src/modules/InventoryV2";
import { InventoryDetail } from "src/modules/InventoryV2/InventoryDetail";
import CompareFormulationWrapper from "src/components/Formulation/Compare/Wrapper";
import { Inventory } from "src/components/Inventory/Inventory";
import SmileAnalytics from "src/components/Analytics/SmileAnalytics/SmileAnalytics";
import FamilyPage from "src/modules/InventoryV2/FamilyPage";
import Repository from "src/modules/InventoryV2/Repository/Repository";
import { QualityCheck } from "src/components/QualityCheck";
import NewExperiment from "src/components/DoE/NewExperiments";
import { ExpHistoryDetails } from "src/components/DoE/ExpHistoryDetails";
import SEMAnalysisWrapper from "src/components/Analytics/SEMAnalysis/SEMAnalysisWrapper";
import CompareIngredients from "src/modules/InventoryV2/Compare";
import SEMAnalysisDetailsWrapper from "src/components/Analytics/SEMAnalysis/SEMAnalysisDetailsWrapper";
import { FirstTimeUpdatePassword } from "src/components/FirstTimeUpdatePassword/FirstTimeUpdatePassword";
import MultistageHeatmap from "src/components/Analytics/MultistageHeatmap";
import AssetLibrary from "src/components/AssetLibrary";
import Dashboard from "src/components/DashboardUpdated/Dashboard";
import { ModelAnalysis } from "src/components/AIEngine/ModelAnalysis/ModelAnalysis";
import QualityCheckMetadata from "src/components/QualityCheckMetadata/QualityCheckMetadata";
import useTranslate from "src/utils/useTranslate";
import { Interpolation } from "src/components/Interpolation";

export interface RouteObj {
  path: string;
  render: () => JSX.Element;
  exact: boolean;
  protectedRoute: boolean;
  enable: boolean;
}

export interface RoutesObj {
  [key: string]: RouteObj;
}

// const Login = React.lazy(() => import(/* webpackChunkName: "Login" */  'src/components/Login/Login'))
// const ForgotPassword = React.lazy(() => import(/* webpackChunkName: "ForgotPassword" */  'src/components/ForgotPassword/ForgotPassword'))
// const ResetPassword = React.lazy(() => import(/* webpackChunkName: "ResetPassword" */  'src/components/ResetPassword/ResetPassword'))
// const Dashboard = React.lazy(() => import(/* webpackChunkName: "Dashboard" */  'src/components/Dashboard/Dashboard'))
// const Formulation = React.lazy(() => import(/* webpackChunkName: "Formulation" */  'src/components/Formulation/Formulation'))
// const SignUp = React.lazy(() => import(/* webpackChunkName: "SignUp" */  'src/components/SignUp/SignUp'))
// const Settings = React.lazy(() => import(/* webpackChunkName: "Settings" */  'src/components/Settings/Settings'))
// const Profile = React.lazy(() => import(/* webpackChunkName: "Profile" */  'src/components/Profile'))
// const CompareFormulation = React.lazy(() => import(/* webpackChunkName: "CompareFormulation" */  'src/components/Formulation/Compare'))
// const Teams = React.lazy(() => import(/* webpackChunkName: "Teams" */  'src/components/Teams').then(module => ({ default: module.Teams })))
// const ConnectTeams = React.lazy(() => import(/* webpackChunkName: "ConnectTeams" */  'src/components/ConnectTeams').then(module => ({ default: module.ConnectTeams })))
// const AccessDenied = React.lazy(() => import(/* webpackChunkName: "AccessDenied" */  'src/components/AccessDenied').then(module => ({ default: module.AccessDenied })))
// const Error = React.lazy(() => import(/* webpackChunkName: "Error" */  'src/components/Error').then(module => ({ default: module.Error })))
// const Feedback = React.lazy(() => import(/* webpackChunkName: "Feedback" */  'src/components/Feedback').then(module => ({ default: module.Feedback })))
// const WorkOrderDetails = React.lazy(() => import(/* webpackChunkName: "WorkOrderDetails" */  'src/components/WorkOrderDetails').then(module => ({ default: module.WorkOrderDetails })))
// const Notifications = React.lazy(() => import(/* webpackChunkName: "Notifications" */  'src/components/Notifications').then(module => ({ default: module.Notifications })))
// const Verify = React.lazy(() => import(/* webpackChunkName: "Verify" */  'src/components/Verify').then(module => ({ default: module.Verify })))
// const Projects = React.lazy(() => import(/* webpackChunkName: "Projects" */  'src/components/Projects').then(module => ({ default: module.Projects })))
// const Project = React.lazy(() => import(/* webpackChunkName: "Project" */  'src/components/Project').then(module => ({ default: module.Project })))
// const WorkOrders = React.lazy(() => import(/* webpackChunkName: "WorkOrders" */  'src/components/WorkOrders').then(module => ({ default: module.WorkOrders })))
// const AfterForgotPassword = React.lazy(() => import(/* webpackChunkName: "AfterForgotPassword" */  'src/components/AfterForgotPassword/AfterForgotPassword').then(module => ({ default: module.AfterForgotPassword })))
// const AfterResetPassword = React.lazy(() => import(/* webpackChunkName: "AfterResetPassword" */  'src/components/AfterResetPassword/AfterResetPassword').then(module => ({ default: module.AfterResetPassword })))
// const Inventory = React.lazy(() => import(/* webpackChunkName: "Inventory" */  'src/components/Inventory').then(module => ({ default: module.InventoryPage })))
// const InventoryAddData = React.lazy(() => import(/* webpackChunkName: "Inventory" */  'src/components/Inventory/AddData').then(module => ({ default: module.AddData })))
// const NewExperiments = React.lazy(() => import(/* webpackChunkName: "NewExperiments" */  'src/components/DoE/NewExperiment'))
// const ExpHistory = React.lazy(() => import(/* webpackChunkName: "ExpHistory" */  'src/components/DoE/ExpHistory').then(module => ({ default: module.ExpHistory })))
// const ModelDetails = React.lazy(() => import(/* webpackChunkName: "ModelDetails" */  'src/components/AIEngine/ModelAnalysis/ModelDetails').then(module => ({ default: module.ModelDetails })))
// const DataImport = React.lazy(() => import(/* webpackChunkName: "DataMapper" */  'src/components/DataManagement/DataMapper/DataImport').then(module => ({ default: module.DataImport })))
// const DataHistory = React.lazy(() => import(/* webpackChunkName: "DataMapper" */  'src/components/DataManagement/DataHistory/DataHistory').then(module => ({ default: module.DataHistory })))
// const AuditLog = React.lazy(() => import(/* webpackChunkName: "AuditLog" */  'src/components/AuditLog').then(module => ({ default: module.AuditLog })))
// const CreateProject = React.lazy(() => import(/* webpackChunkName: "Project" */  'src/components/Project/CreateProject/CreateProject').then(module => ({ default: module.CreateProject })))
// const Templates = React.lazy(() => import(/* webpackChunkName: "Templates" */  'src/components/Templates/Templates').then(module => ({ default: module.Templates })))

// const AIEngine = React.lazy(() => import(/* webpackChunkName: "AIEngine" */  'src/components/AIEngine/AIEnginePage').then(module => ({ default: module.AIEnginePage })))
// const UserVerified = React.lazy(() => import(/* webpackChunkName: "UserVerified" */  'src/components/UserVerified/UserVerified').then(module => ({ default: module.UserVerified })))

const NoMatch = () => {
  const [t] = useTranslate()
  return (
    <Result
      style={{ height: "80vh", display: "flex", flexDirection: "column" }}
      status="404"
      title="Work in Progress!"
      subTitle=""
      extra={
        <Link type="primary" to={"/formulations"}>
          {t("backHome")}
        </Link>
      }
    />
  )
};

export const getRoutes = (navConfig: any, companyId: any, sem_flag: any, isDark: boolean, toggleDarkTheme: () => void) => {
  return {
    accessDenied: {
      path: "/access-denied",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <AccessDenied />
        </Suspense>
      ),
      exact: true,
      protectedRoute: false,
      enable: true,
    },
    afterForgotPassword: {
      path: "/after-forgot",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <AfterForgotPassword />
        </Suspense>
      ),
      exact: true,
      protectedRoute: false,
      enable: true,
    },
    afterResetPassword: {
      path: "/after-reset",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <AfterResetPassword />
        </Suspense>
      ),
      exact: true,
      protectedRoute: false,
      enable: true,
    },
    verify: {
      path: "/verify",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <Verify />
        </Suspense>
      ),
      exact: true,
      protectedRoute: false,
      enable: true,
    },
    login: {
      path: "/login",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <Login />
        </Suspense>
      ),
      exact: true,
      protectedRoute: false,
      enable: true,
    },
    signup: {
      path: "/signup",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <SignUp />
        </Suspense>
      ),
      exact: true,
      protectedRoute: false,
      enable: true,
    },
    forgotPassword: {
      path: "/forgot_password",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <ForgotPassword />
        </Suspense>
      ),
      exact: true,
      protectedRoute: false,
      enable: true,
    },
    resetPassword: {
      path: "/reset-password",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <ResetPassword />
        </Suspense>
      ),
      exact: false,
      protectedRoute: false,
      enable: true,
    },
    formulations: {
      path: "/formulations",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <Formulation />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.formulations,
    },
    compareFormulation: {
      path: "/formulations/compare",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <CompareFormulationWrapper />
        </Suspense>
      ),
      exact: false,
      protectedRoute: true,
      enable: navConfig.formulations,
    },
    dashboard: {
      path: "/dashboard",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <Dashboard />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.dashboard,
    },
    inventory: {
      path: "/inventory/library",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <Inventory />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.inventory,
    },
    inventoryV2: {
      path: "/inventory/ingredients",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <InventoryPage />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.inventory,
    },
    inventoryV2Compare: {
      path: "/inventory/ingredients/compare",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <CompareIngredients />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.inventory,
    },
    inventoryV2Detail: {
      path: "/inventory/ingredients/:uuid",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <InventoryDetail />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.inventory,
    },
    inventoryV2Repository: {
      path: "/inventory/repository/*",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <Repository />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.inventory,
    },
    family: {
      path: "/inventory/family/:uuid?",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <FamilyPage />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: false,
      // navConfig.inventory
    },
    inventoryAddData: {
      path: "/inventory/library/update",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <AddData />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.inventory,
    },
    profile: {
      path: "/profile",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <Profile />
        </Suspense>
      ),
      exact: false,
      protectedRoute: true,
      enable: navConfig.profile,
    },
    aiEngine: {
      path: "/ai-engine/:section/:tab/:id?",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <AIEngine />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.ai_engine?.predict,
    },
    aiEngineModalAnalysis: { // Currently hard Coding it, but when in future if requirement comes we can switch to /ai-engine/:section type routing
      path: "/ai-engine/model_analysis",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <ModelAnalysis />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: true,
    },
    doe: {
      path: "/design-of-experiments",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <NoMatch />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: false,
    },
    analytics: {
      path: "/data-analytics",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <DataAnalytics />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: true,
    },
    semAnalysis: {
      path: "/sem-analysis",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <SEMAnalysisWrapper />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: sem_flag,
    },
    semAnalysisType: {
      path: "/sem-analysis/:analysisType",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <SEMAnalysisWrapper />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: sem_flag,
    },
    semAnalysisBatchDetails: {
      path: "/sem-analysis/:analysisType/batch/:batchId",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <SEMAnalysisWrapper />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: sem_flag,
    },
    semAnalysisFileDetails: {
      path: "/sem-analysis/:analysisType/files/:fileId",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <SEMAnalysisDetailsWrapper />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: sem_flag,
    },
    semAnalysisBatchFileDetails: {
      path: "/sem-analysis/:analysisType/batch/:batchId/files/:fileId",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <SEMAnalysisDetailsWrapper />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: sem_flag,
    },
    smileAnalytcis: {
      path: "/smile-analytics",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <SmileAnalytics />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: true,
    },
    multistageHeatmap: {
      path: "/multistage-heatmap",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <MultistageHeatmap />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: true,
    },
    workOrders: {
      path: "/work-orders",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <WorkOrders />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.work_orders,
    },
    polyGpt: {
      path: "/poly-gpt",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <PolyGPT />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.work_orders,
    },
    createWorkOrder: {
      path: "/work-orders/create-workorder",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <CreateWo />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.work_orders,
    },
    workOrderDetails: {
      path: "/work-orders/details/:woId/details",
      render: () => (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Suspense fallback={<SectionLoader />}>
            <WorkOrderDetails />
          </Suspense>
        </Space>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.work_orders,
    },
    workOrderDetailsStages: {
      path: "/work-orders/details/:woId/",
      render: () => (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Suspense fallback={<SectionLoader />}>
            <StagesWO />
          </Suspense>
        </Space>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.work_orders,
    },
    workOrderDetailsProcedures: {
      path: "/work-orders/details/:woId/procedures",
      render: () => (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Suspense fallback={<SectionLoader />}>
            <Procedures />
          </Suspense>
        </Space>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.work_orders,
    },
    workOrderDetailsChemdraw: {
      path: "/work-orders/details/:woId/chemdraw",
      render: () => (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Suspense fallback={<SectionLoader />}>
            <ChemDraw />
          </Suspense>
        </Space>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.work_orders,
    },
    workOrderDetailsAttachments: {
      path: "/work-orders/details/:woId/attachments",
      render: () => (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Suspense fallback={<SectionLoader />}>
            <Attachments />
          </Suspense>
        </Space>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.work_orders,
    },
    reporting: {
      path: "/reporting",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <NoMatch />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: true,
    },
    teams: {
      path: "/teams",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          {currentPlatform === "connect" ? <ConnectTeams /> : <Teams />}
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.teams,
    },
    notifications: {
      path: "/notifications",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <Notifications />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: true,
    },
    error: {
      path: "/error",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <Error />
        </Suspense>
      ),
      exact: true,
      protectedRoute: false,
      enable: true,
    },
    feedback: {
      path: "/feedback",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <Feedback />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.feedback,
    },
    settings: {
      path: "/settings",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <Settings isDark={isDark} toggleDarkTheme={toggleDarkTheme} />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.settings,
    },
    projects: {
      path: "/projects",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <Projects />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.projects,
    },
    quality_check: {
      path: "/quality-check",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <QualityCheck />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.projects, // TODO: Change this to quality check
    },
    quality_check_metadata: {
      path: "/quality-check/metadata",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <QualityCheckMetadata />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.projects, // TODO: Change this to quality check
    },
    asset_library: {
      path: "/asset-library",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <AssetLibrary />
        </Suspense>
      ),
      exact: false,
      protectedRoute: true,
      enable: true
    },
    project: {
      path: "/projects/details/:id",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <Project />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.projects,
    },
    newExperiments: {
      path: "/generate-new-experiments",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <NewExperiment />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.doe?.generate_new_experiments,
    },
    newExperimentsDetails: {
      path: "/generate-new-experiments/:algo",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <NewExperimentAlgo />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.doe?.generate_new_experiments,
    },
    expHistory: {
      path: "/experiment-history",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <ExpHistory />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.doe?.experiment_history,
    },
    expHistoryDetails: {
      path: "/experiment-history/:doeId",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <ExpHistoryDetails />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.doe?.experiment_history,
    },
    modelDetails: {
      path: "/model-details",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <ModelDetails />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.ai_engine?.new_model,
    },
    dataMapper: {
      path: "/import_data",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <DataImport />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.data_management?.import_data,
    },
    dataHistory: {
      path: "/import_history",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <DataHistory />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.data_management?.import_history,
    },
    auditLog: {
      path: "/audit-log",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <AuditLog />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.audit_log,
    },
    createProject: {
      path: "/project/update",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <CreateProject />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.projects,
    },
    templates: {
      path: "/templates",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <Templates />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.templates,
    },
    verified: {
      path: "/verified",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <UserVerified />
        </Suspense>
      ),
      exact: true,
      protectedRoute: false,
      enable: true,
    },
    updatePassword: {
      path: "/update-password",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <FirstTimeUpdatePassword />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: true,
    },
    interpolation: {
      path: "/interpolation",
      render: () => (
        <Suspense fallback={<SectionLoader />}>
          <Interpolation />
        </Suspense>
      ),
      exact: true,
      protectedRoute: true,
      enable: navConfig.projects,
    },
  };
};
