import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { InputRef, Flex, Input, Tag, Tooltip, Typography } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { antdTheme } from 'src/constants'
import useTranslate from 'src/utils/useTranslate'
import Connector from './Connector'
import EllipseL from './EllipseL'
import EllipseR from './EllipseR'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { stepReverseMapping } from '../../MetadataDrawer'

const MappingTable = ({
  data,
  setTags,
  removeTag,

  columnName,
  tagPlaceholder,

  step
}: {
  data: any[]
  setTags: any
  removeTag: any

  columnName: string
  tagPlaceholder: string

  step: number
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
      }}
    >
      {data?.length ? <TableTile columnName={columnName} /> : null}
      {data.map((item, index) => (
        <TableRow
          key={index}
          title_label={item.title_label}
          title_key={item.title_key}
          tags={item.tags}
          tagPlaceholder={tagPlaceholder}
          setTags={setTags}
          removeTag={removeTag}
          step={step}
        />
      ))}
    </div>
  )
}

export default MappingTable

const TableTile = ({ columnName }: { columnName: string }) => {
  const [t] = useTranslate()

  return (
    <div
      style={{
        display: 'flex',
        width: '100%'
      }}
    >
      <div
        style={{
          minWidth: 250,
          fontWeight: 600,
          marginLeft: 13 + 8
        }}
      >
        {t('common.title')}
      </div>
      <div
        style={{
          flexGrow: 1,
          fontWeight: 600
        }}
      >
        {columnName}
      </div>
    </div>
  )
}

const TableRow = ({
  title_key,
  title_label,
  tags,
  setTags,
  removeTag,
  tagPlaceholder,
  step
}: {
  title_key: string
  title_label: string
  tags: string[]
  setTags: any
  removeTag: any
  tagPlaceholder: string
  step: number
}) => {
  const { getMetadataResponse } = useSelector((state: StoreState) => state.qualityCheck)

  const error = useMemo(() => {
    return getMetadataResponse?.[stepReverseMapping[step]]?.[`error_${title_key}`]
  }, [getMetadataResponse, step, title_key])

  return (
    <div
      style={{
        display: 'flex',
        width: '100%'
      }}
    >
      <div
        style={{
          minWidth: 250,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <MinusCircleOutlined
          style={{
            color: 'rgba(0, 0, 0, 0.45)',
            marginRight: 8,
            cursor: 'pointer',
            outline: 'none'
          }}
          onClick={() => removeTag(title_key)}
        />
        <div
          style={{
            padding: '0.75rem 1rem',
            border: '1px solid #D9D9D9',
            borderRadius: 8,
            fontWeight: 600,
            fontSize: antdTheme.fontSize,
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#fff',
            width: '160px',
            minWidth: '160px',
            maxWidth: '160px',
          }}
        >
          <Typography.Text
            ellipsis={{
              tooltip: title_label
            }}
          >
            {title_label}
          </Typography.Text>
        </div>
        <EllipseL />
        <Connector />
        <EllipseR />
      </div>
      <div
        style={{
          flexGrow: 1,
          padding: '0.75rem 1rem',
          border: '1px solid #D9D9D9',
          borderColor: error ? '#FF4D4F' : '#D9D9D9',
          borderRadius: 8,
          backgroundColor: '#fff',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <TagGroups tags={tags} setTags={(tags: string[]) => setTags(title_key, tags)} tagPlaceholder={tagPlaceholder} />
        {error && (
          <Typography.Text
            style={{
              fontSize: antdTheme.fontSizeSM,
              fontWeight: 600
            }}
            type="danger"
          >
            {error}
          </Typography.Text>
        )}
      </div>
    </div>
  )
}

const TagGroups = ({ tags, setTags, tagPlaceholder }: { tags: string[]; setTags: any; tagPlaceholder: string }) => {
  const tagInputStyle: React.CSSProperties = {
    width: 64,
    height: 22,
    marginInlineEnd: 8,
    verticalAlign: 'top'
  }

  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [editInputIndex, setEditInputIndex] = useState(-1)
  const [editInputValue, setEditInputValue] = useState('')
  const inputRef = useRef<InputRef>(null)
  const editInputRef = useRef<InputRef>(null)

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus()
    }
  }, [inputVisible])

  useEffect(() => {
    editInputRef.current?.focus()
  }, [editInputValue])

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag)
    setTags(newTags)
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = () => {
    const trimmedValue = String(inputValue).trim()
    if (trimmedValue && !tags.includes(trimmedValue)) {
      setTags([...tags, trimmedValue])
    }
    setInputVisible(false)
    setInputValue('')
  }

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value)
  }

  const handleEditInputConfirm = () => {
    const newTags = [...tags]
    const trimmedValue = String(editInputValue).trim()
    newTags[editInputIndex] = trimmedValue ? trimmedValue : newTags[editInputIndex]
    setTags(newTags)
    setEditInputIndex(-1)
    setEditInputValue('')
  }

  const tagPlusStyle: React.CSSProperties = {
    height: 22,
    borderStyle: 'dashed'
  }

  return (
    <Flex gap="4px 0" wrap={'wrap'}>
      {tags.map<React.ReactNode>((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              ref={editInputRef}
              key={tag}
              size="small"
              style={tagInputStyle}
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          )
        }
        const isLongTag = tag.length > 20
        const tagElem = (
          <Tag key={tag} closable={index !== 0} style={{ userSelect: 'none' }} onClose={() => handleClose(tag)} >
            <span
              onDoubleClick={(e) => {
                setEditInputIndex(index)
                setEditInputValue(tag)
                e.preventDefault()
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
          </Tag>
        )
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        )
      })}
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={tagInputStyle}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      ) : (
        <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
          {tagPlaceholder}
        </Tag>
      )}
    </Flex>
  )
}
