import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useTranslate from 'src/utils/useTranslate'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { Checkbox, Dropdown, Input, InputNumber, Select, Tooltip, Typography } from 'antd'
import './CriteriaTable.scss'
import {
  CopyOutlined,
  DeleteOutlined,
  IssuesCloseOutlined,
  MinusCircleOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import EmptyComponent from 'src/components/QualityCheckMetadata/EmptyComponent'
import { StyledButton } from 'src/styled_components/StyledButton'
import { useValue } from 'src/utils/useValue'
import _ from 'lodash'

const CriteriaTable = ({ data, setData }: { data: any[]; setData: any }) => {
  const [t] = useTranslate()
  const { formatter, parser } = useValue()
  const [localData, setLocalData] = useState(data)

  useEffect(() => {
    setLocalData(data)
  }, [data])

  useEffect(() => {
    setData(localData)
  }, [localData, setData])

  const onChange = useCallback((key: string, idx: number, value: any) => {
    setLocalData((prev) => {
      const updatedData = _.cloneDeep(prev || [])
      delete updatedData[idx][`error_${key}`]
      updatedData[idx][key] = value

      // Clearing or setting few cases when related fields change

      if (key === 'optional' && value) {
        delete updatedData[idx]['error_operator']
        delete updatedData[idx]['error_value']
        delete updatedData[idx]['error_type']
      }

      if (key === 'type') {
        updatedData[idx]['operator'] = value === 'categorical' ? 'or' : ''
        delete updatedData[idx][`error_operator`]

        updatedData[idx]['value'] = value === 'categorical' ? [] : ''
        delete updatedData[idx][`error_value`]
      }

      if (key === 'operator' && value === 'range') {
        updatedData[idx]['value'] = ['', '']
        delete updatedData[idx][`error_value`]
      }

      if (key === 'operator' && Array.isArray(updatedData[idx]['value'])) {
        updatedData[idx]['value'] = ''
        delete updatedData[idx][`error_value`]
      }

      if (key === 'operator' && value==='or') {
        updatedData[idx]['value'] = []
        delete updatedData[idx][`error_value`]
      }
      return updatedData
    })
  }, [])

  const onRemove = useCallback((idx: number) => {
    setLocalData((prev) => {
      const updatedCriteria = [...prev]
      updatedCriteria.splice(idx, 1)
      return updatedCriteria
    })
  }, [])

  const onDuplicate = useCallback((idx: number) => {
    setLocalData((prev) => {
      const updatedCriteria = [...prev]
      updatedCriteria.splice(idx + 1, 0, _.cloneDeep(updatedCriteria[idx]))
      return updatedCriteria
    })
  }, [])

  const { getMetadataResponse } = useSelector((state: StoreState) => state.qualityCheck)

  const inputCriteriaRedux = useMemo(() => getMetadataResponse?.input_criteria, [getMetadataResponse?.input_criteria])

  const columns = useMemo(() => {
    const commonStyle: React.CSSProperties = { width: '100%', borderRadius: 2 }
    const dropdownStyle: React.CSSProperties = { borderRadius: 2 }

    return [
      {
        key: 'test',
        title: t('common.test'),
        width: 250,
        render: (record: any, idx: number) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <MinusCircleOutlined
              style={{
                color: 'rgba(0, 0, 0, 0.45)',
                marginRight: 8,
                cursor: 'pointer',
                outline: 'none'
              }}
              onClick={() => onRemove(idx)}
            />
            <Input
              value={record['test']}
              style={commonStyle}
              onChange={(e) => onChange('test', idx, e.target.value)}
              {...(inputCriteriaRedux?.[idx]?.[`error_test`] && { status: 'error' })}
            />
          </div>
        )
      },
      {
        key: 'method',
        title: t('common.method'),
        width: 200,
        render: (record: any, idx: number) => (
          <Input
            value={record['method']}
            style={commonStyle}
            onChange={(e) => onChange('method', idx, e.target.value)}
            // {...(inputCriteriaRedux?.[idx]?.[`error_method`] && { status: 'error' })}
          />
        )
      },
      {
        key: 'unit',
        title: t('common.unit'),
        width: 150,
        render: (record: any, idx: number) => (
          <Input
            value={record['unit']}
            style={commonStyle}
            onChange={(e) => onChange('unit', idx, e.target.value)}
            // {...(inputCriteriaRedux?.[idx]?.[`error_unit`] && { status: 'error' })}
          />
        )
      },
      {
        key: 'type',
        title: t('common.type'),
        width: 150,
        render: (record: any, idx: number) => (
          <Select
            value={record['type']}
            onChange={(value) => {
              onChange('type', idx, value)
            }}
            options={[
              { label: t('common.numerical'), value: 'numerical' },
              { label: t('common.categorical'), value: 'categorical' }
            ]}
            style={commonStyle}
            dropdownStyle={dropdownStyle}
            className="qc_criteria_table_select"
            {...(inputCriteriaRedux?.[idx]?.[`error_type`] && { status: 'error' })}
          />
        )
      },
      {
        key: 'operator',
        title: t('common.operator'),
        width: 150,
        render: (record: any, idx: number) => (
          <Select
            value={record['operator']}
            onChange={(value) => onChange('operator', idx, value)}
            options={
              record['type'] === 'categorical'
                ? [{ label: t('common.OR'), value: 'or' }]
                : [
                    { label: '>', value: '>' },
                    { label: '<', value: '<' },
                    { label: '=', value: '=' },
                    { label: '>=', value: '>=' },
                    { label: '<=', value: '<=' },
                    { label: '~', value: '~' },
                    { label: t('common.range'), value: 'range' }
                  ]
            }
            style={commonStyle}
            dropdownStyle={dropdownStyle}
            className="qc_criteria_table_select"
            {...(inputCriteriaRedux?.[idx]?.[`error_operator`] && { status: 'error' })}
          />
        )
      },
      {
        key: 'value',
        title: t('common.value'),
        width: 400,
        render: (record: any, idx: number) => {
          const optionalComponentRender = (show: boolean) => {
            return show ? (
              <Tooltip title={t('qc.metadata.inputCriteria.markAsOptionalTooltip')}>
                <IssuesCloseOutlined style={{ outline: 'none', color: '#FAAD14', fontSize: '1rem' }} />
              </Tooltip>
            ) : null
          }

          // const overrideComponentRender = (show: boolean) => {
          //   return show ? (
          //     <Tooltip title={t('qc.metadata.inputCriteria.overrideTooltip')}>
          //       <FileProtectOutlined  style={{ outline: 'none', color: '#02b802', fontSize: '1rem' }} />
          //     </Tooltip>
          //   ) : null
          // }

          const valueComponent = (record: any) => {
            if (record['operator'] === 'range') {
              return (
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', width: '100%' }}>
                  <InputNumber
                    value={record['value']?.[0] || ''}
                    formatter={formatter}
                    parser={parser}
                    style={{ ...commonStyle, flexGrow: 1 }}
                    onChange={(val) => {
                      const [min, max] = record['value'] || ['', '']
                      if (val === '' || val === undefined || val === null) {
                        onChange('value', idx, ['', max])
                      } else if (max === '' || max >= val) {
                        onChange('value', idx, [val, max])
                      } else {
                        onChange('value', idx, [min, max])
                      }
                    }}
                    {...(inputCriteriaRedux?.[idx]?.[`error_value`] && !record['value']?.[0] && { status: 'error' })}
                  />
                  <Checkbox value={record?.['min_exclusive']} onChange={(val) => onChange('min_exclusive', idx, [val])}>
                    {t('common.exclusive')}
                  </Checkbox>
                  <Typography.Text>-</Typography.Text>
                  <InputNumber
                    value={record['value']?.[1] || ''}
                    formatter={formatter}
                    parser={parser}
                    style={{ ...commonStyle, flexGrow: 1 }}
                    onChange={(val) => {
                      const [min, max] = record['value'] || ['', '']
                      if (val === '' || val === undefined || val === null) {
                        onChange('value', idx, [min, ''])
                      } else if (min === '' || min <= val) {
                        onChange('value', idx, [min, val])
                      } else {
                        onChange('value', idx, [min, max])
                      }
                    }}
                    {...(inputCriteriaRedux?.[idx]?.[`error_value`] && !record['value']?.[1] && { status: 'error' })}
                  />
                  <Checkbox value={record?.['max_exclusive']} onChange={(val) => onChange('max_exclusive', idx, [val])}>
                    {t('common.exclusive')}
                  </Checkbox>
                </div>
              )
            }
            if (record['type'] === 'categorical') {
              return (
                <Select
                  value={record['value'] || []}
                  onChange={(value) => onChange('value', idx, value)}
                  mode="tags"
                  style={commonStyle}
                  dropdownStyle={dropdownStyle}
                  maxTagCount={'responsive'}
                  tokenSeparators={[',']}
                  options={[]}
                  className="qc_criteria_table_select"
                  {...(inputCriteriaRedux?.[idx]?.[`error_value`] && { status: 'error' })}
                />
              )
            }
            return (
              <InputNumber
                value={record['value']}
                formatter={formatter}
                parser={parser}
                style={commonStyle}
                onChange={(val) => onChange('value', idx, val)}
                {...(inputCriteriaRedux?.[idx]?.[`error_value`] && { status: 'error' })}
              />
            )
          }

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                width: '100%'
              }}
            >
              {valueComponent(record)}
              {optionalComponentRender(record?.['optional'])}
              {/* {overrideComponentRender(record?.['override'])} */}
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'duplicate',
                      label: t('common.duplicate'),
                      onClick: () => {
                        onDuplicate(idx)
                      },
                      style: {
                        outline: 'none'
                      },
                      icon: <CopyOutlined />
                    },
                    {
                      key: 'optional',
                      label: record?.['optional'] ? t('qc.metadata.inputCriteria.unmarkAsOptional') : t('qc.metadata.inputCriteria.markAsOptional'),
                      onClick: () => {
                        onChange('optional', idx, !record?.['optional'])
                      },
                      style: {
                        outline: 'none'
                      },
                      icon: <IssuesCloseOutlined />
                    },
                    // {
                    //   key: 'override',
                    //   label: record?.['override'] ? t('qc.metadata.inputCriteria.revertToFailed') : t('qc.metadata.inputCriteria.overrideCriteria'),
                    //   onClick: () => {
                    //     onChange('override', idx, !record?.['override'])
                    //   },
                    //   style: {
                    //     outline: 'none'
                    //   },
                    //   icon: <FileProtectOutlined />
                    // },
                    {
                      key: 'delete',
                      label: t('common.delete'),
                      onClick: () => {
                        onRemove(idx)
                      },
                      style: {
                        outline: 'none'
                      },
                      icon: <DeleteOutlined />
                    }
                  ]
                }}
                overlayStyle={{
                  borderRadius: '2px'
                }}
                overlayClassName="qc-criteria-more-options"
              >
                <StyledButton
                  icon={<MoreOutlined />}
                  style={{
                    outline: 'none',
                    border: 'none'
                  }}
                />
              </Dropdown>
            </div>
          )
        },
        style: {
          flexGrow: 1
        }
      }
    ]
  }, [t, inputCriteriaRedux, onRemove, onChange, formatter, parser, onDuplicate])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        height: '100%'
      }}
    >
      <TableTile columns={columns} />
      <TableBody data={localData} columns={columns} />
    </div>
  )
}

export default CriteriaTable

const TableTile = ({ columns }: { columns: any[] }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: 'max-content',
        minWidth: '100%',
        border: '1px solid rgba(0, 0, 0, 0.06)',
        backgroundColor: '#f5f5f5'
        // For left indent
        // paddingLeft: 24
      }}
    >
      {columns.map((column) => (
        <React.Fragment key={column.key}>
          {/* Actual column name */}
          <div
            style={{
              minWidth: column.width,
              width: column.width,
              fontWeight: 600,
              padding: '0.5rem',
              ...column.style
            }}
          >
            {column.title}
          </div>
          {/* Right separator */}
          <div
            style={{
              borderLeft: '1px solid rgba(0, 0, 0, 0.06)',
              margin: '0.25rem 0rem'
            }}
          />
        </React.Fragment>
      ))}
    </div>
  )
}

const TableBody = ({ data, columns }: { data: any[]; columns: any[] }) => {
  return (
    <div
      style={{
        height: 'calc(100% - 2rem)',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: 'max-content',
        minWidth: '100%'
      }}
    >
      {data?.length ? (
        data?.map((row, idx) => (
          <div
            style={{
              display: 'flex',
              width: 'max-content',
              minWidth: '100%',
              borderBottom: '1px solid rgba(0, 0, 0, 0.06)'
            }}
            key={idx}
          >
            {columns.map((column) => (
              <div
                key={`${column.key}_${idx}`}
                style={{
                  minWidth: column.width,
                  width: column.width,
                  padding: '0.5rem',
                  ...column.style
                }}
              >
                {column.render ? column.render(row, idx) : row[column.key]}
              </div>
            ))}
          </div>
        ))
      ) : (
        <EmptyComponent />
      )}
    </div>
  )
}
