import { CreditCardOutlined } from '@ant-design/icons'
import { Divider, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { BilingDetail } from './ConnectBilingDetail'
import "./ConnectBilling.scss"
import { useDispatch, useSelector } from 'react-redux'
import { billingDetailsRequest, updatePaymentMethodRequest } from 'src/store/actions/settings'
import { StoreState } from 'src/store/configureStore'
import { AsyncStates } from 'src/constants'
import { BillingCurrentPlan } from './BillingCurrentPlan'
import { EditBillingDetailModal } from './EditConnectBillingDetailModal/EditConnectBillingDetailModal'
import { toTitleCase } from 'src/utils/general'
import useTranslate from 'src/utils/useTranslate'
import { StyledButton } from 'src/styled_components/StyledButton'

const { Title, Text } = Typography

export const ConnectBilling = () => {
    const [t] = useTranslate()
    const dispatch = useDispatch()
    const { billingDetails, billingDetailsStatus, updatePaymentMethodStatus, billingDetailsErrorMessage } = useSelector((state: StoreState) => state.settings)
    const userRole = useSelector<StoreState, string>((state: StoreState) => state.login.loginResponse.user_role)
    const [showEditBillingDetailModal, setShowEditBillingDetailModal] = useState(false)

    useEffect(() => {
        if (userRole === "admin") {
            dispatch(billingDetailsRequest())
        }
    }, [dispatch, userRole])

    return (
        <>
            <div className={`billing__tab__container ${billingDetailsStatus === AsyncStates.LOADING ? "billing__tab__container__loading" : ""}`}>
                <div className="billing__header__container">
                    <Title level={4} className={"common__billing__title"}>{t("common.billing")}</Title>
                    <Text type="secondary">{t("billing.subheading")} <a href="mailto: contact@polymerize.io">{"contact@polymerize.io"}</a></Text>
                </div>
                <Divider className='common__divider' />
                {userRole !== "admin" ? <><Text strong>{t("billing.contactAdmin")}.</Text></> :
                    billingDetailsStatus === AsyncStates.ERROR ?
                        <div className='billing__error__container'>
                            <div className='billing_error_title'>{billingDetailsErrorMessage}</div>
                            <div className='billing_error_actions'><StyledButton onClick={() => { dispatch(billingDetailsRequest()) }}>{t("common.retry")}</StyledButton></div>
                        </div> :
                        <>
                            <div className="payment__method__container">
                                <div className='payment__method__container__header'>
                                    <Title level={4} className={"common__billing__title"}>{t("billing.paymentMethods")}</Title>
                                    <div className='billing__card__actions'>
                                        <StyledButton icon={<CreditCardOutlined />} loading={updatePaymentMethodStatus === AsyncStates.LOADING} onClick={() => dispatch(updatePaymentMethodRequest())}>{!!billingDetails?.payment_methods?.length ? t("billing.update") : t("billing.add")}</StyledButton>
                                    </div>
                                    <Divider className='common__divider' />

                                    {
                                        !!billingDetails?.payment_methods?.length ?
                                            <div className="payment__method__container">
                                                {billingDetails?.payment_methods?.map((paymentMethod: any) =>
                                                    <div className='payment__method__info__container' key={paymentMethod?.id}>
                                                        <div>
                                                            <CreditCardOutlined className='credit__card__icon' />
                                                        </div>
                                                        <div className='payment__method__info'>
                                                            <Text type="secondary">{toTitleCase(paymentMethod?.brand)} {toTitleCase(paymentMethod?.funding)} {t("billing.cardEndingIn")} {paymentMethod?.last4}</Text>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            : <div className='payment__method__info'>
                                                {<Text type="secondary">{t("billing.addPayment")}</Text>}
                                            </div>
                                    }
                                </div>
                            </div>
                            {!!Object.keys(billingDetails?.subscription_details || {}).length &&
                                <>
                                    <Divider className='common__divider' />
                                    <BillingCurrentPlan subscription={billingDetails?.subscription_details} />
                                </>
                            }
                            <Divider className='common__divider' />

                            <div className="billing__detail__container">
                                <Title level={4}>{t("billing.billingDetail")}</Title>
                                <div className='billing__detail__info'>
                                    <div className='billing__detail__name'>
                                        <Title level={5} className={"common__billing__title"}>{t("billing.name")}</Title>
                                        <Text type="secondary">{billingDetails?.customer_details?.name}</Text>
                                    </div>
                                    {
                                        !!billingDetails?.customer_details?.email &&
                                        <div className='billing__detail__email'>
                                            <Title level={5} className={"common__billing__title"}>{t("billing.email")}</Title>
                                            <Text type="secondary">{billingDetails?.customer_details?.email}</Text>
                                        </div>
                                    }
                                </div>
                                <div className='edit__billing__detail__action'>
                                    <StyledButton onClick={() => { setShowEditBillingDetailModal(true) }}>{t("billing.edit")}</StyledButton>
                                </div>
                            </div>
                            {
                                !!billingDetails?.invoices?.length &&
                                <>
                                    <Divider className='common__divider' />

                                    <div className="billing__history__container">
                                        <Title level={4} className={"common__billing__title"}>{t("billing.history")}</Title>
                                        {
                                            billingDetails?.invoices?.map((billing: any) => <BilingDetail billing={billing} key={billing.id} />)
                                        }
                                    </div>
                                </>
                            }
                        </>
                }
            </div>
            <EditBillingDetailModal showEditBillingDetailModal={showEditBillingDetailModal} setShowEditBillingDetailModal={setShowEditBillingDetailModal} customerDetails={billingDetails?.customer_details} />
        </>
    )
}
