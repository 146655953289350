import { PlusOutlined, ContainerOutlined } from '@ant-design/icons'
import { Space, Popover, Checkbox } from 'antd'
import { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { qcMetadataStepErrors, qcMetadataUpdate } from 'src/store/actions/qualityCheck'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'
import StepTitle from './StepTitle'
import MappingTable from './MappingTable/MappingTable'
import _ from 'lodash'

const titlesMap:Record<string,string> = {
  test: 'Test',
  method: 'Method',
  unit: 'Unit',
  result: 'Result',
}

const MapTestTitles = ({ step, openImportModal }: { step: number; openImportModal: () => void }) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const { getMetadataResponse, importDataResponse } = useSelector((state: StoreState) => state.qualityCheck)

  const [selectedTitles, setSelectedTitles] = useState<any[]>(
    Object.keys(getMetadataResponse?.test_title_mapping || {})
  )
  const [selectedTitlesMap, setSelectedTitlesMap] = useState<Record<string, string[]>>({
    ...(getMetadataResponse?.test_title_mapping || {})
  })

  const options = useMemo(() => {
    return [
      {
        value: 'test',
        label: t('common.test')
      },
      {
        value: 'method',
        label: t('common.method')
      },
      {
        value: 'unit',
        label: t('common.unit')
      },
      {
        value: 'result',
        label: t('common.result')
      },
    ]
  }, [t])

  const selectedTitlesWithoutErrors = useMemo(() => {
    return selectedTitles.filter((key) => !key.startsWith('error_'))
  }, [selectedTitles])

  const isSelectAll = useMemo(() => selectedTitlesWithoutErrors.length === options.length, [options.length, selectedTitlesWithoutErrors.length])
  const indeterminate = useMemo(() => selectedTitlesWithoutErrors.length > 0 && selectedTitlesWithoutErrors.length < options.length, [options.length, selectedTitlesWithoutErrors.length])

  // Import data
  useEffect(() => {
    if(importDataResponse?.test_title_mapping) {
      setSelectedTitlesMap(importDataResponse.test_title_mapping)
      setSelectedTitles(Object.keys(importDataResponse.test_title_mapping))
    }
  }, [importDataResponse])

  // Updating entires
  useEffect(() => {
    setSelectedTitlesMap((prev) => {
      const dataToAdd = selectedTitles.reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: prev[curr] || [titlesMap[curr]]
        }
      }, {})
      dispatch(qcMetadataUpdate({ test_title_mapping: dataToAdd }))
      return {
        ...dataToAdd
      }
    })
  }, [dispatch, selectedTitles])

  // Updating tags
  useEffect(() => {
    dispatch(qcMetadataUpdate({ test_title_mapping: selectedTitlesMap }))
  }, [dispatch, selectedTitlesMap])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '90%',
        width: '100%',
        flexGrow: 1
      }}
    >
      {/* Action bar */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0.75rem 1.5rem',
          paddingTop: '1.5rem',
          borderBottom: '1px solid #f0f0f0'
        }}
      >
        <Space>
          <StepTitle step={step} />
          <Popover
            placement="bottomLeft"
            autoAdjustOverflow
            showArrow={false}
            arrow={false}
            arrowContent={null}
            style={{
              padding: 0
            }}
            overlayStyle={{
              padding: 0
            }}
            overlayInnerStyle={{
              padding: '0.25rem 0rem'
            }}
            content={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Checkbox
                  style={{
                    padding: '0.25rem 1rem'
                  }}
                  checked={isSelectAll}
                  indeterminate={indeterminate}
                  onChange={(e) => {
                    setSelectedTitles(e.target.checked ? options.map((opt) => opt.value) : [])
                  }}
                >
                  {t('common.selectAll')}
                </Checkbox>
                <Checkbox.Group
                  value={selectedTitles}
                  onChange={(values) => {
                    setSelectedTitles(values)
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  {options.map((opt) => (
                    <Checkbox
                      key={opt.value}
                      value={opt.value}
                      style={{
                        padding: '0.25rem 1rem'
                      }}
                    >
                      {opt.label}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </div>
            }
          >
            <StyledButton
              icon={<PlusOutlined />}
              size="small"
              style={{
                fontSize: '0.75rem',
                fontWeight: 500
              }}
              type={'link'}
            >
              {t('common.addNew')}
            </StyledButton>
          </Popover>
        </Space>
        <StyledButton
          size="small"
          style={{
            fontSize: '0.75rem',
            fontWeight: 500
          }}
          icon={<ContainerOutlined />}
          onClick={openImportModal}
        >
          {t('common.importData')}
        </StyledButton>
      </div>

      {/* Table/Mapper */}
      <div
        style={{
          height: 'calc(100% - 4rem)',
          overflow: 'auto',
          width: '100%',
          // dot pattern
          backgroundImage: 'radial-gradient(circle at 1px 1px, #b8b8b8 .1em, transparent .1em)',
          backgroundSize: '1.75rem 1.75rem',
          padding: '1.5rem 2rem'
        }}
      >
        <MappingTable
          data={selectedTitlesWithoutErrors.map((title_key) => {
            return {
              title_label: options.find((opt) => opt.value === title_key)?.label || '',
              title_key,
              tags: selectedTitlesMap[title_key] || []
            }
          })}
          setTags={(title_key: string, tags: string[]) => {
            setSelectedTitlesMap(() => {
              const newState = _.cloneDeep(getMetadataResponse?.test_title_mapping || {})
              delete newState[`error_${title_key}`]
              return { ...newState, [title_key]: tags }
            })
            dispatch(
              qcMetadataStepErrors({
                test_title_mapping: false
              })
            )
          }}
          removeTag={(title_key: string) => {
            setSelectedTitles((prev) => prev.filter((t) => t !== title_key))
            setSelectedTitlesMap((prev) => {
              const newState = { ...prev }
              delete newState[title_key]
              return newState
            })
          }}
          columnName={t('qc.metadata.mapTestColumns.columnTitle')}
          tagPlaceholder={t('qc.metadata.mapTestColumns.tagPlaceholder')}
          step={step}
        />
      </div>
    </div>
  )
}

export default MapTestTitles
