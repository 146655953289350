import { createActions } from "redux-actions";
import { displayNames } from "src/decorator/displayNames";
import { DisplayName } from "../../services/common/interface";

export enum DisplayNamesActionTypes {
  REQUEST = "DISPLAY_NAMES_REQUEST",
  SUCCESS = "DISPLAY_NAMES_SUCCESS",
  FAILURE = "DISPLAY_NAMES_FAILURE",
  CLEANUP = "DISPLAY_NAMES_CLEANUP",
  UPDATE = "DISPLAY_NAMES_UPDATE",
}

export enum FilteredDisplayNamesActionTypes {
  REQUEST = "FILTERED_DISPLAY_NAMES_REQUEST",
  SUCCESS = "FILTERED_DISPLAY_NAMES_SUCCESS",
  FAILURE = "FILTERED_DISPLAY_NAMES_FAILURE",
  CLEANUP = "FILTERED_DISPLAY_NAMES_CLEANUP",
}

export enum UpdateDisplayNamesItemsActionTypes {
  REQUEST = "UPDATE_DISPLAY_NAMES_ITEMS_REQUEST"
}

export const {
  displayNamesRequest,
  displayNamesSuccess,
  displayNamesFailure,
  displayNamesCleanup,
  displayNamesUpdate,
  filteredDisplayNamesRequest,
  filteredDisplayNamesSuccess,
  filteredDisplayNamesFailure,
  filteredDisplayNamesCleanup,
  updateDisplayNamesItemsRequest
} = createActions({
  [DisplayNamesActionTypes.REQUEST]: payload => payload,
  [DisplayNamesActionTypes.SUCCESS]: (data: DisplayName[]) => data,
  [DisplayNamesActionTypes.FAILURE]: (error: string) => ({ error }),
  [DisplayNamesActionTypes.CLEANUP]: () => { },
  [DisplayNamesActionTypes.UPDATE]: (data) => data,
  [FilteredDisplayNamesActionTypes.REQUEST]: payload => payload,
  [FilteredDisplayNamesActionTypes.SUCCESS]: (data: any[]) => ({ data: displayNames(data) }),
  [FilteredDisplayNamesActionTypes.FAILURE]: (error: string) => ({ error }),
  [FilteredDisplayNamesActionTypes.CLEANUP]: () => { },
  [UpdateDisplayNamesItemsActionTypes.REQUEST]: (payload: { type: string, data: { [key: string]: any } }) => payload,
});
