import { handleActions } from "redux-actions"
import { AsyncStates } from "src/constants"
import { FindArchivedParametersActionTypes, LinkedFormulationsAiEngineActionTypes, UpdateArchivedParametersActionTypes } from "../actions/common";

export type CommonState = {
    linkedFormulationData: any,
    linkedFormulationsError: null | string,
    linkedFormulationsStatus: any,

    foundArchivedParameters: {
        archiveParametersFoundInitially: boolean,
        type: "inverse" | "forward" | "doe" | "work_order" | "work-order-fileupload" | null,
        status: AsyncStates,
        data: any[],
        error: string | null,
        id: string | null,
        ingredients?: any[]
    }
}

const defaultState: CommonState = {
    linkedFormulationData: {},
    linkedFormulationsError: null,
    linkedFormulationsStatus: {},

    foundArchivedParameters: {
        archiveParametersFoundInitially: false,
        type: null,
        status: AsyncStates.INITIAL,
        data: [],
        error: null,
        id: null,
        ingredients: []
    }
}


const commonReducer = handleActions<CommonState, any>({
    [LinkedFormulationsAiEngineActionTypes.REQUEST]: (state, action) => {
        return {
            ...state,
            linkedFormulationsStatus: AsyncStates.LOADING,
            linkedFormulationsError: null,
        }
    },
    [LinkedFormulationsAiEngineActionTypes.SUCCESS]: (state, action) => {
        return {
            ...state,
            linkedFormulationsStatus: AsyncStates.SUCCESS,
            linkedFormulationsError: null,
            linkedFormulationData: {
                ...state.linkedFormulationData,
                ...action.payload.data
            },
        };
    },
    [LinkedFormulationsAiEngineActionTypes.FAILURE]: (state, action) => ({
        ...state,
        linkedFormulationsStatus: AsyncStates.ERROR,
        linkedFormulationsError: action.payload.error,
    }),
    [LinkedFormulationsAiEngineActionTypes.CLEAR]: (state, action) => ({
        ...state,
        linkedFormulationsStatus: AsyncStates.INITIAL,
        linkedFormulationsError: null,
        linkedFormulationData: {}
    }),

    [FindArchivedParametersActionTypes.REQUEST]: (state, action: any) => ({
        ...state,
        foundArchivedParameters: {
            ...state.foundArchivedParameters,
            status: AsyncStates.LOADING,
            archiveParametersFoundInitially: false,
            data: [],
            error: null,
            id: action.payload.id,
            type: action.payload.type,
            ingredients: action.payload?.ingredients ?? []
        }
    }),
    [FindArchivedParametersActionTypes.SUCCESS]: (state, action: any) => {
        const apiPayload = action.payload.apiPayload
        const apiResponse = action.payload?.data ?? []
        return {
            ...state,
            foundArchivedParameters: {
                ...state.foundArchivedParameters,
                archiveParametersFoundInitially: !!apiResponse?.length,
                status: AsyncStates.SUCCESS,
                data: apiResponse,
                error: null,
                id: apiPayload?.id,
                type: apiPayload?.type,
                ingredients: apiPayload?.ingredients ?? []
            }
        }
    },
    [FindArchivedParametersActionTypes.FAILURE]: (state, action: any) => ({
        ...state,
        foundArchivedParameters: {
            ...state.foundArchivedParameters,
            status: AsyncStates.ERROR,
            error: action.payload,
        }
    }),
    [FindArchivedParametersActionTypes.CLEAR]: (state, action: any) => ({
        ...state,
        foundArchivedParameters: {
            ...state.foundArchivedParameters,
            archiveParametersFoundInitially: false,
            status: AsyncStates.INITIAL,
            error: null,
            data: [],
            id: null,
            type: null,
            ingredients: []
        }
    }),
    [UpdateArchivedParametersActionTypes.REQUEST]: (state, action: any) => {
        return {
            ...state,
            foundArchivedParameters: {
                ...state.foundArchivedParameters,
                data: !!state.foundArchivedParameters.data?.length ? state.foundArchivedParameters.data.filter((item: any) => !action.payload.inventory_ids.includes(item.inventory_id)) : state.foundArchivedParameters.data,
            }
        }
    }
}, defaultState);

export default commonReducer;
