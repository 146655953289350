import { DeleteOutlined, LoadingOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons'
import { Collapse, Dropdown, Empty, Modal, Spin, Switch, Table, Typography } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { InventoryNewPropertyValueModal } from './InventoryDetailsProperties/InventoryNewPropertyValueModal'
import { AddPropertyToIngredientModal } from './AddPropertyToIngredientModal'
import { deleteIngredientPropertyClear, deleteIngredientPropertyRequest, deleteIngredientPropertyValueRequest, getIngredientPropertiesClear, getIngredientPropertiesDetailsClear, getIngredientPropertiesDetailsRequest, getIngredientPropertiesRequest, getIngredientPropertyValuesRequest, getPropertyMethodsListRequest } from 'src/store/actions/inventoryV2'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { antdTheme, AsyncStates } from 'src/constants/index'
import useTranslate from 'src/utils/useTranslate'
import { InventoryFormulationsTable } from './InventoryFormulationsTable'
import InventoryIngredientPropertyDetail from './InventoryIngredientPropertyDetail'
import { fetchPropertyUnitsRequest } from 'src/store/actions/repository'
import { useValue } from 'src/utils/useValue'
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'
import { StyledButton } from 'src/styled_components/StyledButton'

const { Text } = Typography;
const { Panel } = Collapse;

type InventoryPropertyDetailsProps = {
    inventory_id?: string,
    ingredient: any,
    from: string,
    inventoryData: any,
    isReadOnly?: boolean
}

export const medthodOptions = [{
    method_id: "manual",
    method_name: "Manual"
}, {
    method_id: "automatic",
    method_name: "Automatic"
}, {
    method_id: "-",
    method_name: "-"
}]

export const InventoryPropertyDetails = ({ inventory_id, ingredient, from, inventoryData, isReadOnly = false }: InventoryPropertyDetailsProps) => {
    const [t] = useTranslate()
    const { getValue: getLocalValue, addZeroes } = useValue();
    const [addPropertyModalVisible, setAddPropertyModalVisible] = useState({
        isModalVisible: false,
        mode: "create",
        data: ingredient ? { ...ingredient } : null
    })
    const dispatch = useDispatch()
    const [propertyValueModal, setPropertyValueModal] = useState({
        mode: "create",
        isModalVisible: false,
        data: null
    })

    const [activePropertyKeys, setActivePropertyKeys] = useState<string[]>([])

    const propertiesDetails = useSelector((state: StoreState) => state.inventoryV2.ingredientPropertiesDetails);
    const ingredientPropertiesDetailsStatus = useSelector((state: StoreState) => state.inventoryV2.ingredientPropertiesDetailsStatus);
    const ingredientPropertyValuesStatus = useSelector((state: StoreState) => state.inventoryV2.ingredientPropertyValuesStatus);
    const deleteIngredientPropertyStatus = useSelector((state: StoreState) => state.inventoryV2.deleteIngredientPropertyStatus);
    const getIngredientPropertyStatus = useSelector((state: StoreState) => state.inventoryV2.getIngredientPropertyStatus);

    const [ingredientPropertyDetailsViewMode, setIngredientPropertyDetailsViewMode] = useState({
        isModeChanged: false,
        mode: "view"
    })


    const ingredientPropertiesDetails = useMemo(() => {
        return (ingredientPropertyDetailsViewMode.mode === "edit" ? propertiesDetails : ingredient?.properties)
    }, [ingredientPropertyDetailsViewMode.mode, propertiesDetails, ingredient?.properties])

    useEffect(() => {
        setIngredientPropertyDetailsViewMode((prev) => {
            if (!prev.isModeChanged) {
                return {
                    ...prev,
                    mode: getIngredientPropertyStatus?.[ingredient?.inventory_id] === AsyncStates.SUCCESS ?
                        (!ingredient.properties?.length ? "edit" :
                            ingredient.properties?.some((property: any) => property?.properties?.some((propertyData: any) => propertyData?.property_values?.length)) ? "view" : "edit") : prev.mode
                }
            } else {
                return prev
            }
        })
    }, [getIngredientPropertyStatus, ingredient?.inventory_id, ingredient.properties])



    useEffect(() => {
        return () => {
            setActivePropertyKeys([])
            setIngredientPropertyDetailsViewMode({
                isModeChanged: false,
                mode: "view"
            })
            dispatch(getIngredientPropertiesDetailsClear())
            dispatch(getIngredientPropertiesClear())
            dispatch(deleteIngredientPropertyClear())
        }
    }, [dispatch])


    const getPropertyValueActionsItems = useCallback((record) => {
        return [
            {
                key: "edit",
                label: t("common.edit"),
                onClick: () => {
                    dispatch(getPropertyMethodsListRequest({
                        inventory_id: record.inventory_id,
                        property_id: record.property_id
                    }));
                    dispatch(fetchPropertyUnitsRequest({ property_id: record.property_id }));
                    setPropertyValueModal({
                        mode: "edit",
                        isModalVisible: true,
                        data: record
                    });
                }
            }, {
                key: "delete",
                label: <span style={{ color: antdTheme.colorError, fontSize: antdTheme.fontSizeLG }}>{t("common.delete")}</span>,
                onClick: () => {
                    Modal.confirm({
                        icon: <StyledDeleteIcon />,
                        okText: t("common.confirm"),
                        cancelText: t("common.cancel"),
                        okButtonProps: {
                            style: {
                                background: "#ff4d4f"
                            }
                        },
                        title: t("common.delete"),
                        content: t("common.deletePropertyValueWarningMessage"),
                        onOk: () => {
                            dispatch(deleteIngredientPropertyValueRequest({
                                inventory_id: record.inventory_id,
                                category_id: record.category_id,
                                inventory_property_id: record.inventory_property_id,
                                inventory_property_value_id: record.inventory_property_value_id
                            }))
                        },
                        onCancel: () => { }
                    })
                }
            }
        ]
    }, [dispatch, t])

    const showUnit = (unit: string) => {
        return unit === "-" ? "" : unit
    }

    const getPropertyOrMethodValue = (param: any) => {
        if (param.value_type === "categorical") {
            return <span>{param.value_str} {showUnit(param.unit)}</span>
        } else if (param.value_type === "numerical" && param.value_subtype === "single") {
            return <span>{addZeroes(getLocalValue(param.value), 1) || ""} {showUnit(param.unit)}</span>
        } else if (param.value_subtype === "range") {
            return <span>{addZeroes(getLocalValue(param.value), 1) || ""} - {addZeroes(getLocalValue(param.value_max), 1) || ""} {showUnit(param.unit)}</span>
        } else if (param.value_subtype === "operator") {
            return <span>{param.operator} {addZeroes(getLocalValue(param.value), 1)} {showUnit(param.unit)}</span>
        } else {
            return null
        }
    }

    const stuffDummyColumns = (maxPropertyParameters = [] as any[], actualParameters = []) => {
        const length = maxPropertyParameters.length
        maxPropertyParameters.unshift(...actualParameters)
        return maxPropertyParameters.splice(0, length) ?? []
    }

    useEffect(() => {
        if (ingredientPropertyDetailsViewMode.mode === "view") {
            setActivePropertyKeys([])
            dispatch(getIngredientPropertiesRequest({ inventory_id: ingredient?.inventory_id ?? inventory_id }))
        }
    }, [inventory_id, ingredient?.inventory_id, dispatch, ingredientPropertyDetailsViewMode.mode])

    useEffect(() => {
        if (ingredientPropertyDetailsViewMode.mode === "edit") {
            if (!isReadOnly || from === "ingredients") {
                dispatch(getIngredientPropertiesDetailsRequest({
                    inventory_id: ingredient.inventory_id,
                }))
            }
        }
    }, [dispatch, ingredient.inventory_id, isReadOnly, from, ingredientPropertyDetailsViewMode.mode])


    return (
        <>
            <div style={{ padding: "0.5rem", display: "flex", flexDirection: "column", gap: "1rem" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                    <div style={{ display: "flex", flexDirection: "row", gap: "1rem", alignItems: "center" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem", width: "100%" }}>
                            <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                                <h4>{t("common.properties")}</h4>
                                {ingredientPropertyDetailsViewMode.mode === "edit" ?
                                    isReadOnly ? null :
                                        <StyledButton onClick={() => {
                                            setAddPropertyModalVisible((prev) => {
                                                return {
                                                    ...prev,
                                                    isModalVisible: true,
                                                    data: ingredient
                                                }
                                            })
                                        }} icon={<PlusOutlined />}>{t("common.addNew")}</StyledButton> : null}
                            </div>
                            <div>
                                {(isReadOnly) ? null :
                                    (!!ingredient.properties?.length || !!propertiesDetails?.length) ? <Switch
                                        checked={ingredientPropertyDetailsViewMode.mode === "edit"} onChange={(value) => {
                                            setIngredientPropertyDetailsViewMode((prev) => {
                                                return {
                                                    isModeChanged: true,
                                                    mode: prev.mode === "view" ? "edit" : "view"
                                                }
                                            })
                                        }}
                                        unCheckedChildren={t("common.viewMode")} checkedChildren={t("common.editMode")}
                                    /> : null
                                }
                            </div>
                        </div>
                    </div>
                    {
                        ingredientPropertyDetailsViewMode.mode === "edit" ? <>
                            <Spin spinning={ingredientPropertiesDetailsStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                                {
                                    ingredientPropertiesDetails.length === 0 ? <Empty description={t("common.noProperties")} /> :
                                        <div>
                                            <Collapse size='small'>
                                                {ingredientPropertiesDetails.map((propertyCategoryData: any) => {
                                                    return (
                                                        <Panel key={propertyCategoryData.category_id} header={propertyCategoryData.category_name}>
                                                            <Collapse
                                                                activeKey={activePropertyKeys}
                                                                size='small'
                                                                onChange={(key) => {
                                                                    if (Array.isArray(key)) {
                                                                        const inventory_property_id = key?.[key.length - 1]
                                                                        if (inventory_property_id && !activePropertyKeys.includes(inventory_property_id) && (!isReadOnly || from === "ingredients")) {
                                                                            dispatch(getIngredientPropertyValuesRequest({
                                                                                inventory_id: ingredient.inventory_id,
                                                                                inventory_property_id,
                                                                                category_id: propertyCategoryData.category_id
                                                                            }))
                                                                        }
                                                                        setActivePropertyKeys(key)
                                                                    }
                                                                }}
                                                            >
                                                                {propertyCategoryData?.properties?.map((propertyItem: any) => {
                                                                    const propertyValues = propertyItem?.property_values ?? []
                                                                    const maxPropertyParametersLength = Math.max(...propertyValues.map((propertyValue: any) => propertyValue?.parameters?.length ?? 0))

                                                                    return (
                                                                        <Panel key={propertyItem.inventory_property_id} header={propertyItem.property_name}
                                                                            extra={
                                                                                !isReadOnly ?
                                                                                    <StyledButton loading={deleteIngredientPropertyStatus[propertyItem.inventory_property_id] === AsyncStates.LOADING} danger size='small' icon={<DeleteOutlined />}
                                                                                        onClick={(e) => {
                                                                                            e.stopPropagation()
                                                                                            Modal.confirm({
                                                                                                icon: <StyledDeleteIcon />,
                                                                                                okText: t("common.confirm"),
                                                                                                cancelText: t("common.cancel"),
                                                                                                okButtonProps: {
                                                                                                    style: {
                                                                                                        background: "red"
                                                                                                    }
                                                                                                },
                                                                                                title: t("common.delete"),
                                                                                                content: `${t("common.deleteWarningMessage")} ${propertyItem.property_name} ${t("common.fromIngredient")}?`,
                                                                                                onOk: () => {
                                                                                                    dispatch(deleteIngredientPropertyRequest({
                                                                                                        inventory_id: ingredient.inventory_id,
                                                                                                        inventory_property_id: propertyItem.inventory_property_id,
                                                                                                        category_id: propertyCategoryData.category_id
                                                                                                    }))
                                                                                                },
                                                                                                onCancel: () => { }
                                                                                            })
                                                                                        }}
                                                                                    ></StyledButton> : null}
                                                                        >
                                                                            {!isReadOnly ? <StyledButton type='link' size='small' onClick={() => {
                                                                                setPropertyValueModal({
                                                                                    mode: "create",
                                                                                    isModalVisible: true,
                                                                                    data: {
                                                                                        ...propertyItem,
                                                                                        category_id: propertyCategoryData.category_id
                                                                                    }
                                                                                });
                                                                                dispatch(getPropertyMethodsListRequest({
                                                                                    inventory_id: ingredient.inventory_id,
                                                                                    property_id: propertyItem.property_id
                                                                                }));
                                                                                dispatch(fetchPropertyUnitsRequest({ property_id: propertyItem.property_id }));
                                                                            }} icon={<PlusOutlined />}>{t("common.newPropertyValue")}</StyledButton> : null
                                                                            }
                                                                            <Spin
                                                                                indicator={<LoadingOutlined />}
                                                                                spinning={ingredientPropertyValuesStatus?.[propertyItem.inventory_property_id] === AsyncStates.LOADING}>
                                                                                {!propertyValues.length ? <Empty description={t("common.noPropertyValues")} /> : propertyValues.map((propertyValue: any) => {
                                                                                    const actualPropertyParameters = propertyValue?.parameters
                                                                                    const maxPropertyParametersList: any[] = new Array(maxPropertyParametersLength).fill({
                                                                                        parameter_id: null,
                                                                                        parameter: null,
                                                                                    })
                                                                                    const propertyParametersList = stuffDummyColumns(maxPropertyParametersList, actualPropertyParameters)
                                                                                    return <Table
                                                                                        pagination={false}
                                                                                        dataSource={[propertyValue]}
                                                                                        columns={[
                                                                                            {
                                                                                                title: t("common.propertyValue"),
                                                                                                dataIndex: 'value',
                                                                                                key: 'value',
                                                                                                fixed: 'left',
                                                                                                width: "10rem",
                                                                                                render: (text: any, record: any) => {
                                                                                                    return getPropertyOrMethodValue(record)
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                title: t("common.method"),
                                                                                                dataIndex: 'method_name',
                                                                                                key: 'method_name',
                                                                                                fixed: 'left',
                                                                                                width: "10rem",
                                                                                            }, {
                                                                                                title: t("common.standard"),
                                                                                                dataIndex: 'standard',
                                                                                                key: 'standard',
                                                                                                fixed: 'left',
                                                                                                width: "10rem",
                                                                                            }, {
                                                                                                title: t("inventory.specimen"),
                                                                                                dataIndex: 'specimen',
                                                                                                key: 'specimen',
                                                                                                fixed: 'left',
                                                                                                width: "10rem",
                                                                                            },
                                                                                            ...propertyParametersList?.map((param: any, paramIndex: number) => {
                                                                                                return {
                                                                                                    title: <Text ellipsis={{ tooltip: true }}>{param.parameter ?? "--"}</Text>,
                                                                                                    dataIndex: param.parameter_id,
                                                                                                    key: param.parameter_id,
                                                                                                    width: "12.5rem",
                                                                                                    render: (text: any, record: any) => {
                                                                                                        return getPropertyOrMethodValue(param)
                                                                                                    },
                                                                                                }
                                                                                            }),
                                                                                            ...(!isReadOnly ? [{
                                                                                                title: '',
                                                                                                dataIndex: 'action',
                                                                                                key: 'action',
                                                                                                width: "3rem",
                                                                                                fixed: 'right',
                                                                                                render: (text: any, record: any) => {
                                                                                                    return <Dropdown menu={{
                                                                                                        items: getPropertyValueActionsItems({
                                                                                                            ...record, category_id: propertyCategoryData.category_id
                                                                                                        })
                                                                                                    }} placement="bottom"
                                                                                                    >
                                                                                                        <StyledButton
                                                                                                            onClick={(e) => {
                                                                                                                e.stopPropagation();
                                                                                                            }}
                                                                                                            size='small' icon={<MoreOutlined />}></StyledButton>
                                                                                                    </Dropdown>
                                                                                                }
                                                                                            }] : [])
                                                                                        ] as any[]}
                                                                                        scroll={{ x: 500 }}
                                                                                    />
                                                                                })}
                                                                            </Spin>
                                                                        </Panel>
                                                                    )
                                                                })}
                                                            </Collapse>
                                                        </Panel>
                                                    )
                                                })}
                                            </Collapse>
                                        </div>
                                }
                            </Spin>
                        </> : <>
                            {getIngredientPropertyStatus?.[ingredient?.inventory_id] === AsyncStates.ERROR && <div style={{ display: "flex", justifyContent: "space-between", padding: "0.5rem" }}>
                                <Text type="danger" strong>{t("common.errorFetchingProperties")}</Text>
                                <StyledButton
                                    onClick={() => {
                                        dispatch(getIngredientPropertiesRequest({ inventory_id: ingredient?.inventory_id }))
                                    }}>
                                    {t("common.retry")}
                                </StyledButton>
                            </div>}
                            <Spin spinning={getIngredientPropertyStatus?.[ingredient?.inventory_id] === AsyncStates.LOADING}
                                indicator={<LoadingOutlined />}
                            >
                                <div
                                    style={{
                                        overflowX: "auto",
                                    }}
                                >
                                    <InventoryIngredientPropertyDetail propertiesByCategory={ingredient?.properties ?? []} inventory_id={ingredient?.inventory_id} />
                                </div>
                            </Spin>
                        </>
                    }
                    {Boolean(ingredient?.created_via_formulation) && <>
                        <InventoryFormulationsTable ingredient={ingredient} from={from} />
                    </>}
                </div>
            </div >
            {
                propertyValueModal.isModalVisible && <InventoryNewPropertyValueModal propertyValueModal={propertyValueModal} setPropertyValueModal={setPropertyValueModal} />
            }
            {
                addPropertyModalVisible.isModalVisible && <AddPropertyToIngredientModal addPropertyModalVisible={addPropertyModalVisible} setAddPropertyModalVisible={setAddPropertyModalVisible} />
            }
        </>
    )
}