import _ from "lodash";
import { Collapse, Flex, Slider, Switch, Tag } from "antd";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { modifyWhiteObjectsRequest } from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";

type TProps = {
  showAllMasks: boolean;
  showOriginalImage: boolean;
  hoveredMaskDetails: Record<string, any>;
  setShowAllMasks: React.Dispatch<React.SetStateAction<boolean>>;
  setShowOriginalImage: React.Dispatch<React.SetStateAction<boolean>>;
  showBBox: boolean;
  setShowBBox: React.Dispatch<React.SetStateAction<boolean>>;
  area: number[] | undefined;
  setArea: React.Dispatch<React.SetStateAction<number[] | undefined>>;
};

const DEFAULT_PARAMS = {
  WHITE_COLOR_RANGE: [200, 255],
  ERODE: 0,
  DILATE: 0,
  AREA: [5, 1000],
};

const WhiteObjectAnalysisDetailsSidebar = ({
  showAllMasks,
  showOriginalImage,
  setShowAllMasks,
  setShowOriginalImage,
  showBBox,
  setShowBBox,
  area,
  setArea,
  hoveredMaskDetails,
}: TProps) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const params = useParams<any>();
  const [whiteColorRange, setWhiteColorRange] = useState(
    DEFAULT_PARAMS.WHITE_COLOR_RANGE
  );
  const [erode, setErode] = useState(DEFAULT_PARAMS.ERODE);
  const [dilate, setDilate] = useState(DEFAULT_PARAMS.DILATE);
  const [useModel, setUseModel] = useState(false);
  const { semAnalysisMaskData } = useSelector(
    (state: StoreState) => state.semAnalysis
  );

  const isAIModel = useMemo(() => {
    return semAnalysisMaskData?.approach === "AI Model";
  }, [semAnalysisMaskData]);

  const defaultParams = useMemo(() => {
    const defaultParameters = semAnalysisMaskData?.default_parameters;
    const parameters = semAnalysisMaskData?.parameters || {};

    return {
      white_color_range:
        defaultParameters?.white_color_range ||
        DEFAULT_PARAMS.WHITE_COLOR_RANGE,
      erode: defaultParameters?.erode || DEFAULT_PARAMS.ERODE,
      dilate: defaultParameters?.dilate || DEFAULT_PARAMS.DILATE,
      area: parameters?.area || defaultParameters?.area || DEFAULT_PARAMS.AREA,
    };
  }, [
    semAnalysisMaskData?.default_parameters,
    semAnalysisMaskData?.parameters,
  ]);

  const setParams = useCallback(() => {
    const parameters = semAnalysisMaskData?.parameters || {};
    setWhiteColorRange(
      parameters?.white_color_range || defaultParams.white_color_range
    );
    setErode(parameters?.erode || defaultParams.erode);
    setDilate(parameters?.dilate || defaultParams.dilate);
    setArea(
      parameters?.selected_area || parameters?.area || defaultParams.area
    );
  }, [semAnalysisMaskData?.parameters, defaultParams, setArea]);

  useEffect(() => {
    setParams();
  }, [setParams]);

  useEffect(() => {
    setUseModel(isAIModel);
  }, [isAIModel]);

  const modifyParams = () => {
    dispatch(
      modifyWhiteObjectsRequest({
        file_id: params.fileId,
        approach: "Image Processing",
        thresholds: {
          white_color_range: whiteColorRange,
          erode,
          dilate,
        },
      })
    );
  };

  const modifyArea = () => {
    dispatch(
      modifyWhiteObjectsRequest({
        file_id: params.fileId,
        approach: useModel ? "AI Model" : "Image Processing",
        thresholds: {
          area,
        },
      })
    );
  };

  const resetAll = () => {
    setParams();

    const thresholds = _.cloneDeep(defaultParams);
    delete thresholds.area;

    dispatch(
      modifyWhiteObjectsRequest({
        file_id: params.fileId,
        approach: "Image Processing",
        thresholds,
      })
    );
  };

  const toggleModelUse = (val: boolean) => {
    dispatch(
      modifyWhiteObjectsRequest({
        file_id: params.fileId,
        approach: val ? "AI Model" : "Image Processing",
        thresholds: {
          white_color_range: whiteColorRange,
          erode,
          dilate,
        },
      })
    );
    setUseModel(val);
  }

  return (
    <div className="sidebar" style={{ width: "250px" }}>
      <div className="content" style={{ width: "250px" }}>
        <div className="option">
          <div className="label">{t("sem.showOriginalImage")}</div>
          <Switch
            checkedChildren={t("common.show")}
            value={showOriginalImage}
            unCheckedChildren={t("common.hide")}
            onChange={(val: boolean) => {
              setShowOriginalImage(val);
            }}
            style={{
              outline: "none",
            }}
          />
        </div>

        <div className="option">
          <div className="label">{t("sem.showAllMasks")}</div>
          <Switch
            checkedChildren={t("common.show")}
            value={showAllMasks}
            unCheckedChildren={t("common.hide")}
            onChange={(val: boolean) => {
              setShowAllMasks(val);
            }}
            style={{
              outline: "none",
            }}
          />
        </div>

        <div className="option">
          <div className="label">{t("sem.showBoundingBox")}</div>
          <Switch
            checkedChildren={t("common.show")}
            value={showBBox}
            unCheckedChildren={t("common.hide")}
            onChange={(val: boolean) => {
              setShowBBox(val);
            }}
            style={{
              outline: "none",
            }}
          />
        </div>

        <div className="setting-collapse">
          <div className="option">
            <div className="label">{t("sem.area")}</div>
            <Slider
              range
              step={0.1}
              min={defaultParams.area[0]}
              max={defaultParams.area[1]}
              value={area}
              onChange={(val) => setArea(val)}
              style={{ width: 200, color: "white" }}
              rootClassName="sem-slider"
              tooltip={{
                formatter: (value) => (
                  <>
                    {value} {semAnalysisMaskData?.unit}
                    <sup>2</sup>
                  </>
                ),
              }}
            />
          </div>
          <div className="option" style={{ marginTop: "-20px" }}>
            <StyledButton size="small" type="primary" onClick={modifyArea}>
              {t("common.apply")}
            </StyledButton>
          </div>
        </div>

        <div className="option">
          <div className="label">{t("sem.useAIModel")}</div>
          <Switch
            checkedChildren={t("common.On")}
            value={useModel}
            unCheckedChildren={t("common.Off")}
            onChange={(val: boolean) => toggleModelUse(val)}
            style={{
              outline: "none",
            }}
          />
        </div>

        {!useModel && (<Collapse
          expandIconPosition="end"
          rootClassName="setting-collapse"
          items={[
            {
              key: "1",
              label: t("sem.advancedSettings"),
              children: (
                <>
                  <div className="option">
                    <div className="label">{t("sem.colorIntensity")}</div>
                    <Slider
                      range
                      step={1}
                      min={0}
                      max={255}
                      value={whiteColorRange}
                      onChange={(val) => setWhiteColorRange(val)}
                      style={{ width: 200 }}
                      rootClassName="sem-slider"
                    />
                  </div>

                  <div className="option">
                    <div className="label">{t("sem.erode")}</div>
                    <Slider
                      step={1}
                      min={0}
                      max={5}
                      value={erode}
                      onChange={(val) => setErode(val)}
                      style={{ width: 200, color: "white" }}
                      rootClassName="sem-slider"
                    />
                  </div>

                  <div className="option">
                    <div className="label">{t("sem.dilate")}</div>
                    <Slider
                      step={1}
                      min={0}
                      max={5}
                      value={dilate}
                      onChange={(val) => setDilate(val)}
                      style={{ width: 200, color: "white" }}
                      rootClassName="sem-slider"
                    />
                  </div>

                  <div className="option">
                    <StyledButton
                      size="small"
                      type="primary"
                      onClick={modifyParams}
                    >
                      {t("common.apply")}
                    </StyledButton>
                    <StyledButton size="small" onClick={resetAll}>
                      {t("sem.resetToDefault")}
                    </StyledButton>
                  </div>
                </>
              ),
            },
          ]}
        />)}

        {Object.entries(hoveredMaskDetails || {}).length > 0 && (<div className="multi-option">
          {Object.entries(hoveredMaskDetails).map(([key, value]) => (
            <Flex justify="space-between" key={key}>
              <div className="label">{key}</div>
              <Tag color="#424242">{value}</Tag>
            </Flex>
          ))}
        </div>)}
      </div>
    </div>
  );
};

export default memo(WhiteObjectAnalysisDetailsSidebar);
