import { createActions } from "redux-actions";
import { FormulationDatum } from "src/services/formulation/interface";

export enum LinkedFormulationsAiEngineActionTypes {
    REQUEST = "LINKED_FORMULATIONS_AI_ENGINE_REQUEST",
    SUCCESS = "LINKED_FORMULATIONS_AI_ENGINE_SUCCESS",
    FAILURE = "LINKED_FORMULATIONS_AI_ENGINE_FAILURE",
    CLEAR = "LINKED_FORMULATIONS_AI_ENGINE_CLEAR",
}

export enum FindArchivedParametersActionTypes {
    REQUEST = "FIND_ARCHIVED_PARAMETERS_REQUEST",
    SUCCESS = "FIND_ARCHIVED_PARAMETERS_SUCCESS",
    FAILURE = "FIND_ARCHIVED_PARAMETERS_FAILURE",
    CLEAR = "FIND_ARCHIVED_PARAMETERS_CLEAR",
}

export enum UpdateArchivedParametersActionTypes {
    REQUEST = "UPDATE_ARCHIVED_PARAMETERS_REQUEST",
}

export const {
    linkedFormulationsAiEngineRequest,
    linkedFormulationsAiEngineSuccess,
    linkedFormulationsAiEngineFailure,
    linkedFormulationsAiEngineClear,

    findArchivedParametersRequest,
    findArchivedParametersSuccess,
    findArchivedParametersFailure,
    findArchivedParametersClear,
    updateArchivedParametersRequest

} = createActions({
    [LinkedFormulationsAiEngineActionTypes.REQUEST]: (payload: any) => (payload),
    [LinkedFormulationsAiEngineActionTypes.SUCCESS]: (payload: FormulationDatum[]) => payload,
    [LinkedFormulationsAiEngineActionTypes.FAILURE]: (error: string) => ({ error }),
    [LinkedFormulationsAiEngineActionTypes.CLEAR]: () => { },

    [FindArchivedParametersActionTypes.REQUEST]: payload => payload,
    [FindArchivedParametersActionTypes.SUCCESS]: (payload) => payload,
    [FindArchivedParametersActionTypes.FAILURE]: (error: string) => error,
    [FindArchivedParametersActionTypes.CLEAR]: () => { },
    [UpdateArchivedParametersActionTypes.REQUEST]: payload => payload,
})